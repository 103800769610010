
import { Vue, Component } from 'vue-property-decorator'

@Component({})
export default class CheckoutValuePropositions extends Vue {
  valueProps: { icon: string; title: string; description: string }[] = [
    {
      icon: 'support_agent',
      title: '24/7 Support',
      description: 'Our award winning customer support is here for you.',
    },
    {
      icon: 'share_location',
      title: 'Live tracking',
      description: 'Real-time bus tracking from the first day of your trip.',
    },
    {
      icon: 'payments',
      title: 'Unbeatable prices',
      description: 'Largest marketplace for charter buses in the U.S.',
    },
  ]
}
