
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import pdf from '@teckel/vue-pdf'
import { hostBaseUrl } from '@/utils/env'
import { TranslateResult } from 'vue-i18n'

@Component({
  components: {
    pdf,
  },
})
export default class ViewPdfModal extends Vue {
  @Prop({ type: Boolean, required: true }) readonly value: boolean
  @Prop({ type: String }) readonly title: string
  @Prop({ type: String, default: null }) readonly fileName: string
  @Prop({ type: Boolean }) readonly fullDomain: boolean

  @Watch('fileName', { immediate: true })
  fileNameChanged(newVal: string): void {
    if (newVal) {
      this.src = pdf.createLoadingTask(this.fileName)
      this.src.promise.then((pdf) => {
        this.pageCount = pdf.numPages
      })
    }
  }

  src: any = null
  pageCount = 0

  get computedTitle(): TranslateResult {
    return this.title || this.$t('common.VIEW_PDF')
  }

  download(): void {
    const pdfLink = `${!this.fullDomain ? hostBaseUrl() : ''}${this.fileName}`
    window.open(pdfLink, '_blank')
  }

  emitInput(event: boolean): void {
    if (!event) {
      this.src = null
    }
    this.$emit('input', event)
  }
}
