import { Role } from '@/models/dto'

/**
 * Finds if a given permission exists in an array of roles.
 *
 * @param roles - An array of roles.
 * @param name - The name of the permission to search for.
 * @returns True if the permission exists in at least one of the roles, false otherwise.
 */
export const findPermissionByName = (roles: Role[], name: string): boolean => {
  let hasPermission = false
  if (roles) {
    for (const role of roles) {
      for (const permission of role.permissions) {
        if (permission.permissionName === name) {
          hasPermission = true
        }
      }
    }
  }
  return hasPermission
}

/**
 * Finds if a given permission exists in an array of roles.
 *
 * @param roles - An array of roles.
 * @param id - The ID of the permission to search for.
 * @returns True if the permission exists in at least one of the roles, false otherwise.
 */
export const findPermissionById = (roles: Role[], id: number): boolean => {
  let hasPermission = false
  if (roles) {
    for (const role of roles) {
      for (const permission of role.permissions) {
        if (permission.permissionId === id) {
          hasPermission = true
        }
      }
    }
  }
  return hasPermission
}
