var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:{ 'w-full-vw': _vm.$vuetify.breakpoint.xsOnly }},[_c('v-row',{staticClass:"team-row",attrs:{"no-gutters":"","align":"center"},on:{"click":_vm.handleExpandClick}},[_c('v-col',{staticClass:"shrink"},[_c('v-row',{style:({ width: 'max-content' }),attrs:{"no-gutters":"","align":"center"}},[(_vm.team.lightLogoUrl)?_c('v-col',{attrs:{"cols":"auto"}},[_c('img',{staticClass:"margin-r-2 border-radius-round",class:{
              'mobile-profile-icon margin-y-2 margin-l-3 border-radius-round':
                _vm.$vuetify.breakpoint.xsOnly,
            },attrs:{"width":_vm.$vuetify.breakpoint.xsOnly ? 32 : 42,"src":_vm.team.lightLogoUrl}})]):_vm._e(),_c('v-col',{staticClass:"shrink white-space-nowrap"},[_c('p',{staticClass:"margin-a-0 font-medium font-20",style:(!_vm.team.lightLogoUrl
                ? _vm.$vuetify.breakpoint.smAndUp
                  ? 'margin-left: 17px;'
                  : 'margin-left: 12px;'
                : '')},[_vm._v(" "+_vm._s(_vm.team.name)+" ")])])],1)],1),(_vm.$vuetify.breakpoint.xsOnly)?_c('v-col',{style:({ 'margin-bottom': '-6px' })},[_c('OrganizationTeamActionsMenu',{attrs:{"index":_vm.index,"team":_vm.team,"teams":_vm.teams},on:{"upload-image":function($event){return _vm.$emit('upload-image', $event)}}})],1):_vm._e(),(_vm.$vuetify.breakpoint.smAndUp)?_c('v-col',{attrs:{"cols":"auto"}},[_c('OrganizationTeamActionsMenu',{attrs:{"index":_vm.index,"team":_vm.team,"teams":_vm.teams},on:{"upload-image":function($event){return _vm.$emit('upload-image', $event)},"success":function($event){return _vm.$emit('success')}}})],1):_vm._e(),(_vm.$vuetify.breakpoint.xsOnly)?_c('v-col',{attrs:{"cols":"auto"}},[_c('CUIcon',{staticClass:"mobile-expansion-arrow margin-r-2 cursor-pointer",class:{
          flipped: _vm.expanded,
        },attrs:{"view-box":"0 0 24 24","color":"gray"}},[_vm._v(" keyboard_arrow_down ")])],1):_vm._e()],1),_c('v-expand-transition',[(!_vm.$vuetify.breakpoint.xsOnly || _vm.expanded)?_c('CUDataTable',_vm._g({attrs:{"columns":_vm.columns,"items":_vm.items,"loading":_vm.loading,"item-key":"customerId","no-data-text":"No users found","dense":"","options":_vm.tableOptions,"server-items-length":_vm.itemsLength,"hide-default-header":_vm.$vuetify.breakpoint.xs,"mobile-view-on-breakpoint":"xs"},on:{"update:options":function($event){_vm.tableOptions=$event},"refresh":_vm.updateTable},scopedSlots:_vm._u([{key:"mobileLayout",fn:function({ item }){return [_c('tr',{style:({ height: 'inherit' })},[_c('td',[_c('div',{staticClass:"d-flex flex-column font-16 font-medium margin-y-0 padding-x-2 padding-y-2"},[_c('p',{staticClass:"margin-t-0"},[_vm._v(_vm._s(item.name))]),_c('p',{staticClass:"font-14 margin-t-0"},[_vm._v(_vm._s(item.email))]),_c('p',{staticClass:"font-14 margin-t-0"},[_vm._v(" "+_vm._s(item.title ? `${item.title} | ` : '')+_vm._s(item.role)+" ")]),(item.isSignedUp)?_c('div',{staticClass:"font-14"},[(item.sentEmailStatus === 'unsent')?_c('a',{staticClass:"font-bold",on:{"click":function($event){return _vm.resendInvite(item)}}},[_vm._v(" Resend Invitation ")]):(item.sentEmailStatus === 'sending')?_c('v-progress-circular',{attrs:{"size":15,"width":2,"indeterminate":"","color":"primary"}}):(item.sentEmailStatus === 'sent')?_c('p',{staticClass:"text-primary margin-a-0"},[_vm._v(" Sent! ")]):_vm._e()],1):_vm._e()])])])]}}],null,false,620617770)},_vm.$listeners)):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }