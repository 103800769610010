
import { Vue, Component } from 'vue-property-decorator'
import StickyColumnLayout from '@/layouts/StickyColumnLayout.vue'
import SkeletonTripCollectionCard from '@/components/SkeletonTripCollectionCard.vue'
import SkeletonBox from '@/components/SkeletonBox.vue'

@Component({
  components: {
    StickyColumnLayout,
    SkeletonTripCollectionCard,
    SkeletonBox,
  },
})
export default class ProviderInfoSkeleton extends Vue {}
