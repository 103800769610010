/**
 * Custom plugin for Google Analytics 4 (GA4)
 * $ga4Event() can be used globally to track custom events (e.g., button click)
 * $ga4Event() takes an event name (e.g., 'view_quote') and any event parameters (e.g., quoteId)
 */
export default {
  install(Vue) {
    Vue.prototype.$dataLayer = window.dataLayer || []
    // Some events do not require any event params. In that case, pass in `undefined`.
    // E.g., this.$ga4Event('login', undefined)
    Vue.prototype.$ga4Event = function (name: string, params?: any) {
      this.$dataLayer.push({
        event: name,
        ...params,
      })
    }
  },
}
