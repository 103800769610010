
import { Vue, Component, Prop } from 'vue-property-decorator'
import {
  SpecialRequirementsTypeKey,
  SpecialRequirementsTypeLabel,
} from '@/utils/enum'

@Component({})
export default class TripRequirements extends Vue {
  @Prop({ type: Boolean, default: false }) readonly ada: boolean
  @Prop({ type: Boolean, default: false }) readonly spab: boolean
  @Prop({ type: Boolean, default: false })
  readonly vehicleNeededEntireTrip: boolean

  SpecialRequirementsTypeKey = SpecialRequirementsTypeKey

  get selectedTripRequirements(): Array<{
    icon: string
    title: string
    key: string
  }> {
    return [
      {
        icon: 'bus_alert',
        title: SpecialRequirementsTypeLabel.VehicleNeededEntireTrip,
        condition: this.vehicleNeededEntireTrip,
        key: SpecialRequirementsTypeKey.VehicleNeededEntireTrip,
      },
      {
        icon: 'accessible',
        title: SpecialRequirementsTypeLabel.ADA,
        condition: this.ada,
        key: SpecialRequirementsTypeKey.ADA,
      },
      {
        icon: 'school',
        title: SpecialRequirementsTypeLabel.SPAB,
        condition: this.spab,
        key: SpecialRequirementsTypeKey.SPAB,
      },
    ]
      .filter(({ condition }) => condition)
      .map(({ icon, title, key }) => {
        return { icon, title, key }
      })
  }
}
