
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import auth from '@/store/modules/auth'
import support from '@/store/modules/support'
import CharterUPLogo from '@/icons/CharterUPLogo.vue'
import { NavigationItem } from '@/models/NavigationItem'
import sidebar from '../store/modules/sidebar'
import quotes from '../store/modules/quotes'

@Component({
  components: {
    CharterUPLogo,
  },
})
export default class TheSidebar extends Vue {
  @Prop({ type: Array, default: () => [] }) readonly menuItems: NavigationItem[]

  @Watch('$vuetify.breakpoint.smAndDown')
  onBreakpointChange(value: boolean): void {
    if (value) {
      return
    }
    sidebar.setIsOpen(false)
  }

  listModel = 1
  auth = auth
  support = support
  sidebar = sidebar
  quotes = quotes

  get userEmail(): string {
    return auth.user?.email
  }

  get userDisplayName(): string {
    const name = `${auth.user?.firstName || ''} ${auth.user?.lastName || ''}`
    return name.length > 1 ? name : auth.user?.email
  }

  handleNavigationItemClick(item: NavigationItem): void {
    if (item.link) {
      this.$router.push({ name: item.link })
    } else if (item.action) {
      item.action()
    }
  }
}
