
import { DateTime } from 'luxon'
import { Address } from '@/models/dto'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class EnterpriseTrackingSidebarItineraryCard extends Vue {
  @Prop({ type: Object, required: true }) readonly location: any
  @Prop({ type: String, default: null }) readonly nextStopTime: string
  @Prop({ type: Boolean }) readonly hideExpand: boolean
  @Prop({ type: Number, required: true })
  readonly totalPickupAndDropoffCount: number

  expanded = true

  get locationTitle(): string {
    return this.location?.address?.title || this.addressDisplayText
  }
  get addressDisplayText(): string {
    if (this.location?.address?.name) {
      return this.location.address.name
    }
    const address = this.location.address
    let addressString = address.street1 !== ' ' ? `${address.street1},` : ''
    addressString = address?.street2
      ? `${addressString} ${address.street2},`
      : addressString
    addressString = `${addressString} ${address.city}, ${address.state}`
    return addressString
  }
  get timesLabel(): string {
    if (this.totalPickupAndDropoffCount === 2) {
      return this.location.dropoffTimes.length
        ? 'Scheduled End'
        : 'Scheduled Start'
    }
    return 'Scheduled Times'
  }
  get times(): any[] {
    const mappedDropoffTimes = this.location.dropoffTimes.map((time) => {
      return { timestamp: time, label: 'DROP' }
    })
    const mappedPickupTimes = this.location.pickupTimes.map((time) => {
      return { timestamp: time, label: 'PICK' }
    })
    const times = [...mappedDropoffTimes, ...mappedPickupTimes]
    return times.sort(
      (a, b) =>
        new DateTime.fromISO(a.timestamp) - new DateTime.fromISO(b.timestamp)
    )
  }

  formatStopItineraryTime(timestamp: string, address: Address): void {
    return DateTime.fromISO(timestamp, {
      zone: address.timeZone,
    }).toFormat('h:mm a')
  }
}
