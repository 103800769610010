
import { Vue, Component, Prop } from 'vue-property-decorator'
import OrganizationNewTeamDialog from '@/components/OrganizationNewTeamDialog.vue'
import { Team } from '@/models/dto'
@Component({
  components: {
    OrganizationNewTeamDialog,
  },
})
export default class OrganizationTeamsActionsMenu extends Vue {
  @Prop({ type: Boolean }) readonly isEnterpriseAccount: boolean
  @Prop({ type: Array, required: true }) readonly teams: Team[]

  index = 0
  team: Team = null
  teamOptionsMenuOpen = false
}
