
import { Vue, Component, Prop } from 'vue-property-decorator'
import TwoColumnLayout from '@/layouts/TwoColumnLayout.vue'
import { ReservationDetail, ClientCancellationPenaltyInfo } from '@/models/dto'
import ReservationCancellationSummary from './ReservationCancellationSummary.vue'
import auth from '@/store/modules/auth'
import { getSupportNumber, phoneFormatFilter } from '@/utils/phone'

@Component({ components: { TwoColumnLayout, ReservationCancellationSummary } })
export default class ReservationCancelComplete extends Vue {
  @Prop({ type: Number, required: true }) readonly reservation: ReservationDetail
  @Prop({ type: Object, required: true })
  readonly penaltyInfo!: ClientCancellationPenaltyInfo
  @Prop({ type: String, required: true })
  readonly header: string

  get customerEmail(): string {
    return this.reservation?.customer?.email
  }

  get supportNumber(): string {
    return getSupportNumber(auth.customer.isElite)
  }

  get formattedSupportNumber(): string {
    return phoneFormatFilter(this.supportNumber)
  }

  get isRefundDue(): boolean {
    return this.penaltyInfo?.balanceDue < 0
  }

}
