
import { Vue, Component, Prop, InjectReactive } from 'vue-property-decorator'
import TripReviewItinerary from '@/components/TripReviewItinerary.vue'
import TripReviewRequirements from '@/components/TripReviewRequirements.vue'
import TripReviewMetadata from '@/components/TripReviewMetadata.vue'
import tripModificationStore from '@/store/modules/tripModification'
import { TripModificationTrip, ModifiedTrip } from '@/models/dto'

@Component({
  components: {
    TripReviewItinerary,
    TripReviewRequirements,
    TripReviewMetadata,
  },
})
export default class TripModificationReview extends Vue {
  @InjectReactive('removePadding') removePadding

  get currentTrip(): TripModificationTrip {
    return tripModificationStore.currentTrip
  }

  get requestedTripForReview(): ModifiedTrip {
    return tripModificationStore.requestedTripForReview
  }

  get changesMade(): boolean {
    const requirementsModified =
      this.requestedTripForReview.adaChanged ||
      this.requestedTripForReview.spabChanged ||
      this.requestedTripForReview.vehicleNeededEntireTripChanged
    const metadataModified =
      this.requestedTripForReview.passengerCountChanged ||
      this.requestedTripForReview.tripTypeChanged ||
      this.requestedTripForReview.vehiclesChanged
    const stopsModified = this.requestedTripForReview.stops.some(
      (stop) => stop.stopChanged
    )
    return requirementsModified || metadataModified || stopsModified
  }

  get isMobile(): boolean {
    return this.$vuetify.breakpoint.xsOnly
  }
}
