
import { Vue, Prop, Component } from 'vue-property-decorator'
import SharedTrip from '@/models/dto/SharedTrip'

@Component({})
export default class SharedTripsModalReservationColumn extends Vue {
  @Prop({ required: true }) readonly row: SharedTrip

  navigateToReservation(): void {
    this.$router.push({
      name: 'reservation-detail',
      params: {
        id: this.row.reservationId.toString(),
      },
    })
  }
}
