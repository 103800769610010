import { Action, Module, VuexModule } from 'vuex-class-modules'
import store from '@/store/index'
import { ConfettiSettings } from '@/models/Confetti'

const DEFAULT_SETTINGS: ConfettiSettings = {
  width: 6,
  length: 50,
  colors: ['#00a6f2', '#00dd9c', '#ffffff'],
  iterations: 1,
  duration: 5,
  count: 750,
  minimumOpacity: 0.8,
  xMovementThreshold: 25,
}

@Module({ generateMutationSetters: true })
class ConfettiModule extends VuexModule {
  _show = false
  _settings: ConfettiSettings | null = null

  /**
   * Whether the confetti should be shown or not.
   */
  get show(): boolean {
    return this._show
  }

  /**
   * The current settings for the confetti.
   */
  get settings(): ConfettiSettings {
    return this._settings
  }

  /**
   * Sets the confetti settings and starts the confetti animation.
   * @param settings - The confetti settings.
   */
  @Action
  setShow(settings: ConfettiSettings = DEFAULT_SETTINGS) {
    this.setSettings(settings)
    this._show = true
    const duration = settings?.duration || DEFAULT_SETTINGS.duration
    setTimeout(() => {
      this._show = false
    }, (duration + 0.5) * 1000)
  }

  /**
   * Sets the confetti settings.
   * @param settings - The confetti settings.
   */
  @Action
  setSettings(settings: ConfettiSettings) {
    if (!settings) {
      settings = DEFAULT_SETTINGS
    } else {
      for (const [key, value] of Object.entries(DEFAULT_SETTINGS)) {
        if (!settings.hasOwnProperty(key)) {
          settings[key] = value
        }
      }
    }
    this._settings = settings
  }
}

export default new ConfettiModule({ store, name: 'confetti' })
