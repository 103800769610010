
import { TableViewQuote } from '@/models/dto'
import { PricingMethod } from '@/utils/enum'
import { EventBus } from '@/utils/eventBus'
import { currencyFilter } from '@/utils/string'
import { TranslateResult } from 'vue-i18n'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { QuoteDetailV2, QuoteDetailV2Bid } from '../models/dto/QuoteDetailV2'

@Component({})
export default class QuoteIndexPrice extends Vue {
  @Prop({ required: true }) readonly row: TableViewQuote

  get isSelfServe(): boolean {
    return !!this.row?.isSelfServe
  }

  get quoteDetail(): QuoteDetailV2 {
    return this.row?.quoteDetail
  }

  get isExpired(): boolean {
    const expiration = this.row?.expirationDate
    if (!expiration) {
      return true
    }
    const now = new Date()
    const thenDate = new Date(`${expiration.split('+')[0]}Z`)
    return now.getTime() - thenDate.getTime() > 0
  }

  get bid(): QuoteDetailV2Bid {
    if (this.quoteDetail?.pricingMethod === PricingMethod.Bids) {
      return null
    }
    return this.quoteDetail?.bids?.[0] || null
  }

  get price(): string | TranslateResult {
    if (this.isSelfServe && !this.quoteDetail) {
      return this.$t('quotesTable.EXPIRED')
    }
    return this.getPrice(
      this.bid,
      this.isExpired,
      this.row.salesWillCall,
      this.row.promptCall,
      this.row.promptBASCall
    )
  }

  get salesWillCall(): boolean {
    return !!this.row?.salesWillCall
  }

  get promptCall(): boolean {
    return !!this.row?.promptCall
  }

  get promptBASCall(): boolean {
    return !!this.row?.promptBASCall
  }

  getPrice(
    bid: QuoteDetailV2Bid,
    isExpired = false,
    salesWillCall = false,
    promptCall = false,
    promptBASCall = false
  ): string | TranslateResult {
    const rawPrice = bid?.totalAmount
    if (rawPrice) {
      return currencyFilter(rawPrice)
    }

    if (!this.row?.isCharterUpQuote && this?.row?.amount) {
      return currencyFilter(this.row.amount)
    }

    if (isExpired) {
      return this.$t('quotesTable.EXPIRED')
    }

    if (salesWillCall || promptCall || promptBASCall) {
      return this.$t('common.MORE_INFO')
    }

    return this.$t('quotesTable.VIEW_PRICES')
  }

  openDialog(): void {
    if (this.promptCall) {
      EventBus.$emit('open-prompt-call-dialog')
      return
    }
    if (this.salesWillCall) {
      EventBus.$emit('open-sales-will-call-dialog')
      return
    }
    if (this.promptBASCall) {
      EventBus.$emit('open-prompt-call-for-bas-dialog')
      return
    }
  }
}
