
import {
  Customer,
  InviteCustomerPayload,
  UpdateTripPayload,
} from '@/models/dto'
import deepClone from '@/utils/deepClone'
import {
  formatReservationPickupDestinationText,
  validateEmailAddress,
  getInitials,
} from '@/utils/string'
import { Vue, Component, Prop, Watch, Inject } from 'vue-property-decorator'
import { DateTime } from 'luxon'
import trip from '@/services/trip'
import CustomerInviteSearch from '@/components/CustomerInviteSearch.vue'
import CustomerInviteForm from '@/components/CustomerInviteForm.vue'
import reservation from '@/services/reservation'
import customer from '@/services/customer'
import user from '@/services/user'
import { ReservationDetail } from '../models/dto/ReservationDetail'

@Component({ components: { CustomerInviteSearch, CustomerInviteForm } })
export default class ReservationDetailTripContactForm extends Vue {
  @Inject({ from: 'isInPostConversionDialog', default: false })
  readonly isInPostConversionDialog: boolean
  @Prop({ type: Array, required: true })
  readonly reservations: ReservationDetail[]
  @Prop({ type: Array, required: true }) readonly sharedList: Customer[]
  @Prop({ type: Boolean, default: false }) readonly closeable: boolean
  @Prop({ type: Boolean }) readonly showTripName: boolean

  @Watch('currentReservation', { deep: true, immediate: true })
  currentReservationChanged(): void {
    this.reservation = deepClone(this.currentReservation)
    const routeName = this.reservation?.routeName
    this.routeName = routeName ? `${routeName}` : ''
  }

  @Watch('submitSuccess')
  submitSuccessChanged(value: boolean): void {
    if (!value) {
      return
    }
    this.goToNextTrip()
  }

  reservation: ReservationDetail = null
  index = 0
  showInviteForm = false
  routeName = ''

  tripNameSubmitting = false
  tripContactSubmitting = false
  tripNameSuccess = false
  tripContactSuccess = false
  inviteEmail = ''
  inviteErrorMessages = []

  showTripNameInput = false
  validEmailField = true

  mounted() {
    this.showTripNameInput = this.showTripName
  }

  get submitting(): boolean {
    return this.tripNameSubmitting || this.tripContactSubmitting
  }

  get showButton(): boolean {
    return this.showInviteForm || this.showTripNameInput
  }

  get currentReservation(): ReservationDetail {
    if (!(this.reservations && this.reservations.length)) {
      return null
    }
    return this.reservations[this.index]
  }

  get hasTripContact(): boolean {
    if (!this.reservation) {
      return false
    }
    return !!this.reservation.tripContact?.customerId
  }

  get tripContactInitials(): string {
    if (!this.hasTripContact) {
      return null
    }
    return (
      getInitials(
        this.reservation.tripContact?.firstName,
        this.reservation.tripContact?.lastName
      ) ||
      this.reservation.tripContact?.email?.[0]?.toUpperCase() ||
      ''
    )
  }

  get pickupDropoffText(): string {
    if (!this.reservation) {
      return ''
    }
    return formatReservationPickupDestinationText(
      this.reservation,
      this.$t('common.TO')
    )
  }

  get pickupDatetimeText(): string {
    const firstStop = this.reservation?.stops?.[0]
    const pickupDatetime = firstStop?.pickupDatetime
    const timeZone = firstStop?.address?.timeZone
    if (!pickupDatetime || !timeZone) {
      return ''
    }
    return DateTime.fromISO(pickupDatetime, { zone: timeZone }).toFormat(
      'LL/dd/yy - hh:mm a'
    )
  }

  get tripCount(): number {
    return this.reservations?.length
  }

  get tripCountOfTotalText(): string {
    const currentTrip = this.index + 1
    return `Trip ${currentTrip} of ${this.tripCount}`
  }

  get phoneRaw(): string {
    const phone = this.reservation.tripContact?.phone
    if (!phone) {
      return ''
    }
    return phone.replace(/\D/g, '')
  }

  get submitSuccess(): boolean {
    return (
      this.tripContactSuccess &&
      (!this.showTripNameInput || this.tripNameSuccess)
    )
  }

  stopSubmitting(): void {
    this.tripNameSubmitting = false
    this.tripContactSubmitting = false
  }

  close(): void {
    this.$emit('close')
  }

  goToNextTrip(): void {
    if (this.index < this.reservations.length - 1) {
      this.index++
      this.setSuccessFalse()
    } else {
      this.$emit('confirm')
    }
  }

  setSuccessFalse(): void {
    this.tripNameSuccess = false
    this.tripContactSuccess = false
  }

  showForm(email: string): void {
    this.showInviteForm = true
    this.showTripNameInput = false
    this.inviteEmail = email
  }

  hideForm(): void {
    this.showInviteForm = false
    this.showTripNameInput = this.showTripName
  }

  async editTripContact(customerId: number): Promise<void> {
    await trip.editTripContact(this.reservation.tripId, {
      tripContact: { customerId },
    })
    if (this.isInPostConversionDialog) {
      this.refreshReservation()
    }
    this.$emit('refresh')
  }

  async refreshReservation(): Promise<void> {
    const reservationResponse = await reservation.byId(
      this.reservation.reservationId
    )
    this.reservation = reservationResponse.data.data
  }

  validateEmail(email: string): boolean {
    this.inviteErrorMessages = []
    const valid = validateEmailAddress(email)
    if (!valid) {
      this.inviteErrorMessages.push('Please enter a valid email.')
      return false
    }
    return true
  }

  async startSubmit(): Promise<void> {
    const inviteSearch = this.$refs['customerInviteSearch'] as any
    const inviteEmail = inviteSearch ? inviteSearch.search : null
    if (inviteEmail) {
      this.tripContactSubmitting = true
      if (!this.showInviteForm) {
        await this.submitCustomer({
          email: inviteEmail,
          firstName: null,
          lastName: null,
          phone: null,
        })
      }
    } else {
      //empty email field is valid
      this.validEmailField = true
    }

    if (this.routeName && this.validEmailField) {
      this.tripNameSubmitting = true
      this.submitRouteName()
    } else if (this.validEmailField && !inviteEmail) {
      //no email and no trip name should allow continue
      this.$emit('confirm')
    }
  }

  handleTripContactSubmit(success: boolean): void {
    this.tripContactSubmitting = false
    this.tripContactSuccess = success
  }

  async submitRouteName(): Promise<void> {
    this.tripNameSubmitting = true
    if (this.showTripNameInput) {
      const payload: Partial<UpdateTripPayload> = {
        routeName: this.routeName,
      }
      try {
        await trip.editRouteName(
          this.reservation.tripId,
          payload as UpdateTripPayload
        )
        this.tripNameSuccess = true
        this.$emit('confirm')
      } catch (error) {
        console.error(error)
      }
    }
    this.tripNameSubmitting = false
  }

  async submitCustomer(payload: InviteCustomerPayload): Promise<void> {
    const valid = this.validateEmail(payload.email)
    if (!valid && payload.email) {
      this.validEmailField = false
      this.tripContactSubmitting = false
      return
    }
    this.validEmailField = true

    const response = await user.checkEmail(payload.email)
    const userExists = response?.data?.exists
    if (!userExists && !this.showInviteForm) {
      this.handleTripContactSubmit(false)
      this.showForm(payload.email)
      return
    }

    if (
      this.showInviteForm &&
      (!payload.firstName || !payload.lastName || !payload.phone)
    ) {
      return
    }

    try {
      const customerResponse = await customer.inviteByReservationId(
        this.reservation.reservationId,
        payload,
        true
      )
      const customerId = customerResponse.data.customer.userId
      await this.editTripContact(customerId)
      this.handleTripContactSubmit(true)
      this.hideForm()
      if (!this.isInPostConversionDialog) {
        this.$emit('close')
      }
    } catch (error) {
      console.error(error)
      this.handleTripContactSubmit(false)
    }
  }
}
