import { Address, Stop, Trip } from '@/models/dto'
import {
  doTimestampsSpanMultipleYears,
  formatStopTimeV2,
  getMinimizedFormattedTimeBetween,
} from './datetime'
import { ItineraryStopTypeKey } from './enum'
import { formatCityStateZip, formatStreetAddress } from './address'
import { ItineraryStop } from '../models/ItineraryStop'

const ICON_MAP = {
  stop: 'trip_stop',
  dropoff: 'trip_finish',
  wait: 'place',
  pickup: 'trip_origin',
  travel: 'arrow_downward',
}

const ICON_COLOR_MAP = {
  stop: 'cup-500',
  dropoff: 'cup-500',
  wait: 'midnight-gray-300',
  pickup: 'cup-500',
  travel: 'midnight-gray-300',
}

/**
 * Returns an array of ItineraryStop objects based on the input array of Trip objects.
 * @param trips - An array of Trip objects.
 * @returns An array of ItineraryStop objects.
 */
export const getTripItineraryStops = (trip: Trip): ItineraryStop[] => {
  if (!trip) {
    return []
  }
  const allStops = getAllStopsFromTrip(trip)
  const multiYearTrip = doStopsSpanMultipleYears(allStops)
  const itineraryStopTypes = createItineraryStopTypes(allStops)
  const vehicleStaysOnSite = trip.vehicleNeededEntireTrip

  return itineraryStopTypes.map(({ stop, type }, i) =>
    createItineraryStop(
      type,
      stop,
      i,
      itineraryStopTypes,
      multiYearTrip,
      vehicleStaysOnSite
    )
  )
}

/**
 * Creates and returns an ItineraryStop object.
 * @param type - The type of the itinerary stop.
 * @param stop - The Stop object containing details of the stop.
 * @param index - The index of the stop in the itinerary.
 * @param allItineraryStopTypes - An array of all itinerary stop types.
 * @param multiYearTrip - A boolean indicating if the trip spans multiple years.
 * @returns An ItineraryStop object.
 */
const createItineraryStop = (
  type: ItineraryStopTypeKey,
  stop: Stop,
  index: number,
  allItineraryStopTypes: {
    type: ItineraryStopTypeKey
    stop: Stop
  }[],
  multiYearTrip: boolean,
  vehicleStaysOnSite: boolean
): ItineraryStop => {
  const nextItineraryStop = allItineraryStopTypes[index + 1] || null
  const formattedTime = getformattedStopTimeObject(stop, type)
  const itineraryStop: ItineraryStop = {
    duration: getDuration(type, stop, nextItineraryStop?.stop),
    icon: ICON_MAP[type] || '',
    iconColor: ICON_COLOR_MAP[type] || '',
    year: multiYearTrip ? formattedTime.year : '',
    label: getLabel(type, stop),
    type,
    dashedLine:
      type === ItineraryStopTypeKey.Stop || type === ItineraryStopTypeKey.Wait,
    noTopGap: nextItineraryStop?.type === ItineraryStopTypeKey.Travel,
    vehicleStaysOnSite:
      type === ItineraryStopTypeKey.Wait && vehicleStaysOnSite,
  }

  if (
    ![ItineraryStopTypeKey.Wait, ItineraryStopTypeKey.Travel].includes(type)
  ) {
    Object.assign(itineraryStop, {
      time: formattedTime.time,
      date: formattedTime.date,
    })
    itineraryStop.formattedAddress = getFormattedAddress(stop.address)
  }

  return itineraryStop
}

/**
 * Filters, sorts, and order marks all stops from a Trip objects and returns them.
 * @param trip - A Trip object.
 * @returns An array of Stop objects.
 */
const getAllStopsFromTrip = (trip: Trip): Stop[] => {
  const activeStops: Stop[] = trip?.stops?.filter(({ active }) => active)
  return activeStops.map((stop, orderIndex) => {
    return {
      ...stop,
      orderIndex,
    }
  })
}

const doStopsSpanMultipleYears = (stops: Stop[]): boolean => {
  const firstStop = stops[0]
  const lastStop = stops[stops.length - 1]
  return doTimestampsSpanMultipleYears(
    firstStop.pickupDatetime || firstStop.dropoffDatetime,
    firstStop.address.timeZone,
    lastStop.pickupDatetime || lastStop.dropoffDatetime,
    lastStop.address.timeZone
  )
}

const createItineraryStopTypes = (
  stops: Stop[]
): { type: ItineraryStopTypeKey; stop: Stop }[] => {
  let itineraryStops = []

  for (const [i, stop] of stops.entries()) {
    const nextStop = stops[i + 1]
    itineraryStops = [
      ...itineraryStops,
      ...generateItineraryStopTypesByStop(stop, nextStop),
    ]
  }

  return itineraryStops
}

const generateItineraryStopTypesByStop = (
  stop: Stop,
  nextStop: Stop
): { type: ItineraryStopTypeKey; stop: Stop }[] => {
  const itineraryStopTypes = []

  const hasPickup = !!stop.pickupDatetime
  const hasDropoff = !!stop.dropoffDatetime

  const addItineraryStopType = (type, stop) =>
    itineraryStopTypes.push({ type, stop })

  if (hasDropoff) {
    addItineraryStopType(
      hasPickup && hasDropoff
        ? ItineraryStopTypeKey.Stop
        : ItineraryStopTypeKey.Dropoff,
      stop
    )
  }

  if (hasPickup && hasDropoff) {
    addItineraryStopType(ItineraryStopTypeKey.Wait, stop)
  }

  if (hasPickup) {
    addItineraryStopType(ItineraryStopTypeKey.Pickup, stop)
  }

  if (nextStop) {
    addItineraryStopType(ItineraryStopTypeKey.Travel, stop)
  }

  return itineraryStopTypes
}

const getLabel = (type: ItineraryStopTypeKey, stop: Stop): string => {
  const { address } = stop

  if (type === ItineraryStopTypeKey.Travel) {
    return ''
  }

  const labelMap = {
    stop: 'Stop',
    dropoff: 'Dropoff',
    wait: 'Stays',
    pickup: 'Pickup',
  }

  const prefix = `${labelMap[type]} at`

  if (type === ItineraryStopTypeKey.Wait) {
    return `${prefix} the destination`
  }

  const location =
    getLocation(address) ||
    address.street1 ||
    formatCityStateZip(address)?.replace(/[0-9]/g, '')
  return `${prefix} ${location}`.trim()
}

const getLocation = (address: Address): string => {
  return address?.title || ''
}

const getFormattedAddress = (address: Address): string => {
  const streetAddress = formatStreetAddress(address)
  const cityStateZip = formatCityStateZip(address)
  const country = address?.country

  return [streetAddress, cityStateZip, country].filter(Boolean).join(', ')
}

const getDuration = (
  itineraryStopType: ItineraryStopTypeKey,
  stop: Stop,
  nextStop: Stop
): string => {
  if (itineraryStopType === ItineraryStopTypeKey.Wait) {
    return getMinimizedFormattedTimeBetween(
      stop.dropoffDatetime,
      stop.pickupDatetime
    )
  }

  if (itineraryStopType === ItineraryStopTypeKey.Travel) {
    return getMinimizedFormattedTimeBetween(
      stop.pickupDatetime,
      nextStop ? nextStop.dropoffDatetime : null
    )
  }

  return ''
}

const getformattedStopTimeObject = (
  stop: Stop,
  type: ItineraryStopTypeKey
): { date: string; time: string; year: string } => {
  const time =
    type === ItineraryStopTypeKey.Pickup
      ? stop.pickupDatetime
      : stop.dropoffDatetime
  return formatStopTimeV2(time, stop.address.timeZone)
}
