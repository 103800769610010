
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

@Component({})
export default class CUDatePicker extends Vue {
  @Prop({ type: String, required: false, default: '' }) readonly label: string
  @Prop({ type: String, required: false, default: '' }) readonly id: string
  @Prop({ type: Array, required: false, default: () => [] })
  readonly rules: any[]
  @Prop({ type: Boolean }) readonly hideDetails: boolean
  @Prop({ type: String, required: true }) readonly value: string
  @Prop({ type: String, required: false }) readonly displayValue: string
  @Prop({ type: String, required: false }) readonly min: string
  @Prop({ type: Boolean }) readonly top: boolean
  @Prop({ type: Boolean }) readonly bottom: boolean
  @Prop({ type: Boolean }) readonly right: boolean
  @Prop({ type: Boolean }) readonly left: boolean
  @Prop({ type: String, required: false }) readonly origin: string

  @Watch('value')
  valueChanged(): void {
    this.isOpen = false
  }

  get inputDisplayText(): string {
    return this.displayValue || this.value
  }

  isOpen = false
}
