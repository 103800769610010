
import { Vue, Component, Prop } from 'vue-property-decorator'
import rider from '@/services/rider'
import { CustomerAccount } from '@/models/dto'

@Component({})
export default class ImportActionsDropdown extends Vue {
  uploadReady = true

  @Prop({ type: Object }) readonly customerAccount: CustomerAccount

  importAll(): void {
    if (!this.uploadReady) {
      return
    }
    const inputElement = document.getElementById('importAllInput')
    inputElement.click()
  }

  importUpdate(): void {
    if (!this.uploadReady) {
      return
    }
    const inputElement = document.getElementById('importUpdateInput')
    inputElement.click()
  }

  importAllSubmit(event: Event): void {
    const target = event.target as HTMLInputElement
    const formData = new FormData()
    formData.set('file', target.files[0])
    rider.import(this.customerAccount.customerAccountId, formData, true)
    this.uploadReady = false
    this.$nextTick(() => {
      this.uploadReady = true
    })
    this.$emit('open-import-dialog')
  }

  importUpdateSubmit(event: Event): void {
    const target = event.target as HTMLInputElement
    const formData = new FormData()
    formData.set('file', target.files[0])
    rider.import(this.customerAccount.customerAccountId, formData)
    this.uploadReady = false
    this.$nextTick(() => {
      this.uploadReady = true
    })
    this.$emit('open-import-dialog')
  }
}
