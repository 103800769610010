import RouteConfigWithMeta from '@/models/RouteConfig'

const auth: RouteConfigWithMeta = {
  path: '',
  name: 'auth',
  meta: { requiresAuth: false },
  component: () => import('@/views/Guest.vue'),
  children: [
    {
      path: 'auth-callback',
      name: 'auth-callback',
      meta: { requiresAuth: false },
      component: () =>
        import(
          /* webpackChunkName: "auth-callback" */
          '@/views/Auth0Callback.vue'
        ),
    },
  ],
}

export default auth
