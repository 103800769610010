
import { Vue, Component, Prop } from 'vue-property-decorator'
import { QuoteDetailV2 } from '../models/dto/QuoteDetailV2'

@Component({})
export default class QuoteDetailBidListExpirationWarning extends Vue {
  @Prop({ type: Object, default: null }) readonly quote: QuoteDetailV2

  get expirationDate(): string {
    return this.quote?.expirationDate
  }

  get expirationTimezone(): string {
    return this.quote?.expirationTimezone
  }

  get formattedExpirationDate(): string {
    const expirationDateWithTimezone = this.$dayjs(this.expirationDate).tz(
      this.expirationTimezone
    )

    const localTimezone = (this.$dayjs as any).tz.guess()
    const expirationDateInLocalTimezone = expirationDateWithTimezone.tz(
      localTimezone
    )

    return expirationDateInLocalTimezone.format('MMM D, YYYY, h:mm A z')
  }
}
