
import { Vue, Component, Prop } from 'vue-property-decorator'
import { Trip } from '../models/dto'
import TripMap from '@/components/TripMap.vue'
import TripItineraryV2 from '@/components/TripItineraryV2.vue'

@Component({ components: { TripMap, TripItineraryV2 } })
export default class CheckoutTripItinerary extends Vue {
  @Prop({ type: Object, required: true }) readonly trip!: Trip
}
