
import { Vue, Component, Prop } from 'vue-property-decorator'
import Carousel from '@/components/Carousel.vue'
import { VehiclePhoto } from '@/models/dto'

@Component({ components: { Carousel } })
export default class ProviderInfoVehiclePhotoCarousel extends Vue {
  @Prop({ type: Boolean, required: true }) readonly value!: boolean
  @Prop({ type: Number, required: true }) readonly index!: number
  @Prop({ type: Array, required: true }) readonly photos!: VehiclePhoto[]
}
