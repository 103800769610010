var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"d-flex padding-t-4",class:{
    'justify-space-between margin-b-4 align-center': !_vm.isSingleColumn,
    'flex-column margin-b-4 ': _vm.isSingleColumn,
  }},[_c('h1',{staticClass:"font-28",class:{
      'margin-b-2': _vm.isSingleColumn,
      'text-center': _vm.fullWidth,
      'margin-b-0': !_vm.fullWidth,
    }},[_vm._v(" "+_vm._s(_vm.headerText)+" ")]),(!_vm.isInGuestCheckout)?_c('div',{staticClass:"d-flex align-center",class:{ 'justify-end': !_vm.fullWidth, 'justify-center': _vm.fullWidth }},[(_vm.showShareQuoteDialog)?_c('ShareQuoteDialog',{attrs:{"quote-id":_vm.quoteId}}):_vm._e(),(_vm.quote.hash && _vm.companyId)?_c('DownloadQuoteButton',{staticClass:"margin-l-2",attrs:{"quote-id":_vm.quote.quoteId,"quote-hash":_vm.quote.hash,"company-id":_vm.companyId}}):_vm._e(),_c('v-btn',{staticClass:"padding-x-2",attrs:{"id":"quote-detail-button-contact-support","text":"","color":"primary","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.openSupport.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('common.CONTACT_SUPPORT'))+" ")])],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }