
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import CUSkeletonLoaderTextField from '@/components/CUSkeletonLoaderTextField.vue'

@Component({ components: { CUSkeletonLoaderTextField } })
export default class CUSelect extends Vue {
  @Prop({ required: false, type: Boolean }) displaySelectAll: boolean
  @Prop({ type: Boolean, required: false, default: false })
  skeletonLoader: boolean
  // For selects with the `multiple` prop.
  // When selecting more than this index, display just (+# others)
  @Prop({ required: false, type: Number }) aggregateSelectionIndex: number
  @Prop({ required: false, default: 'text', type: String }) itemText!: string
  @Prop({ required: false, default: 'value', type: String }) itemValue!: string
  @Prop({ required: false }) items!: any
  @Prop({ required: false, default: () => [] }) value!: string | unknown[]
  @Prop({ type: Boolean, required: false, default: false }) multiple!: boolean
  @Prop({ type: Boolean, required: false, default: false }) dense!: boolean

  isAllToggled = true

  @Watch('value', { immediate: true })
  onValueChange(newVal: unknown[]): void {
    if (!this.multiple) {
      return
    }

    if (newVal?.length === this.items?.length) {
      this.isAllToggled = true
    } else {
      this.isAllToggled = false
    }
  }

  get slots(): any {
    return this.$slots
  }

  get listeners(): Record<string, unknown> {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const vm = this
    return Object.assign({}, this.$listeners, {
      input(event: InputEvent) {
        vm.$emit('input', event)
      },
      change(event: InputEvent) {
        vm.$emit('change', event)
      },
    })
  }

  aggregateSelectionText(item: any, index: number): string {
    const appendText =
      index !== this.aggregateSelectionIndex - 1 &&
      this.value.length - 1 > index
        ? ', '
        : ''
    return `${item[this.itemText]}${appendText}`
  }

  toggleAllFilters(): void {
    if (!this.isAllToggled) {
      if (this.itemValue) {
        this.$emit(
          'input',
          this.items.map((item) => item[this.itemValue])
        )
      } else {
        this.$emit('input', this.items)
      }
    } else {
      this.$emit('input', [])
    }
  }
}
