
import { Vue, Component, Prop } from 'vue-property-decorator'
import { Customer } from '../models/dto'
import PhoneOTCVerificationDialog from '@/components/PhoneOTCVerificationDialog.vue'
import ReservationDetailConfirmPhone from '@/components/ReservationDetailConfirmPhone.vue'

@Component({
  components: { ReservationDetailConfirmPhone, PhoneOTCVerificationDialog },
})
export default class ReservationDetailVerifySMS extends Vue {
  @Prop({ type: Object, required: false }) readonly customer: Customer
  @Prop({ type: Boolean, required: false }) readonly anonymous: boolean
  @Prop({ type: String, required: true }) readonly confirmationHeader: string
  @Prop({ type: String, required: true }) readonly confirmationSubheader: string
  @Prop({ type: Boolean, required: false, default: true }) readonly showConfirmCheckbox: boolean
  @Prop({ type: Boolean, required: false }) readonly skippable: boolean
  @Prop({ type: Boolean, required: false, default: false }) readonly closeable: boolean
  @Prop({ type: String, required: false }) readonly reservationHash: string

  showConfirmPhone = true
  showVerifyPhone = false
  showPhoneConfirmed = false
  phoneRaw = null

  retryVerify(): void {
    this.showConfirmPhone = true
  }

  markPhoneConfirmed(phoneRaw: string): void {
    this.showConfirmPhone = false
    this.showVerifyPhone = true
    this.phoneRaw = phoneRaw
  }
}
