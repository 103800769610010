
import { Vue, Component, Prop } from 'vue-property-decorator'
import { Trip } from '../models/dto'
import CUItineraryV2 from '@/components/CUItineraryV2.vue'
import CUItineraryItemV2 from '@/components/CUItineraryItemV2.vue'
import TripItineraryV2Timing from '@/components/TripItineraryV2Timing.vue'
import TripItineraryV2Icon from '@/components/TripItineraryV2Icon.vue'
import TripItineraryV2Details from '@/components/TripItineraryV2Details.vue'
import { getTripItineraryStops } from '@/utils/tripItinerary'
import { ItineraryStop } from '@/models/ItineraryStop'

@Component({
  components: {
    CUItineraryV2,
    CUItineraryItemV2,
    TripItineraryV2Timing,
    TripItineraryV2Icon,
    TripItineraryV2Details,
  },
})
export default class TripItineraryV2 extends Vue {
  @Prop({ type: Object, required: true }) readonly trip!: Trip

  get itineraryStops(): ItineraryStop[] {
    return getTripItineraryStops(this.trip)
  }
}
