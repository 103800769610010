
import { Vue, Prop, Component } from 'vue-property-decorator'
import { HotelRequirement } from '@/models/dto'
import { DateTime } from 'luxon'
import { TranslateResult } from 'vue-i18n'

@Component({})
export default class DriverHotelWarningInfoLabel extends Vue {
  @Prop({ required: false, type: Object, default: null })
  readonly hotelRequirement: HotelRequirement

  get driverHotelCheckIn(): string {
    return this.hotelRequirement?.checkIn
      ? DateTime.fromISO(this.hotelRequirement?.checkIn).toFormat('LL/dd/yy')
      : ''
  }

  get driverHotelCheckOut(): string {
    return this.hotelRequirement?.checkOut
      ? DateTime.fromISO(this.hotelRequirement?.checkOut).toFormat('LL/dd/yy')
      : ''
  }

  get neededFromText(): TranslateResult {
    return this.$t('reservationDetail.informationNeeded.NEEDED_FROM')
  }

  get neededToText(): TranslateResult {
    return this.$t('reservationDetail.informationNeeded.NEEDED_TO')
  }
}
