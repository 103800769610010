
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class CUSkeletonLoader extends Vue {
  @Prop({ type: String, default: '', required: false }) readonly type: string
  @Prop({ type: String, default: '', required: false }) readonly width: string
  @Prop({ type: String, default: '', required: false }) readonly height: string
  @Prop({ type: Boolean, default: false, required: false })
  readonly multiply: boolean
  @Prop({ type: String, default: '', required: false }) readonly text: string
  @Prop({ type: String, default: '', required: false })
  readonly textClasses: string
  @Prop({ type: Boolean, default: false, required: false })
  readonly round: boolean

  get computedHeight(): string {
    if (this.height) {
      return this.height
    }
    if (this.type === 'chip') {
      return '32px'
    }
    if (!this.text) {
      switch (this.type) {
        case 'button':
          return '40px'
        case 'text-button':
          return '24px'
        case 'h1':
          return '29px'
        case 'h2':
          return '31px'
        case 'h3':
          return '27px'
        case 'h4':
          return '24px'
        case 'text':
          return '18px'
        case 'detail-text':
          return '16px'
        case 'icon':
          return '24px'
        case 'checkbox':
          return '20px'
        case 'divider':
          return '1px'
        case 'mobile-table-cell':
          return '200px'
        case 'table-cell':
        case 'table-header':
          return '18px'
        case 'text-field':
          return '50px'
        default:
          return '14px'
      }
    }
    return 'auto'
  }

  get computedWidth(): string {
    if (this.text) {
      return 'auto'
    }
    if (this.width) {
      return this.width
    }
    switch (this.type) {
      case 'icon':
        return '24px'
      case 'checkbox':
        return '20px'
      case 'avatar':
        return this.computedHeight
      case 'table-cell':
      case 'table-header':
        return '88px'
      default:
        return '100%'
    }
  }

  get styles(): Record<string, string | number> {
    const styles = {
      height: this.computedHeight,
      width: this.computedWidth,
    }
    if (this.type === 'chip') {
      styles['border-radius'] = this.computedHeight
    }
    if (this.type === 'checkbox') {
      styles['margin'] = '2px'
    }
    return styles
  }

  get computedClasses(): string {
    let classes = ''
    if (this.type === 'avatar') {
      classes = `${classes} border-radius-round`
    }
    if (this.multiply) {
      classes = `${classes} multiply`
    }
    if (this.round) {
      classes = `${classes} border-radius-round`
    } else {
      classes = `${classes} border-radius-regular`
    }
    switch (this.type) {
      case 'text-button':
        classes = `${classes} margin-y-2`
        break
      case 'h1':
        classes = `${classes} text-h1`
        break
      case 'h2':
        classes = `${classes} text-h2`
        break
      case 'h3':
        classes = `${classes} text-h3`
        break
      case 'h4':
        classes = `${classes} text-h4`
        break
      case 'text':
        classes = `${classes} text-body-1`
        break
      case 'detail-text':
        classes = `${classes} text-body-2`
        break
      case 'chip':
        classes = `${classes} text-body-2 padding-x-3`
        break
      case 'table-cell':
      case 'table-header':
        classes = `${classes} border-radius-3`
        break
    }
    classes = `${classes} cursor-wait`
    return classes
  }
}
