
import { Vue, Component, Watch } from 'vue-property-decorator'
import { phoneRaw } from '@/utils/phone'
import { SalesBotCustomer } from '@/classes/SalesBotCustomer'
import selfServe from '@/store/modules/selfServe'
import customer from '@/services/customer'
import selfServeService from '@/services/selfServe'
import auth from '@/store/modules/auth'
import authService from "@/services/auth";
import SelfServeQuoteSalesSMSOptOut from './SelfServiceQuoteSalesSMSOptOut.vue'

@Component({ components: { SelfServeQuoteSalesSMSOptOut } })
export default class SelfServeCustomerInformation extends Vue {
  isLoggingIn = false
  debounce = null
  selfServe = selfServe
  submitting = false
  passwordHidden = true
  asyncPasswordResponse = {
    data: {
      valid: false,
      message: 'Something went wrong'
    }
  }
  shouldValidatePassword = false

  @Watch('selfServe.isExistingUser', { immediate: true })
  scrollToSignIn(): void {
    if (!selfServe.isExistingUser || !this.$vuetify.breakpoint.xsOnly) {
      return
    }

    this.$nextTick(() => {
      const signInElement = this.$refs.signIn as HTMLElement
      if (!signInElement) {
        return
      }

      signInElement.scrollIntoView({
        behavior: 'smooth',
      })
    })
  }

  get customer(): SalesBotCustomer {
    return selfServe.quote.customer
  }

  get firstName(): string {
    return this.customer?.firstName
  }

  get lastName(): string {
    return this.customer?.lastName
  }

  get phone(): string {
    return this.customer?.phone
  }

  get email(): string {
    return this.customer?.email
  }

  get bookings(): number {
    return this.customer?.bookings
  }

  get password(): string {
    return this.customer?.password || ''
  }

  get phoneRaw(): string {
    return this.customer?.phone ? phoneRaw(this.customer.phone) : ''
  }

  get disabled(): boolean {
    return (
      selfServe.isExistingUser ||
      !(!!this.firstName && !!this.lastName && !!this.email && !!this.phoneRaw)
    )
  }

  mounted(): void {
    this.focusEmail()
  }

  focusEmail(): void {
    const emailInput = this.$refs['email'] as any
    emailInput.focus()
  }

  checkCustomerEmail(immediate = false): void {
    const isEmailValid = this.validateEmail()
    if (!isEmailValid) {
      selfServe.setIsExistingUser(false)
      return
    }

    if (this.debounce) {
      window.clearTimeout(this.debounce)
    }
    const delay = immediate ? 0 : 250
    this.debounce = window.setTimeout(async () => {
      const response = await customer.isSignedUpByEmail(this.email)
      selfServe.setIsExistingUser(!!response?.data?.signedUp)
    }, delay)
  }

  validateEmail(): boolean {
    if (!this.email) {
      return false
    }
    return /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,13}$/.test(this.email)
  }

  async submit(): Promise<void> {
    this.asyncPasswordResponse = await authService.validatePassword(this.password)
    this.shouldValidatePassword = true

    const form = this.$refs['self-serve-customer-form'] as any
    const isFormValid = form.validate()
    if (!isFormValid) {
      return
    }
    try {
      this.submitting = true
      await this.createSelfServeQuotes()
    } catch (err) {
      console.error(err)
    }
    this.submitting = false
  }

  async createSelfServeQuotes(): Promise<void> {
    const payload = selfServe.quote.toObject()

    const result = await selfServeService.createAsGuest(payload)
    this.trackRegistration()
    await this.login(payload)

    const quotes = result.data?.data
    const selfServeId = quotes?.[0]?.selfServeId
    selfServe.setSelfServeId(selfServeId)
    const quoteDetailsResponse = await selfServeService.bySelfServeIdV2(
      selfServeId
    )
    const quoteDetails = quoteDetailsResponse.data?.data
    selfServe.setQuoteDetails(quoteDetails)

    selfServe.getAvailableAmenities()
    selfServe.moveToVehicleStep()
  }

  async login(payload: any): Promise<void> {
    const authPayload = {
      email: payload.customer.email,
      password: payload.customer.password,
    }
    await auth.login(authPayload)
    await auth.fetchUserDetails()
    await auth.refreshRolesAndPermissions()
    await auth.refreshCustomerAccount()
  }

  trackRegistration(): void {
    this.$ga4Event('registration', undefined)
  }

  resetPasswordValidation(): void {
    this.shouldValidatePassword = false
  }

}
