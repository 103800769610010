var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{staticClass:"padding-a-0 fill-height",attrs:{"fluid":""}},[_c('div',{staticClass:"fill-height overflow-hidden",attrs:{"no-gutters":""}},[_c('div',{staticClass:"padding-x-6 bid-panel padding-b-0 overflow-y-auto z-10 background-white",class:{
        'padding-t-6 w-400 max-w-400': _vm.$vuetify.breakpoint.smAndUp,
        'padding-t-2 max-h-two-thirds-vh w-full-vw max-w-full-vw position-fixed bottom-0 left-0 border-radius-top-left-2x border-radius-top-right-2x':
          _vm.$vuetify.breakpoint.xsOnly,
      },style:({
        height: _vm.$vuetify.breakpoint.smAndUp ? 'calc(100vh - 68px)' : '',
        'box-shadow': _vm.$vuetify.breakpoint.smAndUp
          ? 'box-shadow: 12px 0px 10px -5px rgba(0, 0, 0, 0.15)'
          : '0px -8px 6px 0px rgba(0, 0, 0, 0.0375)',
      })},[_vm._t("sidebar")],2),_c('div',{staticClass:"position-absolute top-0 z-0",class:{
        'left-400': _vm.$vuetify.breakpoint.smAndUp,
        'left-0': _vm.$vuetify.breakpoint.xs,
      },style:({
        'max-width': _vm.$vuetify.breakpoint.smAndUp
          ? 'calc(100vw - 400px)'
          : '100vw',
        width: _vm.$vuetify.breakpoint.smAndUp ? 'calc(100vw - 400px)' : '100vw',
        height: 'calc(100vh - 68px)',
        flex: _vm.$vuetify.breakpoint.smAndUp ? '' : '1',
      })},[_vm._t("map")],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }