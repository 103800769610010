import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'

export class HttpService {
  /**
   * Makes a POST request to the specified URL with the given payload and configuration options.
   *
   * @param url - The URL to which the request will be made.
   * @param payload - The data to be sent in the request body.
   * @param config - Optional configuration options for the request.
   * @returns A promise that resolves to the response of the request.
   */
  post<T, P>(
    url: string,
    payload: P,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    return axios.post<P, AxiosResponse<T>>(url, payload, config)
  }

  /**
   * Makes a PATCH request to the specified URL with the given payload and configuration options.
   *
   * @param url - The URL to which the request will be made.
   * @param payload - Optional data to be sent in the request body.
   * @param config - Optional configuration options for the request.
   * @returns A promise that resolves to the response of the request.
   */
  patch<T, P>(
    url: string,
    payload?: P,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    return axios.patch<P, AxiosResponse<T>>(url, payload, config)
  }

  /**
   * Makes a PUT request to the specified URL with the given payload and configuration options.
   *
   * @param url - The URL to which the request will be made.
   * @param payload - The data to be sent in the request body.
   * @param config - Optional configuration options for the request.
   * @returns A promise that resolves to the response of the request.
   */
  put<T, P>(
    url: string,
    payload: P,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    return axios.put<P, AxiosResponse<T>>(url, payload, config)
  }

  /**
   * Makes a GET request to the specified URL with the given configuration options.
   *
   * @param url - The URL to which the request will be made.
   * @param config - Optional configuration options for the request.
   * @returns A promise that resolves to the response of the request.
   */
  get<T, P>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    return axios.get<P, AxiosResponse<T>>(url, config)
  }

  /**
   * Makes a DELETE request to the specified URL with the given configuration options.
   *
   * @param url - The URL to which the request will be made.
   * @param config - Optional configuration options for the request.
   * @returns A promise that resolves to the response of the request.
   */
  delete<T, P>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    return axios.delete<P, AxiosResponse<T>>(url, config)
  }
}
