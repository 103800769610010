var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"d-flex flex-column"},[_c('h3',{staticClass:"margin-b-0 subtitle-1"},[_vm._v(" "+_vm._s(_vm.$t('reservationDetail.contactInformation.TITLE'))+" ")]),(!_vm.isUserSMSVerified)?_c('ReservationDetailContactInfomationVerifySMS',{attrs:{"is-verify-phone-modal-open":_vm.isVerifyPhoneModalOpen}}):_vm._e(),_vm._l((_vm.contacts),function(contactType,contactTypeIndex){return [_c('h4',{key:`contact-label-${contactTypeIndex}`,staticClass:"margin-t-3 font-book text-gray-light"},[_vm._v(" "+_vm._s(contactType.label)+" ")]),_vm._l((contactType.list),function(contact,contactIndex){return _c('v-row',{key:`contact-row-${contactTypeIndex}-${contact.id}-${contactIndex}`,class:{
        'elevation-0 margin-y-3 border-1 border-solid border-border-gray rounded contact-card-mobile':
          _vm.$vuetify.breakpoint.xsOnly,
        'last-of-type:margin-b-0 align-center': _vm.$vuetify.breakpoint.smAndUp,
      }},[(contact.firstName || contact.lastName)?_c('v-col',{class:{
          'padding-b-0': _vm.$vuetify.breakpoint.xsOnly,
          'padding-b-1': contactIndex !== contactType.list.length - 1,
        },attrs:{"cols":"6","sm":"6","md":"3"}},[_c('p',{staticClass:"font-14 margin-t-0"},[_vm._v(" "+_vm._s(_vm.$t('reservationDetail.contactInformation.NAME'))+" ")]),_c('p',{staticClass:"margin-t-n1 font-medium"},[_vm._v(" "+_vm._s(contact.firstName)+" "+_vm._s(contact.lastName)+" ")])]):_vm._e(),_c('v-col',{class:{
          'padding-b-0': contact.phone && _vm.$vuetify.breakpoint.xsOnly,
          'padding-b-1': contactIndex !== contactType.list.length - 1,
          'padding-b-2': !contact.phone && _vm.$vuetify.breakpoint.xsOnly,
        },attrs:{"cols":"12","sm":"6","md":"5"}},[_c('p',{staticClass:"font-14 margin-t-0"},[_vm._v(" "+_vm._s(_vm.$t('reservationDetail.contactInformation.EMAIL'))+" ")]),_c('p',{staticClass:"margin-t-n1 font-medium white-space-nowrap overflow-hidden text-overflow-ellipsis"},[_vm._v(" "+_vm._s(contact.email)+" ")])]),(contact.phone)?_c('v-col',{class:{
          'padding-b-0': _vm.$vuetify.breakpoint.xsOnly,
          'padding-b-1': contactIndex !== contactType.list.length - 1,
        },attrs:{"cols":"12","sm":"6","md":"auto"}},[_c('div',{staticClass:"d-flex align-center"},[_c('label',{staticClass:"font-14 margin-t-0"},[_vm._v(" "+_vm._s(_vm.$t('reservationDetail.contactInformation.PHONE_NUMBER'))+" ")]),(_vm.isSMSEnabled(contactType.label))?_c('CUIcon',{staticClass:"margin-l-1 cursor-default",attrs:{"color":"primary","view-box":"0 0 24 24","width":"16px","height":"16px"}},[_vm._v(" verified ")]):_vm._e()],1),_c('p',{staticClass:"margin-t-n1 font-medium",class:{
            'padding-b-2': _vm.$vuetify.breakpoint.xsOnly,
          }},[_vm._v(" "+_vm._s(_vm.phoneFormatFilter(contact.phone))+" ")])]):_vm._e(),(_vm.allowUserManagement && contact.id !== _vm.bookingContact.id)?[_c('v-spacer'),_c('v-col',{staticClass:"shrink",class:{ 'delete-icon-mobile': _vm.$vuetify.breakpoint.xsOnly }},[_c('CUIcon',{staticClass:"margin-b-n3",attrs:{"id":`${_vm.toKebab(
              contactType.label
            )}-remove-contact-${contactIndex}`,"color":"gray-mid-light","hover-color":"error","width":"32","height":"32"},on:{"click":function($event){return _vm.remove(contact.id)}}},[_vm._v(" delete ")])],1)]:_vm._e()],2)}),(contactTypeIndex === 0 && !_vm.showTripContact && _vm.allowUserManagement)?_c('div',{key:`contact-label-${contactTypeIndex}-trip-contact`,staticClass:"d-flex flex-column margin-t-2"},[_c('p',{staticClass:"align-self-start"},[_vm._v(" "+_vm._s(_vm.$t('reservationDetail.contactInformation.IS_THERE_A_TRIP_CONTACT'))+" ")]),_c('p',{staticClass:"font-bold text-primary margin-t-0 padding-t-0 cursor-pointer align-self-start",on:{"click":_vm.openTripContactDialog}},[_vm._v(" "+_vm._s(_vm.$t('reservationDetail.contactInformation.ADD_A_TRIP_CONTACT'))+" ")])]):_vm._e()]}),(_vm.allowUserManagement && !_vm.isFinished)?[_c('p',{staticClass:"font-bold text-primary cursor-pointer align-self-start",on:{"click":function($event){_vm.isShareReservationDialogOpen = true}}},[_vm._v(" "+_vm._s(_vm.$t('reservationDetail.contactInformation.SHARE_WITH_RIDERS'))+" ")]),_c('ReservationDetailShareDialog',{attrs:{"reservation-id":_vm.reservation.reservationId,"shared-list":_vm.sharedList},on:{"refresh-shared-list":function($event){return _vm.$emit('refresh-shared-list')}},model:{value:(_vm.isShareReservationDialogOpen),callback:function ($$v) {_vm.isShareReservationDialogOpen=$$v},expression:"isShareReservationDialogOpen"}}),_c('v-dialog',{attrs:{"fullscreen":_vm.$vuetify.breakpoint.xs,"max-width":"500px"},model:{value:(_vm.isTripContactDialogOpen),callback:function ($$v) {_vm.isTripContactDialogOpen=$$v},expression:"isTripContactDialogOpen"}},[_c('ReservationDetailTripContactForm',{attrs:{"reservations":[_vm.reservation],"shared-list":_vm.sharedList,"closeable":""},on:{"close":function($event){_vm.isTripContactDialogOpen = false},"refresh":function($event){return _vm.$emit('refresh')}}})],1)]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }