var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"d-flex justify-center",class:{
      'flex-column': _vm.$vuetify.breakpoint.xs,
    }},[_c('div',{class:{
        'max-w-188 w-full margin-r-3': _vm.$vuetify.breakpoint.smAndUp,
        'w-full': _vm.$vuetify.breakpoint.xs,
      }},[_c('label',{attrs:{"for":"passengerCount"}},[_vm._v("Number of passengers")]),_c('v-text-field',{attrs:{"id":"self-serve-quote-form-passenger-count-input","value":_vm.passengers,"hide-details":"","outlined":"","required":"","tabindex":"1","type":"number","placeholder":"0"},on:{"input":function($event){return _vm.setPassengerCount($event)}}},[_c('template',{slot:"prepend-inner"},[_c('CUIcon',{attrs:{"view-box":"0 0 24 24"}},[_vm._v(" account_group ")])],1)],2)],1),_c('div',{class:{
        'max-w-400 w-full': _vm.$vuetify.breakpoint.smAndUp,
        'w-full margin-t-3': _vm.$vuetify.breakpoint.xs,
      }},[_c('label',{attrs:{"for":"passengerCount"}},[_vm._v("Event Type")]),_c('v-select',{attrs:{"id":"self-serve-quote-form-event-type-select","value":_vm.tripEventTypeId,"items":_vm.tripEventTypes,"append-icon":"keyboard_arrow_down","hide-details":"","item-text":"label","item-value":"tripEventTypeId","outlined":"","placeholder":"Select an event type","required":"","tabindex":"2"},on:{"change":function($event){return _vm.setTripEventTypeId($event)}}})],1)]),_c('div',{staticClass:"d-flex justify-center"},[_c('div',{staticClass:"max-w-600 w-full margin-t-3"},[_c('label',{attrs:{"for":"tripName"}},[_vm._v("Give your trip a name")]),_c('v-text-field',{ref:"trip-name-input",attrs:{"id":"self-serve-quote-form-trip-name-input","value":_vm.tripName,"outlined":"","hint":"Optional","persistent-hint":"","placeholder":"e.g. Greatest Wedding of All, Game of the Year, or Class of 2015 Reunion","tabindex":"3","counter":"50","type":"text","rules":[
          (v) =>
            (v || '').length <= 50 ||
            'Trip name must be 50 characters or less',
        ]},on:{"update:error":(e) => (_vm.errorMessage = e),"input":function($event){return _vm.setTripName($event)}}})],1)]),_c('div',{staticClass:"d-flex justify-center margin-t-3"},[_c('SelfServeSpecialTripRequirementsSelection')],1),_c('div',{staticClass:"d-flex justify-center align-center margin-t-6"},[_c('v-btn',{staticClass:"w-full d-flex",class:{
        'max-w-320': _vm.$vuetify.breakpoint.smAndUp,
      },attrs:{"color":"primary","disabled":_vm.disabled,"loading":_vm.submitting},on:{"click":_vm.goToNextStep}},[_vm._v(" "+_vm._s(_vm.buttonText)+" "),_c('CUIcon',{staticClass:"margin-l-2",attrs:{"view-box":"0 0 24 24"}},[_vm._v(" arrow_right ")])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }