var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"d-flex justify-space-between",class:{
    'padding-t-8': !_vm.removePadding,
    'padding-t-12 padding-x-32': !_vm.isMobile && !_vm.removePadding,
    'flex-column': _vm.isMobile
  }},[_c('div',{staticClass:"max-w-352 min-w-352"},[(_vm.currentTrip)?[_c('h2',{staticClass:"margin-b-3 font-medium"},[_vm._v(" Current trip ")]),_c('TripReviewMetadata',{attrs:{"vehicles":_vm.currentTrip.vehicles,"passengerCount":_vm.currentTrip.passengerCount,"tripTypeId":_vm.currentTrip.tripTypeId}}),_c('v-card',{staticClass:"background-white margin-t-4"},[_c('v-card-text',{staticClass:"padding-t-4 padding-b-0 padding-x-4"},[(
              _vm.currentTrip.ada ||
              _vm.currentTrip.spab ||
              _vm.currentTrip.vehicleNeededEntireTrip
            )?[_c('TripReviewRequirements',{attrs:{"ada":_vm.currentTrip.ada,"spab":_vm.currentTrip.spab,"vehicleNeededEntireTrip":_vm.currentTrip.vehicleNeededEntireTrip}}),_c('v-divider')]:_vm._e(),_c('TripReviewItinerary',{staticClass:"margin-t-4",attrs:{"stops":_vm.currentTrip.stops}})],2)],1)]:_vm._e()],2),_c('div',{staticClass:"d-flex align-center",class:{ 'justify-center margin-y-8': _vm.isMobile }},[(_vm.isMobile)?_c('CUIcon',{staticClass:"w-24 h-24",attrs:{"view-box":"0 0 24 24"}},[_vm._v(" arrow_downward ")]):_c('CUIcon',{staticClass:"w-36 h-36",attrs:{"view-box":"0 0 24 24"}},[_vm._v(" arrow_right ")])],1),_c('div',{staticClass:"max-w-352 min-w-352"},[_c('h2',{staticClass:"margin-b-3 font-medium text-primary"},[_vm._v(" Trip modifications ")]),(_vm.requestedTripForReview && _vm.changesMade)?[_c('TripReviewMetadata',{attrs:{"vehicles":_vm.requestedTripForReview.vehicles,"passengerCount":_vm.requestedTripForReview.passengerCount,"tripTypeId":_vm.requestedTripForReview.tripTypeId,"tripTypeChanged":_vm.requestedTripForReview.tripTypeChanged,"passengerCountChanged":_vm.requestedTripForReview.passengerCountChanged,"vehiclesChanged":_vm.requestedTripForReview.vehiclesChanged}}),_c('v-card',{staticClass:"background-white margin-t-4"},[_c('v-card-text',{staticClass:"padding-t-4 padding-b-0 padding-x-4"},[(
              _vm.requestedTripForReview.ada ||
              _vm.requestedTripForReview.spab ||
              _vm.requestedTripForReview.vehicleNeededEntireTrip
            )?[_c('TripReviewRequirements',{attrs:{"ada":_vm.requestedTripForReview.ada,"spab":_vm.requestedTripForReview.spab,"vehicleNeededEntireTrip":_vm.requestedTripForReview.vehicleNeededEntireTrip,"adaChanged":_vm.requestedTripForReview.adaChanged,"spabChanged":_vm.requestedTripForReview.spabChanged,"vehicleNeededEntireTripChanged":_vm.requestedTripForReview.vehicleNeededEntireTripChanged}}),_c('v-divider')]:_vm._e(),_c('TripReviewItinerary',{staticClass:"margin-t-4",attrs:{"stops":_vm.requestedTripForReview.stops}})],2)],1)]:[_c('p',[_vm._v(" No changes were specified. ")])]],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }