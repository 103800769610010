
import { Vue, Component, Prop, Provide } from 'vue-property-decorator'
import BidCard from '@/components/BidCard.vue'
import { QuoteDetailV2, QuoteDetailV2Bid } from '../models/dto/QuoteDetailV2'
import { PartnerTypeId } from '../utils/enum'

@Component({
  components: {
    BidCard,
  },
})
export default class BidCardCarousel extends Vue {
  @Provide('isInCarousel') isInCarousel = true

  @Prop({ type: Object, default: null }) readonly quoteDetail: QuoteDetailV2

  carouselIndex = 0

  get selectedCompanyId(): number {
    return parseInt(this.$route?.params?.providerId) || null
  }

  get bids(): QuoteDetailV2Bid[] {
    return (
      this.quoteDetail?.bids.filter(
        (bid) =>
          bid.partnerTypeId !== PartnerTypeId.Bronze &&
          bid.companyId !== this.selectedCompanyId
      ) || []
    )
  }

  get quoteId(): number {
    return this.quoteDetail?.quoteId
  }

  get isPickupInCanada(): boolean {
    return this.quoteDetail?.trips?.[0]?.stops?.[0]?.address?.country === 'CA'
  }

  get isElite(): boolean {
    return !!this.quoteDetail?.isElite
  }
}
