
import { Component, Vue, Prop } from 'vue-property-decorator'
import SoldOutDetail from '@/components/SoldOutDetail.vue'

@Component({ name: 'BidCardSoldOut', components: { SoldOutDetail } })
export default class BidCardSoldOut extends Vue {
  @Prop({ type: Boolean, default: false }) stacked!: boolean

  soldOutInfo = [
    {
      messageText: this.$t('quoteDetail.bidCard.WHY_SOLD_OUT_MATTERS'),
      tooltipText: this.$t('quoteDetail.bidCard.MATTERS_EXPLANATION'),
    },
    {
      messageText: this.$t('quoteDetail.bidCard.HOW_DO_WE_KNOW_SOLD_OUT'),
      tooltipText: this.$t('quoteDetail.bidCard.HOW_WE_KNOW_EXPLANATION'),
    },
  ]
}
