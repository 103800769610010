
import { Vue, Component, Prop } from 'vue-property-decorator'
import { VehiclePhoto } from '@/models/dto'

@Component({})
export default class ProviderInfoVehicleTypePhotos extends Vue {
  @Prop({ type: Array, required: true })
  readonly photos: VehiclePhoto[]

  get vehicleType(): string {
    return this.photos?.[0]?.vehicleTypeDTO?.label
  }

  get photo1(): VehiclePhoto {
    return this.photos?.[0]
  }

  get photo2(): VehiclePhoto {
    return this.photos?.[1]
  }

  get photo3(): VehiclePhoto {
    return this.photos?.[2]
  }

  openModal(image): void {
    this.$emit('open-modal', image)
  }
}
