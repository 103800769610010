import Vue from 'vue'
import VueI18n from 'vue-i18n'

// Manually import locale messages
import enLocale from '../locales/en.json'
import esLocale from '../locales/es.json'

Vue.use(VueI18n)

const numberFormats = {
  en: {
    currency: {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
    },
  },
}
// Add more import statements for each locale file

const messages = {
  en: enLocale,
  es: esLocale,
  // Assign imported locale messages to their respective language keys
  // Add more entries for each locale file
}

export default new VueI18n({
  numberFormats,
  locale: navigator.language.slice(0, 2) || 'en',
  fallbackLocale: 'en',
  messages,
})
