import { render, staticRenderFns } from "./StickyColumnLayout.vue?vue&type=template&id=e359e488"
import script from "./StickyColumnLayout.vue?vue&type=script&lang=ts"
export * from "./StickyColumnLayout.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports