
import { SalesBotTrip } from '@/classes/SalesBotTrip'
import { Address, PlaceDetail } from '@/models/dto'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import AutoCompleteAddress from '@/components/AutoCompleteAddress.vue'
import CUDigitalTimePicker from '@/components/CUDigitalTimePicker.vue'
import CUDatePicker from '@/components/CUDatePicker.vue'
import CUTimePicker from '@/components/CUTimePicker.vue'
import SelfServeTripItineraryStopKeepOnSiteDialog from '@/components/SelfServeTripItineraryStopKeepOnSiteDialog.vue'
import CUIcon from '@/components/CUIcon.vue'
import { SalesBotStop } from '@/classes/SalesBotStop'
import { DateTime } from 'luxon'
import selfServe from '@/store/modules/selfServe'
import { TripTypeId } from '@/utils/enum'
import { secondsToFormattedDuration } from '@/utils/datetime'
import { placeDetailToAddress } from '../utils/address'
import { estimateDropoffDatetime } from '@/utils/stop'

const DEFAULT_STOP_TIME = '08:00:00'

@Component({
  components: {
    AutoCompleteAddress,
    CUDigitalTimePicker,
    CUIcon,
    CUDatePicker,
    CUTimePicker,
    SelfServeTripItineraryStopKeepOnSiteDialog,
  },
})
export default class SalesBotTripItineraryStop extends Vue {
  @Prop({ type: Object, required: true }) readonly stop: SalesBotStop
  @Prop({ type: Object, required: false }) readonly previousStop: SalesBotStop
  @Prop({ type: Object, required: true }) readonly currentTrip: SalesBotTrip
  @Prop({ type: String, default: false }) readonly errorMessage: string
  @Prop({ type: Boolean }) readonly hideDelete: boolean
  @Prop({ type: Boolean }) readonly hideAdd: boolean
  @Prop({ type: Number, required: true }) readonly index: number

  isDatePickerOpen = false
  isTimePickerOpen = false
  isKeepOnSiteDialogOpen = false

  @Watch('stopDatetimeISO')
  stopDatetimeISOChanged(value: string) {
    this.stop.pickupDatetime = value
  }

  @Watch('dropoffEstimate')
  dropoffEstimateChanged(value: string) {
    this.stop.estimatedDropoffDatetime = value
  }

  get isFirstStop(): boolean {
    return this.orderIndex === 0
  }

  get isLastStop(): boolean {
    return this.orderIndex === this.currentTrip.stops.length - 1
  }

  get orderIndex(): number {
    return this.stop.orderIndex
  }

  get nextStop(): SalesBotStop {
    if (this.isLastStop) {
      return null
    }
    return this.currentTrip.stops?.find(
      (stop) => stop.orderIndex === this.orderIndex + 1
    )
  }

  get stopType(): string {
    if (this.isFirstStop) {
      return 'Pickup'
    }
    if (this.isLastStop) {
      return 'Dropoff'
    }
    return 'Stop'
  }

  get stopTitle(): string {
    return this.stopType
  }

  get addressLabel(): string {
    return `${this.stopType} Location`
  }

  get addressTitle(): string {
    return this.stop?.address?.title || ''
  }

  get addressName(): string {
    return this.stop?.address?.addressName || this.stop?.address?.name || ''
  }

  get previousStopDate(): string {
    return this.previousStop?.date || DateTime.now().toISO().substr(0, 10)
  }

  get date(): string {
    const splitDate = this.previousStopDate.split('-')
    let formattedPreviousDate = ''

    if (splitDate.length === 3 && !this.stop.date) {
      formattedPreviousDate = `${splitDate[0]}-${splitDate[1]}`
    }
    return this.stop.date || formattedPreviousDate
  }

  get stopTimeLabel(): string {
    return 'Pickup Time'
  }

  get stopDateLabel(): string {
    return 'Pickup Date'
  }

  get formattedStopDate(): string {
    if (!this.stop.date) {
      return ' '
    }
    return DateTime.fromISO(this.stop.date).toFormat('LL/dd/yy')
  }

  get formattedStopTime(): string {
    if (!this.stop.time) {
      return ''
    }
    return DateTime.fromISO(this.stop.time).toFormat('hh:mm a')
  }

  get zone(): string {
    return this.stop?.address?.timeZone || null
  }

  get stopDatetimeObject(): DateTime {
    if (!this.stop.date || !this.stop.time || !this.zone) {
      return null
    }
    const iso = `${this.stop.date}T${this.stop.time}`
    return DateTime.fromISO(iso, { zone: this.zone })
  }

  get stopDatetimeISO(): string {
    if (!this.stopDatetimeObject) {
      return null
    }
    return this.stopDatetimeObject.toISO()
  }

  get stopDatetime(): string {
    return this.isFirstStop
      ? this.stop.pickupDatetime
      : this.stop.dropoffDatetime
  }

  get showKeepOnSite(): boolean {
    return this.orderIndex === 1 && this.currentTrip?.stops?.length > 2
  }

  get shouldKeepOnSite(): boolean {
    if (!this.showKeepOnSite) {
      return null
    }
    return this.currentTrip.vehicleNeededEntireTrip
  }

  get travelTimeFromPreviousStopInSeconds(): string {
    const seconds = this.stop.travelTimeFromPreviousStopInSeconds
    return seconds ? secondsToFormattedDuration(seconds) : ''
  }

  get dropoffEstimate(): string {
    if (this.isFirstStop) {
      return null
    }
    const travelTimeFromPreviousStopInSeconds = this.stop.travelTimeFromPreviousStopInSeconds
    const previousStop = this.currentTrip.stops[this.orderIndex - 1]
    const previousStopTime = previousStop?.dropoffDatetime || previousStop?.pickupDatetime
    const timeZone = this.stop?.address?.timeZone


    const estimatedDropoffDatetime = estimateDropoffDatetime(
      previousStopTime,
      travelTimeFromPreviousStopInSeconds,
      timeZone
    )
    return estimatedDropoffDatetime?.toISO() || null
  }

  addStop(newStopIndex: number): void {
    if (this.currentTrip) {
      this.currentTrip.addStop(newStopIndex)
    }
  }

  removeStop(stopIndex: number): void {
    if (this.currentTrip) {
      this.currentTrip.removeStop(stopIndex)
    }
  }

  setStopDate(event: any): void {
    this.stop.date = event
    if (!this.stop.time) {
      this.setDefaultStopTime()
    }
    this.isDatePickerOpen = false
  }

  setStopTime(event: any): void {
    this.stop.time = (event === null || event === undefined) ? '' : event
  }

  setDefaultStopTime(): void {
    let calculatedStopTime = DEFAULT_STOP_TIME
    if (this.previousStop) {
      const baseTime = this.previousStop.pickupDatetime
      const secondsFromPrevious = this.stop.travelTimeFromPreviousStopInSeconds || 0
      const zone = this.stop?.address?.timeZone
      if (baseTime && zone) {
        // Get time and round up to the nearest hour
        const datetime = DateTime.fromISO(baseTime, { zone })
          .plus({
            seconds: secondsFromPrevious,
          })
          .plus({ hours: 1 })
          .startOf('hour')
        calculatedStopTime = datetime.toISO().substring(11, 19)
      }
    }
    this.setStopTime(calculatedStopTime)
  }

  setStopAddress(place: PlaceDetail): void {
    let address: Address = null
    if (place) {
      address = placeDetailToAddress(place)
    }
    this.stop.setAddress(address)
    if (address === null) {
      this.removeTimeEstimationFromAffectedStops()
    }
    const isRoundTrip = this.currentTrip.tripTypeId === TripTypeId.RoundTrip
    if (isRoundTrip) {
      if (this.isFirstStop) {
        this.currentTrip.copyAddress(0, this.currentTrip.stops.length - 1)
      } else if (this.isLastStop) {
        this.currentTrip.copyAddress(this.currentTrip.stops.length - 1, 0)
      }
    }
    if (address != null) {
      selfServe.getTripEstimations()
    }
  }

  setVehicleNeededEntireTrip(value: boolean): void {
    if (this.currentTrip) {
      selfServe.quote.trips[
        selfServe.currentTripIndex
      ].vehicleNeededEntireTrip = value
    }
  }

  removeTimeEstimationFromAffectedStops() {
    this.stop.travelTimeFromPreviousStopInSeconds = null
    if (this.nextStop) {
      this.nextStop.travelTimeFromPreviousStopInSeconds = null
    }
  }
}
