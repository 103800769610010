var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"w-full h-full"},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({staticClass:"z-3 position-absolute right-12 top-12 background-white border-1 border-solid border-border-gray w-48 h-48",class:{
          'text-border-gray cursor-default': !_vm.tracking.zoomOptions
            .customPosition,
        },on:{"click":_vm.handleRecenter}},'div',attrs,false),on),[_c('CUIcon',{style:({ margin: '10px' }),attrs:{"width":"35","height":"35"}},[_vm._v(" location ")])],1)]}}])},[_c('span',{staticClass:"text-white"},[_vm._v(_vm._s(_vm.$t('liveTracking.actions.RECENTER_MAP')))])]),_c('LiveTrackingMapToggleGroup',{attrs:{"zoom-options":_vm.tracking.zoomOptions,"disable-zoom-modes":_vm.disableZoomModes},on:{"input":_vm.handleRecenter}}),_c('LiveTrackingMapShareButton'),_c('GmapMap',{ref:"gMap",staticClass:"map z-0",style:({
      width: _vm.$vuetify.breakpoint.smAndUp ? 'calc(100vw - 400px)' : '100vw',
      height: _vm.$vuetify.breakpoint.smAndUp ? 'calc(100vh - 68px)' : '100%',
    }),attrs:{"zoom":_vm.mapConfig.zoom,"center":_vm.mapConfig.center,"options":_vm.mapConfig.options}},_vm._l((_vm.trackingVehicles),function(vehicle){return _c('LiveTrackingVehicle',{key:`vehicle-${vehicle.journeyId}-${vehicle.locationUpdateCount}`,attrs:{"vehicle":vehicle},on:{"directions-service-request":_vm.onServiceRequestHandler}})}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }