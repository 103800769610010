import { Address as AddressDTO } from '@/models/dto'

export class Address implements AddressDTO {
  addressId = null
  name = null
  addressName = null
  street1 = null
  street2 = null
  city = null
  state = null
  country = null
  postalCode = null
  createdOn = null
  updatedOn = null
  lat = null
  lng = null
  timeZone = null
  title = null
  nearestMarketId = null
  companyId = null
  completeAddress = null
  addressTypes = []
  knownAddressId = null
  risks = []

  constructor(address: Address) {
    if (!address) {
      return
    }
    this.addressId = address.addressId
    this.name = address.name
    this.addressName = address.addressName
    this.street1 = address.street1
    this.street2 = address.street2
    this.city = address.city
    this.state = address.state
    this.country = address.country
    this.postalCode = address.postalCode
    this.createdOn = address.createdOn
    this.updatedOn = address.updatedOn
    this.lat = address.lat
    this.lng = address.lng
    this.timeZone = address.timeZone
    this.title = address.title
    this.nearestMarketId = address.nearestMarketId
    this.companyId = address.companyId
    this.completeAddress = address.completeAddress
    this.addressTypes = address.addressTypes
    this.knownAddressId = address.knownAddressId
    this.risks = address.risks
  }
}
