
import { Vue, Component, Prop } from 'vue-property-decorator'
import { BusRule, OperatorDetailCompany } from '../models/dto'

@Component({})
export default class ProviderInfoRules extends Vue {
  @Prop({ type: Object, required: true })
  readonly company!: OperatorDetailCompany

  convertRuleKeyToIcon(/* key */): string {
    return 'not_interested'
  }

  get rules(): BusRule[] {
    return this.company.companyBusRules.filter((rule) => rule.supported)
  }
}
