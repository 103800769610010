var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"border-radius-2x border-2 border-solid padding-a-3 shadow-actionable-card",class:{
    'border-primary': _vm.selected,
    'border-border-gray': !_vm.selected,
    'cursor-pointer': _vm.isSelectable,
    'cursor-default': !_vm.isSelectable,
  },on:{"click":_vm.select}},[(_vm.showRecommendedBanner)?_c('RecommendedBanner'):_vm._e(),_c('div',{class:{
      'd-flex flex-column': _vm.$vuetify.breakpoint.xsOnly,
      'd-flex': _vm.$vuetify.breakpoint.smAndUp,
    }},[_c('CURadioButton',{staticClass:"margin-r-2 margin-b-2",attrs:{"large":"","selected":_vm.selected}}),_c('div',{staticClass:"d-flex",class:{
        'flex-row': _vm.$vuetify.breakpoint.xsOnly,
        'flex-column min-w-136 w-136 max-w-136 margin-r-4':
          _vm.$vuetify.breakpoint.smAndUp,
      }},[_c('VehicleOptionVehicleList',{attrs:{"vehicles":_vm.vehicles}}),(_vm.$vuetify.breakpoint.xsOnly)?[_c('div',{staticClass:"d-flex grow"}),(_vm.isSelfServe)?_c('SelfServeVehiclePricingCardPrice',{attrs:{"quote":_vm.quote}}):_vm._e()]:_c('VehicleOptionImage',{staticClass:"margin-y-2",attrs:{"vehicle-type-keys":_vm.vehicleTypeKeys}})],2),(_vm.$vuetify.breakpoint.smAndUp)?_c('div',{staticClass:"d-flex grow"}):_vm._e(),_c('div',{staticClass:"d-flex flex-column",class:{ 'flex-column': _vm.$vuetify.breakpoint.xsOnly }},[(_vm.$vuetify.breakpoint.smAndUp)?_c('div',{staticClass:"d-flex margin-t-n1"},[_c('VehicleOptionPassengers',{attrs:{"vehicles":_vm.vehicles}}),(_vm.isSelfServe)?_c('div',{staticClass:"d-flex grow"}):_vm._e(),(_vm.isSelfServe)?_c('SelfServeVehiclePricingCardPrice',{attrs:{"quote":_vm.quote}}):_vm._e()],1):[_c('VehicleOptionPassengers',{attrs:{"vehicles":_vm.vehicles}}),_c('VehicleOptionImage',{staticClass:"margin-y-2",attrs:{"vehicle-type-keys":_vm.vehicleTypeKeys}})],_c('VehicleOptionDescription',{attrs:{"vehicle-type-keys":_vm.vehicleTypeKeys}})],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }