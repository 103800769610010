import { AxiosResponse } from 'axios'
import { HttpService } from '@/services/common/HttpService'
import {
  QuoteTypesResult,
  TableViewParameters,
  TableViewResult,
  TripEventType,
} from '@/models/dto'
import { baseUrl } from '@/utils/env'
import { AmenityType } from '@/models/dto/Amenity'

const httpService: HttpService = new HttpService()

export default {
  /**
   * Gets trip event types using the given table view parameters.
   * @param params - The table view parameters to use to get trip event types.
   * @returns A promise that resolves to the result containing the trip event types.
   */
  tripEvent(
    params: TableViewParameters
  ): Promise<AxiosResponse<TableViewResult<TripEventType>>> {
    const { pageSize = 10, page = 1, sorts = null, filters = null } = params
    let query = `page=${page}&pageSize=${pageSize}`
    if (sorts) {
      query = `${query}&${sorts}`
    }
    if (filters) {
      query = `${query}&${filters}`
    }
    query = encodeURI(query)

    const host = baseUrl()
    const url = `https://${host}/tables/tripEventTypes?${query}`
    return httpService.get(url)
  },
  amenity(
    params: TableViewParameters
  ): Promise<AxiosResponse<TableViewResult<AmenityType>>> {
    const { pageSize = -1, page = 1, sorts = null, filters = null } = params
    let query = `page=${page}&pageSize=${pageSize}`
    if (sorts) {
      query = `${query}&${sorts}`
    }
    if (filters) {
      query = `${query}&${filters}`
    }
    query = encodeURI(query)

    const host = baseUrl()
    const url = `https://${host}/tables/amenityTypes?${query}`
    return httpService.get(url)
  },
  /**
   * Fetches various types related to quotes including rate types, charge types,
   * vehicle types, payment types, payment methods, and trip types.
   * @returns A promise that resolves to the result containing various quote types.
   */
  quoteTypes(): Promise<AxiosResponse<QuoteTypesResult>> {
    const host = baseUrl()
    const url = `https://${host}/types/quote-types`
    return httpService.get(url)
  },
}
