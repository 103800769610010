
import { Vue, Component, Prop } from 'vue-property-decorator'
import AddressPicker from '@/components/AddressPicker.vue'
import { Address } from '../models/dto'

@Component({ components: { AddressPicker } })
export default class CheckAndWireFormV2 extends Vue {
  @Prop({ type: Object, default: () => {} }) readonly value: Address

  validate(): boolean {
    const checkAndWireForm = this.$refs['form'] as any

    let firstInvalidInput = null
    checkAndWireForm.inputs.some((input) => {
      if (!input.valid && !firstInvalidInput) {
        firstInvalidInput = input
      }
      return !input.valid
    })

    // If there was an invalid element, scroll to it
    if (firstInvalidInput) {
      this.$vuetify.goTo(firstInvalidInput.$el.offsetTop)
    }

    return checkAndWireForm.validate();
  }
}
