
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { Note, Stop } from '../models/dto'
import TripAmenityCard from '@/components/TripAmenityCard.vue'
import TripTabs from '@/components/TripTabs.vue'
import TripMap from '@/components/TripMap.vue'
import TripMetadata from '@/components/TripMetadata.vue'
import TripNotes from '@/components/TripNotes.vue'
import TripItinerary from '@/components/TripItinerary.vue'
import QuoteCardMobileInformation from '@/components/QuoteCardMobileInformation.vue'
import { NoteTypeId, VehicleTypeKey } from '../utils/enum'
import { QuoteDetailV2, QuoteDetailV2Trip } from '../models/dto/QuoteDetailV2'
import { AmenityItem } from '@/models/AmenityItem'
import { AMENITY_CARD_SUPPORTED_VEHICLE_TYPE_KEYS } from '@/utils/amenity'
import { SplitFeatureFlag } from '@/utils/enum'

@Component({
  components: {
    TripAmenityCard,
    TripTabs,
    TripMap,
    TripMetadata,
    TripNotes,
    TripItinerary,
    QuoteCardMobileInformation,
  },
})
export default class QuoteCard extends Vue {
  @Prop({ type: Object, required: true }) quote!: QuoteDetailV2
  @Prop({ type: Boolean, required: false, default: false }) hideMap!: boolean
  @Prop({ type: Boolean, required: false, default: false })
  hideItinerary!: boolean

  currentTripIndex = 0
  areNewTripAmenitiesEnabled = false

  get trips(): QuoteDetailV2Trip[] {
    return this.quote?.trips
  }

  get currentTrip(): QuoteDetailV2Trip {
    return this.trips?.[this.currentTripIndex] || null
  }

  get amenityEligibleVehicleTypeKeys(): VehicleTypeKey[] {
    const keys = this.currentTrip?.vehicles
      .map(({vehicleType}) => vehicleType.key)
      .filter((key) => AMENITY_CARD_SUPPORTED_VEHICLE_TYPE_KEYS.includes(key))

    return [...new Set(keys)]
  }

  get stops(): Stop[] {
    return this.currentTrip?.stops || []
  }

  get amenities(): AmenityItem[] {
    return this.currentTrip?.amenities || []
  }

  get showTabs(): boolean {
    return this.trips?.length > 1
  }

  get tripNotes(): Note[] {
    return this.currentTrip?.tripNotes?.filter(
      (note) => note.noteType === NoteTypeId.Customer
    )
  }

  get isMobile(): boolean {
    return this.$vuetify.breakpoint.smAndDown
  }

  async mounted() {
    this.areNewTripAmenitiesEnabled = await this.$split.isFeatureEnabled(
      SplitFeatureFlag.NewTripAmenities
    )
  }
}
