
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import OrganizationTeamActionsMenu from '@/components/OrganizationTeamActionsMenu.vue'
import CUDataTable from '@/components/CUDataTable.vue'
import { filter } from '@/utils/filter'
import customerAccount from '@/services/customerAccount'
import { DataOptions } from 'vuetify'
import { TableViewCustomer, Team, Role } from '@/models/dto'
import { DataTableColumn } from '@/models/DataTableColumn'
import OrganizationTeamMoreActions from '@/components/OrganizationTeamMoreActions.vue'

@Component({
  components: {
    OrganizationTeamActionsMenu,
    OrganizationTeamMoreActions,
    CUDataTable,
  },
})
export default class OrganizationTeam extends Vue {
  @Prop({ type: String, required: true }) readonly search: string

  @Prop({ type: Object, required: true }) readonly team: Team
  @Prop({ type: Number, required: true }) readonly index: number
  @Prop({ type: Array, required: true }) readonly teams: Team[]

  @Watch('search')
  async searchChanged(): Promise<void> {
    await this.updateTable()
    if (this.search !== '') {
      const parentCustomerTable = this.$refs.parentCustomerTable as any
      this.expanded = parentCustomerTable?.serverItemsLength > 0
    } else {
      this.expanded = false
    }

    this.tableOptions.page = 1
  }

  @Watch('team')
  teamChanged(): void {
    this.updateTable()
  }

  expanded = false
  loading = false
  tableOptions: DataOptions = {
    page: 1,
    itemsPerPage: 10,
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    sortBy: [],
    sortDesc: [],
  }

  columns: DataTableColumn[] = [
    {
      _t_id: '3f3a68d2-8feb-41cc-98ff-674eb40fe005',
      elementId: 'name',
      text: 'Name',
      value: 'firstName',
      type: 'text',
      computedText: (row: TableViewCustomer) =>
        `${row.firstName} ${row.lastName}`,
    },
    {
      _t_id: 'edb46ba6-1429-4332-9d74-6f3c1ab258cf',
      elementId: 'title',
      text: 'Title',
      value: 'title',
      type: 'text',
    },
    {
      _t_id: 'cbf63228-804e-41b8-9e57-485f9b5df77a',
      elementId: 'email',
      text: 'Email',
      value: 'email',
      type: 'text',
    },
    {
      _t_id: '9ac992fe-1361-49b8-83d1-9a3d0d7414d9',
      elementId: 'role',
      text: 'Role',
      value: 'role',
      computedText: (row: TableViewCustomer) => {
        return row.roles.find(
          (role: Role) => role.roleName === 'customer_account_admin'
        )
          ? 'Admin'
          : 'User'
      },
      type: 'text',
    },
    {
      _t_id: '0ba7b445-a04e-48a1-b9b4-21da189096cd',
      elementId: 'more-actions',
      text: '',
      value: '',
      type: 'slot',
      component: OrganizationTeamMoreActions,
    },
  ]

  items = []
  itemsLength = 0

  created(): void {
    this.updateTable()
  }

  handleExpandClick(): void {
    if (this.$vuetify.breakpoint.xsOnly) {
      this.expanded = !this.expanded
    }
  }

  async updateTable(): Promise<void> {
    const firstNameSearchFilter = {
      column: {
        _t_id: 'text_search_cust',
        value: 'firstName',
        filterType: 'contains',
      },
      value: this.search,
    }
    const lastNameSearchFilter = {
      column: {
        _t_id: 'text_search_cust_1',
        value: 'lastName',
        filterType: 'contains',
      },
      value: this.search,
    }
    const emailSearchFilter = {
      column: {
        _t_id: 'text_search_cust_2',
        value: 'email',
        filterType: 'contains',
      },
      value: this.search,
    }
    const customerAccountFilter = {
      column: {
        _t_id: 'c6f1ca74',
        value: 'customerAccountId',
        filterType: 'eq',
      },
      value: this.team.customerAccountId,
    }
    const customerDataFilter = filter()
    const filterParentAnd = customerDataFilter.createParent('and')
    const filterParent2And = customerDataFilter.createParent('or')
    customerDataFilter.add(filterParentAnd, customerAccountFilter)

    if (this.search) {
      customerDataFilter.add(filterParent2And, firstNameSearchFilter)
      customerDataFilter.add(filterParent2And, lastNameSearchFilter)
      customerDataFilter.add(filterParent2And, emailSearchFilter)
    }

    const filters = customerDataFilter.asQueryParams()

    this.loading = true
    const {
      data: { resultList, count },
    } = await customerAccount.customersTableView({
      filters,
      page: this.tableOptions.page,
      pageSize: this.tableOptions.itemsPerPage,
    })
    this.itemsLength = count
    this.items = resultList.map((item) => {
      return {
        ...item,
        name: `${item.firstName} ${item.lastName}`,
        sentEmailStatus: 'unsent',
      }
    })
    this.loading = false
  }
}
