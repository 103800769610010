
import { Vue, Component, Prop } from 'vue-property-decorator'
import CUDigitalTimePicker from '@/components/CUDigitalTimePicker.vue'

@Component({ components: { CUDigitalTimePicker } })
export default class CUTimePicker extends Vue {
  @Prop({ type: String, required: false, default: '' }) readonly label: string
  @Prop({ type: String, required: false, default: '' }) readonly id: string
  @Prop({ type: Array, required: false, default: () => [] })
  readonly rules: any[]
  @Prop({ type: Boolean }) readonly hideDetails: boolean
  @Prop({ type: String, required: true }) readonly value: string
  @Prop({ type: String, required: false }) readonly displayValue: string

  get inputDisplayText(): string {
    return this.displayValue || this.value
  }

  isOpen = false
}
