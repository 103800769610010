
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import QuoteCard from '@/components/QuoteCard.vue'
import SkeletonTripCollectionCard from '@/components/SkeletonTripCollectionCard.vue'
import quote from '@/services/quotes'
import footer from '@/store/modules/footer'
import { TranslateResult } from 'vue-i18n'
import auth from '@/store/modules/auth'
import { QuoteDetailV2 } from '../models/dto/QuoteDetailV2'
import SkeletonBox from '@/components/SkeletonBox.vue'
import { SplitEvents } from '@/utils/enum'

@Component({
  components: {
    QuoteCard,
    SkeletonTripCollectionCard,
    SkeletonBox,
  },
})
export default class QuoteConfirmation extends Vue {
  @Prop({ type: Object, default: () => {} }) readonly quote: QuoteDetailV2

  @Watch('$route', { deep: true })
  routeChanged(): void {
    footer.setShow(false)
  }

  loading = false

  get headerText(): TranslateResult {
    if (!this.quote) {
      return null
    }
    return this.$t('quoteDetail.confirmation.HEADER', {
      firstName: this.quote.customer.firstName,
    })
  }

  mounted() {
    footer.setShow(false)
  }

  async handleQuoteConfirm(): Promise<void> {
    this.loading = true
    const response = await quote.confirm(this.quote.quoteId)
    if (this.quote?.isSelfServe === false) {
      this.trackCreateQuote()
      await this.$split.track(SplitEvents.QuoteCreated)
    }
    this.loading = false
    if (response.data.quote.isConfirmed) {
      this.$emit('confirm')
      footer.setShow(true)
    }
  }

  trackCreateQuote(): void {
    this.$ga4Event('create_quote', {
      isElite: this.quote?.isElite,
      isSelfServe: false,
      hasBookedBefore: auth?.customer?.convertedQuoteCount > 0,
      isLoggedIn: true,
    })
  }
}
