
import { Vue, Component, Watch } from 'vue-property-decorator'
import auth from '@/store/modules/auth'
import user from '@/services/user'
import customer from '@/services/customer'
import { phoneRaw } from '@/utils/phone'
import PhoneOTCVerificationDialog from '@/components/PhoneOTCVerificationDialog.vue'
import profile from '@/store/modules/profile'
import { validateEmailAddress } from '@/utils/string'

@Component({
  components: {
    PhoneOTCVerificationDialog,
  },
})
export default class ProfileProfile extends Vue {
  @Watch('profile.customer.email')
  customerEmailChanged(): void {
    this.checkCustomerEmail()
  }

  submitting = false
  rules: any = {}
  isExistingUser = false
  debounce = null
  isModeView = true
  isModeEdit = false

  profile = profile
  validateEmailAddress = validateEmailAddress

  get disabled(): boolean {
    return this.isModeView
  }
  get avatarPhoto(): string {
    const photo = profile?.uploadedPhoto || profile?.profilePhoto
    return photo?.imagePath
  }
  get phoneRawNumber(): string {
    return phoneRaw(profile.customer?.phone)
  }
  get smsConfirmed(): boolean {
    return profile?.smsConfirmed
  }

  mounted(): void {
    profile.loadCustomerProfile()
  }

  startEdit(): void {
    this.isModeEdit = true
    this.isModeView = false
  }

  endEdit(): void {
    this.isModeEdit = false
    this.isModeView = true
  }

  async cancel(): Promise<void> {
    await profile.loadCustomerProfile()
    this.endEdit()
  }

  choosePhoto(): void {
    const avatarUpload = this.$refs.avatarUpload as any
    avatarUpload.click()
  }

  addPhotoForUpload(): void {
    const avatarUpload = this.$refs.avatarUpload as any
    const file = avatarUpload.files[0]
    const newImageObject = {
      active: true,
      imagePath: null,
      primaryImage: true,
      userId: profile.customer.userId,
      userPhotoId: null,
      file: null,
      filename: null,
    }

    if (file) {
      const link = URL.createObjectURL(file)
      newImageObject.imagePath = link
      newImageObject.file = file
      newImageObject.filename = `${
        profile.customer.userId
      }_${new Date().getTime()}_${file.name}`
    } else {
      delete newImageObject.file
      delete newImageObject.filename
    }
    profile.setUploadedPhoto(newImageObject)
  }

  clearPhotoUpload(): void {
    profile.setUploadedPhoto(null)
    const fileInput = this.$refs.avatarUpload as any
    fileInput.value = ''
  }

  checkCustomerEmail(immediate = false): void {
    const isEmailValid = validateEmailAddress(profile.customer?.email)
    if (!isEmailValid) {
      this.isExistingUser = false
      return
    }

    if (this.debounce) {
      window.clearTimeout(this.debounce)
    }
    const delay = immediate ? 0 : 250
    this.debounce = window.setTimeout(async () => {
      const response = await user.checkEmail(profile.customer.email)
      this.isExistingUser =
        response?.data?.exists && profile.customer?.email !== auth.user.email
    }, delay)
  }

  async uploadPhoto(): Promise<void> {
    await profile.uploadPhoto()
    this.clearPhotoUpload()
  }

  async submit(): Promise<void> {
    if (this.submitting) {
      return
    }
    const profileSettingsForm = this.$refs.profileSettingsForm as any
    if (!profileSettingsForm.validate()) {
      return
    }
    this.submitting = true
    try {
      profile.setCustomerPhone(this.phoneRawNumber)
      profile.setCustomerAccount(null)
      await customer.edit(profile.customer)
      if (profile?.uploadedPhoto?.imagePath) {
        await this.uploadPhoto()
      }
      profile.loadCustomerProfile()
      profile.setUploadedPhoto(null)
      auth.fetchUserDetails()
      this.endEdit()
    } catch (err) {
      console.warn(err)
    }
    this.rules = {}
    this.submitting = false
  }
}
