
import { Vue, Component, Prop } from 'vue-property-decorator'
import upcomingIcon from '@/assets/images/stopIcons/upcoming.png'
import inprogressIcon from '@/assets/images/stopIcons/inprogress.png'
import completedIcon from '@/assets/images/stopIcons/completed.png'
import lastIcon from '@/assets/images/stopIcons/last.png'
import SkeletonBox from '@/components/SkeletonBox.vue'

@Component({ components: { SkeletonBox } })
export default class CUItineraryItem extends Vue {
  @Prop({ type: Boolean, required: false }) readonly upcoming: boolean
  @Prop({ type: Boolean, required: false }) readonly inProgress: boolean
  @Prop({ type: Boolean, required: false }) readonly completed: boolean
  @Prop({ type: Boolean, required: false }) readonly lastStop: boolean
  @Prop({ type: Boolean, required: false }) readonly loading: boolean

  get icon() {
    if (this.completed) {
      return completedIcon
    }
    if (this.lastStop) {
      return lastIcon
    }
    if (this.inProgress) {
      return inprogressIcon
    }
    if (this.upcoming) {
      return upcomingIcon
    }
    return upcomingIcon
  }
}
