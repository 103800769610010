
import { Vue, Component, Prop } from 'vue-property-decorator'
import VehicleOptionImage from '@/components/VehicleOptionImage.vue'
import { AmenityItemDetail } from '@/models/AmenityItem'
import { TripVehicle } from '@/models/dto'
import { getAmenityIcon } from '@/utils/amenity'

@Component({
  components: {
    VehicleOptionImage,
  },
})
export default class CheckoutTripDetailsVehicle extends Vue {
  @Prop({ type: Object }) vehicle: TripVehicle
  @Prop({ type: Array }) amenities: AmenityItemDetail[]

  getAmenityIcon = getAmenityIcon

  get isWidth320OrLess(): boolean {
    return this.$vuetify.breakpoint.width <= 320
  }

}
