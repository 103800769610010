import { Customer } from '@/models/dto'

/**
 * Checks if a customer has everything that should be filled out in the sign-up form
 *
 * @param customer - The customer to check.
 * @returns true if the customer is missing a required value, false if the user is complete.
 */
export const isIncompleteCustomer = (customer: Customer): boolean => {
  return (
    !customer ||
    !customer?.firstName ||
    !customer?.lastName ||
    !customer?.phone ||
    !customer?.email
  )
}
