
import { Vue, Component, Watch } from 'vue-property-decorator'
import TripModificationVehicleOptions from '@/components/TripModificationVehicleOptions.vue'
import { WizardVehicle } from '@/models/dto'
import tripModification from '@/services/tripModification'
import tripModificationStore from '@/store/modules/tripModification'
import { DateTime } from 'luxon'
import { estimateDropoffDatetime } from '@/utils/stop'
import { deepCompareArrays } from '@/utils/array'
@Component({
  components: {
    TripModificationVehicleOptions,
  },
})
export default class TripModificationVehicle extends Vue {
  tripModificationStore = tripModificationStore
  vehicleOptions: WizardVehicle[][] = []
  debounce: any = null
  loading = false

  /**
   * Generates a key string based on the ADA, SPAB, and passenger count values.
   * This key is used solely to trigger the `@Watch('optionsKey')` method,
   * where changes in these values will prompt the fetching of vehicle options.
   */
  get optionsKey(): string {
    return `${this.ada}-${this.spab}-${this.passengerCount}`
  }

  @Watch('optionsKey')
  async onOptionsKeyChange(): Promise<void> {
    await this.getVehicleOptions()
  }

  async created(): Promise<void> {
    await this.getVehicleOptions()
  }

  async getVehicleOptions(): Promise<void> {
    tripModificationStore.setForwardButtonDisabled(true)
    if (this.debounce) {
      window.clearTimeout(this.debounce)
    }

    this.debounce = window.setTimeout(async () => {
      this.loading = true
      const requestedTrip = tripModificationStore.requestedTrip
      const { stops, passengerCount, ada } = requestedTrip

      if (!passengerCount) {
        return
      }

      const firstStop = stops[0]
      const penultimateStop = stops[stops.length - 2]
      const lastStop = stops[stops.length - 1]

      const travelTimeInSeconds = lastStop.travelTimeFromPreviousStopInSeconds
      const lastStopTimeZone = lastStop.address.zoneId

      const lastStopEstimatedDropoffdateTime: DateTime = estimateDropoffDatetime(
        penultimateStop.pickupDatetime,
        travelTimeInSeconds,
        lastStopTimeZone
      )

      const payload = {
        passengerCount,
        firstPickupLat: firstStop.address.lat,
        firstPickupLng: firstStop.address.lng,
        firstPickupTime: DateTime.fromISO(firstStop.pickupDatetime)
          .toUTC()
          .toISO(),
        lastDropoffTime: lastStopEstimatedDropoffdateTime.toUTC().toISO(),
        ada,
      }

      try {
        const { data } = await tripModification.vehicleConfigurations(payload)
        this.vehicleOptions = data
      } catch (error) {
        console.error(error)
      }
      this.loading = false
      tripModificationStore.setForwardButtonDisabled(
        !this.isValidVehicleConfigurationSelected
      )
    }, 250)
  }

  get ada(): boolean {
    return tripModificationStore.requestedTrip.ada
  }

  get spab(): boolean {
    return tripModificationStore.requestedTrip.spab
  }

  get passengerCount(): number {
    return tripModificationStore.requestedTrip.passengerCount
  }

  get isValidVehicleConfigurationSelected(): boolean {
    const requestedVehicles = tripModificationStore.requestedTrip.vehicles
    return this.vehicleOptions.some((vehicles) =>
      deepCompareArrays(vehicles, requestedVehicles)
    )
  }

  mounted() {
    tripModificationStore.setForwardButtonDisabled(
      this.passengerCount < 1 || !this.isValidVehicleConfigurationSelected
    )
  }

  setAda(value: boolean): void {
    tripModificationStore.setRequestedAda(value)
  }

  setSpab(value: boolean): void {
    tripModificationStore.setRequestedSpab(value)
  }

  setPassengerCount(passengerCount: string): void {
    const parsedPassengerCount = parseInt(passengerCount, 10)
    if (parsedPassengerCount < 1) {
      tripModificationStore.setForwardButtonDisabled(true)
    } else {
      tripModificationStore.setRequestedPassengerCount(parsedPassengerCount)
    }
  }

  selectVehicles(vehicles: WizardVehicle[]): void {
    tripModificationStore.setRequestedVehicles(vehicles)
    tripModificationStore.setForwardButtonDisabled(
      !this.isValidVehicleConfigurationSelected
    )
  }
}
