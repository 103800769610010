
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'CharterUPLogo',
})
export default class CharterUPLogo extends Vue {
  @Prop({ type: String, required: false, default: 'blue' }) private readonly color!: 'white' | 'blue'

  get fill(): string {
    return this.color === 'white' ? this.$vuetify.theme.themes.light.white.toString() : this.$vuetify.theme.themes.light.blue.toString()
  }
}
