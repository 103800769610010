
import { Vue, Component } from 'vue-property-decorator'
import { DateTime } from 'luxon'
import app from '@/store/modules/app'
import { TranslateResult } from 'vue-i18n'

@Component({})
export default class TheFooter extends Vue {
  sectionLinks: { id: string; link: string; label: TranslateResult }[] = [
    {
      id: 'footer-link-join-our-team',
      link: 'https://www.charterup.com/careers',
      label: this.$t('footer.JOIN_OUR_TEAM'),
    },
    {
      id: 'footer-link-contact-us',
      link: 'mailto:info@charterup.com?subject=CharterUP Web Contact',
      label: this.$t('footer.CONTACT_US'),
    },
    {
      id: 'footer-link-terms-and-conditions',
      link: 'https://www.charterup.com/terms-and-conditions',
      label: this.$t('common.TERMS_AND_CONDITIONS'),
    },
    {
      id: 'footer-link-privacy-policy',
      link: 'https://www.charterup.com/privacy-policy',
      label: this.$t('common.PRIVACY_POLICY'),
    },
  ]
  socialLinks: { id: string; link: string; icon: string }[] = [
    {
      id: 'footer-link-facebook',
      link: 'https://www.facebook.com/charterup/',
      icon: '$facebook',
    },
    {
      id: 'footer-link-instagram',
      link: 'https://www.instagram.com/charterup/',
      icon: '$instagram',
    },
    {
      id: 'footer-link-twitter',
      link: 'https://twitter.com/charter_up',
      icon: '$twitter',
    },
    {
      id: 'footer-link-linkedin',
      link: 'https://www.linkedin.com/company/charterup/',
      icon: '$linkedin',
    },
  ]

  get appVersion(): string {
    return app.version
  }
  get currentYear(): number {
    return DateTime.local().year
  }
  get copyrightText(): string {
    return `${this.$t('common.COPYRIGHT_ICON')} ${this.currentYear} ${this.$t(
      'common.COPYRIGHT'
    )}`
  }
}
