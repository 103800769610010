
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class Main extends Vue {
  @Prop({ type: Boolean, required: false, default: false })
  readonly fullHeight!: boolean

  get styles(): Record<string, string> {
    const styles = {
      'max-width': `${this.$vuetify.breakpoint.thresholds.md}px`,
    }
    return styles
  }
}
