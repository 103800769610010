
import { Vue, Component } from 'vue-property-decorator'
import authStore from '@/store/modules/auth'
import { phoneFormatFilterHyphens, getSupportNumber } from '@/utils/phone'

@Component({})
export default class TripModificationError extends Vue {

  get supportNumber(): string {
    return getSupportNumber(authStore.customer.isElite)
  }

  get formattedSupportNumber(): string {
    return `1-${phoneFormatFilterHyphens(this.supportNumber)}`
  }
}
