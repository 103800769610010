
import { Vue, Component, Watch, Inject } from 'vue-property-decorator'
import { ComputedPaymentProfile, TableViewPaymentProfile } from '../models/dto'
import paymentProfile from '../services/paymentProfile'
import auth from '../store/modules/auth'
import PaymentProfileV2 from '@/components/PaymentProfileV2.vue'

@Component({ components: { PaymentProfileV2 } })
export default class PaymentProfileSelectorV2 extends Vue {
  @Inject({ from: 'isInGuestCheckout', default: false }) readonly isInGuestCheckout: boolean
  @Inject({ from: 'isInBilling', default: false }) readonly isInBilling: boolean

  paymentProfiles: ComputedPaymentProfile[] = []
  selectedPaymentProfileId: number = null

  @Watch('selectedPaymentProfileId')
  onSelectedPaymentProfileIdChange(): void {
    const selectedPaymentProfile =
      this.paymentProfiles.find(
        ({ customerPaymentProfileId }) =>
          customerPaymentProfileId === this.selectedPaymentProfileId
      ) || null
    this.$emit('input', selectedPaymentProfile)
  }

  async created(): Promise<void> {
    if (this.isInGuestCheckout && !auth.user) {
      this.$emit('update:has-payment-profiles', false)
      this.$emit('update:loading', false)
      return
    }
    await this.loadCustomerPaymentProfiles()
    this.selectDefaultOrFirstPaymentProfile()
  }

  async loadCustomerPaymentProfiles(): Promise<void> {
    this.$emit('update:loading', true)
    const { data } = await paymentProfile.loadCustomerPaymentProfiles(auth.userId)
    const paymentProfilesResults = data?.resultList || []
    this.paymentProfiles = paymentProfilesResults.map((profile) =>
      this.processPaymentProfile(profile)
    )
    this.$emit('update:has-payment-profiles', !!this.paymentProfiles?.length)
    this.$emit('update:loading', false)
  }

  selectDefaultOrFirstPaymentProfile(): void {
    if (!this.paymentProfiles?.length) {
      return
    }

    const defaultProfileId = this.paymentProfiles.find(
      ({ isDefault }) => isDefault
    )?.customerPaymentProfileId
    const firstProfileId = this.paymentProfiles[0].customerPaymentProfileId

    this.selectedPaymentProfileId = (defaultProfileId || firstProfileId)
  }

  async select(customerPaymentProfileId: number): Promise<void> {
    if (this.isInBilling) {
      await this.setDefault(customerPaymentProfileId)
      await this.loadCustomerPaymentProfiles()
    }
    this.selectedPaymentProfileId = customerPaymentProfileId
  }

  processPaymentProfile(
    paymentProfile: TableViewPaymentProfile
  ): ComputedPaymentProfile {
    return {
      customerPaymentProfileId: paymentProfile.customerPaymentProfileId,
      mask: paymentProfile.mask,
      typeLabel: paymentProfile.label,
      isDefault: paymentProfile.isDefault,
      accountHolderName: paymentProfile.accountHolderName,
      expiration: paymentProfile.expiration,
      address: {
        street1: paymentProfile.address?.street1,
        street2: paymentProfile.address?.street2,
        city: paymentProfile.address?.city,
        state: paymentProfile.address?.state,
        postalCode: paymentProfile.address?.postalCode,
        timeZone: paymentProfile.address?.timeZone,
        lat: paymentProfile.address?.lat,
        lng: paymentProfile.address?.lng,
        completeAddress: paymentProfile.address?.completeAddress,
        addressName: paymentProfile.address?.addressName,
      },
    }
  }

  async setDefault(customerPaymentProfileId: number): Promise<void> {
    try {
      const payload = {
        customerId: auth.userId,
        customerPaymentProfileId,
      }
      await paymentProfile.setDefault(payload)
    } catch (error) {
      console.error(error)
    }
  }
}
