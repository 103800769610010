
import { Vue, Component, Prop } from 'vue-property-decorator'
import support from '@/store/modules/support'
import { SupportTicketKey } from '@/utils/enum'

@Component
export default class ItineraryRequirementsDialog extends Vue {
  @Prop({ type: Boolean }) readonly value: boolean
  @Prop({ type: Number, default: null }) readonly reservationId: number

  requirements = [
    this.$t('itineraryRequirements.requirements.AVOID_SURPRISE_CHARGES'),
    this.$t('itineraryRequirements.requirements.DOT_COMPLIANCE'),
    this.$t('itineraryRequirements.requirements.PLAN_ROUTES'),
  ]

  openSupportModal(): void {
    this.close()
    support.open({
      reservationId: this.reservationId,
      ticketTypeKey: SupportTicketKey.Change,
    })
  }

  close(): void {
    this.$emit('input', false)
  }
}
