import auth from '@/store/modules/auth'
import RouteConfigWithMeta from '@/models/RouteConfig'
import modules from '@/store/modules'

const quotes: RouteConfigWithMeta =
{
  path: 'quotes',
  meta: { requiresAuth: true },
  component: () => import('@/views/Empty.vue'),
  children: [
    {
      path: '',
      name: 'quote-index',
      meta: { requiresAuth: true },
      component: () =>
        import(
          /* webpackChunkName: "quote-index" */ '@/views/QuoteIndex.vue'
        ),
    },
    {
      path: 'hash/:hash/:mode?',
      name: 'quote-detail-by-hash',
      meta: { requiresAuth: true },
      props: (router) => ({
        passedQuote: router.params.passedQuote,
      }),
      component: () =>
        import(
          /* webpackChunkName: "quote-detail" */ '@/views/QuoteDetail.vue'
        ),
      beforeEnter: async (_to, _from, next) => {
        await auth.refreshCustomerAccount()
        next()
      },
    },
    {
      path: ':quoteId/checkout',
      name: 'checkout-single-bid',
      meta: { requiresAuth: true },
      props: (router) => ({
        disputedCheckout: router.params.disputedCheckout === 'true',
      }),
      component: () =>
        import(
          /* webpackChunkName: "checkout" */ '@/views/Checkout.vue'
        ),
    },
    {
      path: ':id/:mode?',
      name: 'quote-detail',
      meta: { requiresAuth: true },
      props: (router) => ({
        passedQuote: router.params.passedQuote,
      }),
      component: () =>
        import(
          /* webpackChunkName: "quote-detail" */ '@/views/QuoteDetail.vue'
        ),
      beforeEnter: async (to, _from, next) => {
        await auth.refreshCustomerAccount()
        const goingToQuoteConfirm = to.params?.mode === 'confirm'
        if (goingToQuoteConfirm) {
          modules.footer.setShow(false)
        } else {
          modules.footer.setShow(true)
        }
        next()
      },
    },
    {
      path: ':quoteId/provider/:providerId',
      name: 'provider-detail',
      meta: { requiresAuth: true },
      component: () =>
        import(
          /* webpackChunkName: "reservation-index" */ '@/views/Provider.vue'
        ),
    },
    {
      path: ':quoteId/provider/:providerId/checkout',
      name: 'checkout',
      meta: { requiresAuth: true },
      props: (router) => ({
        disputedCheckout: router.params.disputedCheckout === 'true',
      }),
      component: () =>
        import(
          /* webpackChunkName: "checkout" */ '@/views/Checkout.vue'
        ),
    },
  ],
}

export default quotes
