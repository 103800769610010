
import { Vue, Prop, Component } from 'vue-property-decorator'
import SharedTrip from '@/models/dto/SharedTrip'

@Component({})
export default class SharedTripsModalQuoteColumn extends Vue {
  @Prop({ required: true }) readonly row: SharedTrip

  get isConverted(): boolean {
    return this.row.quoteIsConverted || !!this.row.reservationId
  }

  get isExpired(): boolean {
    if (!this.row.quoteExpirationDate) {
      return true
    }
    return this.$dayjs(this.row.quoteExpirationDate).isBefore(this.$dayjs())
  }

  navigateToQuote(): void {
    if (this.isConverted || this.isExpired) {
      return
    }
    this.$router.push({
      name: 'quote-detail',
      params: {
        id: this.row.quoteId.toString(),
      },
    })
  }
}
