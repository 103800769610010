
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

@Component({})
export default class EnterpriseTrackingFiltering extends Vue {
  @Prop({ type: Array, default: () => [] }) readonly lastVehiclePositions: any[]
  @Prop({ type: Boolean, required: true }) readonly isEnterpriseAdmin: boolean

  @Watch('filters')
  filtersChanged(): void {
    this.$emit('input', this.filters)
  }
  @Watch('selectedContracts')
  selectedContractsChanged(): void {
    this.selectedVehicleNames = []
  }

  selectedOperators = []
  selectedReservationIds = []
  selectedActiveValues = []
  selectedADAValues = []
  selectedContracts = []
  selectedTripNames = []
  selectedVehicleNames = []
  selectedVehicleTypes = []

  get operators(): any[] {
    return [
      ...new Set(
        this.lastVehiclePositions.map((vehicle) => vehicle.operatorName)
      ),
    ].sort()
  }
  get contracts(): any[] {
    const contractSet = new Set()
    for (const vehicle of this.lastVehiclePositions) {
      if (vehicle?.reservation?.contractName) {
        contractSet.add(vehicle.reservation.contractName)
      }
    }
    return [...contractSet].sort()
  }
  get vehicles(): any[] {
    const vehicleContractPair = this.lastVehiclePositions.map((vehicle) => {
      return {
        vehicleName: vehicle.vehicleName,
        contractName: vehicle.reservation.contractName,
      }
    })
    return [...new Set(vehicleContractPair)].sort()
  }
  get reservationIds(): any[] {
    const reservationIdSet = new Set()
    for (const vehicle of this.lastVehiclePositions) {
      if (vehicle?.reservation?.managedId) {
        reservationIdSet.add(vehicle.reservation.managedId)
      }
    }
    return [...reservationIdSet].sort()
  }
  get tripNames(): any[] {
    const tripNameSet = new Set()
    for (const vehicle of this.lastVehiclePositions) {
      if (vehicle?.reservation?.tripName) {
        tripNameSet.add(vehicle.reservation.tripName)
      }
    }
    return [...tripNameSet].sort()
  }
  get vehicleTypeValues(): any[] {
    return [
      ...new Set(
        this.lastVehiclePositions.map((vehicle) => vehicle.vehicleType)
      ),
    ].sort()
  }
  get booleanValues(): any[] {
    return [true, false]
  }
  get filters(): any {
    const operatorName = this.selectedOperators.map(
      (operatorIndex) => this.operators[operatorIndex]
    )
    const reservationId = this.selectedReservationIds.map(
      (reservationIndex) => this.reservationIds[reservationIndex]
    )
    const contractName = this.selectedContracts.map(
      (contractIndex) => this.contracts[contractIndex]
    )
    const tripName = this.selectedTripNames.map(
      (tripNameIndex) => this.tripNames[tripNameIndex]
    )
    const vehicleName = this.selectedVehicleNames.map(
      (vehicleIndex) => this.vehicles[vehicleIndex].vehicleName
    )
    const vehicleType = this.selectedVehicleTypes.map(
      (vehicleTypeIndex) => this.vehicleTypeValues[vehicleTypeIndex]
    )
    const active = this.selectedActiveValues.map(
      (activeValueIndex) => this.booleanValues[activeValueIndex]
    )
    const isADACompliant = this.selectedADAValues.map(
      (adaValueIndex) => this.booleanValues[adaValueIndex]
    )

    const filters = {}
    if (operatorName.length) {
      filters['operatorName'] = operatorName
    }
    if (contractName.length) {
      filters['contractName'] = contractName
    }
    if (tripName.length) {
      filters['tripName'] = tripName
    }
    if (vehicleName.length) {
      filters['vehicleName'] = vehicleName
    }
    if (reservationId.length) {
      filters['reservationId'] = reservationId
    }
    if (vehicleType.length) {
      filters['vehicleType'] = vehicleType
    }
    if (isADACompliant.length) {
      filters['isADACompliant'] = isADACompliant
    }
    if (active.length) {
      filters['active'] = active
    }
    return filters
  }
}
