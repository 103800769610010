
import { Vue, Component, Watch } from 'vue-property-decorator'
import CUDataTable from '@/components/CUDataTable.vue'
import customerAccount from '@/services/customerAccount'
import { DataOptions } from 'vuetify'
import { DataTableColumn } from '@/models/DataTableColumn'
import SharedContact from '@/models/dto/SharedContact'
import SharedContactsMoreActions from './SharedContactsMoreActions.vue'
import auth from '@/store/modules/auth'
import SharedContactsSharedTrips from './SharedContactsSharedTrips.vue'
import { EventBus } from '@/utils/eventBus'
import deepClone from '@/utils/deepClone'

@Component({
  components: {
    SharedContactsMoreActions,
    SharedContactsSharedTrips,
    CUDataTable,
  },
})
export default class SharedContacts extends Vue {
  loading = false
  tableOptions: DataOptions = {
    page: 1,
    itemsPerPage: 10,
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    sortBy: [],
    sortDesc: [],
  }

  columns: DataTableColumn[] = [
    {
      _t_id: '894e36ca-fa88-4e51-8187-90960ba2393b',
      elementId: 'email',
      text: 'Email',
      value: 'email',
      type: 'text',
    },
    {
      _t_id: 'e1845d04-9880-483d-84c5-fe72ea21f36d',
      elementId: 'name',
      text: 'Name',
      value: 'name',
      type: 'text',
      computedText: (row: SharedContact): string =>
        `${row.firstName} ${row.lastName}`,
    },
    {
      _t_id: '6747d6b9-1e3b-4796-bad2-35ffdbf93b31',
      elementId: 'lastLogin',
      text: 'Last Login',
      value: 'lastLogin',
      type: 'text',
      computedText: (row: SharedContact): string =>
        row.lastLogin
          ? this.$dayjs(row.lastLogin).format('DD MMM YY - h:mm A')
          : '',
    },
    {
      _t_id: '0f8858ea-02d0-455a-9b1c-e79b94f79417',
      elementId: 'sharedTrips',
      text: 'Shared Trips',
      value: 'sharedTrips',
      type: 'slot',
      component: SharedContactsSharedTrips,
    },
    {
      _t_id: '3895c486-2b7f-4a88-bdc1-cc67ae99b277',
      elementId: 'more-actions',
      text: 'Actions',
      value: '',
      type: 'slot',
      component: SharedContactsMoreActions,
    },
  ]

  items = []
  itemsLength = 0
  search = ''

  get paginatedItems(): SharedContact[] {
    let items: SharedContact[] = deepClone(this.items)
    if (this.search) {
      items = items.filter(
        (item) =>
          item.firstName.includes(this.search) ||
          item.lastName.includes(this.search) ||
          item.email.includes(this.search)
      )
    }
    this.itemsLength = items.length
    return items.slice(
      (this.tableOptions.page - 1) * this.tableOptions.itemsPerPage,
      this.tableOptions.page * this.tableOptions.itemsPerPage
    )
  }

  @Watch('search')
  searchChanged(): void {
    this.tableOptions.page = 1
  }

  created(): void {
    this.getTable()
    EventBus.$on('update-shared-contacts', () => {
      this.getTable()
    })
  }

  async getTable(): Promise<void> {
    this.loading = true
    try {
      const sharedContactResponse = await customerAccount.sharedContactsTable(
        auth.customerAccount.customerAccountId
      )
      this.items = sharedContactResponse.data.sort((a, b) =>
        a.email.localeCompare(b.email)
      )
      this.itemsLength = this.items.length
      this.loading = false
    } catch (e) {
      console.error(e)
      this.loading = false
    }
  }

  clearSearch(): void {
    if (this.search) {
      this.search = ''
    }
  }
}
