
import confetti from '@/store/modules/confetti'
import { Vue, Component } from 'vue-property-decorator'

@Component({})
export default class Confetti extends Vue {
  width = null
  length = null
  colors = []
  iterations = null
  duration = null
  count = null
  xMovementThreshold = null
  minimumOpacity = null
  initialXPositions = []

  created(): void {
    const settings = confetti.settings
    if (settings) {
      for (const [key, value] of Object.entries(settings)) {
        this[key] = value
      }
    }
  }

  async mounted(): Promise<void> {
    await this.initializeXPositions()
    const css = document.styleSheets
    const style = css[css.length - 1]
    for (let i = 0; i < this.count; i++) {
      const keyframe = `@keyframes drop-${i} {100% {left: ${this.randomConfettiFinalXPos(
        this.initialXPositions[i]
      )}%; top: 110%; transform: rotate(${this.randomRotation()}deg);}}`
      style.insertRule(keyframe, style.rules.length)
    }
  }

  randomWidth(): number {
    return Math.floor(Math.random() * this.width)
  }
  randomLength(): number {
    return Math.floor(Math.random() * this.length)
  }
  randomColor(): string {
    const max = this.colors.length
    const randomIndex = Math.floor(Math.random() * (max + 1))
    return this.colors[randomIndex]
  }
  randomOpacity(): number {
    const minOpacity = this.minimumOpacity || 1
    return Math.random() + minOpacity
  }
  randomRotation(): number {
    return Math.random() * 360
  }
  randomAnimationDuration(): number {
    return this.duration + Math.random()
  }
  randomConfettiHeight(): number {
    return this.randomLength() * 0.4
  }
  randomConfettiInitialYPos(): number {
    const min = 10
    const max = 75
    const random = Math.floor(Math.random() * (max - min + 1) + min)
    return -1 * random
  }
  randomConfettiInitialXPos() {
    return this.randomLength() * (100 / this.length)
  }
  randomConfettiFinalXPos(initialX: number): number {
    const direction = Math.round(Math.random()) * 2 - 1
    return (
      initialX +
      Math.floor(direction * Math.random() * (this.xMovementThreshold + 1))
    )
  }
  initializeXPositions(): void {
    for (let i = 0; i < this.count; i++) {
      this.initialXPositions.push(this.randomConfettiInitialXPos())
    }
  }
}
