
import { Vue, Component, Prop } from 'vue-property-decorator'
import { WizardVehicle } from '@/models/dto'
import { VehicleTypeKey } from '@/utils/enum'
import { pluralize } from '@/utils/string'
import { vehicleTypeKeyToLabel } from '@/utils/vehicle'
import { tripTypeIdToLabel } from '@/utils/trip'

@Component({})
export default class TripReviewMetadata extends Vue {
  @Prop({ type: Array, required: true }) readonly vehicles: WizardVehicle[]
  @Prop({ type: Number, required: true }) readonly passengerCount: number
  @Prop({ type: Number, required: true }) readonly tripTypeId: number
  @Prop({ type: Boolean, default: false }) readonly tripTypeChanged: boolean
  @Prop({ type: Boolean, default: false })
  readonly passengerCountChanged: boolean
  @Prop({ type: Boolean, default: false }) readonly vehiclesChanged: boolean

  get tripPassengerCount(): string {
    return `${this.passengerCount} Passengers`
  }

  get tripTypeTitle(): string {
    return tripTypeIdToLabel(this.tripTypeId) ?? 'Unknown trip type'
  }

  get formattedVehiclesList(): string {
    return (
      this.vehicles
        ?.map(({ vehicleTypeKey, quantity }) =>
          this.vehiclesPretty(vehicleTypeKey, quantity)
        )
        .join(', ') || ''
    )
  }

  vehiclesPretty(vehicleTypeKey: VehicleTypeKey, quantity: number): string {
    const label = pluralize(quantity, vehicleTypeKeyToLabel(vehicleTypeKey))
    return `${quantity} ${label}`
  }
}
