import { DateTime } from 'luxon'

/**
 * Gets the formatted date from a timestamp string
 *
 * @param startDate The start date of the trip as a string
 * @param daysBefore Number of days before the trip starts
 * @returns Formatted date including time e.g., "March 20, 1:09 PM"
 */
export const formattedCancellationPolicyDate = (date: string) => {
  if (!date) {
    return null
  }

  return DateTime.fromISO(date)
    .toLocaleString({
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
    })
    .replace(' at', ',')
}
