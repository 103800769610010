
import footer from '@/store/modules/footer'
import { Vue, Component } from 'vue-property-decorator'

@Component
export default class MapWithSidebar extends Vue {
  mounted(): void {
    footer.setShow(false)
  }
}
