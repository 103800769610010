
import { Vue, Component, Prop, Model } from 'vue-property-decorator'
import { InfoModalDetail } from '@/models/InfoModalDetail'
import CharterUPLogo from '@/icons/CharterUPLogo.vue'
import { phoneFormatFilterHyphens, getSupportNumber } from '@/utils/phone'
import auth from '@/store/modules/auth'
import InfoModalTextSimple from './InfoModalTextSimple.vue'
import InfoModalTextItemized from './InfoModalTextItemized.vue'
import InfoModalTextStandard from './InfoModalTextStandard.vue'
import { InfoModalVariant, KeyCode } from '@/utils/enum'

@Component({ components: { CharterUPLogo } })
export default class InfoModal extends Vue {
  @Prop({ type: Object, required: true }) readonly modalDetail: InfoModalDetail
  @Model('input') readonly value!: boolean | null

  KeyCode = KeyCode

  get icon(): string {
    return this.modalDetail?.icon?.name || ''
  }

  get iconViewBox(): string {
    return this.modalDetail?.icon?.viewBox || '0 0 38 37'
  }

  get iconColor(): string {
    return this.modalDetail?.icon?.color || ''
  }

  get label(): string {
    return this.$vuetify.breakpoint.mdAndUp
      ? this.modalDetail.label
      : this.modalDetail.shortLabel
  }

  get showCharterUPLogo(): boolean {
    return !!this.modalDetail?.showCharterUPLogo
  }

  get showFooterText(): boolean {
    return !!this.modalDetail?.footerText
  }

  get textComponent(): string {
    const variant = this.modalDetail?.modalVariant || ''
    const map = {
      [InfoModalVariant.Simple]: InfoModalTextSimple,
      [InfoModalVariant.Itemized]: InfoModalTextItemized,
      [InfoModalVariant.Standard]: InfoModalTextStandard,
    }
    return map[variant] || null
  }

  get showSupportButton(): boolean {
    return !!this.modalDetail?.showSupportButton
  }

  get supportNumber(): string {
    return getSupportNumber(auth.customer.isElite)
  }

  get formattedSupportNumber(): string {
    return `1-${phoneFormatFilterHyphens(this.supportNumber)}`
  }

  close(): void {
    this.$emit('input', false)
  }
}
