
import { Vue, Component } from 'vue-property-decorator'
import TripModificationReview from '@/components/TripModificationReview.vue'
import TripModificationError from '@/components/TripModificationError.vue'
import tripModificationStore from '@/store/modules/tripModification'
import authStore from '@/store/modules/auth'

@Component({
  components: {
    TripModificationReview,
    TripModificationError
  },
})
export default class TripModificationApplication extends Vue {

  get isTripModificationApplied(): boolean {
    return tripModificationStore.isTripModificationApplied
  }

  get subHeader(): string {
    const managedId = tripModificationStore.managedId
    const routeName = tripModificationStore.routeName
    const formattedId = `ID ${managedId}`
    return routeName ? `${routeName} – ${formattedId}` : formattedId
  }

  get customerEmail(): string {
    return authStore.customer.email
  }
}
