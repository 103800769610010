const brandColor1 = '#3C4853'
const brandTextColor = '#ffffff'

export default {
  client() {
    return Twilio.FlexWebChat
  },
  brandedColors: {
    MainContainer: {
      borderRadius: '4px',
      boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 4px 0px',
      color: brandColor1,
    },
    Chat: {
      WelcomeMessage: {
        Container: {
          color: brandColor1,
        },
      },
      MessageListItem: {
        FromMe: {
          Bubble: {
            color: '#3c4853',
            background: '#C4CDD4',
          },
          Header: {
            color: '#3c4853',
          },
        },
        FromOthers: {
          Avatar: {
            alignItems: 'center',
            background: '#F9FAFB',
            color: brandColor1,
          },
          Bubble: {
            color: brandColor1,
            background: '#e7ebee',
          },
          Header: {
            color: brandColor1,
          },
        },
      },
      MessageInput: {
        Container: {
          Button: {
            background: '#08a6f0',
            color: brandTextColor,
          },
        },
      },
      MessageCanvasTray: {
        Container: {
          background: '#334167',
          color: brandTextColor,
        },
        Button: {
          background: '#0BA5F0',
        },
      },
    },
    MainHeader: {
      Container: {
        background: '#e1f4fd',
        color: '#3C4853',
      },
      Logo: {
        fill: brandTextColor,
        maxWidth: '24px',
      },
    },
    EntryPoint: {
      Container: {
        background: '#08A6F0',
        color: brandTextColor,
        'font-weight': '1200',
        '&:hover': {
          backgroundColor: '#0684bf',
          backgroundBlendMode: 'color',
        },
      },
    },
  }
}
