import { Action, Module, VuexModule } from 'vuex-class-modules'
import store from '@/store/index'

@Module({ generateMutationSetters: true })
class SidebarModule extends VuexModule {
  _isOpen = false

  /**
   * Whether the sidebar is open or not.
   * @returns whether the sidebar is open or not.
   */
  get isOpen(): boolean {
    return this._isOpen
  }

  /**
   * Sets the open status of the sidebar
   */
  @Action
  setIsOpen(isOpen: boolean): void {
    this._isOpen = isOpen
  }
}

export default new SidebarModule({ store, name: 'sidebar' })
