export const createDownloadUri = (base64Data: string, mimeType: string = 'application/pdf'): string => {
  // Decode Base64 data
  const binaryData = atob(base64Data);
  const byteArray = new Uint8Array(binaryData.length);
  for (let i = 0; i < binaryData.length; i++) {
    byteArray[i] = binaryData.charCodeAt(i);
  }

  // Convert to Blob
  const blob = new Blob([byteArray], { type: mimeType });
  return window.URL.createObjectURL(blob);
}

export const triggerFileDownload = (blobUrl: string, fileName: string): void => {
  // Best way to download a blob with a given file name is to make an
  // invisble 'a' tag, click it, and then remove it from the DOM
  const anchor = document.createElement('a');
  anchor.href = blobUrl;
  anchor.download = fileName;
  anchor.style.display = 'none';
  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
  window.URL.revokeObjectURL(blobUrl);
}
