import { render, staticRenderFns } from "./TripItineraryV2Details.vue?vue&type=template&id=51d6e3b2"
import script from "./TripItineraryV2Details.vue?vue&type=script&lang=ts"
export * from "./TripItineraryV2Details.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports