
import { KeyCode } from '@/utils/enum'
import { Model, Prop, Vue, Component } from 'vue-property-decorator'

@Component
export default class CUModal extends Vue {
  @Model('input') readonly value!: boolean | null
  @Prop({ type: String, required: false, default: '500px' }) width!: string
  @Prop({ type: String, required: false, default: '500px' }) maxWidth!: string
  @Prop({ type: Boolean, default: true }) closeable!: boolean
  @Prop({ type: Boolean }) fullscreen!: boolean
  @Prop({ type: Boolean }) persistent!: boolean
  @Prop({ type: Boolean, default: false }) closeIconRightPadding!: boolean

  get showCloseIcon(): boolean {
    return this.closeable
  }

  keyListener(event: any): void {
    if (event.keyCode === KeyCode.Escape && this.closeable) {
      this.$emit('input', false)
    }
  }

  close(): void {
    this.$emit('input', false)
    this.$emit('close')
  }
}
