
import { Vue, Component, Prop, Watch, ProvideReactive } from 'vue-property-decorator'
import { tripModificationRequestModalDetail } from '@/utils/infoModals'
import { EventBus } from '@/utils/eventBus'
import tripModificationStore from '@/store/modules/tripModification'
import InfoModal from '@/components/InfoModal.vue'
import TripModificationReview from '@/components/TripModificationReview.vue'

@Component({
  components: {
    InfoModal,
    TripModificationReview
  },
})
export default class TripModificationRequestDialog extends Vue {
  @Prop({ type: String, required: true }) readonly managedId: string

  @ProvideReactive('removePadding') removePadding = true

  tripModificationRequestModalDetail = tripModificationRequestModalDetail
  isOpen = false

  async mounted(): Promise<void> {
    EventBus.$on('open-trip-modification-request-dialog', () => this.isOpen = true)

    if (this.managedId) {
      await tripModificationStore.setManagedId(this.managedId)
      await tripModificationStore.getPendingModification()
    }
  }

  handleClickClose(): void {
    this.isOpen = false
  }
}
