
import { Vue, Component, Prop } from 'vue-property-decorator'
import alert from '@/store/modules/alert'
import SharedContact from '@/models/dto/SharedContact'
import quotes from '@/services/quotes'
import reservation from '@/services/reservation'
import { EventBus } from '@/utils/eventBus'

@Component({})
export default class SharedContactsMoreActions extends Vue {
  @Prop({ required: true }) readonly row: SharedContact

  isModalOpen = false
  isUnshare = false
  loading = false

  openUnshareModal(): void {
    this.isUnshare = true
    this.isModalOpen = true
  }

  openShareModal(): void {
    this.isUnshare = false
    this.isModalOpen = true
  }

  async confirm(): Promise<void> {
    this.loading = true
    try {
      if (this.isUnshare) {
        for (const quoteId of this.row.quoteIds) {
          await quotes.unshare(quoteId, this.row.userId)
        }
        for (const reservationId of this.row.reservationIds) {
          await reservation.unshare(reservationId, this.row.userId)
        }
      } else {
        await Promise.all([
          ...this.row.quoteIds.map(async (quoteId) => {
            await quotes.share(quoteId, this.row.userId)
          }),
          ...this.row.reservationIds.map(async (reservationId) => {
            await reservation.share(reservationId, this.row.userId)
          }),
        ])
      }
      this.loading = false
      this.isModalOpen = false
      alert.add({
        text: `Successfully ${
          this.isUnshare ? 'unshared' : 'shared'
        } all quotes and reservations.`,
        color: 'primary',
        title: 'Success',
        dismissible: true,
      })
      EventBus.$emit('update-shared-contacts')
    } catch (e) {
      this.loading = false
      console.error(e)
    }
  }
}
