
import { InviteCustomerPayload } from '@/models/dto'
import deepClone from '@/utils/deepClone'
import { phoneRaw } from '@/utils/phone'
import { validateEmailAddress } from '@/utils/string'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

@Component({})
export default class CustomerInviteForm extends Vue {
  @Prop({ type: Boolean }) readonly triggerSubmit: boolean
  @Prop({ type: String, default: '' }) readonly initialEmail: string
  @Prop({ type: Boolean }) readonly tripContactMode: boolean

  @Watch('triggerSubmit')
  submitTriggered(value: boolean): void {
    if (!value) {
      return
    }
    this.submit()
  }

  @Watch('initialEmail', { immediate: true })
  initialEmailChanged(value: string): void {
    this.customer.email = value
  }

  customer: InviteCustomerPayload = {
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
  }
  validateEmailAddress = validateEmailAddress

  get phoneRaw(): string {
    return phoneRaw(this.customer.phone)
  }

  submit(): void {
    const form = this.$refs['invite-user-form'] as any
    if (!form.validate()) {
      this.$emit('invalid')
      return
    }
    const payload = deepClone(this.customer)
    payload.phone = this.phoneRaw

    this.$emit('submit', payload)
  }
}
