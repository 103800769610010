
import { Vue, Component, Prop } from 'vue-property-decorator'
import { convertAmenityKeyToIconName } from '@/utils/string'
import { AmenityType } from '../models/dto'

@Component({})
export default class AmenityIcon extends Vue {
  @Prop({ type: Object, required: true }) readonly amenityType: AmenityType
  @Prop({ type: String, default: '' }) readonly iconClasses: string
  @Prop({ type: Boolean, default: true }) readonly tooltip: boolean

  get iconName(): string {
    return convertAmenityKeyToIconName(this.amenityType?.key)
  }

  get label(): string {
    return this.amenityType?.label
  }
}
