
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class CURadioButton extends Vue {
  @Prop({ type: Boolean, required: false, default: false })
  readonly loading: boolean
  @Prop({ type: Boolean, required: true }) readonly selected: boolean
  @Prop({ type: Boolean }) readonly disabled: boolean
  @Prop({ type: Boolean }) readonly large: boolean
  @Prop({ type: Boolean }) readonly xLarge: boolean

  get sizeClass(): string {
    if (this.xLarge) {
      return 'x-large'
    }
    if (this.large) {
      return 'large'
    }
    return 'regular'
  }

  get outerDiameter(): string {
    if (this.xLarge) {
      return '24px'
    }
    if (this.large) {
      return '20px'
    }
    return '16px'
  }
}
