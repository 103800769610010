
import { Vue, Component, Prop } from 'vue-property-decorator'
import VehicleOptionCard from '@/components/VehicleOptionCard.vue'
import { WizardVehicle } from '@/models/dto'
import tripModification from '@/store/modules/tripModification'
import { deepCompareArrays } from '@/utils/array'

@Component({
  components: {
    VehicleOptionCard,
  }
})
export default class TripModificationVehicleOptions extends Vue {
  @Prop({ type: Array, required: true }) readonly vehicleOptions: WizardVehicle[][]
  @Prop({ type: Boolean, default: false }) readonly loading: boolean

  selected(vehicles: WizardVehicle[]): boolean {
    return deepCompareArrays(tripModification.requestedTrip.vehicles, vehicles)
  }

}
