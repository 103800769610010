
import { Vue, Component, Prop } from 'vue-property-decorator'
import InfoModal from '@/components/InfoModal.vue'
import { certifiedPartnerModalDetail } from '@/utils/infoModals';


@Component({
  components: {
    InfoModal
  },
})
export default class BidCardCertifiedPartnerBadge extends Vue {
  @Prop({ type: Boolean, default: false }) readonly carousel: boolean

  certifiedPartnerModalDetail = certifiedPartnerModalDetail
  isOpen = false
}

