
import systemParameter from '@/store/modules/systemParameter'
import { baseUrl } from '@/utils/env'
import { Vue, Component, Prop } from 'vue-property-decorator'
import ViewPdfModal from '@/components/ViewPdfModal.vue'

@Component({ components: { ViewPdfModal } })
export default class ReservationDetailDocuments extends Vue {
  @Prop({ type: String, required: false, default: '' })
  readonly insuranceUrl: string
  @Prop({ type: String, required: true }) readonly hash: string

  isInvoiceDialogOpen = false
  isInsuranceDialogOpen = false
  isW9DialogOpen = false

  close(): void {
    this.isInvoiceDialogOpen = false
    this.isInsuranceDialogOpen = false
    this.isW9DialogOpen = false
  }

  get isOpen(): boolean {
    return (
      this.isInvoiceDialogOpen ||
      this.isInsuranceDialogOpen ||
      this.isW9DialogOpen
    )
  }

  get fileName(): string {
    if (this.isInvoiceDialogOpen) {
      return this.invoiceUrl
    }
    if (this.isInsuranceDialogOpen) {
      return this.insuranceUrl
    }
    if (this.isW9DialogOpen) {
      return this.w9Url
    }
    return ''
  }

  get w9Url(): string {
    return systemParameter.w9Url
  }

  get invoiceUrl(): string {
    return `https://${baseUrl(
      'pdf'
    )}/pdf/charterup-invoice/reservations?reservationHashes=${encodeURIComponent(
      [this.hash].join('')
    )}`
  }
}
