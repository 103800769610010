
import { Vue, Component } from 'vue-property-decorator'
import SkeletonBox from '@/components/SkeletonBox.vue'

@Component({
  components: {
    SkeletonBox,
  },
})
export default class SkeletonResTableView extends Vue {}
