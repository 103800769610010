import { Action, Module, VuexModule } from 'vuex-class-modules'
import store from '@/store/index'
import { Customer, UserPhotoDTO } from '@/models/dto'
import auth from './auth'
import customer from '@/services/customer'
import user from '@/services/user'
import { phoneRaw } from '@/utils/phone'

@Module({ generateMutationSetters: true })
class ProfileModule extends VuexModule {
  _customer: Customer = null
  _verifyingPhone = false
  _uploadedPhoto = null
  _profilePhoto: UserPhotoDTO = null

  /**
   * Returns the customer for this instance.
   *
   * @returns The customer.
   */
  get customer(): Customer {
    return this._customer
  }

  /**
   * Returns whether this instance is currently verifying the phone number of the customer.
   *
   * @returns `true` if the phone number is being verified, `false` otherwise.
   */
  get verifyingPhone(): boolean {
    return this._verifyingPhone
  }

  /**
   * Returns whether the SMS confirmation has been completed for the customer of this instance.
   *
   * @returns `true` if the SMS confirmation has been completed, `false` otherwise.
   */
  get smsConfirmed(): boolean {
    return !!this._customer?.smsConfirmed
  }

  /**
   * Returns the uploaded photo for this instance.
   *
   * @returns The uploaded photo.
   */
  get uploadedPhoto(): UploadedPhoto {
    return this._uploadedPhoto
  }

  /**
   * Returns the profile photo for this instance.
   *
   * @returns The profile photo.
   */
  get profilePhoto(): UserPhotoDTO {
    return this._profilePhoto
  }

  /**
   * Loads the customer profile by calling the `customer.byId` method and
   * storing the result in the `_customer` property.
   *
   * @returns A promise that resolves when the customer profile has been loaded.
   */
  @Action
  async loadCustomerProfile(): Promise<void> {
    const customerResponse = await customer.byId(auth.userId)
    this._customer = customerResponse.data.customer
    await this.loadUserPhoto()
  }

  /**
   * Loads the user photo by calling the `user.byId` method and
   * storing the result in the `_profilePhoto` property.
   * If no primary image is found, a default image will be used instead.
   *
   * @returns A promise that resolves when the user photo has been loaded.
   */
  @Action
  async loadUserPhoto(): Promise<void> {
    const { data: userResponse } = await user.byId(auth.userId)
    this._profilePhoto = userResponse.user.userPhotoDTOs.find(
      (photo) => photo.primaryImage === true
    ) || {
      imagePath: require('@/assets/images/defaultProfileAvatar.svg'),
      active: true,
      primaryImage: true,
      userId: 0,
      userPhotoId: 0,
    }
  }

  /**
   * Sets whether this instance is currently verifying the phone number of the customer.
   *
   * @param verifyingPhone - `true` if the phone number is being verified, `false` otherwise.
   */
  @Action
  setVerifyingPhone(verifyingPhone: boolean): void {
    this._verifyingPhone = verifyingPhone
  }

  /**
   * Sets the first name of the customer for this instance.
   *
   * @param firstName - The customer's first name.
   */
  @Action
  setCustomerFirstName(firstName: string): void {
    this._customer.firstName = firstName
  }

  /**
   * Sets the last name of the customer for this instance.
   *
   * @param lastName - The customer's last name.
   */
  @Action
  setCustomerLastName(lastName: string): void {
    this._customer.lastName = lastName
  }

  /**
   * Sets the email of the customer for this instance.
   *
   * @param email - The customer's email.
   */
  @Action
  setCustomerEmail(email: string): void {
    this._customer.email = email
  }

  /**
   * Sets the phone number of the customer for this instance.
   *
   * @param phone - The customer's phone number.
   */
  @Action
  setCustomerPhone(phone: string): void {
    this._customer.phone = phone
  }

  /**
   * Sets the `_verifyingPhone` property to `true` and calls the `loadCustomerProfile` method.
   */
  @Action
  setPhoneVerified(): void {
    this.setVerifyingPhone(true)
    this.loadCustomerProfile()
  }

  /**
   * Sets the uploaded photo for this instance.
   *
   * @param uploadedPhoto - The uploaded photo.
   */
  @Action
  setUploadedPhoto(uploadedPhoto: UploadedPhoto): void {
    this._uploadedPhoto = uploadedPhoto
  }

  /**
   * Uploads the user photo by calling the `user.uploadPhoto` method.
   * If an error occurs, it will be logged to the console.
   *
   * @returns A promise that resolves when the user photo has been uploaded.
   */
  @Action
  async uploadPhoto(): Promise<void> {
    const imageUploadForm = new FormData()
    imageUploadForm.append(
      'file',
      this._uploadedPhoto.file,
      this._uploadedPhoto.filename
    )
    try {
      await user.uploadPhoto(this._customer.userId, true, imageUploadForm)
    } catch (err) {
      console.warn(err)
    }
  }

  /**
   * Sets the customer account for this instance.
   *
   * @param customerAccount - The customer account.
   */
  @Action
  setCustomerAccount(customerAccount: any): void {
    this._customer.customerAccount = customerAccount
  }

  /**
   * Begins the SMS confirmation process by calling the `customer.updateSMS` method
   * and setting the `_verifyingPhone` property to `true`.
   * If an error occurs, it will be logged to the console.
   *
   * @returns A promise that resolves when the SMS confirmation process has begun.
   */
  @Action
  async beginSMSConfirmation(): Promise<void> {
    try {
      await customer.updateSMS(this._customer.userId, {
        phoneNumber: phoneRaw(this._customer.phone),
      })
      this.setVerifyingPhone(true)
    } catch (err) {
      console.warn(err)
    }
  }
}

interface UploadedPhoto {
  active: boolean
  imagePath: any
  primaryImage: boolean
  userId: number
  userPhotoId: any
  file: any
  filename: any
}

export default new ProfileModule({ store, name: 'profile' })
