
import { timeAgo } from '@/utils/datetime'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { TypeWithId } from '../models/dto'

@Component({})
export default class EnterpriseTrackingSidebarVehicleCard extends Vue {
  @Prop({ type: Object, required: true }) readonly vehicle: any
  @Prop({ type: Boolean }) readonly isEnterpriseAdmin: boolean
  @Prop({ type: Boolean }) readonly hover: boolean
  @Prop({ type: Array, default: () => [] }) readonly eldTypes: TypeWithId[]

  timeAgo = timeAgo

  get cssVariables(): any {
    return { '--hover-color': this.vehicle.color }
  }

  getEldLabel(vehicle) {
    return (
      vehicle.eldType ??
      (this.eldTypes.find((eldType) => eldType.id === vehicle.eldTypeId)
        ?.label ||
        '')
    )
  }
}
