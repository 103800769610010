
import { Vue, Component, Prop } from 'vue-property-decorator'
const STATUS_INITIAL = 0
const STATUS_SAVING = 1
const STATUS_SUCCESS = 2
const STATUS_FAILED = 3
@Component({})
export default class FileUploader extends Vue {
  @Prop({ type: FileList, default: null }) readonly value: FileList
  @Prop({ type: String, default: 'Change Image' }) readonly resetLabel: string

  uploadError = null
  currentStatus = null
  uploadFieldName = 'photos'

  get isInitial(): boolean {
    return this.currentStatus === STATUS_INITIAL
  }
  get isSaving(): boolean {
    return this.currentStatus === STATUS_SAVING
  }
  get isSuccess(): boolean {
    return this.currentStatus === STATUS_SUCCESS
  }
  get isFailed(): boolean {
    return this.currentStatus === STATUS_FAILED
  }

  created(): void {
    this.reset()
  }

  reset(): void {
    // reset form to initial state
    this.currentStatus = STATUS_INITIAL
    this.$emit('input', null)
    this.uploadError = null
  }

  filesChange(fieldName, fileList): void {
    this.currentStatus = STATUS_SAVING
    // handle file changes
    if (!fileList.length) {
      return
    }
    // append the files to FormData
    this.$emit('input', fileList)
    // save it
    this.currentStatus = STATUS_SUCCESS
  }
}
