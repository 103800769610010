import { TripModificationAddress as TripModificationAddressDTO, Address as AddressDTO } from '@/models/dto'
import { TripModificationRisk } from './TripModificationRisk'
import { Address } from './Address'
export class TripModificationAddress implements TripModificationAddressDTO {
  name = null
  city = null
  country = null
  lat = null
  lng = null
  postalCode = null
  state = null
  street1 = null
  zoneId = null
  title = null
  risks = []
  addressTypeKeys = []

  constructor(address: AddressDTO | TripModificationAddressDTO) {
    if (!address) {
      return
    }
    this.name = address.name
    this.city = address.city
    this.country = address.country
    this.lat = address.lat
    this.lng = address.lng
    this.postalCode = address.postalCode
    this.state = address.state
    this.street1 = address.street1
    this.zoneId = address.zoneId
    this.title = address.title
    this.risks = (address.risks || []).map((risk) => new TripModificationRisk(risk))

    if (address instanceof TripModificationAddress) {
      this.addressTypeKeys = address.addressTypeKeys
    } else if (address instanceof Address) {
      this.addressTypeKeys = (address.addressTypes || []).map((type) => type.key)
    }
  }
}
