import { Action, Module, Mutation, VuexModule } from 'vuex-class-modules'
import { DateTime } from 'luxon'
import { AxiosResponse } from 'axios'
import store from '@/store/index'
import {
  TableViewQuote,
  TableViewReservation,
  TableViewStop,
  TableViewFilter,
  Reservation,
} from '@/models/dto'
import quotes from '@/services/quotes'
import reservation from '@/services/reservation'
import { cityFromAddressName } from '@/utils/string'
import { load, save } from '@/utils/localStorage'
import { filter } from '@/utils/filter'

@Module({ generateMutationSetters: true })
class SupportModule extends VuexModule {
  _isOpen = false
  _reservationId: number = null
  _reservations: {
    id: number
    managedId: string
    hash: string
    label: string
    customerId: number
    reservationStatus: string
  }[] = load('reservations') || null
  _quoteId: number = null
  _quotes: {
    id: number
    hash: string
    label: string
    customerId: number
    reservationStatus: string
  }[] = load('quotes') || null
  _ticketTypeKey: string = null

  /**
   * Whether the support modal is open or not.
   * @returns whether the support modal is open or not.
   */
  get isOpen(): boolean {
    return this._isOpen
  }

  /**
   * Returns the ticket type associated with the support modal.
   * @returns the ticket type associated with the support modal.
   */
  get ticketTypeKey(): string {
    return this._ticketTypeKey
  }

  /**
   * Returns the reservation ID associated with the support modal.
   * @returns the reservation ID currently associated with the support modal.
   */
  get reservationId(): number {
    return this._reservationId
  }

  /**
   * Returns the reservations associated with the support modal.
   * @returns the reservations currently associated with the support modal.
   */
  get reservations(): {
    id: number
    managedId: string
    hash: string
    label: string
    customerId: number
    reservationStatus: string
  }[] {
    return this._reservations
  }

  /**
   * Returns the quote ID associated with the support modal.
   * @returns the quote ID currently associated with the support modal.
   */
  get quoteId(): number {
    return this._quoteId
  }

  /**
   * Returns the quotes associated with the support modal.
   * @returns the quotes currently associated with the support modal.
   */
  get quotes(): {
    id: number
    hash: string
    label: string
    customerId: number
    reservationStatus: string
  }[] {
    return this._quotes
  }

  /**
   * Updates the ticket type associated with the support modal.
   * @param ticketTypeKey - the new ticket type.
   */
  @Mutation
  setTicketTypeKey(ticketTypeKey: string) {
    this._ticketTypeKey = ticketTypeKey
  }

  /**
   * Updates the reservation ID associated with the support modal.
   * @param reservationId - the new reservation ID.
   */
  @Mutation
  setReservationId(reservationId: number) {
    this._reservationId = reservationId
  }

  /**
   * Updates the reservations associated with the support modal.
   * @param reservations - the new reservations.
   */
  @Mutation
  setReservations(
    reservations: {
      id: number
      managedId: string
      hash: string
      label: string
      customerId: number
      reservationStatus: string
    }[]
  ) {
    this._reservations = reservations
    save('reservations', reservations)
  }

  /**
   * Updates the quote ID associated with the support modal.
   * @param quoteId - the new quote ID.
   */
  @Mutation
  setQuoteId(quoteId: number) {
    this._quoteId = quoteId
  }

  /**
   * Updates the quotes associated with the support modal.
   * @param quotes - the new quotes.
   */
  @Mutation
  setQuotes(
    quotes: {
      id: number
      hash: string
      label: string
      customerId: number
      reservationStatus: string
    }[]
  ) {
    this._quotes = quotes
    save('quotes', quotes)
  }

  /**
   * Opens the support modal and sets an optional reservation ID and an optional quote ID.
   *
   * @param modalContext - object containing context to associate with the support modal.
   */
  @Action
  open(modalContext: {
    reservationId?: number
    quoteId?: number
    ticketTypeKey?: string
  }): void {
    this._isOpen = true
    this._reservationId = modalContext?.reservationId
    this._quoteId = modalContext?.quoteId
    this._ticketTypeKey = modalContext?.ticketTypeKey
  }

  /**
   * Closes the support modal.
   */
  @Action
  close(): void {
    this._isOpen = false
    this._reservationId = null
    this._quoteId = null
    this._ticketTypeKey = null
  }

  /**
   * Fetches all support quotes and reservations.
   */
  @Action
  async fetchAll(): Promise<void> {
    await Promise.all([this.fetchQuotes(), this.fetchReservations()])
  }

  /**
   * Fetches all tableview quotes and sets them in the store.
   */
  @Action
  async fetchQuotes(): Promise<void> {
    const filters = filter()
    const activeUnconvertedFilter: TableViewFilter[] = [
      {
        column: {
          _t_id: '02d4396a-762b-43bf-9e36-9ba82d419916',
          value: 'isConverted',
          filterType: 'eq',
          text: '',
        },
        value: 0,
      },
      {
        column: {
          _t_id: 'a2570cf1-70a1-40ee-a5ec-57c46e38601f',
          value: 'expirationDate',
          filterType: 'gte',
          text: '',
        },
        value: DateTime.now(),
      },
    ]
    for (const filter of activeUnconvertedFilter) {
      filters.and(filter).add(filter)
    }
    const response: AxiosResponse = await quotes.tableView({
      pageSize: -1,
      filters: filters.asQueryParams(),
    })
    if (response.status === 200) {
      const supportDialogQuotes = response.data.resultList.map(
        (quote: TableViewQuote) => {
          return {
            id: quote.quoteId,
            hash: quote.hash,
            label: this.getLabel(quote.trips[0].stops, quote.pickupDate), // show first trip for multi-trip quotes
          }
        }
      )
      this.setQuotes(supportDialogQuotes)
    }
  }

  /**
   * Fetches all tableview reservations and sets them in the store.
   */
  @Action
  async fetchReservations(): Promise<void> {
    const response: AxiosResponse = await reservation.tableView({
      pageSize: -1,
    })
    if (response.status === 200) {
      const supportDialogReservations = response.data.resultList.map(
        (reservation: TableViewReservation) => {
          return {
            id: reservation.reservationId,
            managedId: reservation.managedId,
            hash: reservation.hash,
            customerId: reservation.customerId,
            reservationStatus: reservation.reservationStatus,
            label: this.getLabel(
              reservation.stops,
              reservation.startDate,
              reservation.tripRouteName
            ),
          }
        }
      )
      this.setReservations(supportDialogReservations)
    }
  }

  /**
   * Clears all tableview quotes and reservations.
   */
  @Action
  clear(): void {
    this.setReservations(null)
    this.setQuotes(null)
  }

  /**
   * Condenses trip metadata into label for view layer.
   *
   * @param stops - array containing stop metadata to associate with label.
   * @param pickupDateISO - ISO string containing pickup time to associate with label.
   * @param tripRouteName - optional string containing custom name to associate with label.
   *
   * @returns a string containing the label.
   */
  getLabel(
    stops: TableViewStop[],
    pickupDateISO: string,
    tripRouteName?: string
  ): string {
    const firstStopName = this.getStopName(stops[0])
    const lastStopName = this.getStopName(stops[stops.length - 1])
    const pickupDate = DateTime.fromISO(pickupDateISO).toLocaleString({
      month: 'numeric',
      day: '2-digit',
    })
    return tripRouteName
      ? `(${pickupDate} ${firstStopName} > ${lastStopName}) - ${tripRouteName}`
      : `(${pickupDate} ${firstStopName} > ${lastStopName})`
  }

  /**
   * Extracts most relevant stop name from address.
   *
   * @param stop - object containing address metadata to associate with stop name.
   *
   * @returns a string containing the stop name.
   */
  getStopName(stop: TableViewStop): string {
    if (!stop?.address) {
      return ''
    }
    return (
      stop.address?.title ||
      stop.address?.city ||
      cityFromAddressName(stop.address?.addressName)
    )
  }
}

export default new SupportModule({ store, name: 'support' })
