var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('h2',{staticClass:"margin-l-1 margin-t-9"},[_vm._v(" "+_vm._s(_vm.$t('quoteDetail.operatorInfo.MORE_COMPANIES_NEARBY'))+" ")]),(_vm.$vuetify.breakpoint.lgAndUp)?_c('v-slide-group',{style:({ 'margin-top': _vm.bids.length > 3 ? '6px' : '18px' }),attrs:{"multiple":"","show-arrows":""}},_vm._l((_vm.bids),function(bid,bidIndex){return _c('v-slide-item',{key:`${bid.bidIds[0]}-${bidIndex}`},[_c('BidCard',{staticClass:"padding-x-2 padding-b-0",style:({ 'padding-top': '2px' }),attrs:{"id":`provider-bidcard-${bidIndex}`,"stacked":"","carousel":"","bid":bid,"go-to":{
          name: 'provider-detail',
          params: {
            quoteId: _vm.quoteId,
            providerId: bid.companyId,
          },
        },"is-elite":_vm.isElite,"show-usd":_vm.isPickupInCanada}})],1)}),1):_c('div',{staticClass:"margin-b-20",style:({ 'margin-top': '18px' })},_vm._l((_vm.bids),function(bid,bidIndex){return _c('BidCard',{key:`${bid.bidIds[0]}-${bidIndex}`,staticClass:"margin-b-3",attrs:{"id":`provider-bidcard-${bidIndex}`,"bid":bid,"go-to":{
        name: 'provider-detail',
        params: {
          quoteId: _vm.quoteId,
          providerId: bid.companyId,
        },
      },"is-elite":_vm.isElite,"show-usd":_vm.isPickupInCanada}})}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }