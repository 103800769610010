
import { Vue, Component, Prop } from 'vue-property-decorator'
import PaymentSent from '@/components/PaymentSent.vue'
import PayRemainingBalance from '@/components/PayRemainingBalance.vue'
import {
  ReservationDetail,
  ReservationIdentifiers,
  StagePaymentMethod,
} from '@/models/dto'
import { PaymentMethodTypeKey } from '@/utils/enum'
import { getAllowedBalancePaymentMethod } from '@/utils/payment'

@Component({
  components: {
    PaymentSent,
    PayRemainingBalance,
  },
})
export default class PayRemainingBalanceDialog extends Vue {
  @Prop({ type: Boolean }) readonly value: boolean
  @Prop({ type: Array, default: () => [] }) readonly reservationIdentifiers: ReservationIdentifiers[]
  @Prop({ type: Object, required: true }) readonly reservation: ReservationDetail
  @Prop({ type: Array, default: () => [] }) readonly balancePaymentMethods: StagePaymentMethod[]

  get showCreditCardInput(): boolean {
    return this.activePaymentMethod === PaymentMethodTypeKey.CreditCard
  }

  get showCheckWireInput(): boolean {
    return this.activePaymentMethod === PaymentMethodTypeKey.Check ||
      this.activePaymentMethod === PaymentMethodTypeKey.ACH
  }

  get activePaymentMethod(): PaymentMethodTypeKey {
    return getAllowedBalancePaymentMethod(this.balancePaymentMethods)
  }

  handlePaymentSuccess(): void {
    this.close()
    this.$emit('payment-success', this.activePaymentMethod)
  }

  close(): void {
    const payRemainingBalance = this.$refs['pay-remaining-balance'] as any
    payRemainingBalance.newCardSelected = false
    payRemainingBalance.errorMessage = null
    this.$emit('input', false)
  }
}
