
import { SalesBotCustomer } from '@/models/dto'
import selfServe from '@/store/modules/selfServe'
import selfServeService from '@/services/selfServe'
import { Vue, Component, Watch } from 'vue-property-decorator'
import auth from '@/store/modules/auth'
import AlertBox from '@/components/AlertBox.vue'

@Component({ components: { AlertBox } })
export default class SelfServeCustomerSignIn extends Vue {
  submitting = false
  loginFailed = false
  passwordHidden = true

  @Watch('password')
  passwordChanged(): void {
    this.loginFailed = false
  }

  get customer(): SalesBotCustomer {
    return selfServe.quote.customer
  }

  get email(): string {
    return this.customer?.email || ''
  }

  get password(): string {
    return this.customer?.password || ''
  }

  get disabled(): boolean {
    return !this.password
  }

  get forgotPasswordUrl(): string {
    return this.$router.resolve({ name: 'forgot-password' })?.href
  }

  mounted(): void {
    selfServe.quote.customer.password = null
  }

  async login(): Promise<void> {
    try {
      const authPayload = {
        email: this.email,
        password: this.password,
      }
      await auth.login(authPayload)
    } catch (err) {
      this.loginFailed = true
      console.warn(err)
      return
    }

    try {
      await auth.fetchUserDetails()
      await auth.refreshRolesAndPermissions()
      await auth.refreshCustomerAccount()
    } catch (err) {
      console.error(err)
    }
  }

  async submit(): Promise<void> {
    const form = this.$refs['self-serve-sign-in-form'] as any
    const isFormValid = form.validate()
    if (!isFormValid) {
      return
    }
    try {
      this.submitting = true
      await this.login()
      this.trackLogin()
      await this.createSelfServeQuotes()
    } catch (err) {
      console.error(err)
    }
    this.submitting = false
  }

  async createSelfServeQuotes(): Promise<void> {
    const payload = selfServe.quote.toObject()
    payload.customer = null
    const result = await selfServeService.create(payload)

    const quotes = result.data?.data
    const selfServeId = quotes?.[0]?.selfServeId

    selfServe.setSelfServeId(selfServeId)
    const quoteDetailsResponse = await selfServeService.bySelfServeIdV2(
      selfServeId
    )
    const quoteDetails = quoteDetailsResponse.data?.data
    selfServe.setQuoteDetails(quoteDetails)

    selfServe.getAvailableAmenities()
    selfServe.nextStep()
  }

  trackLogin(): void {
    this.$ga4Event('login', undefined)
  }
}
