import { AxiosResponse } from 'axios'
import { baseUrl } from '@/utils/env'
import { HttpService } from './common/HttpService'
import {
  ApiResult,
  EditTripContactPayload,
  EditTripContactResult,
  HotelInformationPayload,
  UpdateTripPayload,
  UpdateTripResult,
} from '@/models/dto'

const httpService: HttpService = new HttpService()
export default {
  /**
   * Edits the trip contact information for a trip with the given trip ID.
   *
   * @param tripId - The trip ID to use to edit the trip contact information.
   * @param payload - The payload containing the updated trip contact information.
   * @returns A promise that resolves to the result of editing the trip contact information.
   */
  editTripContact(
    tripId: number,
    payload: EditTripContactPayload
  ): Promise<AxiosResponse<EditTripContactResult>> {
    const url = `https://${baseUrl()}/trips/${tripId}/tripContact`
    return httpService.patch<EditTripContactResult, EditTripContactPayload>(
      url,
      payload
    )
  },
  /**
   * Edits the route name for a trip with the given trip ID.
   *
   * @param tripId - The trip ID to use to edit the route name.
   * @param payload - The payload containing the updated route name.
   * @returns A promise that resolves to the result of editing the route name.
   */
  editRouteName(
    tripId: number,
    payload: UpdateTripPayload
  ): Promise<AxiosResponse<UpdateTripResult>> {
    const url = `https://${baseUrl()}/trips/routeName/${tripId}`
    return httpService.patch<UpdateTripResult, UpdateTripPayload>(url, payload)
  },
  /**
   * Edits the hotel information for a trip with the given trip ID.
   *
   * @param tripId - The trip ID to use to edit the hotel information.
   * @param payload - The payload containing the hotel information.
   * @returns A promise that resolves to the result of editing the hotel information.
   */
  editHotelInfo(
    tripId: number,
    payload: HotelInformationPayload
  ): Promise<AxiosResponse<ApiResult>> {
    const url = `https://${baseUrl()}/trips/${tripId}/updateHotelInfo`
    return httpService.patch<ApiResult, HotelInformationPayload>(url, payload)
  }
}
