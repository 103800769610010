import { Mutation, Module, VuexModule } from 'vuex-class-modules'

import store from '@/store/index'

@Module({ generateMutationSetters: true })
class ChatModule extends VuexModule {
  _conversationSid: string | null = null

  get conversationSid(): string | null {
    return this._conversationSid
  }

  @Mutation
  setConversationSid(sid: string): void {
    this._conversationSid = sid
  }
}

export default new ChatModule({ store, name: 'chat' })
