
import { Vue, Component, Prop } from 'vue-property-decorator'
import { CheckoutDetailResult } from '@/models/dto'
import { currencyFilter } from '@/utils/string'

@Component({})
export default class CheckoutQuoteSummaryTotal extends Vue {
  @Prop({ type: Object, required: true })
  readonly checkoutDetail!: CheckoutDetailResult

  get hasAdditionalCharge(): boolean {
    return this.checkoutDetail.charges.filter(({amount}) => !!amount)?.length > 2
  }

  get formattedTotal(): string {
    return currencyFilter(this.checkoutDetail.totalAmount)
  }
}
