
import { Vue, Component, Prop } from 'vue-property-decorator'
import BookingProtectionDialog from '@/components/BookingProtectionDialog.vue'

@Component({ components: { BookingProtectionDialog } })
export default class CheckoutBookingProtectionGuarantee extends Vue {
  @Prop({ default: false }) isSelfServe!: boolean
  @Prop({ type: Boolean, required: false, default: false })
  readonly fullWidth!: boolean
}
