var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"zoom-toggle-group z-3 position-absolute right-12 background-white border-1 border-solid border-border-gray w-48 h-192 top-72"},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({staticClass:"zoom-toggle-group__option",on:{"click":function($event){return _vm.changeMode('full-route')}}},'div',attrs,false),on),[_c('CUIcon',{staticClass:"zoom-toggle-group__option__icon",class:{
            active: _vm.zoomOptions.fitEntireRoute,
          },style:({ margin: '10px' }),attrs:{"width":"35","height":"35"}},[_vm._v(" directions ")])],1)]}}])},[_c('span',{staticClass:"text-white"},[_vm._v(" "+_vm._s(_vm.$t('liveTracking.actions.FIT_ENTIRE_ROUTE'))+" ")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({staticClass:"zoom-toggle-group__option",on:{"click":function($event){return _vm.changeMode('next-stop')}}},'div',attrs,false),on),[_c('CUIcon',{staticClass:"zoom-toggle-group__option__icon",class:{
            disabled: _vm.disableZoomModes,
            active: _vm.zoomOptions.fitNextStop || _vm.zoomOptions.fitPreviousStop,
          },style:({ margin: '10px' }),attrs:{"width":"35","height":"35"}},[_vm._v(" bus_to_next_stop ")])],1)]}}])},[_c('span',{staticClass:"text-white"},[_vm._v(" "+_vm._s(_vm.$t('liveTracking.actions.FIT_BUS_AND_NEXT_STOP'))+" ")])]),_c('div',{staticClass:"zoom-toggle-group__option"},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({staticClass:"zoom-toggle-group__option",on:{"click":function($event){return _vm.changeMode('center')}}},'div',attrs,false),on),[_c('CUIcon',{staticClass:"zoom-toggle-group__option__icon",class:{
              disabled: _vm.disableZoomModes,
              active: _vm.zoomOptions.centerOnBus,
            },style:({ margin: '10px' }),attrs:{"width":"35","height":"35"}},[_vm._v(" bus ")])],1)]}}])},[_c('span',{staticClass:"text-white"},[_vm._v(_vm._s(_vm.$t('liveTracking.actions.FIT_BUS')))])])],1),_c('div',{staticClass:"zoom-toggle-group__option"},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({staticClass:"zoom-toggle-group__option",on:{"click":_vm.resetCustomOptions}},'div',attrs,false),on),[_c('CUIcon',{staticClass:"zoom-toggle-group__option__icon",class:{
              disabled: _vm.disableZoomModes || !_vm.zoomOptions.customOption,
            },style:({ margin: '10px' }),attrs:{"width":"35","height":"35"}},[_vm._v(" sync ")])],1)]}}])},[_c('span',{staticClass:"text-white"},[_vm._v(_vm._s(_vm.$t('liveTracking.actions.RESET')))])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }