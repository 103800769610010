import { TripModificationAddress } from '@/classes/TripModificationAddress'
import { Address, AddressType, PlaceDetail } from '../models/dto'
import deepClone from './deepClone'
import { cityFromAddressName } from './string'

export const placeDetailToAddress = (place: PlaceDetail): Address => {
  if (!place) {
    return null
  }

  const address: Address | TripModificationAddress = {
    addressName: place.addressName,
    name: place.addressName,
    city: place.city,
    country: place.country,
    completeAddress: place.completeAddress,
    lat: place.lat,
    lng: place.lng,
    postalCode: place.postalCode,
    state: place.state,
    street1: place.street1,
    timeZone: place.timeZone,
    zoneId: place.timeZone,
    title: place.title,
    addressTypes: placeDetailTypesToAddressTypes(place),
    addressTypeKeys: place.types,
  }

  return address
}

export const placeDetailTypesToAddressTypes = (
  place: PlaceDetail
): AddressType[] => {
  if (!place) {
    return []
  }

  const placeTypes = place.types
  if (!placeTypes) {
    return []
  }

  const addressTypes = placeTypes.map((type) => {
    return {
      addressTypeId: null,
      key: type,
      description: null,
      label: null,
    }
  })

  return addressTypes
}

export const getLatLngFromAddress = (
  address: Address
): { lat: number | undefined; lng: number | undefined } => {
  const { lat, lng } = address || {}
  return { lat, lng }
}

export const areAddressCitiesTheSame = (addresses: Address[]): boolean => {
  if (!addresses || addresses.length === 0) {
    return false
  }

  const cities = addresses.map((address) => address.city)
  const uniqueCities = [...new Set(cities)]
  return uniqueCities.length === 1
}

export const areAddressLocationsTheSame = (addresses: Address[]): boolean => {
  const locations = deepClone(addresses)
    .map((address) => getLatLngFromAddress(address))
    .map((location) => `${location.lat},${location.lng}`)
  const uniqueLocations = [...new Set(locations)]
  return uniqueLocations.length === 1
}

export const formatAddress = (address: Address): string => {
  if (!address) {
    return ''
  }
  const streetAddress = formatStreetAddress(address)
  const cityStateZip = formatCityStateZip(address)
  const addressParts = [streetAddress, cityStateZip]
  return addressParts.filter((x) => !!x).map((x) => x.trim()).join(', ')
}

export const formatStreetAddress = (address: Address): string => {
  if (!address) {
    return ''
  }
  const { street1, street2 } = address
  const streetAddresses = [street1, street2]
    .filter((x) => !!x)
    .map((x) => x.trim())
  return streetAddresses.join(', ')
}

export const formatCityStateZip = (address: Address): string => {
  if (!address) {
    return ''
  }
  const { city: rawCity, state, postalCode, addressName } = address
  const city = rawCity || cityFromAddressName(addressName)

  const cityState = [city, state]
    .filter((x) => !!x)
    .map((x) => x.trim())
    .join(', ')

  return postalCode ? `${cityState} ${postalCode.trim()}`.trim() : cityState
}
