import RouteConfigWithMeta from '@/models/RouteConfig'

const liveTracking: RouteConfigWithMeta = {
  path: '/livetracking/:reservationId?',
  name: 'live-tracking',
  props: { showTheAppBar: false },
  meta: { requiresAuth: true },
  component: () => import( /* webpackChunkName: "livetracking" */ '@/views/LiveTracking.vue'),
}

export default liveTracking
