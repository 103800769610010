
import { Vue, Prop, Component } from 'vue-property-decorator'

@Component
export default class LiveTrackingPickupMarker extends Vue {
  @Prop({ type: String, required: true, default: '#8EA8C0' })
  readonly color: string
  @Prop({ type: Number, required: true, default: 1 })
  readonly scalar: number
}
