
import { Vue, Component, Prop } from 'vue-property-decorator'
import { TranslateResult } from 'vue-i18n'
import { PaymentMethodTypeKey } from '@/utils/enum'

@Component
export default class PaymentSuccessDialog extends Vue {
  @Prop({ type: Boolean }) readonly value: boolean
  @Prop({ type: String }) readonly paymentMethod: PaymentMethodTypeKey

  get confirmationMessage(): TranslateResult {
    if (this.paymentMethod === PaymentMethodTypeKey.CreditCard) {
      return this.$t('paymentSuccess.BODY_CARD')
    }
    return this.$t('paymentSuccess.BODY_CHECK')
  }
}
