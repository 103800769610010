
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { gmapApi } from 'vue2-google-maps'
import { mapStyles } from '@/utils/mapStyles'
import markerSvg from '@/assets/images/marker.svg'

@Component({})
export default class TripMap extends Vue {
  @Prop({ type: Array, default: () => [] }) readonly stops: any[]

  @Watch('stops')
  async stopsChanged(newVal: any, oldVal: any): Promise<void> {
    if (this.detectChanges(newVal, oldVal)) {
      await this.loadData()
      await this.plotRoute()
    }
  }

  map = null
  center = { lat: 10, lng: 10 }
  bounds = null
  options = {
    zoomControl: false,
    mapTypeControl: false,
    scaleControl: false,
    streetViewControl: false,
    rotateControl: false,
    fullscreenControl: false,
    disableDefaultUi: true,
    styles: [
      ...mapStyles,
      {
        featureType: 'poi',
        stylers: [{ visibility: 'off' }],
      },
      {
        featureType: 'transit',
        elementType: 'labels.icon',
        stylers: [{ visibility: 'off' }],
      },
    ],
  }

  markerOptions = {
    visible: false,
    icon: {
      url: markerSvg
    },
  }

  get google(): any {
    return gmapApi()
  }

  async mounted(): Promise<void> {
    await this.loadData()
    await this.plotRoute()
  }

  async loadData(): Promise<void> {
    const mapRef = this.$refs.mapRef as any
    const mp = await mapRef.$mapPromise.then((mp) => mp)
    this.map = mp
    const bounds = new this.google.maps.LatLngBounds()
    for (const stop of this.stops) {
      bounds.extend({ lat: stop.address.lat, lng: stop.address.lng })
    }
    this.map.fitBounds(bounds)
  }

  plotRoute(): void {
    const directionsService = new this.google.maps.DirectionsService()
    const directionsRenderer = new this.google.maps.DirectionsRenderer()
    directionsRenderer.setOptions({
      map: this.map,
      markerOptions: this.markerOptions,
      polylineOptions: {
        strokeColor: '#434343',
        strokeOpacity: 0.6,
        strokeWeight: 5,
      },
    })

    const waypoints = this.stops.map((stop) => {
      return {
        location: new this.google.maps.LatLng(
          Number(stop.address.lat),
          Number(stop.address.lng)
        ),
        stopover: true,
      }
    })

    waypoints.map((waypoint) => {
      return new this.google.maps.Marker({
        position: waypoint.location,
        map: this.map,
        icon: this.markerOptions.icon,
      })
    })

    const origin = waypoints.shift().location
    let destination = waypoints.pop().location

    if (origin.equals(destination)) {
      destination = waypoints.pop().location
    }

    directionsService.route(
      {
        origin,
        destination,
        waypoints,
        travelMode: 'DRIVING',
      },
      (response, status) => {
        if (status === 'OK') {
          directionsRenderer.setDirections(response)
        } else {
          throw new Error(`Directions request failed due to ${status}`)
        }
      }
    )
  }

  detectChanges(newStops: any, oldStops: any): boolean {
    if (newStops.length !== oldStops.length) {
      return true
    }
    for (let i = 0; i < oldStops.length; i++) {
      if (oldStops[i].stopId !== newStops[i].stopId) {
        return true
      }
      if (oldStops[i].orderIndex !== newStops[i].orderIndex) {
        return true
      }
      if (
        oldStops[i].address.lat !== newStops[i].address.lat ||
        oldStops[i].address.lng !== newStops[i].address.lng
      ) {
        return true
      }
    }
    return false
  }
}
