
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import review from '@/services/review'
import { getLinkingVerb } from '@/utils/string'
import auth from '@/store/modules/auth'
import { RequiredVehicle, ReservationDetail, Stop } from '@/models/dto'
import { ReservationStatusKey } from '@/utils/enum'
import TripReviewFormAutosaveIndicator from '@/components/TripReviewFormAutosaveIndicator.vue'
import PickupSatisfactionSelector from '@/components/PickupSatisfactionSelector.vue'
import PickupReviewModal from '@/components/PickupReviewModal.vue'
import { minutesSince } from '@/utils/datetime'
import alert from '@/store/modules/alert'
import { TranslateResult } from 'vue-i18n'
import SkeletonBox from '@/components/SkeletonBox.vue'

@Component({
  components: {
    TripReviewFormAutosaveIndicator,
    PickupSatisfactionSelector,
    PickupReviewModal,
    SkeletonBox,
  },
})
export default class TripReviewForm extends Vue {
  @Prop({ type: Object, required: true }) reservation: ReservationDetail

  @Watch('review', { deep: true })
  reviewChanged(): void {
    if (!this.reviewInitalized) {
      return
    }
    this.isReviewEdited = true
    if (this.isFinished) {
      return
    }
    if (this.debounce) {
      window.clearTimeout(this.debounce)
    }
    this.debounce = window.setTimeout(async () => {
      await this.submit()
    }, 1000)
  }

  review = {
    charterUpRating: null,
    charterUpReview: '',
    operatorRating: null,
    operatorReview: '',
    driverRating: null,
    driverReview: '',
    pickupPositive: null,
    pickupReview: '',
    vehicleRating: null,
    vehicleReview: '',
  }

  submitting = false
  success = true
  error = false
  debounce = null
  isReviewEdited = false
  reviewInitalized = false

  isPickupReviewModalOpen = false
  isPickupAlreadyReviewed = false

  mounted(): void {
    this.initializeReview()
    this.processQueryParams()
  }

  get disabledSubmit(): boolean {
    return (
      !this.review.charterUpRating ||
      !this.review.operatorRating ||
      !this.review.driverRating ||
      !this.review.vehicleRating ||
      this.review.pickupPositive === null
    )
  }

  get isReviewComplete(): boolean {
    return (
      !this.isReviewEdited &&
      !!this.review.charterUpRating &&
      !!this.review.operatorRating &&
      !!this.review.driverRating &&
      !!this.review.vehicleRating &&
      this.review.pickupPositive !== null
    )
  }

  get hasReviewDetails(): boolean {
    return !!this.reservation?.reservationReview
  }

  get isFinished(): boolean {
    return this.reservation?.reservationStatus === ReservationStatusKey.Finished
  }

  get tense(): 'past' | 'present' {
    return this.isFinished ? 'past' : 'present'
  }

  get showPickupReviewFields(): boolean {
    return (
      (this.is15MinutesAfterFirstPickup && this.isStarted) || this.isFinished
    )
  }

  get showDriverAndVehicleReviewFields(): boolean {
    return (
      (this.is30MinutesAfterFirstPickup && this.isStarted) || this.isFinished
    )
  }

  get isStarted(): boolean {
    return this.reservation?.reservationStatus === ReservationStatusKey.Started
  }

  get minutesAfterFirstPickup(): number {
    const stops = this.reservation?.stops
    if (!stops) {
      return 0
    }
    const firstPickup: Stop = stops.find((stop) => stop.orderIndex === 0)
    if (!firstPickup) {
      return 0
    }
    return minutesSince(this.$dayjs(firstPickup.pickupDatetime).toISOString())
  }

  get is15MinutesAfterFirstPickup(): boolean {
    return this.minutesAfterFirstPickup >= 15
  }

  get is30MinutesAfterFirstPickup(): boolean {
    return this.minutesAfterFirstPickup >= 30
  }

  get requiredVehicles(): RequiredVehicle[] {
    return this.reservation?.requiredVehicles || []
  }

  get requiredVehicleLabel(): string {
    if (this.requiredVehicles.length !== 1) {
      return 'Vehicle'
    }
    return this.requiredVehicles[0].vehicleType.label
  }

  get requiredVehicleQuantity(): number {
    if (this.requiredVehicles.length !== 1) {
      return this.requiredVehicles.reduce((acc, curr) => acc + curr.quantity, 0)
    }
    return this.requiredVehicles[0].quantity
  }

  get requiredDriverQuantity(): number {
    return this.reservation?.requiredDrivers || 0
  }

  get driverQuestionText(): TranslateResult {
    if (this.requiredVehicleQuantity === 1) {
      return this.$t('reservationDetail.review.form.driver.LABEL_SINGULAR')
    }
    return this.$t('reservationDetail.review.form.driver.LABEL_PLURAL')
  }

  get vehicleQuestionText(): TranslateResult {
    if (this.requiredVehicleQuantity === 1) {
      return this.$t('reservationDetail.review.form.vehicle.LABEL_SINGULAR')
    }
    return this.$t('reservationDetail.review.form.vehicle.LABEL_PLURAL')
  }

  get charterUPExperienceText(): string {
    if (!this.hasReviewDetails) {
      return ''
    }
    const charterUPExperienceRatingRef = this.$refs
      .charterUPExperienceRatingRef as any
    const hoverIndex = charterUPExperienceRatingRef?.hoverIndex
    const rating = hoverIndex > -1 ? hoverIndex : this.review.charterUpRating
    if (!rating) {
      return ''
    }
    const fieldName = `${rating}_STAR_DETAIL`
    return this.$t(
      `reservationDetail.review.form.charterup.${fieldName}`
    ).toString()
  }

  get reservationCompanyText(): string {
    if (!this.hasReviewDetails) {
      return ''
    }
    const reservationCompanyRatingRef = this.$refs
      .reservationCompanyRatingRef as any
    const hoverIndex = reservationCompanyRatingRef?.hoverIndex
    const rating = hoverIndex > -1 ? hoverIndex : this.review.operatorRating
    if (!rating) {
      return ''
    }
    const fieldName = `${rating}_STAR_DETAIL`
    return this.$t(
      `reservationDetail.review.form.operator.${fieldName}`
    ).toString()
  }

  get driverRatingText(): string {
    if (!this.hasReviewDetails) {
      return ''
    }
    const driverRatingRef = this.$refs.driverRatingRef as any
    const hoverIndex = driverRatingRef?.hoverIndex
    const rating = hoverIndex > -1 ? hoverIndex : this.review.driverRating

    if (!rating) {
      return ''
    }
    const pluralOrSingular =
      this.requiredDriverQuantity === 1 ? 'SINGULAR' : 'PLURAL'
    const fieldName = `${rating}_STAR_DETAIL_${pluralOrSingular}`
    return this.$t(
      `reservationDetail.review.form.driver.${fieldName}`
    ).toString()
  }

  get vehicleRatingText(): string {
    if (!this.hasReviewDetails) {
      return ''
    }

    const vehicleRatingRef = this.$refs.vehicleRatingRef as any
    const hoverIndex = vehicleRatingRef?.hoverIndex
    const rating = hoverIndex > -1 ? hoverIndex : this.review.vehicleRating

    if (!rating) {
      return ''
    }
    const pluralOrSingular =
      this.requiredDriverQuantity === 1 ? 'SINGULAR' : 'PLURAL'
    const fieldName = `${rating}_STAR_DETAIL_${pluralOrSingular}`
    return this.$t(
      `reservationDetail.review.form.vehicle.${fieldName}`
    ).toString()
  }

  initializeReview(): void {
    const review = this.reservation?.reservationReview
    this.review = {
      charterUpRating: review?.charterUpRating || null,
      charterUpReview: review?.charterUpReview || '',
      operatorRating: review?.operatorRating || null,
      operatorReview: review?.operatorReview || '',
      driverRating: review?.driverRating || null,
      driverReview: review?.driverReview || '',
      pickupPositive: review?.pickupPositive,
      pickupReview: review?.pickupReview || '',
      vehicleRating: review?.vehicleRating || null,
      vehicleReview: review?.vehicleReview || '',
    }
    this.$nextTick(() => {
      this.reviewInitalized = true
    })
  }

  processQueryParams(): void {
    const queryParams = this.$route.query
    if (queryParams.hasOwnProperty('pickupRating')) {
      if (!this.isReviewComplete) {
        this.isPickupReviewModalOpen = true
        this.isPickupAlreadyReviewed = this.review.pickupPositive !== null
        if (!this.isPickupAlreadyReviewed) {
          this.review.pickupPositive =
            queryParams.pickupRating === 'null'
              ? null
              : queryParams.pickupRating === 'true'
        }
      }
      const query = Object.assign({}, queryParams)
      delete query.pickupRating
      this.$router.replace({ query })
    }
  }

  ratingTextClarification(
    rating: number,
    label: string,
    singular: boolean
  ): string {
    if (!rating || rating < 1 || rating > 5) {
      return ''
    }

    let description = ''
    let punctuation = '.'
    if (rating === 1) {
      description = 'terrible'
    }
    if (rating === 2) {
      description = 'bad'
    }
    if (rating === 3) {
      description = 'ok'
    }
    if (rating === 4) {
      description = 'good, just some small issues'
    }
    if (rating === 5) {
      description = 'great'
      punctuation = '!'
    }

    const verb = getLinkingVerb(singular, this.tense)
    return `${label} ${verb} ${description}${punctuation}`
  }

  redirectToReservationDetail(): void {
    this.$router.push({
      name: 'reservation-detail',
      params: { id: this.reservation.reservationId.toString() },
    })
  }

  async submit(): Promise<void> {
    this.success = false
    this.submitting = true
    this.error = false

    const payload = {
      ...this.review,
      hash: this.reservation.reservationReview.hash,
      reservationId: this.reservation.reservationId,
      customerId: auth.userId || null,
    }

    let success = false
    try {
      await review.create(payload)
      success = true
    } catch (error) {
      console.warn(error)
    }

    window.setTimeout(() => {
      this.submitting = false
      this.success = success
      if (success) {
        this.isReviewEdited = false
      }
      this.error = !success

      if (this.isFinished) {
        alert.add({
          text: 'Thank you for your feedback!',
          color: 'success',
          title: 'Review Saved',
          dismissible: true,
          duration: 5000,
        })
      }
    }, 500)
  }
}
