
import { CustomerAccount } from '@/models/dto'
import customerAccount from '@/services/customerAccount'
import auth from '@/store/modules/auth'
import { formatCityStateZip, formatStreetAddress } from '@/utils/address'
import {
  getSupportNumber,
  phoneFormatFilter,
  phoneFormatFilterHyphens,
} from '@/utils/phone'
import { Vue, Component } from 'vue-property-decorator'
import OrganizationAccountInfo from '@/components/OrganizationAccountInfo.vue'
import CustomerAccountDefaultValueSet from '@/models/dto/CustomerAccountDefaultValueSet'
import AccountExecutive from '@/models/dto/AccountExecutive'

@Component({ components: { OrganizationAccountInfo } })
export default class OrganizationAccount extends Vue {
  customerAccount: CustomerAccount | null = null
  effectiveDefaultValueSet: CustomerAccountDefaultValueSet | null = null

  async created(): Promise<void> {
    await Promise.all([
      this.getCustomerAccount(),
      this.getEffectiveDefaultValueSet(),
    ])
  }

  async getCustomerAccount(): Promise<void> {
    const { data } = await customerAccount.byId(
      auth.customerAccount.customerAccountId
    )
    this.customerAccount = data
  }

  async getEffectiveDefaultValueSet(): Promise<void> {
    const { data } = await customerAccount.effectiveDefaultValueSet(
      auth.customerAccount.customerAccountId
    )
    this.effectiveDefaultValueSet = data
  }

  get accountExecutiveDetails(): {
    label: string
    value: string
    columnClasses?: string
  }[] {
    return [
      {
        label: 'Account Executive',
        value: this.accountExecutiveName,
        columnClasses: 'col-md-4 col-12',
      },
      {
        label: 'Email',
        value: this.accountExecutiveEmail,
        columnClasses: 'col-md-4 col-12',
      },
      {
        label: 'Phone',
        value: this.accountExecutivePhone,
        columnClasses: 'col-md-4 col-12',
      },
    ]
  }

  get customerAccountInformation(): {
    label: string
    value: string
    columnClasses?: string
  }[] {
    const { name, email, phone, address } = this.customerAccount || {}
    return [
      {
        label: 'Name',
        value: name || '',
        columnClasses: 'col-md-4 col-12',
      },
      {
        label: 'Email',
        value: email || '',
        columnClasses: 'col-md-4 col-12',
      },
      {
        label: 'Phone',
        value: phoneFormatFilter(phone),
        columnClasses: 'col-md-4 col-12',
      },
      {
        label: 'Address',
        value: this.accountAddress,
        columnClasses: 'col-12',
      },
    ]
  }

  get accountTermsInformation(): {
    label: string
    value: string
    columnClasses?: string
  }[] {
    return [
      {
        label: 'Discount',
        value: this.discount,
        columnClasses: 'col-md-4 col-12',
      },
      {
        label: 'Payment Terms',
        value: this.paymentTerms,
        columnClasses: 'col-md-4 col-12',
      },
    ].filter(({ value }) => value)
  }

  get accountExecutive(): AccountExecutive {
    return this.effectiveDefaultValueSet?.accountExecutive
  }

  get accountExecutiveName(): string {
    const { firstName, lastName } = this.accountExecutive || {}
    if (!firstName || !lastName) {
      return ''
    }
    return `${firstName} ${lastName}`
  }

  get accountExecutiveEmail(): string {
    const { email } = this.accountExecutive || {}
    return email || ''
  }

  get accountExecutivePhone(): string {
    const accountExecutivePhoneNumber = this.accountExecutive.phone

    const number = getSupportNumber(auth.customer.isElite)
    return phoneFormatFilterHyphens(accountExecutivePhoneNumber || number)
  }

  get accountAddress(): string {
    const { address } = this.customerAccount || {}
    if (!address) {
      return ''
    }
    const streetAddress = formatStreetAddress(address)
    const cityStateZip = formatCityStateZip(address)
    return `${streetAddress}, ${cityStateZip}`
  }

  get discount(): string {
    const { discountPercent } = this.effectiveDefaultValueSet || {}
    if (!discountPercent) {
      return ''
    }
    return `${discountPercent}%`
  }

  get paymentTerms(): string {
    const { paymentTermsDays } = this.effectiveDefaultValueSet || {}
    if (!paymentTermsDays) {
      return ''
    }
    return `Net ${paymentTermsDays}`
  }

  get imageUrl(): string {
    return this.customerAccount?.lightLogoUrl || ''
  }
}
