
import { Vue, Component, Prop } from 'vue-property-decorator'
import CUItinerary from '@/components/CUItinerary.vue'
import CUItineraryItem from '@/components/CUItineraryItem.vue'
import TripInfoItineraryAddress from '@/components/TripInfoItineraryAddress.vue'
import TripInfoItineraryDropoffTime from '@/components/TripInfoItineraryDropoffTime.vue'
import TripInfoItineraryPickupTime from '@/components/TripInfoItineraryPickupTime.vue'
import { ModifiedStop } from '@/models/dto'

@Component({
  components: {
    CUItinerary,
    CUItineraryItem,
    TripInfoItineraryAddress,
    TripInfoItineraryDropoffTime,
    TripInfoItineraryPickupTime,
  },
})
export default class TripReviewItinerary extends Vue {
  @Prop({ type: Array, required: true }) readonly stops: ModifiedStop[]

  isChanged(stopIndex: number): boolean {
    return this.stops?.[stopIndex].stopChanged
  }
}
