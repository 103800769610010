var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:{ 'd-flex justify-center': _vm.$vuetify.breakpoint.xsOnly }},[(_vm.reservation)?_c('v-card',{staticClass:"border-1 border-solid border-border-gray z-1 display-flex margin-r-0",class:{
      'outline-none border-1 border-solid border-featured-border': _vm.featured,
    },style:({
      'flex-direction': 'column',
      'box-shadow': _vm.featured
        ? `0 0 12px ${_vm.$vuetify.theme.themes.light.featuredBorder}`
        : '',
      width: _vm.stacked ? `${_vm.imgWidth}px` : '',
    }),attrs:{"flat":""}},[_c('div',{staticClass:"d-flex",class:{ 'order-0': !_vm.stacked, 'order-1': _vm.stacked },style:({ 'flex-direction': _vm.stacked ? 'column' : 'row' })},[_c('div',{staticClass:"padding-a-2"},[_c('ReservationCardVehicleImage',{staticClass:"margin-a-0 z-0 border-radius-regular",class:{
            'object-fit-cover h-full': !_vm.stacked,
          },style:({
            height: _vm.stacked ? `${(1 / 2.44) * _vm.imgWidth}px` : '',
          }),attrs:{"width":_vm.stacked ? `${_vm.imgWidth - 20}px` : `${_vm.imgWidth - 60}px`,"required-vehicles":_vm.reservation.requiredVehicles,"loading":_vm.loading}})],1),_c('div',{staticClass:"w-full display-flex flex-wrap align-center justify-space-between",class:{
          'margin-b-2 padding-x-2': _vm.stacked,
          'margin-y-3 padding-r-2': !_vm.stacked,
        }},[_c('div',{class:{
            'margin-l-2 margin-y-4': _vm.stacked,
            'padding-l-4': !_vm.stacked,
          }},[_c('h1',{staticClass:"margin-b-0"},[_vm._v(_vm._s(_vm.reservationCities))]),_c('div',{staticClass:"d-flex align-center"},[(_vm.reservation.routeName)?_c('h3',{staticClass:"text-primary"},[_vm._v(" "+_vm._s(_vm.reservation.routeName)+" ")]):(_vm.allowReservationManagement)?_c('h3',{staticClass:"text-primary cursor-pointer",on:{"click":function($event){_vm.tripNameDialogIsOpen = true}}},[_vm._v(" "+_vm._s(_vm.$t('reservationDetail.reservationCard.WHATS_THIS_TRIP_CALLED'))+" ")]):_vm._e(),(_vm.allowReservationManagement)?_c('div',{staticClass:"d-flex align-center justify-center w-20 h-20 background-border-gray border-radius-round margin-l-1"},[_c('CUIcon',{staticClass:"margin-l-1 margin-t-1",attrs:{"color":"primary","x-small":"","view-box":"0 0 32 32"},on:{"click":function($event){_vm.tripNameDialogIsOpen = true}}},[_vm._v(" edit ")])],1):_vm._e()]),_c('div',{staticClass:"display-flex",class:{
              'margin-t-7':
                !_vm.reservation.routeName &&
                !_vm.allowReservationManagement &&
                _vm.$vuetify.breakpoint.smAndUp,
            }},[_c('div',{staticClass:"font-24"},[_vm._v(" "+_vm._s(_vm.$t('common.ID'))+" "+_vm._s(_vm.reservation.managedId)+" ")]),(_vm.loading)?_c('SkeletonBox',{staticClass:"w-100 h-24 margin-t-1 margin-l-2",style:({
                'border-radius': '12px !important',
              })}):(_vm.isPending)?_c('button',{staticClass:"border-primary border-solid border-1 rounded-pill margin-l-2 margin-t-1 padding-x-3 display-flex align-center",on:{"click":function($event){_vm.pendingTripDialogIsOpen = true}}},[_c('div',{staticClass:"text-primary margin-r-2 font-14"},[_vm._v(" Pending ")]),_c('div',{staticClass:"w-20 h-20 background-border-gray border-radius-round text-primary font-14 font-weight-600 margin-r-n2"},[_vm._v(" i ")])]):_c('button',{staticClass:"border-primary border-solid border-1 rounded-pill margin-l-2 margin-y-1 padding-x-3 display-flex align-center text-primary cursor-default font-14 margin--1"},[_vm._v(" "+_vm._s(_vm.formattedReservationStatus)+" ")])],1)]),_c('div',{staticClass:"d-flex w-full",class:{
            'margin-l-2 margin-b-3': _vm.stacked,
            'margin-t-1 padding-l-4': !_vm.stacked,
          }},[_c('TripLiveTracking',{attrs:{"reservation":_vm.reservation}})],1),(_vm.showViewDetailsButton)?_c('div',{staticClass:"d-flex flex-column",class:{
            'margin-y-2 margin-r-3 margin-l-4': _vm.$vuetify.breakpoint.smAndUp,
            'margin-a-0 w-full': _vm.stacked,
          }},[(_vm.loading)?_c('SkeletonBox',{staticClass:"h-44 margin-l-auto",class:{
              'w-132': !_vm.stacked,
              'w-full': _vm.stacked,
            }}):_vm._e(),_c('v-btn',{staticClass:"margin-l-auto w-full margin-t-2 margin-b-0",class:{
              'margin-r-3': _vm.$vuetify.breakpoint.smAndUp,
              'margin-x-0': _vm.stacked,
            },attrs:{"id":"mobile-reservation-card-button-view-itinerary","color":"primary","small":""},on:{"click":function($event){_vm.isItineraryModalOpen = true}}},[_vm._v(" "+_vm._s(_vm.$t('reservationDetail.reservationCard.VIEW_DETAILS'))+" ")])],1):_vm._e()])]),(_vm.isCancelled)?_c('div',{staticClass:"background-midnight-gray-50 padding-x-2 padding-y-1 font-14 font-medium text-center",class:{
        'order-0 border-radius-bottom-right-none border-radius-bottom-left-none': _vm.stacked,
        'order-1': !_vm.stacked,
      }},[_vm._v(" This trip was cancelled on "+_vm._s(_vm.formattedCancellationDate)+" ")]):_vm._e()]):_vm._e(),_c('PendingTripDialog',{model:{value:(_vm.pendingTripDialogIsOpen),callback:function ($$v) {_vm.pendingTripDialogIsOpen=$$v},expression:"pendingTripDialogIsOpen"}}),_c('TripNameDialog',{attrs:{"reservation":_vm.reservation},on:{"refresh":function($event){return _vm.$emit('refresh')}},model:{value:(_vm.tripNameDialogIsOpen),callback:function ($$v) {_vm.tripNameDialogIsOpen=$$v},expression:"tripNameDialogIsOpen"}}),_c('TripInfoItineraryModal',{attrs:{"reservation":_vm.reservation,"trip":_vm.trip,"title":"Details","allow-reservation-management":_vm.allowReservationManagement},model:{value:(_vm.isItineraryModalOpen),callback:function ($$v) {_vm.isItineraryModalOpen=$$v},expression:"isItineraryModalOpen"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }