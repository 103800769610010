
import { Vue, Component, Prop } from 'vue-property-decorator'
import { ActionColumn } from '@/models/ActionColumn'
import { AxiosResponse } from 'axios'
import { RawLocation } from 'vue-router'
@Component
export default class CUDataTableActionColumn extends Vue {
  @Prop({
    type: String,
    required: false,
    default: () => {
      return ''
    },
  })
  id!: string
  @Prop({
    type: Array,
    required: true,
    default: () => {
      return []
    },
  })
  actions!: ActionColumn[]
  @Prop({ required: false, default: undefined }) row!: any
  @Prop({ type: Number, required: true }) rowIndex!: number
  @Prop({ type: Boolean, required: true }) isMobile!: boolean
  @Prop({ type: Boolean, required: false }) displayActionsOnMobile!: boolean

  @Prop({ type: Boolean, required: false, default: false })
  isDetailTable!: boolean

  dialogOpen = false
  dialogTitle: string | undefined = ''
  dialogText: string | undefined = ''
  dialogSecondaryText: string | undefined = ''
  dialogPrimaryActionText = ''
  dialogPrimaryActionColor = ''
  dialogSecondaryActionText = ''

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  dialogConfirmFn = () => ({})
  currentAction: ActionColumn | undefined = undefined

  async confirmAction(): Promise<void> {
    const action = this.currentAction
    if (action) {
      const res: AxiosResponse = await action.action(this.row)
      if (res && res.status === 200) {
        this.$emit('refresh')
      }
    }
    this.closeDialog()
  }

  closeDialog(): void {
    this.dialogOpen = false
  }

  handleAction(action: ActionColumn): void {
    if (action.confirmModal) {
      this.dialogOpen = true
      this.dialogTitle =
        action.confirmModalTitle ||
        action.confirmModalPrimaryActionText ||
        'Confirm'

      this.dialogText = action.confirmModalTextFormatter
        ? action.confirmModalTextFormatter(this.row, action.confirmModalText)
        : action.confirmModalText

      this.dialogSecondaryText = action.confirmModalSecondaryText

      this.dialogPrimaryActionText =
        action.confirmModalPrimaryActionText || 'OK'
      this.dialogPrimaryActionColor =
        action.confirmModalPrimaryActionColor || action.color || 'primary'
      this.dialogSecondaryActionText =
        action.confirmModalSecondaryActionText || 'Cancel'
      this.currentAction = action
    } else if (action.action) {
      action.action(this.row, this.rowIndex)
    }
  }

  get detailAction(): ActionColumn | undefined {
    return this.actions.find((action) => action.isDetail)
  }

  get isActionsListEmpty(): boolean {
    return this.actions.filter((action) => !action.isDetail).length < 1
  }

  get visibleActionsList(): ActionColumn[] {
    return this.actions
      .filter((action) => !action.isDetail)
      .filter((action) => {
        if (!action.hideOn) {
          return true
        }
        return !action.hideOn(this.row)
      })
      .filter((action) => !action.isButton)
  }

  get visibleActionsButtons(): ActionColumn[] {
    return this.actions
      .filter((action) => !action.isDetail)
      .filter((action) => action.isButton)
      .filter((action) => {
        if (!action.hideOn) {
          return true
        }
        return !action.hideOn(this.row)
      })
  }

  get detailRoute(): RawLocation {
    const detailAction = this.actions.find((action) => action.key === 'details')
    if (detailAction) {
      return detailAction.detailRoute(this.row)
    }
    return {}
  }

  get hideDetailAction(): boolean {
    if (!this.detailAction?.hideOn) {
      return false
    }
    return this.detailAction.hideOn(this.row)
  }
}
