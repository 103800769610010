import { Module, VuexModule } from 'vuex-class-modules'
import store from '@/store/index'
import packageJson from '../../../package.json'

@Module({ generateMutationSetters: true })
class AppModule extends VuexModule {
  _title = 'CharterUP'
  _version: string = packageJson.version

  /**
   * Returns the app title.
   * @returns The app title.
   */
  get title(): string {
    return this._title
  }

  /**
   * Returns the app version.
   * @returns The app version.
   */
  get version(): string {
    return this._version
  }
}

export default new AppModule({ store, name: 'app' })
