var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"d-flex flex-column align-center margin-b-12"},[_c('div',{staticClass:"max-w-700 w-full d-flex flex-column"},[(_vm.hasCharterBus)?_c('SelfServeDefaultAmenities'):_vm._e(),_c('span',{class:`font-medium ${!_vm.hasCharterBus ? 'margin-t-3' : 'margin-t-7'}`},[_vm._v(" Add some extras ")]),_c('v-row',{staticClass:"text-primary w-full d-flex flex-wrap",class:{
        'justify-start': !_vm.displayThreeAmenities,
        'justify-space-between': _vm.displayThreeAmenities,
        'margin-l-0': _vm.$vuetify.breakpoint.smAndDown,
      },attrs:{"id":"self-serve-amenities-row","cols":"12"}},_vm._l((_vm.availableAmenities),function(amenity,amenityIndex){return _c('v-col',{key:`trip-amenity-${amenityIndex}`,class:{
          'min-h-180 padding-x-0': _vm.$vuetify.breakpoint.smAndDown,
          'min-h-220': _vm.$vuetify.breakpoint.mdAndUp,
        },attrs:{"cols":`${_vm.$vuetify.breakpoint.mdAndUp ? 4 : 12}`}},[_c('AmenityCard',{attrs:{"amenity":amenity,"is-vehicle-combination":_vm.isVehicleCombination}})],1)}),1),(_vm.isVehicleCombination)?_c('span',{staticClass:"text-gray-light font-14 margin-t-8",attrs:{"id":"self-serve-amenities-vehicle-combination-text"}},[_vm._v(" *Available for Charter Buses only. ")]):_vm._e(),_c('span',{staticClass:"w-full d-flex justify-center margin-t-12"},[_c('v-btn',{staticClass:"w-full d-flex",class:{
          'max-w-320': _vm.$vuetify.breakpoint.smAndUp,
        },attrs:{"id":"self-serve-amenities-submit-button","color":"primary","loading":_vm.submitting},on:{"click":_vm.submit}},[_vm._v(" Get Your Quote "),_c('CUIcon',{staticClass:"margin-l-2",attrs:{"view-box":"0 0 24 24"}},[_vm._v(" arrow_right ")])],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }