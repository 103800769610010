import alert from '@/store/modules/alert'
import app from '@/store/modules/app'
import auth from '@/store/modules/auth'
import confetti from '@/store/modules/confetti'
import flight from '@/store/modules/flight'
import footer from '@/store/modules/footer'
import ga4 from '@/store/modules/ga4'
import locale from '@/store/modules/locale'
import appMenu from '@/store/modules/appMenu'
import tripModification from '@/store/modules/tripModification'
import profile from '@/store/modules/profile'
import quotes from '@/store/modules/quotes'
import selfServe from '@/store/modules/selfServe'
import sidebar from '@/store/modules/sidebar'
import support from '@/store/modules/support'
import survey from '@/store/modules/survey'
import systemParameter from '@/store/modules/systemParameter'
import tracking from '@/store/modules/tracking'

const modules = {
  alert,
  app,
  appMenu,
  auth,
  confetti,
  flight,
  footer,
  ga4,
  locale,
  tripModification,
  profile,
  quotes,
  selfServe,
  sidebar,
  support,
  survey,
  systemParameter,
  tracking,
}

export default modules
