
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import trip from '@/services/trip'
import { ReservationDetail, UpdateTripPayload } from '@/models/dto'

@Component
export default class TripNameDialog extends Vue {
  @Prop({ type: Boolean }) readonly value: boolean
  @Prop({ type: Object, default: () => {} })
  readonly reservation: ReservationDetail

  routeName = ''

  @Watch('reservation.routeName', { immediate: true })
  routeNameChanged(value: string): void {
    this.routeName = value
  }

  async handleUpdate() {
    const tripPayload: Partial<UpdateTripPayload> = {
      routeName: this.routeName,
    }
    await trip.editRouteName(
      this.reservation.tripId,
      tripPayload as UpdateTripPayload
    )
    this.$emit('refresh')
    this.$emit('input', false)
  }
}
