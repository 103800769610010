var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('CUModal',{attrs:{"value":_vm.value,"max-width":"518px"},on:{"input":function($event){return _vm.$emit('input', false)}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Edit Rider")]},proxy:true},{key:"text",fn:function(){return [_c('v-form',{ref:"form",model:{value:(_vm.formIsValid),callback:function ($$v) {_vm.formIsValid=$$v},expression:"formIsValid"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('label',{attrs:{"for":"employeeId"}},[_vm._v("Employee ID")]),_c('v-text-field',_vm._b({ref:"employeeId",attrs:{"id":"invite-user-team-employeeId","required":"","validate-on-blur":"","outlined":""},model:{value:(_vm.formData.employeeId.value),callback:function ($$v) {_vm.$set(_vm.formData.employeeId, "value", $$v)},expression:"formData.employeeId.value"}},'v-text-field',_vm.formData.employeeId,false))],1),_c('v-col',{attrs:{"cols":"6"}},[_c('label',{attrs:{"for":"firstName"}},[_vm._v("First name")]),_c('v-text-field',_vm._b({ref:"firstName",class:{
              'padding-r-3': _vm.$vuetify.breakpoint.xs,
              'padding-r-4': _vm.$vuetify.breakpoint.smAndUp,
            },attrs:{"id":"invite-user-team-first-name","validate-on-blur":"","outlined":""},model:{value:(_vm.formData.firstName.value),callback:function ($$v) {_vm.$set(_vm.formData.firstName, "value", $$v)},expression:"formData.firstName.value"}},'v-text-field',_vm.formData.firstName,false))],1),_c('v-col',{attrs:{"cols":"6"}},[_c('div',{class:{
              'padding-l-3': _vm.$vuetify.breakpoint.xs,
              'padding-l-4': _vm.$vuetify.breakpoint.smAndUp,
            }},[_c('label',{attrs:{"for":"lastName"}},[_vm._v("Last name")]),_c('v-text-field',_vm._b({ref:"lastName",attrs:{"id":"invite-user-team-last-name","validate-on-blur":"","outlined":""},model:{value:(_vm.formData.lastName.value),callback:function ($$v) {_vm.$set(_vm.formData.lastName, "value", $$v)},expression:"formData.lastName.value"}},'v-text-field',_vm.formData.lastName,false))],1)]),_c('v-col',{attrs:{"cols":"6"}},[_c('label',{attrs:{"for":"email"}},[_vm._v("Email")]),_c('v-text-field',_vm._b({ref:"email",class:{
              'padding-r-3': _vm.$vuetify.breakpoint.xs,
              'padding-r-4': _vm.$vuetify.breakpoint.smAndUp,
            },attrs:{"id":"invite-user-team-email","validate-on-blur":"","outlined":""},model:{value:(_vm.formData.email.value),callback:function ($$v) {_vm.$set(_vm.formData.email, "value", $$v)},expression:"formData.email.value"}},'v-text-field',_vm.formData.email,false))],1),_c('v-col',{attrs:{"cols":"6"}},[_c('div',{class:{
              'padding-l-3': _vm.$vuetify.breakpoint.xs,
              'padding-l-4': _vm.$vuetify.breakpoint.smAndUp,
            }},[_c('label',{attrs:{"for":"phone"}},[_vm._v("Phone")]),_c('v-text-field',_vm._b({ref:"phone",attrs:{"id":"invite-user-team-phone","validate-on-blur":"","outlined":""},model:{value:(_vm.formData.phone.value),callback:function ($$v) {_vm.$set(_vm.formData.phone, "value", $$v)},expression:"formData.phone.value"}},'v-text-field',_vm.formData.phone,false))],1)]),_c('v-col',{attrs:{"cols":"12"}},[_c('OrganizationEditRiderChildCustomerAccounts',{attrs:{"available-customer-accounts":_vm.customerAccount.teams},model:{value:(_vm.childCustomerAccountIds),callback:function ($$v) {_vm.childCustomerAccountIds=$$v},expression:"childCustomerAccountIds"}})],1)],1),_c('AlertBox',{staticClass:"margin-b-3",attrs:{"active":!!_vm.errorMessage,"type":"error"}},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]),_c('v-row',{attrs:{"justify":"end","no-gutters":_vm.$vuetify.breakpoint.xs}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"id":"invite-user-team-cancel","small":"","text":""},on:{"click":_vm.close}},[_vm._v(" Cancel ")])],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"primary",attrs:{"id":"invite-user-team-submit","small":""},on:{"click":_vm.send}},[_vm._v(" Save ")])],1)],1)],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }