
import { Vue, Component, Prop } from 'vue-property-decorator'
import { CheckoutDetailResult, CustomerAccount, Quote } from '@/models/dto'
import {
  currencyFilter,
  twoDigitCentsFromCurrencyAmount,
} from '../utils/string'
import CustomerAccountDefaultValueSet from '@/models/dto/CustomerAccountDefaultValueSet'

@Component({})
export default class CheckoutQuoteSummaryDue extends Vue {
  @Prop({ type: Object, required: true })
  readonly quote!: Quote
  @Prop({ type: Object, required: true })
  readonly checkoutDetail!: CheckoutDetailResult
  @Prop({ type: Object, required: false, default: () => {} })
  readonly effectiveDefaultValueSet!: CustomerAccountDefaultValueSet
  @Prop({ type: Object, default: () => {} }) customerAccount!: CustomerAccount

  get dueDate(): string {
    return this.quote?.trips?.[0]?.dueDate
  }

  get dueNow(): number {
    return this.checkoutDetail.amountDueNow
  }

  get dueNowDollars(): string {
    return `${currencyFilter(this.dueNow).split('.')[0]}`
  }

  get dueNowCents(): string {
    return twoDigitCentsFromCurrencyAmount(this.dueNow)
  }

  get dueLater(): string {
    return currencyFilter(this.checkoutDetail.amountDueEventually)
  }

  get hasAmountDueLater(): boolean {
    return !!this.checkoutDetail?.amountDueEventually
  }

  get formattedDueDate(): string {
    const formattedDate = this.$dayjs(this.dueDate).format('MMM D, YYYY')
    return formattedDate || ''
  }

  get quoteCustomerAccountMatchesLoggedInCustomerAccount() {
    return (
      this.quote?.trips[0]?.customer?.customerAccountId &&
      this.quote?.trips[0]?.customer.customerAccountId ===
        this.customerAccount?.customerAccountId
    )
  }

  get accountPaymentTerms(): string {
    const { paymentTermsDays } = this.effectiveDefaultValueSet || {}
    if (
      !this.quoteCustomerAccountMatchesLoggedInCustomerAccount ||
      !paymentTermsDays
    ) {
      return ''
    }
    return `Net ${paymentTermsDays}`
  }
}
