
import { TranslateResult } from 'vue-i18n'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { CheckoutDetailResult } from '@/models/dto'
import { ChargeTypeId } from '@/utils/enum'
import { currencyFilter } from '@/utils/string'

@Component({})
export default class CheckoutQuoteSummaryCharges extends Vue {
  @Prop({ type: Object, required: true })
  readonly checkoutDetail!: CheckoutDetailResult

  currencyFilter = currencyFilter

  get charges(): { label: TranslateResult; amount: number; notes?: string }[] {
    const charges: {
      label: TranslateResult
      amount: number
      notes?: string
    }[] = [
      {
        label: this.$t('quoteDetail.billingSummaryV2.BASE_FARE'),
        notes: this.formattedVehiclesList,
        amount: this.baseFare,
      },
    ]
    if (this.processingFeeAmount) {
      charges.push({
        label: this.$t('quoteDetail.billingSummaryV2.PROCESSING_FEE'),
        amount: this.processingFeeAmount,
      })
    }
    if (this.totalAmenityAmount) {
      charges.push({
        label: this.$t('quoteDetail.billingSummaryV2.AMENITIES'),
        amount: this.totalAmenityAmount,
      })
    }
    if (this.discountAmount) {
      charges.push({
        label: `${this.$t('quoteDetail.billingSummaryV2.DISCOUNT')} ${
          this.formattedDiscountPercent
        }`,
        amount: this.discountAmount,
      })
    }
    return charges.filter((charge) => charge.amount !== 0)
  }

  get baseFare(): number {
    return this.checkoutDetail.charges.find(
      ({ chargeType }) => chargeType.id === ChargeTypeId.BaseFare
    )?.amount
  }

  get processingFeeAmount(): number {
    return this.checkoutDetail.charges.find(
      ({ chargeType }) => chargeType.id === ChargeTypeId.ProcessingFee
    )?.amount
  }

  get discountAmount(): number {
    return this.checkoutDetail.charges.find(
      ({ chargeType }) => chargeType.id === ChargeTypeId.Discount
    )?.amount
  }

  get totalAmenityAmount(): number {
    return this.checkoutDetail.charges.find(
      ({ chargeType }) => chargeType.id === ChargeTypeId.Amenities
    )?.amount
  }

  get formattedVehiclesList(): string {
    return this.checkoutDetail.quote.trips[0]?.vehicles
      ?.map(({ quantity, vehicleType }) => `${quantity}x ${vehicleType?.label}`)
      .join(', ')
  }

  get formattedDiscountPercent(): string {
    return this.checkoutDetail.discountPercent
      ? `(${Math.abs(this.checkoutDetail.discountPercent)}%)`
      : ''
  }

  get chargeCount(): number {
    return this.charges.length
  }
}
