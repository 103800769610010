
import { TableViewCustomer } from '@/models/dto'
import customerAccount from '@/services/customerAccount'
import { Vue, Component, Prop } from 'vue-property-decorator'
import OrganizationTeamEditUser from '@/components/OrganizationTeamEditUser.vue'
import OrganizationTeamDeleteUser from '@/components/OrganizationTeamDeleteUser.vue'
import alert from '@/store/modules/alert'

@Component({
  components: { OrganizationTeamEditUser, OrganizationTeamDeleteUser },
})
export default class OrganizationTeamMoreActions extends Vue {
  @Prop({ type: Object, required: true }) readonly row: TableViewCustomer

  sending = false
  sent = false
  isEditing = false
  isDeleting = false

  get isSignedUp(): boolean {
    return this.row.isSignedUp
  }

  async resendInvite(): Promise<void> {
    try {
      if (this.isSignedUp) {
        return
      }
      this.sending = true
      await customerAccount.resendInvite({ email: this.row.email })
      this.sent = true
      alert.add({
        text: 'Successfully sent email invite.',
        color: 'primary',
        title: 'Success',
        dismissible: true,
      })
    } catch (e) {
      this.sent = false
      alert.add({
        text: 'Error sending invite. Please try again later.',
        color: 'error',
        title: 'Error',
        dismissible: true,
      })
    }
    this.sending = false
  }
}
