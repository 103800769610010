var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"w-full h-full"},[_c('div',{staticClass:"recenter-button",class:{
      disabled: !_vm.customPosition,
      public: _vm.isModePublic,
      mobile: _vm.$vuetify.breakpoint.xs,
    },on:{"click":_vm.handleRecenter}},[_c('CUIcon',{style:({ margin: '10px' }),attrs:{"width":"35","height":"35"}},[_vm._v(" location ")])],1),_c('GmapMap',{ref:"gMap",staticClass:"map",class:{
      'table-view': _vm.isTableView,
      public: _vm.isModePublic,
    },attrs:{"zoom":_vm.mapConfig.zoom,"center":_vm.mapConfig.center,"options":_vm.mapConfig.options}},[(_vm.isEnterpriseAdmin && !_vm.isModePublic)?_c('GmapCluster',{attrs:{"options":{ maxZoom: 12 },"styles":_vm.clusterStyles}},[_vm._l((_vm.vehicleMarkers),function(marker){return _c('GmapMarker',{key:marker.id,attrs:{"position":marker.position,"icon":marker.icon,"clickable":true},on:{"click":function($event){return _vm.openInfoWindow(marker)}}})}),_c('GmapInfoWindow',{attrs:{"position":_vm.infoWindowPosition,"opened":_vm.infoWindowOpened,"closeable":true},on:{"closeclick":_vm.closeInfoWindow}},[_c('EnterpriseTrackingAdminInfoWindow',{attrs:{"vehicle":_vm.infoWindowContent,"eld-types":_vm.eldTypes},on:{"close":_vm.closeInfoWindow}})],1)],2):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }