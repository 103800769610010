
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import QrcodeVue from 'qrcode.vue'
import { TableViewRider } from '@/models/dto/TableViewRider'
import html2canvas from 'html2canvas'
import rider from '@/services/rider'

@Component({ components: { QrcodeVue } })
export default class ImportRiderModal extends Vue {
  @Prop({ type: Boolean, required: true }) readonly value: boolean
  @Prop({ type: Object }) readonly rider: TableViewRider

  riderTicketHash = ''

  get qrCodeJSON(): string {
    return JSON.stringify({ riderTicketHash: this.riderTicketHash })
  }

  get riderDisplayName(): string {
    if (this.rider.firstName && this.rider.lastName) {
      return `${this.rider.firstName} ${this.rider.lastName}`
    }
    return this.rider.employeeId
  }

  @Watch('rider', { deep: true })
  async riderChanged(): Promise<void> {
    if (this.rider?.riderId) {
      this.riderTicketHash = (
        await rider.getRiderTicketHash(this.rider.riderId)
      ).data.riderTicketHash.hash
    }
  }

  async downloadQRCode(): Promise<void> {
    const el: any = this.$refs.qrCodeContent

    const options: any = {
      type: 'dataURL',
    }
    const printCanvas = await html2canvas(el, options)

    const link = document.createElement('a')
    link.setAttribute('download', 'RiderQRCode.png')
    link.setAttribute(
      'href',
      printCanvas
        .toDataURL('image/png')
        .replace('image/png', 'image/octet-stream')
    )
    link.click()
  }

  close(): void {
    this.$emit('input', false)
  }
}
