
import { Vue, Prop, Component } from 'vue-property-decorator'
import InfoDialogLayout from '@/layouts/InfoDialogLayout.vue'
import auth from '@/store/modules/auth'
import { phoneFormatFilterHyphens, getSupportNumber } from '@/utils/phone'

@Component({ components: { InfoDialogLayout } })
export default class QuoteExpiredDialog extends Vue {
  @Prop({ type: Boolean, required: true }) readonly value: boolean

  quoteExpiredCallNumber(): void {
    window.open(`tel:${this.formattedSupportNumber}`)
  }
  get supportNumber(): string {
    return getSupportNumber(auth.customer.isElite)
  }
  get formattedSupportNumber(): string {
    return `1-${phoneFormatFilterHyphens(this.supportNumber)}`
  }
}
