
import { Vue, Component, Prop } from 'vue-property-decorator'
import { classToHexColor, applyOpacity } from '@/utils/color'
import { LiveTrackingStatus } from '@/utils/enum'
import tracking from '@/store/modules/tracking'
import { DEFAULT_MAP_THEME_COLOR } from '@/views/LiveTracking.vue'
import SkeletonBox from '@/components/SkeletonBox.vue'

@Component({ components: { SkeletonBox } })
export default class LiveTrackingItineraryItem extends Vue {
  @Prop({ type: String, required: true }) readonly status: string
  @Prop({ type: Boolean, required: false }) readonly loading: boolean
  @Prop({ type: String, required: false }) readonly color: string

  get icon(): string {
    switch (this.status) {
      case LiveTrackingStatus.Completed:
        return 'trip_stop'
      case LiveTrackingStatus.InProgress:
        return 'trip_origin'
      case LiveTrackingStatus.Upcoming:
        return 'trip_origin'
      default:
        return 'trip_origin'
    }
  }

  get itineraryItemColor(): string {
    const color = this.color ?? classToHexColor(DEFAULT_MAP_THEME_COLOR)
    if (this.status === LiveTrackingStatus.Completed) {
      return color
    }
    if (tracking.disableTracking) {
      return applyOpacity(color, 0.75)
    }
    return applyOpacity(color, 0.3)
  }
}
