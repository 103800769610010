export const PICKUP_MARKER_OFFSETS = {
  22: { lat: 0.00002, lng: 0.00001, scalar: 2 },
  21: { lat: 0.00002, lng: 0.00001, scalar: 1.8 },
  20: { lat: 0.00005, lng: 0.000025, scalar: 1.7 },
  19: { lat: 0.0001, lng: 0.00005, scalar: 1.6 },
  18: { lat: 0.0002, lng: 0.0001, scalar: 1.5 },
  17: { lat: 0.00025, lng: 0.000125, scalar: 1.4 },
  16: { lat: 0.0005, lng: 0.00025, scalar: 1.3 },
  15: { lat: 0.001, lng: 0.0005, scalar: 1.3 },
  14: { lat: 0.0015, lng: 0.00075, scalar: 1.3 },
  13: { lat: 0.003, lng: 0.0015, scalar: 1.3 },
  12: { lat: 0.007, lng: 0.0035, scalar: 1.2 },
  11: { lat: 0.015, lng: 0.0075, scalar: 1.2 },
  10: { lat: 0.025, lng: 0.0125, scalar: 1.2 },
  9: { lat: 0.05, lng: 0.025, scalar: 1.1 },
  8: { lat: 0.075, lng: 0.0375, scalar: 1.1 },
  7: { lat: 0.1, lng: 0.05, scalar: 1.1 },
  6: { lat: 0.15, lng: 0.075, scalar: 1 },
  5: { lat: 0.2, lng: 0.1, scalar: 1 },
  4: { lat: 0.25, lng: 0.125, scalar: 1 },
  3: { lat: 0.1, lng: 0.05, scalar: 1 },
  2: { lat: 0.2, lng: 0.1, scalar: 1 },
  1: { lat: 0.3, lng: 0.15, scalar: 1 },
  0: { lat: 0.4, lng: 0.2, scalar: 1 },
}
