
import { Vue, Component, Prop } from 'vue-property-decorator'
import { Stop } from '../models/dto'
import flight from '../store/modules/flight'

@Component({})
export default class TripInfoItineraryStopFlightInfo extends Vue {
  @Prop({ required: true }) stop!: Stop
  @Prop({ required: true }) tripStatus!: string
  @Prop({ required: true }) allowReservationManagement!: boolean

  get formattedFlightInformation(): string {
    const flightInfo = this.stop?.flightInformation
    if (!flightInfo) {
      return ''
    }
    return `${flightInfo?.flightNumber} — ${flightInfo?.airline}`
  }

  openFlightInfoModal(): void {
    flight.open({
      flightInformation: this.stop?.flightInformation,
      stop: this.stop,
    })
  }
}
