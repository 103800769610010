
import { Vue, Component, Prop } from 'vue-property-decorator'
import { ItineraryStop } from '../models/ItineraryStop'

@Component({})
export default class TripItineraryV2Details extends Vue {
  @Prop({ type: Object, required: true }) readonly itineraryStop!: ItineraryStop

  get label(): string {
    return this.itineraryStop?.label
  }

  get formattedAddress(): string | undefined {
    return this.itineraryStop?.formattedAddress
  }

  get vehicleStaysOnSite(): boolean {
    return this.itineraryStop?.vehicleStaysOnSite
  }

  get location(): string {
    return this.itineraryStop?.location
  }
}
