var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-row',[_vm._l((_vm.tripTypes),function(tripType,tripTypeIndex){return _c('v-col',{key:`trip-type-${tripType.label}-${tripTypeIndex}`,attrs:{"cols":"12","md":"4"}},[_c('div',{staticClass:"shadow-actionable-card border-radius-2x padding-a-4 border-2 border-solid font-medium text-center cursor-pointer transition-all transition-duration-300 transition-ease h-full d-flex",class:{
        'border-border-gray': !_vm.isTripTypeSelected(tripType.id),
        'border-primary': _vm.isTripTypeSelected(tripType.id),
        'flex-column align-center justify-end': _vm.$vuetify.breakpoint.smAndUp,
      },on:{"click":function($event){return _vm.selectTripType(tripType.id)}}},[(_vm.$vuetify.breakpoint.smAndUp)?_c('img',{staticClass:"min-h-76 max-h-100 margin-b-4",attrs:{"src":tripType.icon}}):_vm._e(),_c('span',{staticClass:"order-1 order-sm-0"},[_vm._v(_vm._s(tripType.label))]),_c('CURadioButton',{staticClass:"order-0 order-sm-1",class:{
          'margin-r-4': _vm.$vuetify.breakpoint.xs,
          'margin-t-2': _vm.$vuetify.breakpoint.smAndUp,
        },attrs:{"x-large":"","selected":_vm.isTripTypeSelected(tripType.id)}})],1)])}),_c('v-expand-transition',[(_vm.description)?_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"align-center d-flex justify-center padding-x-4 text-body-1"},[_c('CUIcon',{staticClass:"margin-r-2",attrs:{"view-box":"0 0 24 24","color":"primary"}},[_vm._v(" info_outlined ")]),_c('div',[_vm._v(_vm._s(_vm.description))])],1)]):_vm._e()],1),_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"w-full",class:{
        'max-w-320': _vm.$vuetify.breakpoint.smAndUp,
      },attrs:{"color":"primary","disabled":!_vm.selectedTripType},on:{"click":_vm.nextStep}},[_vm._v(" "+_vm._s(_vm.buttonText)+" "),_c('CUIcon',{staticClass:"margin-l-2",attrs:{"view-box":"0 0 24 24"}},[_vm._v(" arrow_right ")])],1)],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }