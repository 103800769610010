
import { TableViewCustomer } from '@/models/dto'
import { Vue, Component, Prop } from 'vue-property-decorator'
import Customer from '@/services/customer'
import alert from '@/store/modules/alert'

@Component({})
export default class OrganizationTeamDeleteUser extends Vue {
  @Prop({ type: Object, required: true })
  readonly existingCustomer: TableViewCustomer

  submitting = false

  cancel(): void {
    this.$emit('close')
  }

  async submit(): Promise<void> {
    const form = this.$refs['delete-customer-form'] as any
    if (!form.validate()) {
      this.$emit('invalid')
      return
    }
    this.submitting = true

    try {
      await Customer.uninviteCustomer(this.existingCustomer.customerId)
      this.$emit('close')
    } catch (e) {
      alert.add({
        text:
          'Error deleting user. Please try again later. If the problem persists, contact support.',
        color: 'error',
        title: 'Error',
        dismissible: true,
      })

      this.$emit('close')
    }

    this.submitting = false
  }
}
