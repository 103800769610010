
import { Vue, Component } from 'vue-property-decorator'
import BusSlider from '@/components/BusSlider.vue'
import nps from '@/services/nps'
import auth from '@/store/modules/auth'
import survey from '@/store/modules/survey'
import { NPSTypeId } from '@/utils/enum'

@Component({
  components: {
    BusSlider,
  },
})
export default class TheBookingExperienceSurvey extends Vue {
  submitHidden = true
  submitted = false
  submitting = false
  rating = 5
  survey = survey

  get responseMessage(): string {
    if (this.rating <= 6) {
      return 'We highly value all ideas and suggestions from all our customers. In the future, our team may reach out to you to learn more about how we can improve your experience so that it exceeds your expectations.'
    }
    if (this.rating <= 8) {
      return 'Our goal is to create the best possible booking experience and your feedback plays a major role in helping us improve.'
    }
    return 'It’s great to hear that you enjoyed your CharterUP booking experience.'
  }

  oneClickSubmit(delay): void {
    const delayMilliseconds = delay * 1000
    setTimeout(() => {
      this.submit()
    }, delayMilliseconds)
  }

  async submit(): Promise<void> {
    this.submitting = true
    const payload = {
      quoteId: survey.quoteId,
      userId: auth.userId,
      rating: this.rating,
      npsTypeId: NPSTypeId.PostBooking,
    }
    try {
      await nps.add(payload)
      this.trackTakeNpsSurvey()
      this.submitted = true
    } catch (error) {
      console.error(error)
    }
    this.submitting = false
  }

  trackTakeNpsSurvey(): void {
    this.$ga4Event('take_nps_survey', {
      page: 'Confirmation',
    })
  }
}
