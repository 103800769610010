
import { Component, Vue, Model, Prop } from 'vue-property-decorator'
import { EventBus } from '@/utils/eventBus'

@Component
export default class CUDataTableFilterButton extends Vue {
  @Model('input') readonly value!: boolean | null
  @Prop({ required: true }) readonly loading!: boolean

  filterCount = 0

  mounted(): void {
    EventBus.$on('add-filter', (e) => {
      this.filterCount++
    })

    EventBus.$on('remove-filter', (e) => {
      if (this.filterCount > 0) {
        this.filterCount--
      }
    })
  }
}
