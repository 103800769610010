
import { Vue, Component, Watch } from 'vue-property-decorator'
import auth from '@/store/modules/auth'
import authService from '@/services/auth'
import { TranslateResult } from 'vue-i18n'

@Component({})
export default class ProfilePassword extends Vue {
  @Watch('formFields', { deep: true })
  formFieldsChanged(): void {
    this.success = false
    this.error = false
    this.disabled = this.isDisabled()
  }

  valid = false
  formFields = {
    oldPassword: null,
    newPassword: null,
    confirmNewPassword: null,
  }
  rules: Record<string, any> = {}
  asyncPasswordResponse = {
    data: {
      valid: false,
      message: 'Something went wrong'
    }
  }
  shouldValidatePassword = false
  passwordHidden = true
  success = false
  error = false
  submitting = false
  disabled = true

  get submitButtonOptions(): {
    color: string
    text: TranslateResult
  } {
    let color = 'success'
    let text = this.$t('profile.PASSWORD.PASSWORD_CHANGED')
    if (!this.success) {
      color = this.error ? 'error' : 'primary'
      text = this.error
        ? this.$t('profile.PASSWORD.ERROR_CHANGING_PASSWORD')
        : this.$t('profile.PASSWORD.CHANGE_PASSWORD')
    }
    return {
      color,
      text,
    }
  }

  isDisabled(): boolean {
    if (this.formFields.oldPassword === null || !this.formFields.oldPassword) {
      return true
    }
    if (this.formFields.newPassword === null || !this.formFields.newPassword) {
      return true
    }
    if (
      this.formFields.confirmNewPassword === null ||
      !this.formFields.confirmNewPassword
    ) {
      return true
    }
    return false
  }

  activateRules(): void {
    this.rules = {
      oldPassword: [
        (v) =>
          !!v ||
          this.$t('profile.PASSWORD.FORM.CURRENT_PASSWORD.RULES.REQUIRED'),
      ],
      newPassword: [
        (v) =>
          !!v || this.$t('profile.PASSWORD.FORM.NEW_PASSWORD.RULES.REQUIRED'),
        (v) => !this.shouldValidatePassword || this.asyncPasswordResponse.data.valid || this.asyncPasswordResponse.data.message,
      ],
      confirmNewPassword: [
        (v) =>
          !!v ||
          this.$t('profile.PASSWORD.FORM.CONFIRM_NEW_PASSWORD.RULES.REQUIRED'),
        (v) =>
          v === this.formFields.newPassword ||
          this.$t('profile.PASSWORD.FORM.CONFIRM_NEW_PASSWORD.RULES.MATCH'),
      ],
    }
  }

  async submit(): Promise<void> {
    this.asyncPasswordResponse = await authService.validatePassword(this.formFields.newPassword)
    this.shouldValidatePassword = true
    this.activateRules()

    this.$nextTick(async () => {
      const form = this.$refs.changePasswordForm as any
      this.valid = form.validate()

      if (this.valid) {
        try {
          this.error = false
          const payload = {
            userId: auth.userId,
            currentPassword: this.formFields.oldPassword,
            newPassword: this.formFields.newPassword,
          }
          this.submitting = true
          await authService.setPassword(payload)
          this.success = true
          this.rules = {}
        } catch {
          this.error = true
        }
      } else {
        this.error = true
      }
      this.submitting = false
    })
  }

  resetPasswordValidation(): void {
    this.shouldValidatePassword = false
  }
}
