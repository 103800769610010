
import { Vue, Component, Prop } from 'vue-property-decorator'
import { TranslateResult } from 'vue-i18n'

@Component
export default class ReservationDetailNextSteps extends Vue {
  @Prop({ type: Boolean }) readonly value: boolean
  @Prop({ type: Boolean }) readonly isElite: boolean

  get modalTitle(): TranslateResult {
    return this.$t('reservationDetailNextSteps.TITLE.STANDARD')
  }

  get modalBody(): TranslateResult {
    return this.$t('reservationDetailNextSteps.DESCRIPTION.STANDARD')
  }
}
