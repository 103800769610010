
import { Vue, Component, Prop } from 'vue-property-decorator'
import TwoColumnLayout from '@/layouts/TwoColumnLayout.vue'
import { currencyFilter } from '@/utils/string'
import {
  ClientCancellationPenaltyInfo,
  ReservationDetail,
  RefundPolicyEntry,
} from '@/models/dto'
import ReservationCancellationSummary from './ReservationCancellationSummary.vue'
import ReservationCancellationPolicy from '@/components/ReservationCancellationPolicy.vue'
import { TranslateResult } from 'vue-i18n'
import ReservationCancellationSummaryTripInfo from '@/components/ReservationCancellationSummaryTripInfo.vue'
import ReservationCancelConfirmModal from '@/components/ReservationCancelConfirmModal.vue'
import { CancellationRefundPercentage } from '@/utils/enum'

@Component({
  components: {
    TwoColumnLayout,
    ReservationCancellationSummary,
    ReservationCancellationPolicy,
    ReservationCancellationSummaryTripInfo,
    ReservationCancelConfirmModal,
  },
})
export default class ReservationCancelConfirm extends Vue {
  @Prop({ type: Object, required: true })
  readonly reservation: ReservationDetail
  @Prop({ type: Object, required: true })
  readonly penaltyInfo!: ClientCancellationPenaltyInfo
  @Prop({ type: String, required: true })
  readonly header: string

  isModalOpen = false
  termsAccepted = false
  currencyFilter = currencyFilter
  isRefundPolicyModalOpen = false

  get refundPolicyPercent(): number {
    return this.penaltyInfo?.refundPolicyPercent
  }

  get refundPolicy(): RefundPolicyEntry[] {
    return this.penaltyInfo?.refundPolicy
  }

  get refundPolicyPercentValidUntilTime(): string {
    return this.penaltyInfo?.refundPolicyPercentValidUntilTime
  }

  get bookingAmount(): number {
    return this.penaltyInfo?.amount
  }

  get paidAmount(): number {
    return this.penaltyInfo?.paid
  }

  get cancellationFeeAmount(): number {
    return this.penaltyInfo?.cancellationFee
  }

  get balanceAmount(): number {
    if (!this.penaltyInfo?.balanceDue) {
      return 0
    }
    return this.penaltyInfo?.balanceDue
      ? Math.max(this.penaltyInfo?.balanceDue, 0)
      : 0
  }

  get cancelDisabled(): boolean {
    return !this.termsAccepted
  }

  get refundNoticeCopy(): TranslateResult {
    return this.$t('reservationDetail.cancellationConfirm.REFUND_NOTICE', {
      cancellationPenalityPercentString: this.cancellationPenaltyPercentString,
    })
  }

  get cancellationPenaltyPercentString(): TranslateResult {
    if (
      this.refundPolicyPercent === CancellationRefundPercentage.None ||
      !this.refundPolicyPercent
    ) {
      return this.$t('reservationDetail.cancellationConfirm.NOT')
    }

    return `${this.refundPolicyPercent * 100}%`
  }

  get startDate(): string {
    if (!this.reservation) {
      return null
    }
    return this.reservation?.pickupDate
  }

  get priceBreakdown(): {
    label: TranslateResult
    amount: number
    styles?: string
  }[] {
    const charges: {
      label: TranslateResult
      amount: number
      styles?: string
    }[] = [
      {
        label: this.$t('reservationDetail.cancellationConfirm.YOUR_BOOKING'),
        amount: this.bookingAmount,
      },
      {
        label: this.$t('reservationDetail.cancellationConfirm.YOU_PAID'),
        amount: this.paidAmount,
      },
      {
        label: this.$t(
          'reservationDetail.cancellationConfirm.CANCELLATION_FEE'
        ),
        amount: this.cancellationFeeAmount,
        styles: 'font-20 text-red',
      },
      {
        label: this.$t('reservationDetail.cancellationConfirm.BALANCE_DUE'),
        amount: this.balanceAmount,
      },
    ]
    return charges
  }

  get isElite(): boolean {
    return !!this.reservation?.isElite
  }


  get isSelfServe(): boolean {
    return !!this.reservation?.isSelfServe
  }

  handleContinue(): void {
    this.isModalOpen = true
  }
}
