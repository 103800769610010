import auth from '@/store/modules/auth'
import RouteConfigWithMeta from '@/models/RouteConfig'

const reservations: RouteConfigWithMeta =
{
  path: 'reservations',
  meta: { requiresAuth: true },
  component: () => import('@/views/Empty.vue'),
  children: [
    {
      path: '',
      name: 'reservation-index',
      meta: { requiresAuth: true },
      props: (router) => {
        return { successDialog: router.params.successDialog }
      },
      component: () =>
        import(
          /* webpackChunkName: "reservation-index" */ '@/views/ReservationIndex.vue'
        ),
    },
    {
      path: 'hash/:hash',
      name: 'reservation-detail-by-hash',
      meta: { requiresAuth: true },
      component: () =>
        import(
          /* webpackChunkName: "reservation-detail" */ '@/views/ReservationDetail.vue'
        ),
      beforeEnter: async (_to, _from, next) => {
        await auth.refreshCustomerAccount()
        next()
      },
    },
    {
      path: ':id',
      name: 'reservation-detail',
      meta: { requiresAuth: true },
      props: (router) => ({
        openTripModificationWizard: router.params.openTripModificationWizard == 'true',
      }),
      component: () =>
        import(
          /* webpackChunkName: "reservation-detail" */ '@/views/ReservationDetail.vue'
        ),
      beforeEnter: async (_to, _from, next) => {
        await auth.refreshCustomerAccount()
        next()
      },
    },
    {
      path: ':id/cancel',
      name: 'reservation-cancel',
      meta: { requiresAuth: true },
      component: () =>
        import(
          /* webpackChunkName: "reservation-cancel" */ '@/views/ReservationCancel.vue'
        ),
    },
  ],
}

export default reservations
