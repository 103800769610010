import { Action, Module, VuexModule } from 'vuex-class-modules'
import store from '@/store/index'
import quotes from '@/services/quotes'

@Module({ generateMutationSetters: true })
class QuotesModule extends VuexModule {
  _unreadQuoteCount = 0

  /**
   * Returns the number of unread quotes.
   * @returns The number of unread quotes.
   */
  get unreadQuoteCount(): number {
    return this._unreadQuoteCount
  }

  /**
   * Fetches the number of unread quotes and saves it to the state.
   */
  @Action
  async fetchUnreadQuoteCount(): Promise<void> {
    const updatedQuoteCount = await quotes.count()
    this._unreadQuoteCount = updatedQuoteCount
  }
}

export default new QuotesModule({ store, name: 'quotes' })
