
import { Vue, Component, Prop } from 'vue-property-decorator'
import { ClientCancellationPenaltyInfo, ReservationDetail } from '@/models/dto'
import ReservationCancellationSummaryTripInfo from '@/components/ReservationCancellationSummaryTripInfo.vue'
import ReservationCancellationSummaryCharges from '@/components/ReservationCancellationSummaryCharges.vue'

@Component({
  components: {
    ReservationCancellationSummaryTripInfo,
    ReservationCancellationSummaryCharges,
  },
})
export default class ReservationCancellationSummary extends Vue {
  @Prop({ type: Object, required: true })
  readonly reservation!: ReservationDetail
  @Prop({ type: Object, required: true })
  readonly penaltyInfo!: ClientCancellationPenaltyInfo

  get formattedCancellationDate(): string {
    const { cancelledOn } = this.reservation || {}
    if (!cancelledOn) {
      return ''
    }
    return this.$dayjs(cancelledOn).format('MMMM D, h:mm A')
  }

}
