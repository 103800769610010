
import { Vue, Component, Prop } from 'vue-property-decorator'
import CUIcon from '@/components/CUIcon.vue'
import {
  SpecialRequirementsTypeKey,
  SpecialRequirementsTypeLabel,
} from '@/utils/enum'

@Component({
  components: {
    CUIcon,
  },
})
export default class TripReviewRequirements extends Vue {
  @Prop({ type: Boolean, required: true }) readonly ada: boolean
  @Prop({ type: Boolean, default: false }) readonly adaChanged?: boolean
  @Prop({ type: Boolean, required: true }) readonly spab: boolean
  @Prop({ type: Boolean, default: false }) readonly spabChanged?: boolean
  @Prop({ type: Boolean, required: true })
  readonly vehicleNeededEntireTrip: boolean
  @Prop({ type: Boolean, default: false })
  readonly vehicleNeededEntireTripChanged?: boolean

  SpecialRequirementsTypeKey = SpecialRequirementsTypeKey

  get selectedTripRequirements(): Array<{
    icon: string
    title: string
    key: string
    modified: boolean
  }> {
    return [
      {
        icon: 'bus_alert',
        title: SpecialRequirementsTypeLabel.VehicleNeededEntireTrip,
        condition: this.vehicleNeededEntireTrip,
        key: SpecialRequirementsTypeKey.VehicleNeededEntireTrip,
        modified: this.vehicleNeededEntireTripChanged,
      },
      {
        icon: 'accessible',
        title: SpecialRequirementsTypeLabel.ADA,
        condition: this.ada,
        key: SpecialRequirementsTypeKey.ADA,
        modified: this.adaChanged,
      },
      {
        icon: 'school',
        title: SpecialRequirementsTypeLabel.SPAB,
        condition: this.spab,
        key: SpecialRequirementsTypeKey.SPAB,
        modified: this.spabChanged,
      },
    ]
      .filter(({ condition }) => condition)
      .map(({ icon, title, key, modified }) => {
        return { icon, title, key, modified }
      })
  }
}
