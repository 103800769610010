
import { Vue, Component, Prop } from 'vue-property-decorator'
import TwoColumnLayout from '@/layouts/TwoColumnLayout.vue'
import ReservationCancellationSummary from './ReservationCancellationSummary.vue'
import ReservationCancelCostWarning from '@/components/ReservationCancelCostWarning.vue'
import ReservationCancellationPolicy from '@/components/ReservationCancellationPolicy.vue'
import auth from '@/store/modules/auth'
import reservation from '@/services/reservation'
import cancellation from '@/services/cancellation'
import { phoneFormatFilterHyphens, getSupportNumber } from '@/utils/phone'
import {
  ReservationDetail,
  ClientCancellationPenaltyInfo,
  RefundPolicyEntry,
  Classification,
} from '@/models/dto'

@Component({
  components: {
    TwoColumnLayout,
    ReservationCancelCostWarning,
    ReservationCancellationSummary,
    ReservationCancellationPolicy,
  },
})
export default class ReservationCancelReason extends Vue {
  @Prop({ type: Number }) readonly reasonId: number
  @Prop({ type: Object }) readonly reservation: ReservationDetail
  @Prop({ type: String }) readonly otherDescription: string
  @Prop({ type: Object, required: true })
  readonly penaltyInfo!: ClientCancellationPenaltyInfo
  @Prop({ type: String, required: true })
  readonly header: string

  cancellationClassifications: Classification[] = []

  async created() {
    const response = await cancellation.getCancellationClassifications()
    this.cancellationClassifications = response.data.subClassifications
  }

  get isElite(): boolean {
    return !!this.reservation?.isElite
  }

  get isSelfServe(): boolean {
    return !!this.reservation?.isSelfServe
  }

  get refundPolicyPercent(): number {
    return this.penaltyInfo?.refundPolicyPercent
  }

  get refundPolicy(): RefundPolicyEntry[] {
    return this.penaltyInfo?.refundPolicy
  }

  get refundPolicyPercentValidUntilTime(): string {
    return this.penaltyInfo?.refundPolicyPercentValidUntilTime
  }

  get supportNumber(): string {
    return getSupportNumber(auth.customer.isElite)
  }

  get formattedSupportNumber(): string {
    return phoneFormatFilterHyphens(this.supportNumber)
  }

  get isCancellationReasonOther(): boolean {
    return this.cancellationClassifications.find(
        (classification) => classification.classificationId === this.reasonId
      )?.isOther
  }

  get continueDisabled(): boolean {
    return !this.reasonId  || (this.isCancellationReasonOther && !this.otherDescription)
  }

  get formattedCancellationClassifications(): Classification[] {
    const classificationKeys = {
      'event_cancelled': 'My event was cancelled',
      'event_rescheduled': 'My event was rescheduled',
      'rebooked_with_charterup': 'I found a better option on CharterUP',
      'found_cheaper_option_elsewhere': 'I found a cheaper option somewhere else',
      'found_better_option_elsewhere': 'I found a better option somewhere else',
      'other_please_specify': 'Other - please specify:'
    };

    const filteredClassifications = Object.keys(classificationKeys)
        .map(key => {
            const classification = this.cancellationClassifications.find(c => c.key === key);
            return classification ? { ...classification, label: classificationKeys[key] } : null;
        })
        .filter(Boolean);

    return filteredClassifications;
  }

}
