var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.selectedTripRequirements.length > 0)?_c('div',{staticClass:"d-flex align-center justify-start row margin-t-n1 margin-b-2"},_vm._l((_vm.selectedTripRequirements),function(selectedTripRequirement,selectedTripRequirementIndex){return _c('div',{key:`${selectedTripRequirement}-trip-requirement-${selectedTripRequirementIndex}-${_vm.$vuetify.breakpoint.xs}`,staticClass:"padding-y-1 col-md-4 col-lg-6 col-sm-6",class:{
      'col-6':
        selectedTripRequirement.key !==
        _vm.SpecialRequirementsTypeKey.VehicleNeededEntireTrip,
      'col-12':
        selectedTripRequirement.key ===
        _vm.SpecialRequirementsTypeKey.VehicleNeededEntireTrip,
    }},[_c('div',{staticClass:"d-flex align-center"},[_c('CUIcon',{staticClass:"cursor-default margin-r-1",attrs:{"color":"gray-subtle-text-2"}},[_vm._v(" "+_vm._s(selectedTripRequirement.icon)+" ")]),_c('p',{staticClass:"margin-t-0"},[_vm._v(_vm._s(selectedTripRequirement.title))])],1)])}),0):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }