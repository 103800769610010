import RouteConfigWithMeta from '@/models/RouteConfig'
import auth from '@/store/modules/auth'
import { hasPermissionGuard } from '@/utils/router'

const finance: RouteConfigWithMeta =
{
  path: '/finance',
  meta: { requiresAuth: true },
  component: () => import('@/views/Empty.vue'),
  beforeEnter: (to, from, next) => {
    hasPermissionGuard(auth.isFinanceAdmin)(to, from, next)
  },
  children: [
    {
      path: '',
      name: 'finance',
      props: { mode: 'charge-dispute' },
      meta: { requiresAuth: true },
      component: () => import('@/views/Finance.vue'),
    },
    {
      path: 'charge-dispute',
      name: 'charge-dispute',
      props: { mode: 'charge-dispute' },
      meta: { requiresAuth: true },
      component: () => import('@/views/Finance.vue'),
    },
  ],
}

export default finance
