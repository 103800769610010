var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"cu-timeline-item d-flex"},[_vm._t("left"),_c('div',{staticClass:"cu-timeline-item__divider position-relative top-0"},[_c('div',{staticClass:"cu-timeline-item__line w-0 h-full position-absolute border-l-2 border-y-0 border-r-0 border-midnight-gray-300",class:{
        'top-21': !_vm.noBottomGap,
        'top-17': _vm.noBottomGap,
        'border-solid': !_vm.dashedLine,
        'border-dashed': _vm.dashedLine,
      },style:({
        left: '50%',
        transform: 'translateX(-50%)',
        height: `calc(100% - ${!_vm.noTopGap ? 19 : 6}px)`,
      })}),_c('div',{staticClass:"top-0 position-absolute",style:({
        left: '50%',
        transform: 'translate(-50%, 0%)',
      })},[_vm._t("icon")],2)]),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }