
import { Vue, Component, Prop } from 'vue-property-decorator'
import CUDataTableActionColumn from '@/components/CUDataTableActionColumn.vue'
import CUDataTableHeaderCheckbox from '@/components/CUDataTableHeaderCheckbox.vue'
import CUDataTableHeaderActionRow from '@/components/CUDataTableHeaderActionRow.vue'
import CUDataTableMobilePredefinedSelects from '@/components/CUDataTableMobilePredefinedSelects.vue'
import { ActionColumn } from '@/models/ActionColumn'
import CUDataTableCell from '@/components/CUDataTableCell.vue'
import { DataTableColumn } from '@/models/DataTableColumn'
import { TableViewParameters } from '@/models/TableView'
import deepClone from '@/utils/deepClone'
import { SelectColumn } from '@/models/SelectColumn'

@Component({
  components: {
    CUDataTableActionColumn,
    CUDataTableCell,
    CUDataTableHeaderCheckbox,
    CUDataTableHeaderActionRow,
    CUDataTableMobilePredefinedSelects,
  },
})
export default class CUDataTable extends Vue {
  @Prop({ type: Array, required: false, default: undefined })
  actions!: ActionColumn[]
  @Prop({ type: Array, required: false, default: undefined }) items!: any[]
  @Prop({ type: Array, required: false, default: undefined })
  selectedItems: unknown[]
  @Prop({ type: Object, required: false, default: () => {} })
  selectColumn!: SelectColumn
  @Prop({ type: Array, required: true, default: [] })
  columns!: DataTableColumn[]
  @Prop({ type: Number, required: true }) serverItemsLength!: number
  @Prop({ type: Object, required: true }) options!: TableViewParameters
  @Prop({ type: Boolean, default: false }) isDetailTable!: boolean
  @Prop({ type: String, required: false }) detailName!: string
  @Prop({ type: Boolean, required: false }) isEditableTable!: boolean
  @Prop({ type: String, required: false }) itemKey!: string
  @Prop({ type: String, required: false, default: 'xs' })
  mobileViewOnBreakpoint!: string
  @Prop({ type: Boolean, required: false }) hideDefaultHeader!: boolean
  @Prop({ type: Boolean, required: false }) displayActionsOnMobile!: boolean
  @Prop({ type: String, required: false, default: 'No data found' })
  noDataText!: string
  @Prop({ type: Boolean, required: false, default: false })
  transparentHeaders!: boolean
  @Prop({ type: String, required: false, default: 'data-table' })
  tableElementId!: string
  @Prop({ type: String, required: false, default: '' }) uuid!: string
  @Prop({ type: Boolean, required: false }) dense!: boolean
  @Prop({ type: Boolean, required: false }) showSelect!: boolean
  @Prop({ type: Boolean, required: false }) hideDefaultFooter!: boolean
  @Prop(Function) rowClickHandler: (row: any, index: number) => any

  get isMobile(): boolean {
    switch (this.mobileViewOnBreakpoint) {
      case 'xs':
        return this.$vuetify.breakpoint.xs
      case 'sm':
        return this.$vuetify.breakpoint.smAndDown
      case 'md':
        return this.$vuetify.breakpoint.mdAndDown
      case 'lg':
        return this.$vuetify.breakpoint.lgAndDown
      default:
        return false
    }
  }

  get headerColumns(): any[] {
    return this.columns
      .filter((col) => col.value !== 'select')
      .map((col) => ({
        ...col,
        slotName: `header.${col.value}`,
        headerName: `header.${col.value}`,
      }))
  }

  get areItemsSelected(): boolean {
    return !!this.selectedItems?.length
  }

  hideCell(col, item): boolean {
    if (col.hideCell) {
      return col.hideCell(item)
    }
    return false
  }

  handleRowClick(col: DataTableColumn, item: any, index: number): void {
    if (['actions', 'select'].includes(col.type) || !this.rowClickHandler) {
      return
    }
    this.rowClickHandler(item, index)
  }

  updateOptionsPage(pageNumber: number): void {
    const options = deepClone(this.options)
    options.page = pageNumber
    this.$emit('update:options', options)
  }

  isItemSelected(item: any): boolean {
    return !!this.selectedItems?.includes(item[this.itemKey])
  }
}
