import Vue from 'vue'
import { Action, Module, VuexModule } from 'vuex-class-modules'
import store from '@/store/index'
import router from '@/router'
import { SalesBotQuote } from '@/classes/SalesBotQuote'
import { SalesBotQuote as SalesBotQuoteDTO } from '@/models/dto/SalesBot'
import quotes from '@/services/quotes'
import auth from '@/store/modules/auth'
import selfServeService from '@/services/selfServe'
import typeService from '@/services/type'
import { roundSecondsToNearestXMinutes } from '@/utils/datetime'
import {
  SelfServeStepKey,
  AmenityTypeId,
  PricingMethod,
  SplitEvents,
} from '@/utils/enum'
import selfServe from '../../services/selfServe'
import { SelfServeStep } from '../../models/SelfServeStep'
import { AmenityType, QuoteDetailV2, TripVehicle } from '@/models/dto'
import { WizardStore } from '../WizardStore'

const SELF_SERVE_DRIVE_TIME_MULTIPLIER = 1.1
const PAID_AMENITY_TYPE_IDS = [
  AmenityTypeId.Wifi,
  AmenityTypeId.AlcoholConsumption,
  AmenityTypeId.LeatherSeats,
]
@Module({ generateMutationSetters: true })
class SelfServeModule extends VuexModule implements WizardStore<SelfServeStep> {
  _quote: SalesBotQuote = null
  _currentTripIndex = 0
  _steps: SelfServeStep[] = []
  _step: SelfServeStep = null
  _quoteId: number = null
  _selfServeId: string = null
  _isEditing = false
  _isExistingUser = false
  _quoteDetails: QuoteDetailV2[] = []
  _availableAmenities: AmenityType[] = []

  /**
   * Gets the quote.
   * @returns The quote.
   */
  get quote(): SalesBotQuote {
    return this._quote
  }

  /**
   * Gets the quoteDetails.
   * @returns The quote details.
   */
  get quoteDetails(): QuoteDetailV2[] {
    return this._quoteDetails
  }

  /**
   * Gets the current step.
   * @returns The current step.
   */
  get step(): SelfServeStep {
    return this._step
  }

  /**
   * Gets all steps.
   * @returns All steps.
   */
  get steps(): SelfServeStep[] {
    return this._steps
  }

  /**
   * Gets the total number of steps.
   * @returns The total number of steps.
   */
  get stepCount(): number {
    if (this._isExistingUser) {
      return this.steps.length - 1 // Reduce steps for returning users
    }
    return this.steps.length
  }

  /**
   * Gets the current step index.
   * @returns The current step index.
   */
  get currentStepIndex(): number {
    const stepIndex = this.steps.findIndex(({ key }) => key === this._step?.key)

    // Adjust index for returning users
    if (
      this._step?.key !== SelfServeStepKey.CustomerInformation &&
      this._isExistingUser
    ) {
      return stepIndex - 1
    }

    return stepIndex
  }

  /**
   * Gets the current trip index.
   * @returns The current trip index.
   */
  get currentTripIndex(): number {
    return this._currentTripIndex
  }

  /**
   * Gets the quote ID.
   * @returns The quote ID.
   */
  get quoteId(): number {
    return this._quoteId
  }

  /**
   * Gets the selected quote ID.
   * @returns The quote ID.
   */
  get selectedQuoteId(): number {
    return this._quote?.selectedQuoteId
  }

  /**
   * Gets the self serve ID.
   * @returns The self serve ID.
   */
  get selfServeId(): string {
    return this._selfServeId
  }

  /**
   * Gets a value indicating whether the user is currently editing the quote.
   * @returns A value indicating whether the user is currently editing the quote.
   */
  get isEditing(): boolean {
    return this._isEditing
  }

  /**
   * Gets a value indicating whether the user is an existing user.
   * @returns A value indicating whether the user is an existing user.
   */
  get isExistingUser(): boolean {
    return this._isExistingUser
  }

  /**
   * Gets the quote that is currently selected in the self-serve flow.
   * @returns The currently selected quote.
   */
  get selectedQuoteDetail(): QuoteDetailV2 {
    return this._quoteDetails.find(
      (q) => q.quoteId === this._quote.selectedQuoteId
    )
  }

  /**
   * Gets the current trip's list of vehicles.
   * @returns the current trip's list of vehicles.
   */
  get tripVehicles(): TripVehicle[] {
    return this.selectedQuoteDetail?.trips.flatMap((trip) => trip.vehicles)
  }

  /**
   * Gets the list of available, selectable amenities for the trip.
   * @returns the list of available, selectable amenities.
   */
  get availableAmenities(): AmenityType[] {
    return this._availableAmenities
  }

  /**
   * Initializes the store.
   */
  @Action
  initialize(quote?: SalesBotQuoteDTO): void {
    this._quote = new SalesBotQuote(quote)
    this._currentTripIndex = 0
    this._step = null
    this._quoteId = null
    this._isEditing = false
    this._isExistingUser = false
    this._quoteDetails = []
    this.calculateSteps()
  }

  /**
   * Initializes the store.
   */
  @Action
  setQuoteDetails(quoteDetails: QuoteDetailV2[]): void {
    this._quoteDetails = quoteDetails
  }

  /**
   * Advances to the next step in the self serve quote process.
   */
  @Action
  nextStep(): void {
    //If we're traversing forward with an already signed-in user,
    //make sure we skip sign in & sign up steps
    if (
      this._step?.key === SelfServeStepKey.TripDetails &&
      (auth.isTokenSet || this._isExistingUser)
    ) {
      const vehiclePricing = this.steps.find(
        (step) => step.key === SelfServeStepKey.VehiclePricing
      )
      this.setStep(vehiclePricing)
      return
    }

    const stepIndex = this.steps.findIndex(({ key }) => key === this._step.key)
    if (stepIndex === this.steps.length - 1) {
      return
    }
    const nextStep = this.steps[stepIndex + 1]
    this.setStep(nextStep)
  }

  /**
   * Advances to the select vehicle pricing step in the self serve quote process.
   */
  @Action
  moveToVehicleStep(): void {
    const vehicleStep = this.steps.find(
      (step) => step.key === SelfServeStepKey.VehiclePricing
    )
    this.setStep(vehicleStep)
  }

  /**
   * Advances to the trip itinerart step in the self serve quote process.
   */
  @Action
  moveToItineraryStep(): void {
    const itineraryStep = this.steps.find(
      (step) => step.key === SelfServeStepKey.TripItinerary
    )
    this.setStep(itineraryStep)
  }

  /**
   * Goes back to the previous step in the self serve quote process.
   */
  @Action
  previousStep(): void {
    if (this._step?.key === SelfServeStepKey.CustomerSignIn) {
      const contactInfo = this.steps.find(
        (step) => step.key === SelfServeStepKey.CustomerInformation
      )
      this.setStep(contactInfo)
      return
    }

    //If we're traversing back,
    //ensure we skip sign in & sign up steps
    if (this._step?.key === SelfServeStepKey.VehiclePricing) {
      const tripDetails = this.steps.find(
        (step) => step.key === SelfServeStepKey.TripDetails
      )
      this.setStep(tripDetails)
      return
    }

    const stepIndex = this.steps.findIndex(({ key }) => key === this._step.key)
    if (stepIndex === 0) {
      return
    }
    const previousStep = this.steps[stepIndex - 1]
    this.setStep(previousStep)
  }

  /**
   * Sets the current step.
   * @param step - The step to set.
   */
  @Action
  setStep(step: SelfServeStep): void {
    this._step = step
  }

  /**
   * Sets the current step.
   * @param step - The step to set.
   */
  @Action
  clearStep(): void {
    this._step = null
  }

  /**
   * Sets the steps.
   * @param steps - The steps to set.
   */
  @Action
  private setSteps(steps: SelfServeStep[]): void {
    this._steps = steps
  }

  /**
   * Navigates to the customer sign in step.
   */
  @Action
  goToSignIn(): void {
    const signIn = this.steps?.find(
      (step) => step.key === SelfServeStepKey.CustomerSignIn
    )
    this.setStep(signIn)
  }

  /**
   * Sets the quote ID.
   * @param quoteId - The quote ID to set.
   */
  @Action
  setQuoteId(quoteId: number): void {
    this._quoteId = quoteId
  }
  /**
   * Sets the quote ID.
   * @param quoteId - The quote ID to set.
   */
  @Action
  setSelectedQuoteId(selectedQuoteId: number): void {
    this._quote.selectedQuoteId = selectedQuoteId
  }

  /**
   * Sets the self serve ID.
   * @param selfServeId - The self serve ID to set.
   */
  @Action
  setSelfServeId(selfServeId: string): void {
    this._selfServeId = selfServeId
  }

  /**
   * Sets a value indicating whether the user is currently editing.
   * @param isEditing - A value indicating whether the user is currently editing.
   */
  @Action
  setIsEditing(isEditing: boolean): void {
    this._isEditing = isEditing
  }

  /**
   * Sets a value indicating whether the user is an existing user.
   * @param isExistingUser - A value indicating whether the user is an existing user.
   */
  @Action
  setIsExistingUser(isExistingUser: boolean): void {
    const isValueChanging = this._isExistingUser !== isExistingUser
    this._isExistingUser = isExistingUser
    if (isValueChanging) {
      this.calculateSteps()
    }
  }

  /**
   * Sets the trip type for a given trip.
   * @param params - The parameters, containing trip index and trip type ID, for setting the trip type.
   */
  @Action
  setTripType(params: { tripIndex: number; tripTypeId: number }): void {
    const { tripIndex, tripTypeId } = params
    if (this._quote.trips?.[tripIndex]) {
      this._quote.trips[tripIndex].tripTypeId = tripTypeId
      this._quote.trips[tripIndex].tripType.id = tripTypeId
    }
  }

  /**
   * Sets the passenger count for a given trip.
   * @param params - The parameters, containing trip index and passenger count, for setting the passenger count.
   */
  @Action
  setTripPassengerCount(params: {
    tripIndex: number
    passengerCount: number
  }): void {
    const { tripIndex, passengerCount } = params
    if (!this._quote.trips?.[tripIndex]) {
      return
    }
    this._quote.trips[tripIndex].passengerCount = passengerCount
  }

  /**
   * Sets if a trip is SPAB or not.
   * @param spab - spab value
   */
  @Action
  setTripIsSPAB(spab: boolean): void {
    if (!this._quote.trips?.[this._currentTripIndex]) {
      return
    }
    this._quote.trips[this._currentTripIndex].spab = spab
  }

  /**
   * Sets if a quote customer opts in or out of quote sales SMS messages
   * @param isEnabled
   */
  @Action
  setQuoteSalesSMSEnabled(isEnabled: boolean): void {
    this._quote.customer.isQuoteSalesSMSEnabled = isEnabled
  }

  /**
   * Sets if a trip is ADA or not.
   * @param ada - ada value
   */
  @Action
  setTripIsADA(ada: boolean): void {
    if (!this._quote.trips?.[this._currentTripIndex]) {
      return
    }
    this._quote.trips[this._currentTripIndex].ada = ada
  }

  /**
   * Sets the event type ID for a given trip.
   * @param params - The parameters, containing trip index and trip event type id,
   * for setting the event type ID.
   */
  @Action
  setTripEventTypeId(params: {
    tripIndex: number
    tripEventTypeId: number
  }): void {
    const { tripIndex, tripEventTypeId } = params
    if (this._quote.trips?.[tripIndex]) {
      this._quote.trips[tripIndex].tripEventTypeId = tripEventTypeId
    }
  }

  /**
   * Sets the name for a given trip.
   * @param params - The parameters, containing trip index and user-submitted name,
   * for setting the trip name.
   */
  @Action
  setTripName(params: { tripIndex: number; tripName: string }): void {
    const { tripIndex, tripName } = params
    if (this._quote.trips?.[tripIndex]) {
      this._quote.trips[tripIndex].routeName = tripName
    }
  }

  /**
   * Gets the estimated duration for each stop and the trip as a whole.
   */
  @Action
  async getTripEstimations(): Promise<void> {
    let response
    if (auth.isTokenSet) {
      response = await quotes.tripEstimation(this._quote.toObject())
    } else {
      response = await quotes.tripEstimationPublic(this._quote.toObject())
    }
    const tripEstimations = response?.data
    if (!tripEstimations) {
      return
    }
    for (let tripIndex = 0; tripIndex < tripEstimations.length; tripIndex++) {
      const tripEstimation = tripEstimations[tripIndex]
      for (
        let stopIndex = 0;
        stopIndex < tripEstimation.timesFromPreviousStop.length;
        stopIndex++
      ) {
        const stop = this._quote.trips[tripIndex].stops[stopIndex]
        if (stop && stop.address) {
          const duration = Math.max(
            tripEstimation.timesFromPreviousStop[stopIndex] *
              SELF_SERVE_DRIVE_TIME_MULTIPLIER,
            15
          )
          stop.travelTimeFromPreviousStopInSeconds = roundSecondsToNearestXMinutes(
            duration,
            15
          )
        }
      }
    }
  }

  /**
   * Populates the quote with an existing quote.
   * @param existingQuote - The existing quote to populate with.
   */
  @Action
  populateQuote(existingQuote: SalesBotQuoteDTO): void {
    const quote = new SalesBotQuote(existingQuote)
    this._quote = quote
  }

  @Action
  async fetchQuoteDetails(): Promise<void> {
    try {
      const response = await selfServe.bySelfServeIdV2(this._selfServeId)
      const quoteDetails = response?.data?.data
      this._quoteDetails = quoteDetails
    } catch (error) {
      console.error(error)
    }
  }

  @Action
  calculateSteps(): void {
    const steps = calculateSteps(
      this._isEditing,
      this._quote,
      this._isExistingUser
    )
    this.setSteps(steps)
    if (!this._step) {
      this._step = this._steps[0]
    }
  }

  /**
   * Fetches all existing amenity types and filters for currently available types.
   */
  @Action
  async getAvailableAmenities(): Promise<void> {
    const response = await typeService.amenity({})
    this._availableAmenities = response.data.resultList.filter((amenity) =>
      PAID_AMENITY_TYPE_IDS.includes(amenity.id)
    )
  }

  /**
   * Toggles the amenity's 'selected' value based on input.
   * @param amenityContext - New 'selected' value and the amenity's type ID.
   */
  @Action
  setAmenitySelection(amenityContext: {
    value: boolean
    amenityTypeId: number
  }): void {
    const { value, amenityTypeId } = amenityContext

    const amenity = this._availableAmenities.find(
      (a) => a.id === amenityContext.amenityTypeId
    )

    if (!amenity) {
      return
    }

    const tripAmenities = this._quote.trips[this._currentTripIndex]
      .tripAmenities

    const amenityIndex = tripAmenities.findIndex((a) => {
      return a.id === amenityTypeId
    })

    if (value) {
      // if selected and not already in tripAmenities, add it
      if (amenityIndex === -1) {
        tripAmenities.push(amenity)
      }
    } else if (amenityIndex !== -1) {
      // if not selected and already in tripAmenities, remove it
      tripAmenities.splice(amenityIndex, 1)
    }
  }

  @Action
  autoSelectCheapestQuote(): void {
    if (!this._quoteDetails?.length) {
      return
    }

    const getBidAmount = (quote: any) =>
      quote?.bids?.[0]?.totalAmount ?? Infinity

    const recommendedQuote = this._quoteDetails.find((q) => q.isRecommended)
    if (recommendedQuote?.quoteId) {
      this.setSelectedQuoteId(recommendedQuote.quoteId)
      return
    }

    const cheapestQuote = this._quoteDetails.reduce((acc, curr) => {
      return getBidAmount(curr) < getBidAmount(acc) ? curr : acc
    })
    if (cheapestQuote?.quoteId) {
      this.setSelectedQuoteId(cheapestQuote.quoteId)
    }
  }

  @Action
  async submitSelfServeQuote(): Promise<void> {
    const payload = this.quote.toObject()
    await selfServeService.update(payload, this.selfServeId)

    this.trackCreateQuote(this.quoteDetails?.[0]?.isElite)
    Vue.prototype.$split.track(SplitEvents.QuoteCreated)
  }

  @Action
  async getQuoteDetailAndRedirect(): Promise<void> {
    const quoteId = this.selectedQuoteId
    const response = await quotes.detailV2(quoteId)
    const {
      isLastMinuteTrip,
      isLargeEvent,
      isLongTermShuttle,
      isBillAfterServicesAndWithin4Days,
      areInitialBidsGenerated,
      pricingMethod,
    } = response?.data?.data

    if (isLastMinuteTrip) {
      router.push({
        name: 'quote-index',
        query: { isLastMinuteTrip: 'true' },
      })
      this.clearStep()
      return
    }

    if (isLargeEvent) {
      router.push({
        name: 'quote-index',
        query: { isLargeEvent: 'true', quoteId: quoteId.toString() },
      })
      this.clearStep()
      return
    }

    if (isLongTermShuttle) {
      router.push({
        name: 'quote-index',
        query: { isLongTermShuttle: 'true', quoteId: quoteId.toString() },
      })
      this.clearStep()
      return
    }

    if (isBillAfterServicesAndWithin4Days) {
      router.push({
        name: 'quote-index',
        query: { isBillAfterServicesAndWithin4Days: 'true' },
      })
      this.clearStep()
      return
    }

    if (areInitialBidsGenerated) {
      if (pricingMethod === PricingMethod.Category) {
        this.goToCheckoutPage()
      } else if (pricingMethod === PricingMethod.Bids) {
        this.goToQuoteDetailPage()
      }
    }
  }

  @Action
  trackCreateQuote(isElite: boolean): void {
    Vue.prototype.$ga4Event('create_quote', {
      isElite,
      isSelfServe: true,
      hasBookedBefore: auth?.customer?.convertedQuoteCount > 0,
      isLoggedIn: true,
    })
  }

  @Action
  goToCheckoutPage(): void {
    const selectedQuoteId = this.selectedQuoteId
    const selectedQuote = this.quoteDetails.find(
      (quote) => quote.quoteId === selectedQuoteId
    )

    if (
      !selectedQuote.isLastMinuteTrip &&
      !selectedQuote.isLargeEvent &&
      !selectedQuote.isLongTermShuttle &&
      !selectedQuote.isBillAfterServicesAndWithin4Days
    ) {
      router.push({
        name: 'checkout-single-bid',
        params: { quoteId: selectedQuoteId.toString() },
      })
      this.clearStep()
      return
    }
  }

  @Action
  goToQuoteDetailPage(): void {
    const selectedQuoteId = this.selectedQuoteId
    router.push({
      name: 'quote-detail',
      params: { id: selectedQuoteId.toString(), mode: 'bids' },
    })
    this.clearStep()
    return
  }
}

const calculateSteps = (
  isEditing: boolean,
  quote: SalesBotQuote,
  isExistingUser: boolean
): SelfServeStep[] => {
  const firstName =
    auth.isTokenSet && auth.user.firstName ? ` ${auth.user.firstName}` : ''
  return [
    {
      component: () => import('@/components/SalesBotTripType.vue'),
      header: `Let's ${isEditing ? 'update your' : 'get you a'} quote${
        firstName ? `, ${firstName}` : ''
      }!`,
      subhead: 'What kind of trip is this?',
      key: SelfServeStepKey.TripType,
      orderIndex: 0,
    },
    {
      component: () => import('@/components/SelfServeTripItinerary.vue'),
      header: `Where are you headed${firstName}?`,
      subhead: `${isEditing ? 'Update' : 'Add'} your trip itinerary below.`,
      key: SelfServeStepKey.TripItinerary,
      orderIndex: 1,
    },
    {
      component: () => import('@/components/SelfServeTripDetails.vue'),
      header: 'Perfect! Just a few questions about your trip',
      subhead: 'It will help us find you the best vehicle.',
      key: SelfServeStepKey.TripDetails,
      orderIndex: 2,
    },
    {
      component: () => import('@/components/SelfServeCustomerInformation.vue'),
      header: 'Your bus options are one step away',
      subhead: 'Create an account to save and access your quote.',
      key: SelfServeStepKey.CustomerInformation,
      orderIndex: 3,
      exclude: auth.isTokenSet,
      excludeFromStepCount: auth.isTokenSet,
    },
    {
      component: () => import('@/components/SelfServeCustomerSignIn.vue'),
      header: "It's great to see you again!",
      subhead: 'Sign in below.',
      key: SelfServeStepKey.CustomerSignIn,
      orderIndex: 4,
      exclude: auth.isTokenSet || !isExistingUser,
      excludeFromStepCount: true,
    },
    {
      component: () => import('@/components/SelfServeVehiclePricing.vue'),
      header: 'Choose from the available vehicles',
      subhead: 'Select the best option for your trip.',
      key: SelfServeStepKey.VehiclePricing,
      orderIndex: 5,
    },
    {
      component: () => import('@/components/SelfServeAmenities.vue'),
      header: "Enhance your passengers' experience",
      subhead: 'Choose amenities to enjoy onboard.',
      key: SelfServeStepKey.Amenities,
      orderIndex: 6,
    },
  ].filter((step) => !step.exclude)
}

export default new SelfServeModule({ store, name: 'selfServe' })
