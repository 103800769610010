
import { Vue, Component, Prop } from 'vue-property-decorator'
import CheckoutTripDetails from '@/components/CheckoutTripDetails.vue'
import CheckoutTripItinerary from '@/components/CheckoutTripItinerary.vue'
import { Trip } from '../models/dto'

@Component({
  components: { CheckoutTripDetails, CheckoutTripItinerary },
})
export default class CheckoutTripDetailsAndItinerary extends Vue {
  @Prop({ type: Object, required: true }) readonly trip!: Trip
  @Prop({ type: Boolean, required: false, default: false })
  readonly hideExpand!: boolean
  @Prop({ type: Boolean, required: false, default: false })
  readonly fullWidth!: boolean

  expanded = false

  get computedExpanded(): boolean {
    return this.hideExpand || this.expanded
  }
}
