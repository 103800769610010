import { PlaceDetail, PlaceSearchResult } from '@/models/dto'
import { AxiosResponse } from 'axios'
import { baseUrl } from '@/utils/env'
import { HttpService } from './common/HttpService'
const httpService: HttpService = new HttpService()

export default {
  /**
   * Retrieves the place search results for a given search term.
   * @param searchTerm - The search term to retrieve the place search results for.
   * @returns A promise that resolves to the place search results for the specified search term.
   */
  search(
    term: string,
    includedCountries: string[]
  ): Promise<AxiosResponse<PlaceSearchResult>> {
    const countries = includedCountries
      ? `&country=${includedCountries.join(',')}`
      : ''
    const url = `https://${baseUrl()}/places?term=${term}${countries}`
    return httpService.get(url)
  },
  /**
   * Retrieves the address for a given place ID.
   * @param placeId - The place ID to retrieve the address for.
   * @returns A promise that resolves to the address for the specified place ID.
   */
  byId(placeId: string): Promise<AxiosResponse<PlaceDetail>> {
    const url = `https://${baseUrl()}/places/${placeId}`
    return httpService.get(url)
  },
}
