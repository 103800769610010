import { Action, Module, VuexModule } from 'vuex-class-modules'
import store from '@/store/index'
import i18n from '@/plugins/i18n'
import { load, save } from '@/utils/localStorage'

@Module({ generateMutationSetters: true })
class LocaleModule extends VuexModule {
  _locale: string = load('locale') || navigator.language.slice(0, 2) || 'en'
  _options: { label: string; value: string; flag: string }[] = [
    { label: 'English', value: 'en', flag: 'em-flag-us' },
    { label: 'Español', value: 'es', flag: 'em-flag-es' },
  ]

  /**
   * The currently selected language.
   * @returns the currently selected language.
   */
  get locale(): string {
    return this._locale
  }

  /**
   * The available languages.
   * @returns the available languages.
   */
  get options(): { label: string; value: string; flag: string }[] {
    return this._options
  }

  /**
   * Changes the currently selected language.
   * @param locale - The new language code to use.
   */
  @Action
  setLocale(locale: string): void {
    i18n.locale = locale
    save('locale', locale)
    this._locale = locale
  }
}

export default new LocaleModule({ store, name: 'locale' })
