var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-form',{ref:"invite-user-form"},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('reservationDetail.tripContactForm.form.email.LABEL'))+" ")]),_c('v-text-field',{attrs:{"id":"invite-user-email-text-field","placeholder":_vm.$t('reservationDetail.tripContactForm.form.email.PLACEHOLDER'),"rules":[
          (v) =>
            !!v ||
            _vm.$t('reservationDetail.tripContactForm.form.email.rules.REQUIRED'),
          (v) =>
            /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,13}$/.test(v) ||
            _vm.$t('reservationDetail.tripContactForm.form.email.rules.VALID'),
        ],"hide-details":"auto","required":"","validate-on-blur":"","outlined":""},model:{value:(_vm.customer.email),callback:function ($$v) {_vm.$set(_vm.customer, "email", $$v)},expression:"customer.email"}})],1),(_vm.tripContactMode)?[_c('v-col',{attrs:{"cols":"6"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('reservationDetail.tripContactForm.form.firstName.LABEL'))+" ")]),_c('v-text-field',{attrs:{"id":"invite-user-first-name-text-field","placeholder":_vm.$t('reservationDetail.tripContactForm.form.firstName.PLACEHOLDER'),"rules":[
            (v) =>
              !!v ||
              _vm.$t(
                'reservationDetail.tripContactForm.form.firstName.rules.REQUIRED'
              ),
          ],"hide-details":"auto","required":"","validate-on-blur":"","outlined":""},model:{value:(_vm.customer.firstName),callback:function ($$v) {_vm.$set(_vm.customer, "firstName", $$v)},expression:"customer.firstName"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('reservationDetail.tripContactForm.form.lastName.LABEL'))+" ")]),_c('v-text-field',{attrs:{"id":"invite-user-last-name-text-field","placeholder":_vm.$t('reservationDetail.tripContactForm.form.lastName.PLACEHOLDER'),"rules":[
            (v) =>
              !!v ||
              _vm.$t(
                'reservationDetail.tripContactForm.form.lastName.rules.REQUIRED'
              ),
          ],"hide-details":"auto","required":"","validate-on-blur":"","outlined":""},model:{value:(_vm.customer.lastName),callback:function ($$v) {_vm.$set(_vm.customer, "lastName", $$v)},expression:"customer.lastName"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('reservationDetail.tripContactForm.form.phoneNumber.LABEL'))+" ")]),_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(['(###) ###-####', '+## ## #### ####']),expression:"['(###) ###-####', '+## ## #### ####']"}],attrs:{"id":"invite-user-phone-text-field","placeholder":_vm.$t(
              'reservationDetail.tripContactForm.form.phoneNumber.PLACEHOLDER'
            ),"rules":[
            (v) =>
              !!v ||
              _vm.$t(
                'reservationDetail.tripContactForm.form.phoneNumber.rules.REQUIRED'
              ),
            _vm.phoneRaw.length > 9 ||
              _vm.$t(
                'reservationDetail.tripContactForm.form.phoneNumber.rules.VALID'
              ),
            _vm.phoneRaw.length <= 12 ||
              _vm.$t(
                'reservationDetail.tripContactForm.form.phoneNumber.rules.VALID'
              ),
          ],"hide-details":"auto","required":"","validate-on-blur":"","outlined":""},model:{value:(_vm.customer.phone),callback:function ($$v) {_vm.$set(_vm.customer, "phone", $$v)},expression:"customer.phone"}})],1)]:_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }