
import { Vue, Component, Prop } from 'vue-property-decorator'
import ticket from '@/services/ticket'
import auth from '@/store/modules/auth'
import AlertBox from '@/components/AlertBox.vue'
import { ReservationIdentifiers } from '@/models/dto/Reservation'

@Component({ components: { AlertBox } })
export default class PaymentSent extends Vue {
  @Prop({ type: Array, default: () => [] })
  readonly reservationIdentifiers: ReservationIdentifiers[]

  formIsValid = false
  formData = {
    checkNumber: {
      value: '',
      rules: [(v) => !!v || 'Check/confirmation number is required'],
    },
    comment: '',
  }
  errorMessage = ''

  async send(): Promise<void> {
    const form = this.$refs.form as any
    if (!form.validate()) {
      return
    }

    const currentDate = new Date(Date.now()).toLocaleDateString('en-US')
    this.formData.comment +=
      ` --- ${auth.user.firstName} ${auth.user.lastName} ` +
      `paid on ${currentDate}, check/confirmation number is ${this.formData.checkNumber.value}`

    const params = {
      comments: this.formData.comment,
      title: 'Payment Sent',
      ticketTypeId: 7,
      ticketSeverityTypeId: 3,
      createdById: auth.userId,
      creatorFirstName: auth.user?.firstName,
      creatorLastName: auth.user?.lastName,
      creatorEmail: auth.user?.email,
      reservationId: null,
      managedId: null,
    }

    for (const reservationIdentifier of this.reservationIdentifiers) {
      params.reservationId = reservationIdentifier.reservationId
      params.managedId = reservationIdentifier.managedId
      try {
        // eslint-disable-next-line no-await-in-loop
        const response = await ticket.create(params)
        if (response.status === 200) {
          continue
        }
      } catch {
        this.errorMessage = this.$t('paymentSent.ERROR').toString()
      }
    }

    this.formData.checkNumber.value = ''
    this.formData.comment = ''
    this.$emit('sent')
    this.$emit('close')
  }
}
