
import { Vue, Component, Prop } from 'vue-property-decorator'
import { WizardStore } from '@/store/WizardStore'
import { WizardStep } from '@/models/Wizard'

@Component
export default class WizardProgressTracker extends Vue {
  @Prop({ type: Object, required: true }) readonly wizardStore!: WizardStore<WizardStep>

  get steps(): WizardStep[] {
    return this.wizardStore.steps
  }

  get currentStep(): WizardStep {
    return this.wizardStore.step
  }

  get stepCount(): number {
    return this.wizardStore.steps.reduce((count, step) => {
      return step.excludeFromStepCount ? count : count + 1
    }, 0)
  }

  get currentStepIndex(): number {
    return this.wizardStore.currentStepIndex
  }

}
