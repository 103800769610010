
import { Vue, Component, Inject, Prop } from 'vue-property-decorator'
import { Page } from '@/utils/enum'
import InfoModal from './InfoModal.vue'
import { bookingProtectionModalDetail } from '@/utils/infoModals'

@Component({
  components: {
    InfoModal,
  },
})
export default class BookingProtectionDialog extends Vue {
  @Inject({ from: 'isInProvider', default: false })
  readonly isInProvider: boolean
  @Inject({ from: 'isInCheckout', default: false })
  readonly isInCheckout: boolean
  @Inject({ from: 'isInGuestCheckout', default: false })
  readonly isInGuestCheckout: boolean
  @Prop({ type: Boolean }) readonly isSelfServe: boolean

  bookingProtectionModalDetail = bookingProtectionModalDetail
  isOpen = false

  get page(): string {
    if (this.isInProvider) {
      return Page.Provider
    }
    if (this.isInCheckout || this.isInGuestCheckout) {
      return Page.Checkout
    }
    return Page.Other
  }

  handleOpen(): void {
    this.trackOpenBookingProtection()
  }

  trackOpenBookingProtection(): void {
    this.$ga4Event('open_booking_protection', {
      page: this.page,
      isSelfServe: this.isSelfServe,
    })
  }
}
