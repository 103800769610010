
import { Vue, Component, Prop } from 'vue-property-decorator'
import { TranslateResult } from 'vue-i18n'
import { PaymentMethodTypeId } from '@/utils/enum'
import { StagePaymentMethod } from '@/models/dto'
import { isPaymentMethodAllowed} from '@/utils/payment'

@Component
export default class PaymentMethodSelector extends Vue {
  @Prop({ type: Number, required: true }) readonly value: number
  @Prop({ type: Array, required: true }) readonly checkoutPaymentMethods: StagePaymentMethod[]

  get paymentMethodSelectOptions(): PaymentMethodTypeId[] {
    const isCreditCardAllowed = isPaymentMethodAllowed(this.checkoutPaymentMethods, PaymentMethodTypeId.CreditCard)
    const isCheckAllowed = isPaymentMethodAllowed(this.checkoutPaymentMethods, PaymentMethodTypeId.Check)
    const isACHAllowed = isPaymentMethodAllowed(this.checkoutPaymentMethods, PaymentMethodTypeId.ACH)

    const options = []
    if (isCreditCardAllowed) {
      options.push(PaymentMethodTypeId.CreditCard)
    }

    if (isCheckAllowed) {
      options.push(PaymentMethodTypeId.Check)
    } else if (isACHAllowed) {
      options.push(PaymentMethodTypeId.ACH)
    }
    return options
  }

  getTabLabel(paymentMethodTypeId: number): TranslateResult {
    if (paymentMethodTypeId === PaymentMethodTypeId.CreditCard) {
      return this.$t('paymentInformation.CARD')
    }
    if (
      paymentMethodTypeId === PaymentMethodTypeId.ACH ||
      paymentMethodTypeId === PaymentMethodTypeId.Check
    ) {
      return this.$t('paymentInformation.CHECK_AND_WIRE')
    }
  }
}
