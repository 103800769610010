var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{style:({ height: '65vh' })},[_c('v-row',{staticClass:"fill-height",attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[(_vm.currentImage)?_c('v-img',{attrs:{"contain":"","max-height":_vm.$vuetify.breakpoint.smAndUp ? '50vh' : '60vh',"src":_vm.currentImage.imagePath}}):_vm._e()],1),(_vm.imageLabel)?_c('v-col',{staticClass:"padding-t-0",attrs:{"cols":"12"}},[_c('p',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.imageLabel))])]):_vm._e()],1),_c('v-row',{attrs:{"justify":"center"}},[_vm._l((_vm.visibleThumbnails),function(thumbnail,itemIndex){return _c('v-col',{key:`vehiclePhoto-${_vm.extractVehiclePhotoCharterUpId(thumbnail)}-${itemIndex}`,attrs:{"cols":Math.floor(12 / _vm.thumbnailCount)}},[_c('v-img',{staticClass:"cursor-pointer border-solid border-2 hover:transform-102 transition-all transition-duration-300",class:{
          'border-primary':
            _vm.extractVehiclePhotoCharterUpId(_vm.photos[_vm.value]) ===
            _vm.extractVehiclePhotoCharterUpId(thumbnail),
          'border-transparent':
            _vm.extractVehiclePhotoCharterUpId(_vm.photos[_vm.value]) !==
            _vm.extractVehiclePhotoCharterUpId(thumbnail),
        },attrs:{"src":thumbnail.imagePath,"aspect-ratio":16 / 9},on:{"click":function($event){return _vm.selectPhoto(thumbnail)}}})],1)}),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{staticClass:"padding-t-5",attrs:{"align":"stretch","justify":_vm.$vuetify.breakpoint.lgAndUp ? 'center' : 'space-around'}},[_c('v-col',{staticClass:"d-flex justify-center cursor-pointer",attrs:{"id":"carousel-arrow-left","cols":"4"},on:{"click":function($event){return _vm.handleArrowKey({ keyCode: 37 })}}},[_c('CUIcon',{attrs:{"view-box":"0 0 24 24","color":"gray"}},[_vm._v(" keyboard_arrow_left ")])],1),_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":_vm.$vuetify.breakpoint.mdAndUp ? 1 : 4}},[_vm._v(" "+_vm._s(`${_vm.value + 1} / ${_vm.photos.length}`)+" ")]),_c('v-col',{staticClass:"d-flex justify-center cursor-pointer",attrs:{"id":"carousel-arrow-right","cols":"4"},on:{"click":function($event){return _vm.handleArrowKey({ keyCode: 39 })}}},[_c('CUIcon',{attrs:{"view-box":"0 0 24 24","color":"gray"}},[_vm._v(" keyboard_arrow_right ")])],1)],1)],1)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }