
import { Vue, Component, Prop } from 'vue-property-decorator'
import CUItinerary from '@/components/CUItinerary.vue'
import CUItineraryItem from '@/components/CUItineraryItem.vue'
import SkeletonBox from '@/components/SkeletonBox.vue'

@Component({ components: { CUItinerary, CUItineraryItem, SkeletonBox } })
export default class CUItinerarySkeletonLoader extends Vue {
  @Prop({ type: Number, required: false, default: 3 }) readonly count: number
}
