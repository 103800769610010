import { deepSortArray } from "./array";

/**
 * Recursively sorts the properties of an object. If a property contains an array or object,
 * it will be sorted as well. The sorting is done based on the property keys.
 *
 * @param obj - The object to sort.
 * @returns A new object with sorted properties. Nested arrays and objects are also sorted.
 */
export const deepSortObject = (obj: any): any => {
  if (Array.isArray(obj)) {
    return deepSortArray(obj);
  } else if (typeof obj === 'object' && obj !== null) {
    return Object.keys(obj)
      .sort()
      .reduce((acc, key) => {
        acc[key] = deepSortObject(obj[key]);
        return acc;
      }, {} as any);
  }
  return obj;
}
