
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class PickupSatisfactionSelector extends Vue {
  @Prop({ type: Boolean, required: true }) value!: boolean
  @Prop({ type: Boolean }) disabled!: boolean

  get positiveIconColor(): string {
    return this.value === null || this.value ? 'success' : 'gray-medium-light'
  }

  get positiveFaceColor(): string {
    return this.value === null || this.value ? 'gray' : 'gray-medium-light'
  }

  get negativeIconColor(): string {
    return this.value ? 'gray-medium-light' : 'error'
  }

  get negativeFaceColor(): string {
    return this.value ? 'gray-medium-light' : 'gray'
  }

  changePickupSentiment(positive: boolean): void {
    if (this.disabled) {
      return
    }
    this.$emit('input', positive)
  }
}
