
import { TranslateResult } from 'vue-i18n'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { OperatorDetailCompany } from '../models/dto'
import { numberWithCommas } from '../utils/string'

@Component({})
export default class ProviderInfoCompanyInfo extends Vue {
  @Prop({ type: Object, required: true })
  readonly company!: OperatorDetailCompany

  showAll = false
  compliances: {
    title: TranslateResult
    description: TranslateResult
    key: string
    icon: string
  }[] = [
    {
      title: this.$t('quoteDetail.operatorInfo.DOD_APPROVED'),
      description: this.$t('quoteDetail.operatorInfo.DOD_DESCRIPTION'),
      key: 'dod',
      icon: '$falcon',
    },
    {
      title: this.$t('quoteDetail.operatorInfo.ADA_COMPLIANT'),
      description: this.$t('quoteDetail.operatorInfo.ADA_DESCRIPTION'),
      key: 'ada',
      icon: '$wheelchair',
    },
    {
      title: this.$t('quoteDetail.operatorInfo.CDC_COMPLIANT'),
      description: this.$t('quoteDetail.operatorInfo.CDC_DESCRIPTION'),
      key: 'cdc',
      icon: '$thermometer',
    },
    {
      title: this.$t('quoteDetail.operatorInfo.SPAB_CERTIFIED'),
      description: this.$t('quoteDetail.operatorInfo.SPAB_DESCRIPTION'),
      key: 'spab',
      icon: '$school',
    },
  ]

  get companyFacts(): any[] {
    return [
      {
        title: this.$t('quoteDetail.operatorInfo.MILES_LAST_YEAR'),
        value: this.formattedMiles,
        key: 'milesLastYear',
      },
      {
        title: this.$t('quoteDetail.operatorInfo.ACCIDENTS_LAST_YEAR'),
        value: this.company.accidentsLastYear,
        key: 'accidentsLastYear',
      },
      {
        title: this.$t('quoteDetail.operatorInfo.ON_TIME_PERFORMANCE'),
        value: this.formattedOnTimePerformance,
        key: 'onTimePerformance',
      },
      {
        title: this.$t('quoteDetail.operatorInfo.YEAR_COMPANY_STARTED'),
        value: this.company.yearFounded,
        key: 'yearFounded',
      },
    ].filter((fact) => {
      return !!fact.value
    })
  }

  get companyDescription(): TranslateResult {
    if (this.company.description) {
      if (this.showAll) {
        return this.company.description
      }
      const wordCount = this.company.description.split(' ').length
      if (wordCount > 25) {
        return `${this.company.description
          .split(' ')
          .slice(0, 25)
          .join(' ')}...`
      }
      return this.company.description
    }
    return this.$t('quoteDetail.operatorInfo.NO_INFORMATION_PROVIDED')
  }

  get fleetPhoto(): string | null {
    return this.company.charterupCompanyPhotos.find(
      ({ isFleetImage }) => isFleetImage)
      ?.imagePath || null
  }

  get formattedMiles(): string {
    const { milesLastYear } = this.company
    if (!milesLastYear) {
      return ''
    }
    return numberWithCommas(milesLastYear)
  }

  get formattedOnTimePerformance(): string {
    const { onTimePerformance } = this.company
    if (!onTimePerformance) {
      return ''
    }
    return `${onTimePerformance}%`
  }

  checkComplianceByKey(key): boolean {
    return !!this.company.companyAmenities.find(
      (amenity) => amenity.amenityType.key === key && amenity.supported
    )
  }
}
