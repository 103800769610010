var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"d-flex flex-column text-right",class:{
    'margin-r-5': !_vm.stacked,
    'margin-r-4': _vm.stacked,
    'flex-grow-1': _vm.charCountMoreThan7 && !_vm.stacked,
    'flex-grow-0': !_vm.charCountMoreThan7 || _vm.stacked,
  },style:({
    'margin-top': '2px',
  })},[_c('div',{staticClass:"margin-l-3 margin-r-1"},[(!_vm.isValidBid)?_c('h2',{staticClass:"opacity-50",class:{ 'font-16': _vm.stacked, 'font-18': !_vm.stacked }},[_vm._v(" "+_vm._s(_vm.$t('quoteDetail.bidCard.SOLD_OUT'))+" ")]):(_vm.awaitingBid)?_c('div',{staticClass:"text-right"},[_c('h2',{class:{
          'font-14 ': _vm.stacked,
          'font-16': !_vm.stacked,
        }},[_vm._v(" "+_vm._s(_vm.$t('quoteDetail.bidCard.REQUESTING_PRICE'))+" ")]),_c('h6',{class:{ 'font-10': _vm.stacked, 'font-12': !_vm.stacked }},[_vm._v(" "+_vm._s(_vm.$t('quoteDetail.bidCard.PRICE_WILL_UPDATE'))+" ")])]):_c('h2',{class:{ 'text-featured': _vm.isFeatured && !_vm.isBrandedQuoteFlowEnabled }},[_vm._v(" "+_vm._s(_vm.formattedTotalAmount)+" ")]),(!_vm.awaitingBid && _vm.isValidBid && _vm.hasDiscount)?_c('h6',{staticClass:"font-14"},[_c('span',{staticClass:"white-space-nowrap"},[_vm._v(" "+_vm._s(_vm.$t('quoteDetail.bidCard.WAS'))+" "),_c('span',{staticClass:"text-decoration-line-through"},[_vm._v(" "+_vm._s(_vm.formattedSubtotalAmount)+" ")])])]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }