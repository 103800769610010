var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('h3',[_vm._v(" "+_vm._s(_vm.$t('quoteDetail.operatorInfo.AMENITIES'))+" ")]),(_vm.amenities && _vm.amenities.length)?_c('p',[_c('v-row',{attrs:{"dense":""}},_vm._l((_vm.amenities),function(amenity,amenityIndex){return _c('v-col',{key:`${amenity.id}-${amenityIndex}`,attrs:{"cols":"6","sm":"4"}},[_c('div',{staticClass:"d-flex align-center"},[_c('CUIcon',{staticClass:"margin-r-2",class:{
              'w-16 h-16': amenity.key !== 'spab',
              'w-24 h-24': amenity.key === 'spab',
            },style:({
              marginTop: '-1px',
              marginLeft: amenity.key !== 'spab' ? 0 : '-4px',
            }),attrs:{"view-box":"0 0 24 24","color":"gray-medium-dark","left":""}},[_vm._v(" "+_vm._s(_vm.convertAmenityKeyToIconName(amenity.key))+" ")]),_vm._v(" "+_vm._s(amenity.label)+" ")],1)])}),1)],1):_c('p',[_vm._v(" "+_vm._s(_vm.$t('quoteDetail.operatorInfo.NO_INFORMATION_PROVIDED'))+" ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }