
import { Vue, Component, Prop } from 'vue-property-decorator'
import { ItineraryStop } from '../models/ItineraryStop'

@Component({})
export default class TripItineraryV2Icon extends Vue {
  @Prop({ type: Object, required: true }) readonly itineraryStop!: ItineraryStop

  get icon(): string {
    return this.itineraryStop?.icon
  }

  get color(): string {
    return this.itineraryStop?.iconColor
  }
}
