
import { Vue, Component, Prop } from 'vue-property-decorator'
import { TypeWithId } from '../models/dto'
import { DateTime } from 'luxon'

@Component({})
export default class EnterpriseTrackingAdminInfoWindow extends Vue {
  @Prop({ default: null }) readonly vehicle!: any
  @Prop({ type: Array, default: () => [] }) readonly eldTypes: TypeWithId[]

  get eldTypeLabel(): string {
    return (
      this.eldTypes.find((eldType) => eldType.id === this.vehicle.eldTypeId)
        ?.label || ''
    )
  }

  get timestamp(): string {
    const datetime = DateTime.fromISO(this.vehicle.receivedDate)
    return `${datetime.toLocaleString(
      DateTime.DATE_SHORT
    )} • ${datetime.toLocaleString(DateTime.TIME_SIMPLE)}`
  }
}
