import systemParameter from '@/store/modules/systemParameter'
import modules from '@/store/modules'

/**
 * Formats a phone number by adding parentheses around the area code and a dash between the prefix and line number.
 *
 * @param input - The phone number to format.
 * @returns The formatted phone number.
 */
export const phoneFormatFilter = (input: string): string => {
  if (typeof input !== 'string' || input.length === 0) {
    return ''
  }
  const inp = input.replace(/[^0-9]/gi, '')

  if (input[0] === '1') {
    return `(${inp.substring(1, 4)}) ${inp.substring(4, 7)}-${inp.substring(7)}`
  }
  return `(${inp.substring(0, 3)}) ${inp.substring(3, 6)}-${inp.substring(6)}`
}

/**
 * Formats a phone number by adding hyphens between the area code, prefix, and line number.
 *
 * @param input - The phone number to format.
 * @returns The formatted phone number.
 */
export const phoneFormatFilterHyphens = (input: string): string => {
  if (typeof input !== 'string' || input.length === 0) {
    return ''
  }
  const inp = input.replace(/[^0-9]/gi, '')

  if (input[0] === '1') {
    return `${inp.substring(1, 4)}-${inp.substring(4, 7)}-${inp.substring(7)}`
  }
  return `${inp.substring(0, 3)}-${inp.substring(3, 6)}-${inp.substring(6)}`
}

/**
 * Returns the raw, unformatted phone number.
 *
 * @param input - The phone number to return in raw form.
 * @returns The raw phone number.
 */
export const phoneRaw = (input: string): string => {
  if (typeof input !== 'string' || input.length === 0) {
    return ''
  }
  return input.replace(/\D/g, '')
}

/**
 * Returns the priority phone number if the conditions for Elite are met.
 * Otherwise returns the default support number.
 *
 * @param isElite - The condition for returning the Elite number.
 * @returns The raw phone number.
 */
export const getSupportNumber = (isElite: boolean): string => {
  if (isElite) {
    return systemParameter.elitePriorityPhoneNumber
  }
  return systemParameter.supportPhoneNumber || modules.auth.defaultCustomerSupportNumber
}
