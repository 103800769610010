
import CUDataTableActionColumn from '@/components/CUDataTableActionColumn.vue'
import { ActionColumn } from '@/models/ActionColumn'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { DataTableColumn } from '@/models/DataTableColumn'
import { phoneFormatFilter } from '@/utils/phone'
import op from 'simple-object-path'
import { isNotEmptyInput, isNotNegative } from '@/utils/validators'
import CUDataTableRowEditActions from '@/components/CUDataTableRowEditActions.vue'

@Component({
  components: { CUDataTableActionColumn, CUDataTableRowEditActions },
})
export default class CUDataTableCell extends Vue {
  @Prop({ type: Object, required: true }) row!: any
  @Prop({ type: Number, required: true }) rowIndex!: number
  @Prop({ type: Object, required: true }) column!: DataTableColumn
  @Prop({ type: Array, required: false, default: undefined })
  actions!: ActionColumn[]
  @Prop({ type: Array, required: false, default: undefined })
  selectedItems: unknown[]
  @Prop({ type: Boolean, required: false, default: false })
  isDetailTable!: boolean
  @Prop({ type: String, required: false }) detailName!: string
  @Prop({ type: String, required: false }) itemKey!: string
  @Prop({ type: Boolean, required: true }) isMobile!: boolean
  @Prop({ type: Boolean, required: false }) displayActionsOnMobile!: boolean
  @Prop({ type: String, required: false, default: 'data-table' })
  tableElementId!: string

  op = op

  get cellItem(): any {
    return op(this.row, this.column.value)
  }

  get computedCellItemText(): unknown {
    if (this.column.computedText) {
      return this.column.computedText(this.row)
    }
    return this.cellItem
  }

  get isSelected(): boolean {
    if (this.column?.type !== 'select') {
      return false
    }
    const key = this.row[this.itemKey]
    return !!this.selectedItems?.includes(key)
  }

  phoneFormatFilter = phoneFormatFilter
  isNotEmptyInput = isNotEmptyInput
  isNotNegative = isNotNegative
}
