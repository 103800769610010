import { AmenityType, SalesBotTrip as SalesBotTripDTO } from '@/models/dto'
import { SalesBotStop } from './SalesBotStop'

export class SalesBotTrip implements SalesBotTripDTO {
  ada = false
  customerId = null
  mountain = false
  passengerCount = null
  stops = []
  vehicles = []
  tripType = { id: null }
  routeName = null
  spab = false
  tripTypeId = null
  vehicleNeededEntireTrip = true
  tripEventTypeId = null
  tripAmenities: AmenityType[] = []

  constructor(trip?: SalesBotTripDTO) {
    if (!trip) {
      this.constructDefaultTrip()
    } else {
      this.ada = trip.ada
      this.customerId = trip.customerId
      this.mountain = trip.mountain
      this.passengerCount = trip.passengerCount
      this.vehicles = trip.vehicles
      this.tripType = trip.tripType
      this.routeName = trip.routeName
      this.spab = trip.spab
      this.tripTypeId = trip.tripTypeId
      this.vehicleNeededEntireTrip = trip.vehicleNeededEntireTrip
      this.tripEventTypeId = trip.tripEventTypeId
      this.stops = trip.stops.map((stop) => new SalesBotStop(stop))
      this.tripAmenities = trip?.tripAmenities
    }
  }

  private constructDefaultTrip(): void {
    this.stops = [
      new SalesBotStop({ orderIndex: 0 }),
      new SalesBotStop({ orderIndex: 1 }),
    ]
  }

  addStop(orderIndex?: number): void {
    const stop = new SalesBotStop()
    const insertIntoPosition =
      typeof orderIndex === 'number' &&
      orderIndex >= 0 &&
      orderIndex < this.stops.length
    if (insertIntoPosition) {
      this.stops.splice(orderIndex, 0, stop)
    } else {
      this.stops.push(stop)
    }
    this.computeOrderIndices()
  }

  removeStop(orderIndex?: number): void {
    if (orderIndex < 0 || orderIndex >= this.stops.length) {
      return
    }
    this.stops.splice(orderIndex, 1)
    this.computeOrderIndices()
    if (orderIndex === 0) {
      this.updateFirstStopTimes()
    }
  }

  private computeOrderIndices(): void {
    for (let i = 0; i < this.stops.length; i++) {
      this.stops[i].orderIndex = i
    }
  }

  copyAddress(fromIndex: number, toIndex: number): void {
    if (
      fromIndex < 0 ||
      fromIndex >= this.stops.length ||
      toIndex < 0 ||
      toIndex >= this.stops.length
    ) {
      return
    }
    this.stops[toIndex].address = this.stops[fromIndex].address
  }

  private updateFirstStopTimes(): void {
    if (this.stops?.[0]?.dropoffDatetime) {
      this.stops[0].pickupDatetime = this.stops[0].dropoffDatetime
      this.stops[0].dropoffDatetime = null
    }
  }

  toObject(): SalesBotTripDTO {
    const stops = this.stops.map((stop) => stop.toObject())
    const trip = Object.assign({}, this)
    trip.stops = stops
    return trip
  }

  private setTripType(tripTypeId: number): void {
    this.tripTypeId = tripTypeId
    this.tripType = { id: tripTypeId }
  }
}
