import { TripModificationTrip as TripModificationTripDTO } from '@/models/dto'
import { TripModificationStop } from './TripModificationStop'
import { TripModificationVehicle } from './TripModificationVehicle'

export class TripModificationTrip implements TripModificationTripDTO {
  ada = false
  spab = false
  passengerCount = null
  tripTypeId = null
  vehicleNeededEntireTrip = false
  stops = []
  vehicles = []

  constructor(trip: TripModificationTripDTO) {
    if (!trip) {
      return
    }
    this.ada = trip.ada
    this.spab = trip.spab
    this.passengerCount = trip.passengerCount
    this.vehicles = trip.vehicles
    this.tripTypeId = trip.tripTypeId
    this.vehicleNeededEntireTrip = trip.vehicleNeededEntireTrip
    this.stops = trip.stops.map((stop) => new TripModificationStop(stop))
    this.vehicles = trip.vehicles.map((vehicle) => new TripModificationVehicle(vehicle))
  }

  addStop(orderIndex?: number): void {
    const stop = new TripModificationStop()
    const validOrderIndex = orderIndex ?? this.stops.length
    // Ensure index is within bounds, otherwise append to the end
    const insertIndex = Math.max(0, Math.min(validOrderIndex, this.stops.length))
    this.stops.splice(insertIndex, 0, stop)
    this.computeOrderIndices()
  }

  removeStop(orderIndex?: number): void {
    if (orderIndex < 0 || orderIndex >= this.stops.length) {
      return
    }
    this.stops.splice(orderIndex, 1)
    this.computeOrderIndices()
    if (orderIndex === 0) {
      this.updateFirstStopTimes()
    }
  }

  private computeOrderIndices(): void {
    for (let i = 0; i < this.stops.length; i++) {
      this.stops[i].orderIndex = i
    }
  }

  private updateFirstStopTimes(): void {
    if (this.stops?.[0]?.dropoffDatetime) {
      this.stops[0].pickupDatetime = this.stops[0].dropoffDatetime
      this.stops[0].dropoffDatetime = null
    }
  }
}
