
import { Vue, Component, Prop } from 'vue-property-decorator'
import CURadioButton from '@/components/CURadioButton.vue'
import RecommendedBanner from '@/components/RecommendedBanner.vue'
import SelfServeVehiclePricingCardPrice from '@/components/SelfServeVehiclePricingCardPrice.vue'
import VehicleOptionDescription from '@/components/VehicleOptionDescription.vue'
import VehicleOptionImage from '@/components/VehicleOptionImage.vue'
import VehicleOptionPassengers from '@/components/VehicleOptionPassengers.vue'
import VehicleOptionVehicleList from '@/components/VehicleOptionVehicleList.vue'

import selfServe from '@/store/modules/selfServe'
import { QuoteDetailV2, WizardVehicle } from '@/models/dto'
import { PricingMethod, VehicleTypeKey } from '@/utils/enum'
import { isQuoteSelfServeAndNeedsManualAttention } from '@/utils/quote'

@Component({
  components: {
    CURadioButton,
    RecommendedBanner,
    SelfServeVehiclePricingCardPrice,
    VehicleOptionDescription,
    VehicleOptionImage,
    VehicleOptionPassengers,
    VehicleOptionVehicleList,
  },
})
export default class VehicleOptionCard extends Vue {
  @Prop({ type: Object, default: () => {}, required: false }) readonly quote: QuoteDetailV2
  @Prop({ type: Array, default: () => [], required: true }) readonly vehicles: WizardVehicle[]
  @Prop({ type: Boolean, default: false }) readonly selected: boolean

  get isSelfServe(): boolean {
    return !!this.quote?.isSelfServe
  }

  get isSelectable(): boolean {
    if (this.isSelfServe) {
      return (
        !!this.quote?.bids?.[0]?.totalAmount ||
        isQuoteSelfServeAndNeedsManualAttention(this.quote)
      )
    }
    return true
  }

  get showRecommendedBanner(): boolean {
    if (!this.isSelfServe) {
      return false
    }

    const isRecommended = this.quote?.isRecommended
    const isBidPricing = this.quote?.pricingMethod === PricingMethod.Bids

    return isBidPricing && isRecommended
  }

  get vehicleTypeKeys(): VehicleTypeKey[] {
    return this.vehicles.map(({vehicleTypeKey}) => vehicleTypeKey)
  }

  select(): void {
    if (!this.isSelectable) {
      return
    }
    this.$emit('select')
  }
}
