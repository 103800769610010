import { Stop } from '@/models/dto'
import { RiskTypeId } from './enum'

/**
 * Gets the first stop in a list with a missing flight info risk.
 *
 * @param stops - The list of stops to search
 * @returns a Stop entity with a flight risk
 */
export const firstStopWithFlightRisk = (stops: Stop[]): Stop => {
  if (!stops) {
    return null
  }
  return stops.find((stop) =>
    stop?.risks?.some(
      (risk) => risk?.riskTypeId === RiskTypeId.FlightInfoMissing
    )
  )
}

/**
 * Gets the 'most important' riskTypeId in a list of ids.
 *
 * @param riskTypeIds - The list of riskTypeIds to search
 * @returns an id of the highest priority risk in the list
 */
export const highestPriorityRiskTypeId = (riskTypeIds: number[]): number => {
  // Intentionally doesn't include FlightInfoMissing because we will
  // show those risks in addition to others
  const priorityRiskTypes = [
    RiskTypeId.MissingStop,
    RiskTypeId.IncompleteStop,
    RiskTypeId.PickupTimeMissing,
    RiskTypeId.IncompleteAddress,
  ]

  for (const riskType of priorityRiskTypes) {
    if (riskTypeIds.includes(riskType)) {
      return riskType
    }
  }

  return riskTypeIds[0]
}
