
import { Vue, Component, Prop } from 'vue-property-decorator'
import { ItineraryStop } from '../models/ItineraryStop'

@Component({})
export default class TripItineraryV2Timing extends Vue {
  @Prop({ type: Object, required: true }) readonly itineraryStop!: ItineraryStop

  get duration(): string {
    return this.itineraryStop?.duration
  }

  get time(): string {
    return this.itineraryStop?.time
  }

  get date(): string {
    return this.itineraryStop?.date
  }

  get year(): string {
    return this.itineraryStop?.year
  }
}
