
import { Vue, Prop, Component } from 'vue-property-decorator'
import TripModificationRequestDialog from '@/components/TripModificationRequestDialog.vue'

@Component({
  components: {
    TripModificationRequestDialog
  }
})
export default class ReservationDetailModificationRequested extends Vue {
  @Prop({ required: true, type: String }) readonly managedId: string

}
