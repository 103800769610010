
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class CheckboxDropdown extends Vue {
  @Prop({ type: String, default: null }) readonly id: string
  @Prop({ type: String, default: 'not-selected' }) readonly status: string

  selectTypes: { text: string; event: string }[] = [
    {
      text: 'All',
      event: 'selectAll',
    },
    {
      text: 'None',
      event: 'selectNone',
    },
    {
      text: 'Fully Paid',
      event: 'selectPaid',
    },
    {
      text: 'Not Paid',
      event: 'selectUnpaid',
    },
    {
      text: 'Partially Paid',
      event: 'selectPartPaid',
    },
  ]

  get isChecked(): boolean {
    return this.status === 'selected'
  }

  get isIndeterminate(): boolean {
    return this.status === 'indeterminate'
  }

  checkboxClicked(mode): void {
    this.$emit('checkbox-clicked', mode)
  }

  toggleSelections(): void {
    if (this.isChecked) {
      this.$emit('checkbox-clicked', 'selectNone')
    } else {
      this.$emit('checkbox-clicked', 'selectAll')
    }
  }
}
