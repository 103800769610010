import RouteConfigWithMeta from '@/models/RouteConfig'
import nps from '@/services/nps'

const guest: RouteConfigWithMeta = {
  path: '',
  name: 'guest',
  meta: { requiresAuth: false },
  component: () => import('@/views/Guest.vue'),
  children: [
    {
      path: '/enterprise/tracking/:hash',
      name: 'enterprise-tracking.public',
      props: { mode: 'hash' },
      meta: { requiresAuth: false },
      component: () =>
        import(
          /* webpackChunkName: "public-enterprise-tracking" */ '@/views/EnterpriseTracking.vue'
        ),
    },
    {
      path: '/livetracking/public/:hash',
      name: 'public-tracking',
      props: { showTheAppBar: true },
      meta: { requiresAuth: false },
      component: () =>
        import(
          /* webpackChunkName: "live-tracking" */ '@/views/LiveTracking.vue'
        ),
    },
    {
      path: 'nps',
      meta: { requiresAuth: false },
      component: () => import('@/views/Empty.vue'),
      children: [
        {
          path: '/nps/:hash',
          name: 'nps-survey',
          meta: { requiresAuth: false },
          component: () =>
            import(
              /* webpackChunkName: "nps-survey" */ '@/views/NPSSurvey.vue'
            ),
          beforeEnter: async (to, _from, next) => {
            try {
              const res = await nps.checkByHash(to.params.hash)
              if (res.data.hashActive) {
                next()
              } else {
                next({ name: 'nps-thank-you' })
              }
            } catch (error) {
              next({ name: 'home' })
            }
          },
        },
        {
          path: '/thank-you',
          name: 'nps-thank-you',
          meta: { requiresAuth: false },
          component: () =>
            import(
              /* webpackChunkName: "nps-thank-you" */ '@/views/NPSThankYou.vue'
            ),
        },
      ],
    },
  ],
}

export default guest
