import { baseUrl } from '@/utils/env'
import { HttpService } from './common/HttpService'
import {
  GenericApiResult,
  QuoteDetailV2,
  SalesBotQuote,
  SalesBotQuoteResult,
} from '@/models/dto'
import { AxiosResponse } from 'axios'

const httpService: HttpService = new HttpService()

export default {
  /**
   * Creates a self-serve quote with the given payload.
   * @param payload - The payload containing the information for the self-serve quote.
   * @returns A promise that resolves to the result of creating the self-serve quote.
   */
  create(
    payload: SalesBotQuote
  ): Promise<AxiosResponse<GenericApiResult<SalesBotQuote[]>>> {
    const url = `https://${baseUrl()}/quotes/v2/selfServe`
    return httpService.post(url, payload)
  },
  /**
   * Gets a self-serve quote with the given quote ID.
   * @param quoteId - The quote ID to use to get the self-serve quote.
   * @returns A promise that resolves to the result containing the self-serve quote.
   */
  get(
    quoteId: number
  ): Promise<AxiosResponse<GenericApiResult<SalesBotQuote>>> {
    const url = `https://${baseUrl()}/quotes/v2/selfServe/${quoteId}`
    return httpService.get(url)
  },
  /**
   * Updates a self-serve quote with the given quote and quote ID.
   * @param quote - The updated self-serve quote.
   * @param selfServeId - The self serve ID of the self-serve quote to update.
   * @returns A promise that resolves to the result of updating the self-serve quote.
   */
  update(
    quote: SalesBotQuote,
    selfServeId: string
  ): Promise<AxiosResponse<GenericApiResult<null>>> {
    const url = `https://${baseUrl()}/quotes/v2/selfServe/${selfServeId}`
    return httpService.patch(url, quote)
  },
  /**
   * Creates aself-serve quote as an unauthenticated user with the given payload.
   * @param payload - The payload containing the information for the self-serve quote.
   * @returns A promise that resolves to the result of creating the self-serve quote.
   */
  createAsGuest(
    payload: SalesBotQuote
  ): Promise<AxiosResponse<GenericApiResult<SalesBotQuote[]>>> {
    const url = `https://${baseUrl()}/quotes/v2/selfServe/newCustomer`
    return httpService.post(url, payload)
  },
  bySelfServeIdV2(
    selfServeId: string
  ): Promise<AxiosResponse<GenericApiResult<QuoteDetailV2[]>>> {
    const url = `https://${baseUrl()}/quoteDetail/v2/selfServe/${selfServeId}`
    return httpService.get(url)
  },
}
