
import { Vue, Component, Prop } from 'vue-property-decorator'
import { ReservationDetail } from '@/models/dto'
import {
  pluralize,
  formatReservationPickupDestinationText,
} from '@/utils/string'

@Component({})
export default class ReservationCancellationSummaryTripInfo extends Vue {
  @Prop({ type: Object, required: true })
  readonly reservation!: ReservationDetail

  get managedId(): string {
    return this.reservation?.managedId
  }

  get pickupDropoffText(): string {
    if (!this.reservation) {
      return ''
    }
    return formatReservationPickupDestinationText(
      this.reservation,
      this.$t('common.TO')
    )
  }

  get routeName(): string {
    return this.reservation?.routeName
  }

  get firstPickupDate(): string {
    return this.$dayjs(this.reservation?.pickupDate)
      .tz(this.reservation.stops?.[0]?.address.timeZone)
      .format('MM/DD/YY')
  }

  get lastDropoffDate(): string {
    const stops = this.reservation?.stops
    const lastStop = stops[stops.length - 1]
    return this.$dayjs(lastStop?.dropoffDatetime)
      .tz(lastStop?.address?.timeZone)
      .format('MM/DD/YY')
  }

  get tripDates(): string {
    if (this.firstPickupDate && this.lastDropoffDate && this.firstPickupDate != this.lastDropoffDate) {
      return `${this.firstPickupDate} - ${this.lastDropoffDate}`
    }
    return this.firstPickupDate
  }

  get requiredVehicles(): string {
    return this.reservation?.requiredVehicles.map(requiredVehicle => {
        const quantity = requiredVehicle.quantity
        const label = requiredVehicle.vehicleType.label
        return `${quantity} ${pluralize(quantity, label)}`
      }
    ).join(', ')
  }

}
