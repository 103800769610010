var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticClass:"d-inline-flex align-center",on:{"keydown":_vm.meridianKeyPressHandler}},[_c('input',{directives:[{name:"mask",rawName:"v-mask",value:(['#', '##']),expression:"['#', '##']"}],ref:"hour",staticClass:"font-32 font-medium w-80 h-64 border-2 border-border-gray focus:border-primary border-solid border-radius-regular text-center focus:text-primary text-primary",class:{},attrs:{"id":`hour-input-${_vm.uuid}`,"type":"text","placeholder":"00"},domProps:{"value":_vm.hour},on:{"keydown":_vm.hourKeyPressHandler,"input":function($event){return _vm.setHourValue(null)}}}),_vm._m(0),_c('input',{directives:[{name:"mask",rawName:"v-mask",value:(['#', '##']),expression:"['#', '##']"}],ref:"minute",staticClass:"font-32 font-medium w-80 h-64 border-2 border-border-gray focus:border-primary border-solid border-radius-regular text-center focus:text-primary text-primary",attrs:{"id":`minute-input-${_vm.uuid}`,"type":"text","placeholder":"00"},domProps:{"value":_vm.minute},on:{"keydown":_vm.minuteKeyPressHandler,"input":function($event){return _vm.setMinuteValue(null)}}}),_c('div',{staticClass:"d-flex flex-column font-medium margin-l-2"},[_c('button',{staticClass:"padding-x-2 padding-y-1 font-14 border-2 border-solid border-radius-top-right-regular border-radius-top-left-regular transition-all transition-ease transition-duration-150",class:{
          'text-primary border-primary z-2 background-blue-10': _vm.isAM,
          'border-border-gray text-gray-light': !_vm.isAM,
        },on:{"click":_vm.setToAM}},[_vm._v(" AM ")]),_c('button',{staticClass:"padding-x-2 padding-y-1 font-14 border-2 border-solid border-radius-bottom-right-regular border-radius-bottom-left-regular transition-all transition-ease transition-duration-150",class:{
          'text-primary border-primary z-2 background-blue-10': _vm.isPM,
          'border-border-gray text-gray-light': !_vm.isPM,
        },style:({ 'margin-top': '-2px' }),on:{"click":_vm.setToPM}},[_vm._v(" PM ")])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"d-flex flex-column margin-x-2"},[_c('div',{staticClass:"background-primary h-6 w-6 border-radius-round margin-b-2"}),_c('div',{staticClass:"background-primary h-6 w-6 border-radius-round"})])
}]

export { render, staticRenderFns }