
import { Vue, Component, Prop } from 'vue-property-decorator'
import { LiveTrackingStatus } from '@/utils/enum'

@Component
export default class LiveTrackingMapStopIcon extends Vue {
  @Prop({ type: String, required: true, default: '#C4CCD4' })
  readonly color: string
  @Prop({
    type: String,
    required: true,
    default: LiveTrackingStatus.Upcoming,
  })
  readonly iconType: string
}
