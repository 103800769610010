
import { Vue, Component, Prop } from 'vue-property-decorator'
import ItineraryRiskWarning from '@/components/ItineraryRiskWarning.vue'
import HotelInformationDialog from '@/components/HotelInformationDialog.vue'
import DriverHotelRequirementsDialog from '@/components/DriverHotelRequirementsDialog.vue'
import { RiskTypeId } from '@/utils/enum'
import { DateTime } from 'luxon'
import { Trip, HotelInformation, HotelRequirement } from '@/models/dto'

@Component({
  components: {
    ItineraryRiskWarning,
    HotelInformationDialog,
    DriverHotelRequirementsDialog,
  },
})
export default class TripInfoItineraryHotelInfo extends Vue {
  @Prop({ type: Object, default: () => {} }) readonly trip: Trip
  @Prop({ type: Boolean, default: false }) readonly allowReservationManagement: boolean
  @Prop({ type: Number, required: true }) readonly reservationId: number
  @Prop({ type: String, required: false }) readonly managedId: string
  @Prop({ required: false, type: Object, default: null }) readonly hotelInformation: HotelInformation
  @Prop({ required: false, type: Object, default: null }) readonly hotelRequirement: HotelRequirement
  @Prop({ type: String, default: '' }) readonly tripStatus: string

  hotelInformationDialogIsOpen = false
  hotelRequirementsDialogIsOpen = false

  get risksPerTrip(): any[] {
    return this.trip?.risks || []
  }

  get isHotelRiskOnTrip(): boolean {
    return this.risksPerTrip?.some(
      (risk) => risk.riskTypeId === RiskTypeId.DriverHotelMissing
    )
  }

  get tripId(): number {
    return this.hotelRequirement?.tripId
  }

  get hotelInfoList(): string[] {
    const checkIn = this.hotelRequirement?.checkIn
      ? DateTime.fromISO(this.hotelRequirement?.checkIn).toFormat('LL/dd/yyyy')
      : ''
    const checkOut = this.hotelRequirement?.checkOut
      ? DateTime.fromISO(this.hotelRequirement?.checkOut).toFormat('LL/dd/yyyy')
      : ''
    const address = this.hotelInformation?.address
    return [
      `Hotel booked ${checkIn} — ${checkOut}`,
      `${this.hotelInformation?.hotelName} — ${this.hotelInformation?.confirmationNumber}`,
      `${address?.street1}, ${address?.city}, ${address?.state} ${address?.postalCode}`,
      `${this.hotelInformation?.nameOnReservation}`,
    ]
  }

  get useMobileDisplay(): boolean {
    return this.$vuetify.breakpoint.xsOnly
  }

  get showHotelInformation(): boolean {
    return (
      !this.isHotelRiskOnTrip &&
      this.hotelInformation !== null &&
      this.hotelInformation?.hotelName !== null &&
      this.hotelInformation?.confirmationNumber !== null &&
      this.hotelInformation?.nameOnReservation !== null &&
      this.hotelInformation?.address !== null &&
      this.hotelRequirement?.checkIn !== null &&
      this.hotelRequirement?.checkOut !== null
    )
  }

  get isTripUpcomingOrStarted(): boolean {
    return this.tripStatus === 'upcoming' || this.tripStatus === 'started'
  }
}
