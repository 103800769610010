
import { Vue, Component } from 'vue-property-decorator'
import customerAccount from '@/services/customerAccount'
import auth from '@/store/modules/auth'
import { CreateCustomerAccountPayload, InvitePayload } from '@/models/dto'
import AlertBox from '@/components/AlertBox.vue'

@Component({ components: { AlertBox } })
export default class OrganizationNewTeamDialog extends Vue {
  dialog = false
  success = false
  formData = {
    teamName: {
      value: '',
      rules: [(v) => !!v || 'Team name is required'],
    },
    firstName: {
      value: '',
      rules: [(v) => !!v || 'First name is required'],
    },
    lastName: {
      value: '',
      rules: [(v) => !!v || 'First name is required'],
    },
    email: {
      value: '',
      rules: [
        (v) => !!v || 'E-mail is required',
        (v) => /.+@.+/.test(v) || 'E-mail must be valid',
      ],
    },
    title: { value: '', rules: [(v) => !!v || 'Title is required'] },
  }
  errorMessage = null
  formIsValid = false

  async send(): Promise<void> {
    const form = this.$refs.form as any
    if (!form.validate()) {
      return
    }
    this.errorMessage = false
    const parentCustomerAccountId = auth.customerAccount.customerAccountId
    const {
      data: { address },
    } = await customerAccount.byId(parentCustomerAccountId)
    if (address) {
      delete address.addressId
    }
    const newTeamPayload: CreateCustomerAccountPayload = {
      name: this.formData.teamName.value,
      email: this.formData.email.value,
      title: this.formData.title.value,
      industryId: 2,
      address,
      parentCustomerAccountId,
      phone: '',
    }
    try {
      const { data: customerAccountId } = await customerAccount.create(
        newTeamPayload
      )
      const inviteCustomerPayload: InvitePayload = {
        firstName: this.formData.firstName.value,
        lastName: this.formData.lastName.value,
        customerAccountId,
        email: this.formData.email.value,
        role: 1,
        title: '',
      }
      await customerAccount.invite(inviteCustomerPayload)
      this.success = true
      this.dialog = false
      this.$emit('close')
      this.$emit('input', false)
      this.$emit('success')
    } catch (e) {
      const error = e as any
      const errorMessage = error.response?.data?.message
      this.errorMessage = errorMessage
    }
  }
}
