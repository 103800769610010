
import { Vue, Component, Prop } from 'vue-property-decorator'
import { ReservationDetail, ReservationReview } from '@/models/dto'
import TripReviewForm from '@/components/TripReviewForm.vue'

@Component({ components: { TripReviewForm } })
export default class ReservationDetailReview extends Vue {
  @Prop({ type: Object, default: () => {} })
  readonly reservation: ReservationDetail

  isReviewExpanded = true

  get reservationReview(): ReservationReview {
    return this.reservation?.reservationReview
  }
}
