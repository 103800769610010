
import { Vue, Component } from 'vue-property-decorator'
import trackingVehicleStore from '@/store/modules/trackingVehicles'
import tracking from '@/store/modules/tracking'
import { pluralize } from '@/utils/string'

@Component({})
export default class LiveTrackingVehicleSelector extends Vue {
  trackingVehicleStore = trackingVehicleStore
  tracking = tracking

  get trackingAndNotTrackingVehicles() {
    return this.trackingVehicleStore.trackingAndNotTrackingVehicles
  }

  get hasSingleVehicle() {
    return this.tracking.reservation?.requiredVehicleCount === 1
  }

  get selectedValue(): number | null {
    const { isTracking } =
      this.trackingVehicleStore?.activeTrackingVehicle || {}
    if (!isTracking) {
      return null
    }
    return this.trackingVehicleStore.activeTrackingVehicle.journeyId
  }

  get vehicleCountText() {
    let vehicleCount = this.trackingAndNotTrackingVehicles?.length

    if (tracking.disableTracking) {
      vehicleCount = tracking.reservation?.requiredVehicleCount
    }

    if (!vehicleCount) {
      return this.$t('liveTracking.itinerary.NO_VEHICLES')
    }

    if (vehicleCount === 1 && tracking.disableTracking) {
      return `${vehicleCount} ${
        tracking.reservation?.requiredVehicles?.[0].vehicleType.label ||
        this.$t('liveTracking.itinerary.VEHICLE').toString()
      }`
    }
    return `${vehicleCount} ${pluralize(
      vehicleCount,
      this.$t('liveTracking.itinerary.VEHICLE').toString()
    )}`
  }

  async onVehicleSelect(journeyId: number) {
    const vehicle = this.trackingAndNotTrackingVehicles.find(
      (v) => v.journeyId === journeyId
    )
    if (vehicle) {
      await this.trackingVehicleStore.setActiveTrackingVehicle(vehicle)
    }
  }
}
