
import { Vue, Component, Watch } from 'vue-property-decorator'
import { filter } from '@/utils/filter'
import quotes from '@/services/quotes'
import { DateTime } from 'luxon'
import { logger } from '@/utils/logger'
import { FinanceTableViewQuote } from '@/models/dto'
import { DataOptions, DataTableHeader } from 'vuetify'

@Component({})
export default class FinanceChargeDispute extends Vue {
  @Watch('search', { deep: true })
  searchChanged() {
    if (this.debounce) {
      window.clearTimeout(this.debounce)
    }
    this.debounce = window.setTimeout(async () => {
      await this.getQuotes()
    }, 250)
  }

  search = {
    quoteId: null,
    customerName: null,
    email: null,
    convertedOn: null,
  }
  datePickerMenu = false
  headers: DataTableHeader[] = [
    { text: 'Quote ID', value: 'quoteId', sortable: false },
    { text: 'Name', value: 'customerName', sortable: false },
    { text: 'Email', value: 'customer.email', sortable: false },
    { text: 'Checkout Date', value: 'convertedOn', sortable: false },
  ]
  quotes: FinanceTableViewQuote[] = []
  quoteCount = 0
  tableOptions: DataOptions = {
    page: 1,
    itemsPerPage: 10,
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
    sortBy: [],
    sortDesc: [],
  }
  debounce = null
  loading = false
  menu = false

  get paginationPages(): number {
    return Math.ceil(this.quoteCount / this.tableOptions.itemsPerPage)
  }
  get userSearchExists(): boolean {
    // eslint-disable-next-line no-unused-vars
    for (const [key, value] of Object.entries(this.search)) {
      if (value) {
        return true
      }
    }
    return false
  }

  async getQuotes(): Promise<void> {
    const params = this.buildQuotesParams()
    this.loading = true
    const { data } = await quotes.financeAdminTableView(params)
    this.quoteCount = data.count
    this.quotes = data.resultList
    this.loading = false
  }
  buildQuotesParams() {
    const filterIsConverted = {
      column: { _t_id: '198b3123', value: 'isConverted', filterType: 'eq' },
      value: true,
    }
    const quotesDataFilter = filter()
    const filterParentAnd = quotesDataFilter.createParent('and')
    quotesDataFilter.add(filterParentAnd, filterIsConverted)

    const filterIsMarketplace = {
      column: {
        _t_id: '41a65a1b',
        value: 'isCharterUpQuote',
        filterType: 'eq',
      },
      value: true,
    }
    quotesDataFilter.add(filterParentAnd, filterIsMarketplace)

    if (this.search.quoteId) {
      const filterQuoteId = {
        column: { _t_id: 'f3523e1d', value: 'quoteId', filterType: 'eq' },
        value: this.search.quoteId,
      }
      quotesDataFilter.add(filterParentAnd, filterQuoteId)
    }
    if (this.search.customerName) {
      let firstName
      let lastName
      const spaceIndex = this.search.customerName.indexOf(' ')
      if (spaceIndex) {
        firstName = this.search.customerName.substr(
          0,
          this.search.customerName.indexOf(' ')
        )
        lastName = this.search.customerName.substr(
          this.search.customerName.indexOf(' ') + 1
        )
      } else {
        firstName = this.search.customerName
        lastName = this.search.customerName
      }
      const filterCustomerFirstName = {
        column: {
          _t_id: '907abb4e',
          value: 'customer.firstName',
          filterType: 'contains',
        },
        value: firstName,
      }
      const filterCustomerLastName = {
        column: {
          _t_id: '22c508dc',
          value: 'customer.lastName',
          filterType: 'contains',
        },
        value: lastName,
      }
      quotesDataFilter.add(filterParentAnd, filterCustomerFirstName)
      quotesDataFilter.add(filterParentAnd, filterCustomerLastName)
    }
    if (this.search.email) {
      const filterCustomerEmail = {
        column: {
          _t_id: '62ad758d',
          value: 'customer.email',
          filterType: 'contains',
        },
        value: this.search.email,
      }
      quotesDataFilter.add(filterParentAnd, filterCustomerEmail)
    }
    if (this.search.convertedOn) {
      const filterConvertedOnGTE = {
        column: {
          _t_id: 'f3472fe8',
          value: 'convertedOn',
          filterType: 'gte',
        },
        value: this.search.convertedOn,
      }
      const filterConvertedOnLTE = {
        column: {
          _t_id: 'd32c8bac',
          value: 'convertedOn',
          filterType: 'lte',
        },
        value: this.search.convertedOn,
      }
      quotesDataFilter.add(filterParentAnd, filterConvertedOnGTE)
      quotesDataFilter.add(filterParentAnd, filterConvertedOnLTE)
    }
    const params = {
      pageSize: this.tableOptions.itemsPerPage,
      page: this.tableOptions.page,
      filters: quotesDataFilter.asQueryParams(),
    }
    return params
  }
  formatDate(timestamp: string): string {
    const datetime = DateTime.fromISO(timestamp)
    return datetime.toFormat('yyyy-LL-dd - hh:mm a')
  }
  async handleClickRow(row: FinanceTableViewQuote): Promise<void> {
    const tripId = row?.trips?.[0]?.tripId
    if (!tripId) {
      console.warn('No trips associated with this quote')
      return
    }
    try {
      const data = await quotes.convertedByCompany(tripId)
      if (data.data.successful) {
        const convertedCompanyId = data?.data?.companyId
        this.$router.push({
          name: 'checkout',
          params: {
            quoteId: row.quoteId.toString(),
            providerId: convertedCompanyId.toString(),
            disputedCheckout: 'true',
          },
        })
      } else {
        logger.error(data.data.message)
      }
    } catch (e) {
      logger.error(e)
    }
  }
  handleAppendIconClick(): void {
    this.search.convertedOn = null
  }
}
