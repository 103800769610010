import auth from '@/store/modules/auth'
import { hasPermissionGuard } from '@/utils/router'
import RouteConfigWithMeta from '@/models/RouteConfig'

const organization: RouteConfigWithMeta = {
  path: '/organization',
  name: 'organization',
  redirect: { name: 'organization-account' },
  meta: { requiresAuth: true },
  component: () => import('@/views/Empty.vue'),
  beforeEnter: (to, from, next) => {
    hasPermissionGuard(auth.isCustomerAccountAdmin)(to, from, next)
  },
  children: [
    {
      path: 'account',
      name: 'organization-account',
      meta: { requiresAuth: true },
      component: () => import('@/views/Organization.vue'),
    },
    {
      path: 'riders',
      name: 'organization-riders',
      meta: { requiresAuth: true },
      component: () => import('@/views/Organization.vue'),
    },
    {
      path: 'shared-contacts',
      name: 'shared-contacts',
      meta: { requiresAuth: true },
      component: () => import('@/views/Organization.vue'),
    },
    {
      path: 'rider-data',
      name: 'organization-rider-data',
      meta: { requiresAuth: true },
      component: () => import('@/views/Organization.vue'),
    },
    {
      path: 'teams',
      name: 'organization-teams',
      meta: { requiresAuth: true },
      component: () => import('@/views/Organization.vue'),
    },
  ],
}


export default organization
