import { render, staticRenderFns } from "./ReservationDetailContactInfo.vue?vue&type=template&id=5c4287ff&scoped=true"
import script from "./ReservationDetailContactInfo.vue?vue&type=script&lang=ts"
export * from "./ReservationDetailContactInfo.vue?vue&type=script&lang=ts"
import style0 from "./ReservationDetailContactInfo.vue?vue&type=style&index=0&id=5c4287ff&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c4287ff",
  null
  
)

export default component.exports