
import { DataTableColumn } from '@/models/DataTableColumn'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class CUSkeletonLoaderTableView extends Vue {
  @Prop({ type: Array, required: false, default: () => [] })
  columns!: DataTableColumn[]
  @Prop({ type: Number, required: false, default: 10 }) rows!: number
  @Prop({ type: Boolean, required: false, default: false })
  hidePagination!: boolean
  @Prop({ type: Boolean, required: false }) dense!: boolean

  get columnCount(): number {
    if (this.columns.length) {
      return this.columns.length
    }
    return 7
  }

  get rowsCount(): number {
    return this.rows
  }
}
