
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class ImportRiderFileUpload extends Vue {
  errorMessage: string | null = null
  submittedFileName: string | null = null

  get importDivClass(): string {
    if (this.errorMessage) {
      return 'background-red-10 border-error'
    }
    if (this.submittedFileName) {
      return 'background-green-10 border-success'
    }
    return 'background-blue-10 border-primary'
  }

  get importMessage(): string {
    if (this.errorMessage) {
      return this.errorMessage
    }
    if (this.submittedFileName) {
      return `File submitted: ${this.submittedFileName}`
    }
    return 'Drop 1 file here, or click to browse'
  }

  handleBrowse(event: Event): void {
    const target = event.target as HTMLInputElement
    this.addFiles(target.files)
  }
  handleDrop(event: DragEvent): void {
    if (this.submittedFileName) {
      return
    }
    this.addFiles(event.dataTransfer.files)
  }

  addFiles(files: FileList): void {
    this.errorMessage = null
    const filesArray = Array.from(files)
    if (filesArray.length > 1) {
      this.errorMessage = `Only 1 file allowed to upload at a time. Please try again.`
      return
    }
    this.submittedFileName = files.item(0).name
    this.$emit('file-added', files[0])
  }

  clearFile(): void {
    this.submittedFileName = null
  }
}
