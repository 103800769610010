
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({ name: 'DateSelector' })
export default class DateSelector extends Vue {
  @Prop({ type: String, required: false, default: '' }) readonly value: string
  @Prop({ type: String, default: '' }) readonly id: string
  @Prop({ type: Array, default: () => [] }) readonly reservationDates: string[]
  @Prop({ type: String, required: false, default: '' })
  readonly placeholder: string

  open = false

  get currentDate(): any {
    if (!this.value) {
      return null
    }
    return this.$dayjs(this.value)
  }

  get labelDisplayTime(): string {
    if (!this.currentDate) {
      return ''
    }
    return this.currentDate.format('MM/DD/YY')
  }

  increment(): void {
    this.$emit('input', this.currentDate.add(1, 'day').format('YYYY-MM-DD'))
  }

  decrement(): void {
    this.$emit(
      'input',
      this.currentDate.subtract(1, 'day').format('YYYY-MM-DD')
    )
  }

  close(): void {
    this.open = false
  }

  clear(): void {
    this.$emit('input', null)
  }

  handleSelectDate(date: string): void {
    this.$emit('input', date)
    this.close()
  }
}
