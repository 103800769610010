
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class TwoColumnLayout extends Vue {
  @Prop({ type: String, default: '1250px' }) readonly maxWidth: string
  @Prop({ type: Number, default: 7 }) readonly leftColumn: number
  @Prop({ type: Boolean, default: false }) readonly plainContainer: boolean

  get rightColumn(): number {
    return 12 - this.leftColumn
  }
}
