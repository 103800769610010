import quotes from './quotes'
import reservations from './reservations'
import finance from './finance'
import organization from './organization'
import profile from './profile'
import preauth from './preauth'
import provider from './provider'
import liveTracking from './liveTracking'
import enterpriseTracking from './enterpriseTracking'
import guest from './guest'
import RouteConfigWithMeta from '@/models/RouteConfig'
import auth from './auth'

const routes: RouteConfigWithMeta[] = [
  {
    path: '/',
    component: () => import('@/views/Empty.vue'),
    meta: {
      requiresAuth: false,
    },
    children: [
      {
        path: '',
        name: 'home',
        meta: {
          requiresAuth: true,
        },
        redirect: 'quotes',
        component: () => import('@/views/Home.vue'),
        children: [
          quotes,
          reservations,
          provider,
          organization,
          finance,
          profile,
          liveTracking,
          enterpriseTracking,
        ],
      },
      auth,
      guest,
      preauth
    ],
  },
]

export default routes
