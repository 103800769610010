
import { Vue, Prop, Component, Watch } from 'vue-property-decorator'
import { gmapApi } from 'vue2-google-maps'
import { TrackingVehicle, Path, MotionLineData } from '@/models/dto'
import LiveTrackingVehicleInfoWindow from '@/components/LiveTrackingVehicleInfoWindow.vue'
import tracking from '@/store/modules/tracking'
import trackingVehicleStore from '@/store/modules/trackingVehicles'
import { makeSvgVehicleIcon } from '@/utils/trackingIcon'

const BUS_FRAMES_PER_SECOND = 30
const busAnimationIncrementInterval = 1000.0 / BUS_FRAMES_PER_SECOND

@Component({
  components: {
    GmapPolyline: () => import('vue2-google-maps/dist/components/polyline'),
    LiveTrackingVehicleInfoWindow,
  },
})
export default class LiveTrackingVehiclePolyline extends Vue {
  @Prop({ type: Object, required: true }) readonly vehicle: TrackingVehicle
  @Prop({ type: Object, required: true })
  readonly motionLineData: MotionLineData

  tracking = tracking
  trackingVehicleStore = trackingVehicleStore

  firstLoad = true
  animationInterval = null
  positionCounter = 0
  positionPercent = 0
  icons = []

  infoWindowPosition = null
  infoWindowOpen = false

  @Watch('motionLineData', { immediate: true })
  onDistanceChange(value: MotionLineData) {
    if (!value?.legs.length) {
      return
    }

    const distanceMoved = value.legs.reduce((sum, leg) => {
      return sum + leg.distance.value
    }, 0)

    if (distanceMoved > 0 || this.firstLoad) {
      this.animateVehicle()
    }
  }

  get google(): any {
    return gmapApi()
  }

  get path(): Path[] {
    const path: Path[] = []
    for (const leg of this.motionLineData.legs) {
      for (const step of leg.steps) {
        for (const point of step.path) {
          path.push({ lat: point.lat(), lng: point.lng() })
        }
      }
    }
    return path
  }

  get strokeOpacity(): number {
    return this.vehicle.journeyId ===
      this.trackingVehicleStore.activeTrackingVehicle?.journeyId
      ? 1
      : 0
  }

  get counterToPercentDivider(): number {
    const totalFrames =
      BUS_FRAMES_PER_SECOND * this.trackingVehicleStore.refreshInterval
    return totalFrames / 100
  }

  get options(): object {
    return {
      path: this.path,
      zIndex: this.isActiveVehicle ? 11 : 10,
      strokeColor: this.vehicle.color,
      strokeWeight: 5,
      strokeOpacity: this.strokeOpacity,
      icons: this.icons,
    }
  }

  get isActiveVehicle(): boolean {
    return (
      this.trackingVehicleStore.activeTrackingVehicle?.journeyId ===
      this.vehicle.journeyId
    )
  }

  beforeDestroy(): void {
    if (this.animationInterval) {
      clearInterval(this.animationInterval)
    }
  }

  @Watch('trackingVehicleStore.activeTrackingVehicle')
  animateVehicle(): void {
    this.firstLoad = false
    if (this.animationInterval) {
      clearInterval(this.animationInterval)
    }

    this.positionCounter = 0
    this.positionPercent = 0
    this.setBusIconPositionsOnLine()

    this.animationInterval = setInterval(() => {
      if (this.positionPercent < 100) {
        this.positionCounter++
        this.positionPercent =
          this.positionCounter / this.counterToPercentDivider
        this.setBusIconPositionsOnLine()
      }
    }, busAnimationIncrementInterval)
  }

  setBusIconPositionsOnLine(): void {
    this.icons = makeSvgVehicleIcon(
      this.vehicle.vehicleType || 'charterBus',
      this.google,
      this.vehicle.color
    )
    this.icons[0].offset = `${this.positionPercent}%`
    this.icons[1].offset = `${this.positionPercent}%`
    this.icons[2].offset = `${this.positionPercent}%`
    this.icons[3].offset = `${this.positionPercent}%`
    this.icons[4].offset = `${this.positionPercent}%`
  }

  onMouseOver(event) {
    this.infoWindowOpen = true
    this.infoWindowPosition = event.latLng
  }

  async onClick() {
    await this.trackingVehicleStore.setActiveTrackingVehicle(this.vehicle)
  }
}
