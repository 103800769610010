
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

const FULL_DASH_ARRAY = 283

@Component({})
export default class CountdownTimer extends Vue {
  @Prop({ type: Number, required: true }) readonly timeLimit: number
  @Prop({ type: Number, required: true }) readonly size: number
  @Prop({ type: Boolean }) readonly hidden: boolean

  @Watch('timeLeft')
  timeLeftChanged(newValue: number) {
    if (newValue === 0) {
      this.onTimesUp()
      this.startTimer()
    }
  }

  timePassed = 0
  timerInterval: any = null
  colorCodes = {
    start: {
      color: 'blue',
    },
    end: {
      color: 'purple',
      threshold: 0,
    },
  }

  get circleDasharray(): string {
    return `${(this.timeFraction * FULL_DASH_ARRAY).toFixed(0)} 283`
  }
  get timeLeft(): number {
    return this.timeLimit - this.timePassed
  }
  get timeFraction(): number {
    const rawTimeFraction = this.timeLeft / this.timeLimit
    return rawTimeFraction - (1 / this.timeLimit) * (1 - rawTimeFraction)
  }
  get remainingPathColor(): string {
    const { end, start } = this.colorCodes

    if (this.timeLeft <= end.threshold) {
      return end.color
    } else {
      return start.color
    }
  }

  mounted(): void {
    const colorChangeThreshold = this.timeLimit / 3.0
    this.colorCodes.end.threshold = colorChangeThreshold
    this.startTimer()
  }

  onTimesUp(): void {
    clearInterval(this.timerInterval)
    this.timePassed = 0
    this.$emit('countdown-finished')
  }

  startTimer(): void {
    this.timerInterval = setInterval(() => (this.timePassed += 1), 1000)
  }
}
