
import { AmenityTypeId } from '@/utils/enum'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { currencyFilter } from '@/utils/string'
import selfServe from '@/store/modules/selfServe'
import { AmenityType } from '../models/dto'
import { getAmenityIcon } from '@/utils/amenity'

@Component({})
export default class AmenityCard extends Vue {
  @Prop({ type: Object, default: null }) readonly amenity: any
  @Prop({ type: Boolean, default: false })
  readonly isVehicleCombination: boolean

  getAmenityIcon = getAmenityIcon

  get isSelected(): boolean {
    const amenityTypeId = this.amenity.id
    const currentTrip = selfServe.quote.trips[selfServe.currentTripIndex]
    const amenity = currentTrip.tripAmenities.find(
      (a: AmenityType) => a.id === amenityTypeId
    )
    return !!amenity
  }

  get title(): string {
    let title = this.amenity.label
    if (this.isVehicleCombination && this.amenity.id === AmenityTypeId.Wifi) {
      title += '*'
    }
    return title
  }

  get priceCopy(): string {
    if (this.amenity.price === 0) {
      return 'Free'
    }
    return `${currencyFilter(this.amenity.price)} per vehicle`
  }

  get amenityIconColor(): string {
    return this.isSelected ? 'primary' : 'black'
  }

  get amenityCardClass(): string {
    let cardClass =
      'w-full h-full shadow-actionable-card border-radius-2x border-solid cursor-pointer margin-t-4 '
    if (this.isSelected) {
      cardClass += 'border-2 border-solid border-primary '
    } else {
      cardClass += 'border-1 border-solid border-border-gray '
    }
    return cardClass
  }

  handleAmenityClick(): void {
    selfServe.setAmenitySelection({
      value: !this.isSelected,
      amenityTypeId: this.amenity.id,
    })
  }
}
