import colors from '@/scss/_colors-export.scss'

/**
 * Converts a string hex color in 3 or 6 value syntax to 8 value syntax with an alpha channel.
 *
 * @param hexColorString - A string describing a color using hexadecimal color notation.
 * @param opacity - The opacity value to apply to the color, between 0 and 1.
 * @returns A hexcolor string in 8 value syntax.
 * @throws An error if the opacity value is not between 0 and 1 or if the hex color string is not in a valid format.
 */
export const applyOpacity = (hexColorString: string, opacity: number): string => {
  if (opacity < 0 || opacity > 1) {
    throw new Error('Opacity must be between 0 and 1')
  }

  if (!(/^#[0-9A-F]{6}$/i.test(hexColorString) || /^#[0-9A-F]{3}$/i.test(hexColorString))) {
    throw new Error('Invalid hex color format')
  }

  let hexColor = hexColorString.replace('#', '')

  // Expand shorthand form to full form if needed
  if (hexColor.length === 3) {
    hexColor = hexColor.split('').map((color) => {
      return color + color;
    }).join('');
  }

  // Convert hex to RGB
  const r = parseInt(hexColor.substring(0, 2), 16)
  const g = parseInt(hexColor.substring(2, 4), 16)
  const b = parseInt(hexColor.substring(4, 6), 16)

  // Convert opacity to alpha (0-255)
  const alpha = Math.round(opacity * 255)

  // Convert RGB back to hex
  const hexR = r.toString(16).padStart(2, '0')
  const hexG = g.toString(16).padStart(2, '0')
  const hexB = b.toString(16).padStart(2, '0')
  const hexAlpha = alpha.toString(16).padStart(2, '0')

  return '#' + hexR + hexG + hexB + hexAlpha
}

/**
 * Converts a color class string to a hexcolor string using the SCSS color class map.
 *
 * @param classString - A string that maps to a color class in the SCSS file.
 * @returns The corresponding hexcolor string for a given class.
 */
export const classToHexColor = (classString: string): string => {
  if (!colors.hasOwnProperty(classString)) {
    throw new Error('Invalid color class')
  }
  return colors[classString]
}
