import { Action, Module, Mutation, VuexModule } from 'vuex-class-modules'
import store from '@/store/index'
import { FlightInformation, Stop } from '@/models/dto'

@Module({ generateMutationSetters: true })
class FlightModule extends VuexModule {
  _isOpen = false
  _flightInformation: FlightInformation = null
  _activeFlightNumber = ''
  _activeAirline = ''
  _stop: Stop = null

  /**
   * Whether the flight information modal is open or not.
   * @returns whether the flight information modal is open or not.
   */
  get isOpen(): boolean {
    return this._isOpen
  }

  /**
   * Returns the flight information associated with the stop.
   * @returns the flight information currently associated with the stop.
   */
  get flightInformation(): FlightInformation {
    return this._flightInformation
  }

  /**
   * Returns the flight number associated with the stop.
   * @returns the flight number currently associated with the stop.
   */
  get activeFlightNumber(): string {
    return this._activeFlightNumber
  }

  /**
   * Returns the airline associated with the stop.
   * @returns the airline currently associated with the stop.
   */
  get activeAirline(): string {
    return this._activeAirline
  }

  /**
   * Returns the airport stop.
   * @returns the airport stop.
   */
  get stop(): Stop {
    return this._stop
  }

  /**
   * Updates the flight number associated with the stop.
   * @param flightNumber - the new flight number.
   */
  @Mutation
  setActiveFlightNumber(flightNumber: string): void {
    this._activeFlightNumber = flightNumber
  }

  /**
   * Updates the airline associated with the stop.
   * @param airline - the new airline.
   */
  @Mutation
  setActiveAirline(airline: string): void {
    this._activeAirline = airline
  }

  /**
   * Opens the flight information modal and sets an optional FlightInformation and/or Stop object.
   *
   * @param modalContext - object containing context to associate with the flight information modal.
   */
  @Action
  open(modalContext: {
    flightInformation?: FlightInformation
    stop: Stop
  }): void {
    this._isOpen = true
    this._flightInformation = modalContext?.flightInformation
    this._activeFlightNumber = modalContext?.flightInformation?.flightNumber
    this._activeAirline = modalContext?.flightInformation?.airline
    this._stop = modalContext?.stop
  }

  /**
   * Closes the flight information modal.
   */
  @Action
  close(): void {
    this._isOpen = false
    this._flightInformation = null
    this._activeFlightNumber = ''
    this._activeAirline = ''
    this._stop = null
  }
}

export default new FlightModule({ store, name: 'flight' })
