
import {
  Vue,
  Component,
  Watch,
  Prop,
  ProvideReactive,
} from 'vue-property-decorator'
import TheAppBar from '@/components/TheAppBar.vue'
import TheSidebar from '@/components/TheSidebar.vue'
import { DateTime } from 'luxon'
import reservation from '@/services/reservation'
import trackingService from '@/services/tracking'
import { distanceBetween } from '@/utils/distance'
import { formatDisplayTime, formattedStopName } from '@/utils/string'
import LiveTrackingItinerary from '@/components/LiveTrackingItinerary.vue'
import LiveTrackingMapStatusCard from '@/components/LiveTrackingMapStatusCard.vue'
import LiveTrackingMap from '@/components/LiveTrackingMap.vue'
import CountdownTimer from '@/components/CountdownTimer.vue'
import TheSupportDialog from '@/components/TheSupportDialog.vue'
import MapWithSidebar from '@/layouts/MapWithSidebar.vue'
import tracking from '@/store/modules/tracking'
import auth from '@/store/modules/auth'
import support from '@/store/modules/support'
import appMenu from '@/store/modules/appMenu'
import trackingVehicleStore from '@/store/modules/trackingVehicles'
import { TrackingReservation } from '../models/dto/TrackingReservation'
import { TrackingStop } from '../models/dto/TrackingStop'
import {
  ReservationStatusKey,
  LiveTrackingMapRefreshInterval,
} from '@/utils/enum'
import {
  GenericApiResult,
  GpsData,
  ReservationIdJourneyIdVehicleIdPairList,
  TrackingJourneyData,
} from '../models/dto'
import { AxiosResponse } from 'axios'
import { JourneyStatus, JourneyStopStatus } from '@/models/dto/OnTime'
import onTimeStatus from '@/services/onTimeStatus'
import SkeletonBox from '@/components/SkeletonBox.vue'
import linkShortener from '@/services/linkShortener'
import { hostBaseUrl } from '../utils/env'

export const DEFAULT_MAP_THEME_COLOR = '#222930' // CUP BLACK
const COUNT_THRESHOLD = 3
const ARRIVED_THRESHOLD_METERS = 200
const LATE_PICKUP_BUFFER = 30

@Component({
  components: {
    TheAppBar,
    TheSidebar,
    LiveTrackingItinerary,
    LiveTrackingMapStatusCard,
    LiveTrackingMap,
    CountdownTimer,
    MapWithSidebar,
    TheSupportDialog,
    SkeletonBox,
  },
})
export default class LiveTracking extends Vue {
  @Prop({ type: Boolean, required: true }) readonly showTheAppBar: boolean

  @ProvideReactive('trackingLink') trackingLink = ''

  date = DateTime.local()
  refreshMap = false
  loading = true
  statusDetails = {
    isMultiVehicle: false,
    gpsData: false,
    isAtAnyStop: false,
    currentStop: null,
    isNextStopFirstStop: false,
    isPickupEtaOnSchedule: false,
  }
  statusText = ''
  trackingDataCache = {}
  tracking = tracking
  auth = auth
  support = support
  appMenu = appMenu
  trackingVehicleStore = trackingVehicleStore

  @Watch('tracking.singleVehicleEta')
  calculateStatus() {
    this.calculateStatusDetails()
    this.calculateStatusMessage()
  }

  @Watch('tracking.reservation.hash', { immediate: true })
  async updateTrackingLink() {
    if (!this.reservationHash) return
    await this.setLink()
  }

  @Watch('trackingVehicleStore.activeTrackingVehicle')
  async getVehicleTrackingData(): Promise<void> {
    if (tracking.disableTracking) {
      return
    }
    await this.getTrackingDataForReservation(tracking.reservation)
    const journeyIds = tracking.reservation.journeys.map(
      (journey) => journey.journeyId
    )
    const response = await onTimeStatus.getJourneyStatusesV2(journeyIds)
    const activeJourneyId =
      trackingVehicleStore.activeTrackingVehicle?.journeyId ||
      tracking.reservation?.journeys[0]?.journeyId
    const journeyStatus: JourneyStatus = response?.data?.journeyStatuses.find(
      (js: JourneyStatus) => js.journeyId === activeJourneyId
    )
    const stopStatuses: JourneyStopStatus[] = journeyStatus?.journeyStopStatuses.sort(
      (a, b) => a.orderIndex - b.orderIndex
    )
    tracking.setActiveStopStatuses(stopStatuses)
    this.calculateStatus()
  }

  async setLink(): Promise<void> {
    try {
      const shortenedResult = await linkShortener.createShortLink(this.longLink)
      this.trackingLink = shortenedResult.data?.shortURL || this.longLink
    } catch (error) {
      this.trackingLink = this.longLink
    }
  }

  get reservationHash(): string {
    return tracking?.reservation?.hash
  }

  get longLink(): string {
    return `${hostBaseUrl()}/livetracking/public/${this.reservationHash}`
  }

  get computedRefreshInterval() {
    const status = tracking.reservation.reservationStatus
    if (status === ReservationStatusKey.Started) {
      this.trackingVehicleStore.setRefreshInterval(
        LiveTrackingMapRefreshInterval.STANDARD_REFRESH_INTERVAL
      )
      return LiveTrackingMapRefreshInterval.STANDARD_REFRESH_INTERVAL
    }
    this.trackingVehicleStore.setRefreshInterval(
      LiveTrackingMapRefreshInterval.LONG_REFRESH_INTERVAL
    )
    return LiveTrackingMapRefreshInterval.LONG_REFRESH_INTERVAL
  }

  get activeVehiclesCount() {
    return tracking.nextStop?.journeyStops?.length || 0
  }

  get isFirstStopComplete(): boolean {
    if (!tracking.activeStopStatuses) {
      return false
    }
    return tracking.activeStopStatuses[0]?.complete
  }

  get isPastScheduledPickup(): boolean {
    if (!this.firstStopTime) {
      return false
    }
    const firstStopDatetime = DateTime.fromISO(this.firstStopTime)
    return firstStopDatetime.diff(DateTime.now()).milliseconds < 0
  }

  get firstStopTime(): string {
    const firstStop = tracking.waypoints[0].stops[0]
    return firstStop.pickupDatetime || firstStop.dropoffDatetime
  }

  get firstStopTimeZone(): string {
    return tracking.waypoints[0].stops[0].address.timeZone
  }

  get isEtaTextValid(): boolean {
    return !!tracking.etaCountdownText && !!tracking.etaTimestampText
  }

  get scheduledPickupTimestampText(): string {
    if (!this.firstStopTime) {
      return ''
    }
    const isTripInOneOrMoreDays =
      DateTime.fromISO(this.firstStopTime).diff(DateTime.now(), ['days'])
        .days >= 1
    if (isTripInOneOrMoreDays) {
      return (
        this.$t('liveTracking.statusMessage.PICKUP_TIME_ON') +
        ' ' +
        formatDisplayTime(this.firstStopTime, this.firstStopTimeZone, 'LLL d')
      )
    }
    return (
      this.$t('liveTracking.statusMessage.PICKUP_TIME_AT') +
      ' ' +
      formatDisplayTime(this.firstStopTime, this.firstStopTimeZone)
    )
  }

  get isPickupEtaOnSchedule(): boolean {
    const scheduledArrival = DateTime.fromISO(this.firstStopTime)
    return (
      tracking.nextStopEtaDatetime.diff(scheduledArrival, ['minutes'])
        .minutes <= LATE_PICKUP_BUFFER
    )
  }

  async mounted(): Promise<void> {
    if (!this.$route.params?.reservationId && !this.$route.params?.hash) {
      this.$router.push({ name: 'home' })
    }
    await this.getReservations()
    this.calculateStatusMessage()
    this.trackViewLiveTracking()
  }

  async getReservations(): Promise<void> {
    try {
      let reservation = await this.getSingleReservation()
      reservation.stops.sort((a, b) => a.orderIndex - b.orderIndex)
      tracking.setReservation(reservation)
      tracking.calculateWaypointsForCurrentReservation()
      await this.getVehicleTrackingData()
      this.loading = false
    } catch (err) {
      console.error(err)
    }
  }

  async getTrackingDataForReservation(
    reservation: TrackingReservation
  ): Promise<TrackingReservation> {
    if (reservation.reservationStatus !== ReservationStatusKey.Started) {
      return reservation
    }

    const trackerList = await this.getTrackingDataByParentReservation(
      reservation
    )

    // TODO: move tracking data cache to vuex
    for (const tracker of trackerList) {
      const key = `${tracker.reservationId || reservation.reservationId}-${
        tracker.vehicleId
      }`
      const cache = this.trackingDataCache[key] || []

      const reportedOnList = cache.map((c) => new Date(c.reportedOn).getTime())
      const maxReportedOn = Math.max(...reportedOnList)
      const newValues = tracker.gpsData.filter((gpsPoint) => {
        return new Date(gpsPoint.reportedOn).getTime() > maxReportedOn
      })
      this.trackingDataCache[key] = [...newValues, ...cache]
      tracker.gpsData = [...newValues, ...cache]
    }
    tracking.setTrackerList(trackerList)
  }

  async getSingleReservation(): Promise<TrackingReservation> {
    let reservationResponse: AxiosResponse<GenericApiResult<
      TrackingReservation
    >>
    let reservationHash = this.$route.params.hash

    if (!reservationHash) {
      const reservationId = parseInt(this.$route.params.reservationId)
      const reservationDetail = await reservation.byId(reservationId)
      reservationHash = reservationDetail?.data?.data?.hash
    }

    reservationResponse = await reservation.trackingByHash(reservationHash)

    this.date = DateTime.fromISO(reservationResponse.data.data.startDate)
    return reservationResponse.data.data
  }

  async getTrackingDataByParentReservation(
    reservation: TrackingReservation
  ): Promise<TrackingJourneyData[]> {
    const payloads: ReservationIdJourneyIdVehicleIdPairList[] =
      reservation.reservationIdJourneyIdVehicleIdPairs
    const promiseList = payloads.map((payload) =>
      trackingService.byIdPairsV2Hash(payload)
    )

    const trackerList = []
    const childReservations = await Promise.all(promiseList)

    for (const childReservation of childReservations) {
      for (const tracker of childReservation.data.trackingJourneyDataList) {
        if (tracker.gpsData && tracker.gpsData.length > 0) {
          tracker.gpsData
            .sort(
              (a, b) =>
                new Date(a.receivedDate || a.reportedOn).getTime() -
                new Date(b.receivedDate || a.reportedOn).getTime()
            )
            .reverse()
          trackerList.push(tracker)
        }
      }
    }
    return trackerList
  }

  async refresh(): Promise<void> {
    await this.getReservations()
    this.refreshMap = true
  }

  calculateStatusDetails(): void {
    let lastXGpsPoints: GpsData[] = []
    const isMultiVehicle = this.activeVehiclesCount > 1
    const activeVehicleId =
      trackingVehicleStore.activeTrackingVehicle?.vehicleId
    const gpsData = tracking.trackerList?.find(
      (tracker) => tracker.vehicleId === activeVehicleId
    )?.gpsData
    if (gpsData && gpsData.length >= COUNT_THRESHOLD) {
      lastXGpsPoints = gpsData.slice(0, COUNT_THRESHOLD)
    }
    const isNextStopFirstStop =
      tracking.reservation?.stops?.[0]?.stopId === tracking.nextStop?.stopId
    const currentStop = this.currentlyAtStop(lastXGpsPoints)
    const isAtAnyStop = !!currentStop
    const isPickupEtaOnSchedule = this.isPickupEtaOnSchedule

    this.statusDetails = {
      isMultiVehicle,
      gpsData: !!lastXGpsPoints?.length,
      isAtAnyStop,
      currentStop,
      isNextStopFirstStop,
      isPickupEtaOnSchedule,
    }
  }

  calculateStatusMessage(): void {
    if (
      ['upcoming', 'hold'].includes(tracking.reservation.reservationStatus) &&
      this.date > DateTime.local()
    ) {
      tracking.setZoomToFullRoute(true)
      if (tracking.disableTracking) {
        if (tracking.willHaveLiveTracking) {
          tracking.setStatusText(
            this.$t('liveTracking.statusMessage.WILL_HAVE_TRACKING').toString()
          )
          return
        }
        if (this.scheduledPickupTimestampText) {
          tracking.setStatusText(
            this.$t('liveTracking.statusMessage.WILL_NOT_HAVE_TRACKING', {
              pickupTimeCopy: this.scheduledPickupTimestampText,
            }).toString()
          )
          return
        }
      }
      tracking.setStatusText(
        this.$t('liveTracking.statusMessage.WILL_BEGIN_SHORTLY').toString()
      )
      return
    }

    if (tracking.isFinished) {
      tracking.setStatusText(
        this.$t('liveTracking.statusMessage.FINISHED').toString()
      )
      tracking.setZoomToFullRoute(true)
      return
    }

    if (tracking.reservation.reservationStatus === 'started') {
      // Vehicle is at a stop (whether next or previous).
      if (
        tracking.isComplete(this.statusDetails.currentStop) &&
        this.statusDetails.isAtAnyStop
      ) {
        tracking.setStatusText(
          this.$t('liveTracking.statusMessage.ARRIVED_AT_STOP', {
            stopName: formattedStopName(this.statusDetails.currentStop),
          }).toString()
        )
        return
      }

      // Vehicle is approaching a stop (very close but not deemed arrived by eld service).
      if (tracking.isEnRoute && tracking.isArrivingNowEta) {
        tracking.setStatusText(
          this.$t('liveTracking.statusMessage.ARRIVING_AT_STOP_NOW', {
            stopName: formattedStopName(tracking.nextStop),
          }).toString()
        )
        return
      }

      // Vehicle is en route to the first stop.
      if (this.statusDetails.isNextStopFirstStop) {
        if (
          this.statusDetails.isPickupEtaOnSchedule &&
          tracking.isEnRoute &&
          this.isEtaTextValid
        ) {
          tracking.setStatusText(
            this.$t('liveTracking.statusMessage.PICKUP_ARRIVAL_ETA', {
              etaCountDown: tracking.etaCountdownText,
              etaTimestamp: tracking.etaTimestampText,
            }).toString()
          )
          return
        }
        // Fall back on scheduled pickup time if ETA not valid or if pickup is expected to be late.
        tracking.setStatusText(
          this.$t('liveTracking.statusMessage.PICKUP_ARRIVAL_SCHEDULED', {
            pickupTimeCopy: this.scheduledPickupTimestampText,
          }).toString()
        )
        return
      }

      // Vehicle is en route to next stop (as determined by eld).
      if (tracking.isEnRoute && this.isEtaTextValid) {
        tracking.setStatusText(
          this.$t('liveTracking.statusMessage.STOP_ARRIVAL_ETA', {
            stopName: formattedStopName(tracking.nextStop),
            etaCountDown: tracking.etaCountdownText,
            etaTimestamp: tracking.etaTimestampText,
          }).toString()
        )
        tracking.setZoomToFullRoute(true)
        return
      }

      // Until eld deems vehicle en route, simply display next stop name.
      tracking.setStatusText(
        this.$t('liveTracking.statusMessage.NEXT_STOP', {
          stopName: formattedStopName(tracking.nextStop),
        }).toString()
      )
      tracking.setZoomToFullRoute(true)
      return
    }

    tracking.setStatusText(null)
    return
  }

  currentlyAtStop(gpsPoints: GpsData[]): TrackingStop {
    const nextStopStatus = tracking.nextStopStatus
    // If the next stop's status has an enRouteTimestamp,
    // the vehicle has already departed the previous stop.
    if (nextStopStatus?.enRouteTimestamp) {
      return null
    }

    // If no enRouteTimestamp exists, use last 3 GPS points.
    // Find the stop for which vehicle is within 200m.
    if (
      !gpsPoints ||
      gpsPoints.length < COUNT_THRESHOLD ||
      !tracking.reservation
    ) {
      return null
    }
    const isAtNextStop = this.isVehicleAtStop(tracking.nextStop, gpsPoints)
    if (isAtNextStop) {
      return tracking.nextStop
    }
    const isAtPreviousStop = this.isVehicleAtStop(
      tracking.previousStop,
      gpsPoints
    )
    if (isAtPreviousStop) {
      return tracking.previousStop
    }
    return null
  }

  isVehicleAtStop(stop: TrackingStop, gpsPoints: GpsData[]) {
    return gpsPoints.every((gpsPoint) => {
      const distance = distanceBetween(
        parseFloat(gpsPoint.lat),
        parseFloat(gpsPoint.lng),
        stop?.address?.lat,
        stop?.address?.lng
      )
      return distance < ARRIVED_THRESHOLD_METERS
    })
  }

  trackViewLiveTracking(): void {
    const isLoggedIn = auth.customer != null

    let tripStage = 'pre_trip'
    if (tracking.isFinished) {
      tripStage = 'post_trip'
    } else if (this.isFirstStopComplete) {
      tripStage = 'during_trip'
    } else if (this.isPastScheduledPickup) {
      tripStage = 'late_pickup'
    }

    this.$ga4Event('view_live_tracking', {
      isLoggedIn,
      tripStage,
    })
  }
}
