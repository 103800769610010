
import { Vue, Component } from 'vue-property-decorator'
import auth from '@/store/modules/auth'
import user from '@/services/user'

@Component({})
export default class QuoteNotOwned extends Vue {
  auth = auth
  email = ''

  async mounted(): Promise<void> {
    const response = await user.byId(auth.userId)
    this.email = response.data.user.email
  }
}
