import { TripTypeId, TripTypeLabel } from './enum'

export const tripTypeIdToLabel = (id: TripTypeId): string => {
  const map: { [key in TripTypeId]: TripTypeLabel } = {
    [TripTypeId.OneWay]: TripTypeLabel.OneWay,
    [TripTypeId.RoundTrip]: TripTypeLabel.RoundTrip,
    [TripTypeId.Hourly]: TripTypeLabel.Hourly,
    [TripTypeId.Daily]: TripTypeLabel.Daily,
    [TripTypeId.Shuttle]: TripTypeLabel.Shuttle,
    [TripTypeId.LargeEvent]: TripTypeLabel.LargeEvent,
    [TripTypeId.LongTermShuttle]: TripTypeLabel.LongTermShuttle
  }

  if (!(id in map)) {
    throw new Error(`Invalid TripTypeId: ${id}`)
  }

  return map[id]
}
