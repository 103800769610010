import { StagePaymentMethod } from '@/models/dto'
import { PaymentMethodTypeKey } from '@/utils/enum'

export const paymentMethodTypeIdToKey = {
  1: PaymentMethodTypeKey.CreditCard,
  2: PaymentMethodTypeKey.ACH,
  3: PaymentMethodTypeKey.Check,
}

/**
 * Checks whether a give payment method type (e.g., credit card or ACH) is allowed for the given
 * payment stage.
 * @param stagePaymentMethods – payment methods for a given payment stage (i.e., checkout or balance)
 * @param paymentMethodTypeId – the ID of the payment method type to be checked (e.g., credit card)
 * @return `true` if payment method is allowed for the payment stage, `false` otherwise
 */
export const isPaymentMethodAllowed = (
  stagePaymentMethods: StagePaymentMethod[],
  paymentMethodTypeId: number
): boolean => {
  return !!stagePaymentMethods.find(({paymentMethodId}) => paymentMethodId === paymentMethodTypeId)?.isAllowed
}

/**
 * Given a trip's balance payment methods, returns the allowed payment method. Only one is allowed
 * for the balance stage.
 * @param balancePaymentMethods – payment methods for the balance stage
 * @return the allowed balance payment method's type key if one exists or null
 */
export const getAllowedBalancePaymentMethod = (
  balancePaymentMethods: StagePaymentMethod[]
): PaymentMethodTypeKey => {
  const allowedMethod = balancePaymentMethods.find(({isAllowed}) => isAllowed)
  return allowedMethod ? paymentMethodTypeIdToKey[allowedMethod.paymentMethodId] : null
}
