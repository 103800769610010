
import { DataTableColumn } from '@/models/DataTableColumn'
import { Vue, Component, Prop, Watch, PropSync } from 'vue-property-decorator'
import { v4 as uuidv4 } from 'uuid'
import { calculatedValues } from '@/utils/predefined'
import { EventBus } from '@/utils/eventBus'
import deepPluckRef from 'deep-pluck-ref'
import {
  PredefinedFilter,
  TableViewChip,
  TableViewFilter,
  TableViewTab,
} from '@/models/TableView'

@Component
export default class CUDataTableFilters extends Vue {
  @Prop({
    type: Array,
    required: false,
    default: () => [],
  })
  columns!: DataTableColumn[]

  @Prop({ required: false, default: () => [] }) sorts!: any
  @Prop({ required: false, default: () => [] }) filters!: any
  @Prop({ required: false, default: () => [] })
  initialFilters!: TableViewFilter[]
  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  open: boolean
  @PropSync('filterList', { type: Array, default: () => [] })
  tableFilterList!: any[]
  @Prop({ required: false, default: () => [] })
  tabs!: TableViewTab[]
  @Prop({ required: false, default: () => [] })
  chips!: TableViewChip[]
  @Prop({ required: true }) readonly loading!: boolean
  @Prop({ type: Object, default: () => null })
  readonly activeTab!: TableViewTab | null
  @Prop({ type: String, required: false }) readonly uuid!: string

  @Watch('currentSort', { deep: true })
  handleSortChanged(): void {
    this.refresh(true)
  }
  @Watch('initialFilters', { deep: true, immediate: true })
  handleInitialFiltersChanged(): void {
    this.setInitialFilters()
  }
  @Watch('tabs', { immediate: true })
  handleTabsChanged(): void {
    this.selectDefaultTab()
  }

  currentSort: any = {
    prop: undefined,
    direction: undefined,
  }
  debounce: any = null
  activePredefinedFilters = []

  mounted(): void {
    for (const column of this.columns) {
      if (column?.predefined) {
        for (const p of column.predefined) {
          p.active = false
        }
      }
    }
    this.initiateDefaultSort()
  }

  activePredefinedFilter(column: any): any {
    return this.activePredefinedFilters.find(
      (pf) => pf.column._t_id === column._t_id
    )
  }

  isTabActive(tab: TableViewTab): boolean {
    return tab._t_id === this.activeTab?._t_id
  }

  isFilterActive(
    filter: DataTableColumn | PredefinedFilter | TableViewChip | TableViewTab
  ): boolean {
    return !!this.findFilterById(filter._t_id)
  }

  isColumnSortActive(column: DataTableColumn): boolean {
    return this.currentSort.id === column._t_id
  }

  getFilterByColumn(column: DataTableColumn): any {
    return (
      this.tableFilterList.find((f: any) => f.column?._t_id === column._t_id) ||
      {}
    )
  }

  getColumnSortIcon(column: DataTableColumn): string {
    if (this.isColumnSortActive(column)) {
      if (this.currentSort.direction === 'asc') {
        return 'arrow_up'
      }
    }
    return 'arrow_down'
  }

  findFilterById(_t_id: string): any {
    return this.tableFilterList.find((f) => f.column._t_id === _t_id)
  }

  async setInitialFilters(): Promise<void> {
    for (const initialFilter of this.initialFilters) {
      const hide = initialFilter?.hideOnFilterBar || false
      // eslint-disable-next-line no-await-in-loop
      await this.setFilter(initialFilter.column, hide)
      this.updateFilterCriteria(initialFilter.value, initialFilter.column._t_id)
    }
    this.receiveFilters()
    this.handleFilterAdded()
    this.$emit('initial-filters-set')
  }

  receiveFilters(): void {
    this.$emit('update:filters', this.filters)
  }

  async selectPredefined(
    column: DataTableColumn,
    predefinedFilter: PredefinedFilter
  ): Promise<void> {
    for (const predefined of column.predefined) {
      predefined.active = predefined._t_id === column._t_id
    }
    const filter = this.tableFilterList.find(
      (f: any) => f.column?._t_id === column._t_id
    )
    predefinedFilter.active = true
    if (!predefinedFilter.id) {
      predefinedFilter.id = uuidv4()
    }
    const currentSelectedPredefinedId = filter?.selectedPredefined?.id
    if (currentSelectedPredefinedId !== predefinedFilter.id) {
      this.unsetPeerFilters(filter)
    }
    filter.selectedPredefined = predefinedFilter
    const valueRefs = deepPluckRef(predefinedFilter, ['value'])
    for (const valueRef of valueRefs) {
      if (typeof valueRef !== 'object') {
        continue
      }
      const calculationFunction =
        calculatedValues?.[valueRef.recalculate || valueRef.value]
      if (typeof calculationFunction === 'function') {
        // eslint-disable-next-line no-await-in-loop
        valueRef.value = await calculationFunction()
        valueRef.displayValue = this.$dayjs(valueRef.value).format('YYYY-MM-DD')
      }
    }
    const findActiveFilter = this.activePredefinedFilters.find(
      // eslint-disable-next-line eqeqeq
      (pf) => pf.parent == filter.parent
    )
    if (findActiveFilter) {
      this.activePredefinedFilters.splice(
        this.activePredefinedFilters.indexOf(findActiveFilter),
        1
      )
    }
    this.activePredefinedFilters.push(filter)
    if (predefinedFilter.refreshOnSelect) {
      this.handleFilterAdded()
    }
  }

  setChipFilter(chip: TableViewChip): void {
    const exists = !!this.isFilterActive(chip)

    if (!exists) {
      const filterParentAnd = this.filters.createParent('and')
      for (const value of chip.values) {
        this.filters.add(filterParentAnd, value)
      }
      this.tableFilterList.push({ column: { ...chip } })
      this.handleFilterAdded()
      EventBus.$emit('add-filter')
    } else {
      this.unsetChipFilter(chip)
    }
  }

  unsetChipFilter(chip: TableViewChip): void {
    this.unsetChipChildFilters(chip)
    this.unsetFilter(chip)
  }

  unsetChipChildFilters(chip: TableViewChip): void {
    const chipFilter = this.tableFilterList.find(
      (f) => f.column._t_id === chip._t_id
    )
    if (!chipFilter) {
      return
    }
    for (const filter of chipFilter.column.values) {
      this.filters.remove(filter)
      this.$emit('update:filters', this.filters)
    }
  }

  setFilter(column: DataTableColumn, hideOnFilterBar = false): void {
    const doesFilterAlreadyExist = this.filters.find({ column })
    if (!doesFilterAlreadyExist) {
      const newFilter = { column }
      this.tableFilterList.push(newFilter)
      if (newFilter.column.method) {
        const grandParent = this.filters.createParent('and')
        const parent = this.filters.createParent('or', grandParent)
        this.filters.add(parent, newFilter)
        this.$emit('update:filters', this.filters)
      } else {
        this.filters.and(newFilter).add(newFilter)
        this.$emit('update:filters', this.filters)
      }
      if (this.open) {
        EventBus.$emit('add-filter')
      }
    }
  }

  unsetFilter(column: DataTableColumn | TableViewChip): void {
    const filter = this.tableFilterList.find(
      (f) => f.column._t_id === column._t_id
    )
    if (!filter) {
      return
    }
    if (this.activePredefinedFilters) {
      const activeFilter = this.activePredefinedFilters.find(
        (pf) => pf?.column?._t_id === column._t_id
      )
      if (activeFilter) {
        this.activePredefinedFilters.splice(
          this.activePredefinedFilters.indexOf(activeFilter),
          1
        )
      }
    }

    for (const f of this.filters.parents()) {
      for (const c of this.filters.children(f)) {
        if (filter.column._t_id === c.stepParent) {
          this.filters.remove(c)
          this.$emit('update:filters', this.filters)
        }
      }
    }
    this.unsetPeerFilters(filter)
    this.filters.remove(filter)
    if (this.open) {
      EventBus.$emit('remove-filter')
    }
    this.$emit('update:filters', this.filters)
    this.$nextTick(() => {
      this.tableFilterList = this.tableFilterList.filter(
        (f) => f.column._t_id !== filter.column._t_id
      )
      // This functionality is not needed at this time and causes issues with removing filters
      // const nextDefaultFilter = this.tableFilterList.find(
      //   (f) => !f.hideOnFilterBar
      // )
      // if (nextDefaultFilter && nextDefaultFilter.column) {
      //   this.setFilter(nextDefaultFilter.column)
      // }
      this.handleFilterRemoved()
    })
  }

  unsetPredefinedFilter(column: DataTableColumn): void {
    for (const c of column?.predefined) {
      c.active = false
    }
    this.unsetFilter(column)
  }

  unsetPeerFilters(filter: any): void {
    const unset = filter?.column?.unset || []
    for (const unsetFilterId of unset) {
      const foundColumn = this.columns.find(
        (column) => column._t_id === unsetFilterId
      )
      if (foundColumn) {
        const foundFilter = this.filters.find({ column: foundColumn })
        if (foundFilter) {
          this.filters.remove(foundFilter)
          this.$emit('update:filters', this.filters)
        }
      }
    }
  }

  updateFilterCriteria(filterValue: any, _t_id: string): void {
    const matchingFilter = this.findFilterById(_t_id)
    if (matchingFilter) {
      if (filterValue?.target) {
        matchingFilter.value = filterValue?.target?.value
      } else {
        matchingFilter.value = filterValue
      }
    }
    this.handleFilterAdded()
  }

  handleDatePickerInput(
    event: any,
    column: DataTableColumn,
    controlIndex: number
  ): void {
    const timestamp = this.$dayjs(event).format('YYYY-MM-DD')
    this.updateMultiValueFilterCriteria(timestamp, column, controlIndex)
  }

  updateMultiValueFilterCriteria(
    filterValue: any,
    column: DataTableColumn,
    index: number
  ): void {
    const value =
      filterValue && filterValue.target ? filterValue.target.value : filterValue
    const columnFilter = this.getFilterByColumn(column)
    const { selectedPredefined } = columnFilter
    selectedPredefined.controls[index].value = value
    if (columnFilter.column.type === 'date') {
      const formattedDateValue = this.$dayjs(value).format('YYY-MM-DD')
      selectedPredefined.controls[index].displayValue = formattedDateValue
    }
    this.handleFilterAdded()
    this.initSort(column)
  }

  initiateDefaultSort(): void {
    const defaultSortColumn = this.columns.find((column) => column.defaultSort)
    if (defaultSortColumn) {
      this.initSort(defaultSortColumn)
    }
  }

  selectDefaultTab(): void {
    const defaultTab = this.tabs.find((tab) => tab.default)
    if (defaultTab) {
      this.setTabFilter(defaultTab)
    }
  }

  setTabFilter(tab: TableViewTab): void {
    this.clearSorts()
    this.unsetTabFilter()
    const exists = !!this.isFilterActive(tab)
    if (!exists) {
      const filterParentAnd = this.filters.createParent('and')
      for (const value of tab.values) {
        if (value.column.method === 'or') {
          const filterOr = this.filters.createParent('or', filterParentAnd)
          this.filters.add(filterOr, value)
        } else {
          this.filters.add(filterParentAnd, value)
        }
        this.tableFilterList.push(value)
        if (value.column.sortProp) {
          this.initSort(value.column)
        }
      }
      this.handleFilterAdded()
      this.$emit('active-tab', tab)
    }
  }

  unsetTabFilter(): void {
    for (const tabVals of this.tabs) {
      for (const item of tabVals.values) {
        this.unsetFilter(item.column)
      }
    }
  }

  isInitialFilter(filter: TableViewFilter): boolean {
    return !!this.initialFilters.find(
      (initialFilter) => initialFilter.column._t_id === filter.column._t_id
    )
  }

  initSort(column: DataTableColumn): void {
    const sortProp = column.sortProp || column.value
    this.currentSort.key = uuidv4()
    if (this.currentSort && this.currentSort.prop === sortProp) {
      if (this.currentSort.direction === 'desc') {
        this.currentSort.direction = 'asc'
      } else if (this.currentSort.direction === 'asc') {
        this.clearSorts()
      } else {
        this.currentSort.direction = 'desc'
      }
      this.sorts.add(this.currentSort)
      this.$emit('update:sorts', this.sorts)
      return
    }
    this.currentSort = {
      key: uuidv4(),
      id: column._t_id,
      prop: sortProp,
      direction: column.sortDirection || 'desc',
    }
    this.sorts.add(this.currentSort)
    this.$emit('update:sorts', this.sorts)
  }

  clearSorts(): void {
    this.sorts.remove()
    this.currentSort = {
      prop: undefined,
      direction: undefined,
    }
    this.initiateDefaultSort()
  }

  clearAddedFilters(): void {
    const initialFilterList = []
    for (const filter of this.tableFilterList) {
      const isInitialFilter = this.isInitialFilter(filter)
      const isInActiveTab = this.isInActiveTab(filter)
      if (isInitialFilter || isInActiveTab) {
        initialFilterList.push(filter)
      } else {
        this.unsetFilter(filter.column)
      }
    }
    for (const chip of this.chips) {
      this.unsetChipFilter(chip)
    }
    this.tableFilterList = initialFilterList
  }

  isInActiveTab(filter: any): boolean {
    const columnIds = this.activeTab?.values?.map((val) => val.column._t_id)
    return columnIds.includes(filter.column._t_id)
  }

  handleFilterAdded(): void {
    this.resetPage()
    this.refresh(false)
  }

  handleFilterRemoved(): void {
    this.resetPage()
    this.refresh(true)
  }

  resetPage(): void {
    EventBus.$emit('set-tableview-page', 1)
  }

  refresh(immediate = false): void {
    if (this.debounce) {
      clearTimeout(this.debounce)
    }
    if (immediate) {
      EventBus.$emit(`refresh-tableview-${this.uuid}`)
    } else {
      this.debounce = setTimeout(
        () => EventBus.$emit(`refresh-tableview-${this.uuid}`),
        500
      )
    }
  }

  clear(): void {
    this.clearSorts()
    this.clearAddedFilters()
    this.resetSortForActiveTab()

    this.close()
  }

  resetSortForActiveTab(): void {
    if (this.activeTab) {
      const sortColumn = this.activeTab.values.filter(
        (value) => !!value.column.sortProp
      )?.[0]?.column
      if (sortColumn) {
        this.initSort(sortColumn)
      }
    }
  }

  close(): void {
    this.$emit('update:open', false)
  }
}
