
import { Vue, Prop, Component } from 'vue-property-decorator'
import InfoDialogLayout from '@/layouts/InfoDialogLayout.vue'
import largeEventIcon from '@/assets/images/selfServe/blueLargeEvent.svg'
import greenCheckCircle from '@/assets/images/selfServe/greenCheckCircle.svg'
import checkCircle from '@/assets/images/selfServe/checkCircle.svg'
import dayjs from 'dayjs'
import { phoneFormatFilterHyphens } from '@/utils/phone'

@Component({ components: { InfoDialogLayout } })
export default class SalesWillCallDialog extends Vue {
  @Prop({ type: Boolean, required: true }) readonly value: boolean
  @Prop({ type: Number, required: true }) readonly busCount: number
  @Prop({ type: String, required: true }) readonly createdOn: string
  @Prop({ type: String, required: true }) readonly customerPhoneNumber: string

  largeEventIcon = largeEventIcon
  greenCheckCircle = greenCheckCircle
  checkCircle = checkCircle

  get mainActionText() {
    if (this.isOutsideOfCharterUPESTBusinessHours) {
      return ' during business hours'
    }

    if (!this.quoteCreatedMoreThan10MinutesAgo) {
      return ' within 10 minutes'
    }
    return ' shortly'
  }

  get customerPhoneComputed() {
    return phoneFormatFilterHyphens(this.customerPhoneNumber)
  }

  get nowUTC() {
    return dayjs.utc()
  }

  get nowEST() {
    return this.nowUTC.tz('America/New_York')
  }

  get isOutsideOfCharterUPESTBusinessHours() {
    const businessStart = dayjs()
      .tz('America/New_York')
      .hour(8)
      .minute(0)
      .second(0)
      .millisecond(0)
    const businessEnd = dayjs()
      .tz('America/New_York')
      .hour(23)
      .minute(0)
      .second(0)
      .millisecond(0)

    return (
      this.nowEST.isBefore(businessStart) || this.nowEST.isAfter(businessEnd)
    )
  }

  get quoteCreatedMoreThan10MinutesAgo() {
    const tenMinutesAgo = this.nowUTC.subtract(10, 'minute')
    const createdOnTime = dayjs.utc(this.createdOn)
    return createdOnTime.isBefore(tenMinutesAgo)
  }

  close(): void {
    this.$emit('input', false)
    this.$emit('close')
  }
}
