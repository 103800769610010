
import { Vue, Component, Prop } from 'vue-property-decorator'
import { TripVehicle } from '@/models/dto'
import { pluralize } from '@/utils/string'
import { vehicleTypeKeyToLabel } from '@/utils/vehicle';

@Component({})
export default class TripMetadata extends Vue {
  @Prop({ type: Array, required: true }) readonly vehicles: TripVehicle[]
  @Prop({ type: Number, required: true }) readonly passengerCount: number
  @Prop({ type: String, required: true }) readonly tripTypeLabel: string

  get formattedVehiclesList(): string {
    if (!this.vehicles) {
      return ''
    }
    return this.vehicles
      .map((vehicle) => this.vehiclesPretty(vehicle))
      .join(', ')
  }

  vehiclesPretty(vehicle): string {
    const label = pluralize(
      vehicle?.quantity,
      vehicle?.vehicleType?.label || vehicleTypeKeyToLabel(vehicle.vehicleType.key)
    )
    return `${vehicle?.quantity} ${label}`
  }
}
