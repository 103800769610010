var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"margin-y-6 h-172"},[_c('v-card',{attrs:{"flat":""}},[_c('v-row',{attrs:{"no-gutters":_vm.$vuetify.breakpoint.smAndUp,"dense":_vm.$vuetify.breakpoint.xs,"align":"stretch"}},[_c('v-col',{attrs:{"cols":4}},[_c('SkeletonBox',{staticClass:"overflow-hidden padding-t-0",style:({ height: '173px', 'border-radius': '4px 0px 0px 4px' })})],1),_c('v-col',{staticClass:"padding-a-0 margin-t-3"},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{class:{
              'margin-l-5': _vm.$vuetify.breakpoint.smAndUp,
              'margin-l-4': _vm.$vuetify.breakpoint.xs,
            },style:({ 'margin-bottom': '6px' }),attrs:{"cols":"6"}},[_c('SkeletonBox',{attrs:{"type":"text"}}),_c('br'),_c('SkeletonBox',{staticClass:"w-one-half",attrs:{"type":"text"}})],1),_c('v-row',{class:{
              'margin-l-5': _vm.$vuetify.breakpoint.smAndUp,
              'margin-l-4': _vm.$vuetify.breakpoint.xs,
            },style:({ 'margin-top': '2px' }),attrs:{"justify":"end"}},[_c('div',{staticClass:"margin-l-3 margin-r-1 text-right"})])],1),_c('v-row',{staticClass:"margin-l-3 margin-r-1 h-one-half",attrs:{"align":"center","dense":""}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('SkeletonBox',{attrs:{"type":"text"}})],1),_c('v-spacer'),_c('v-col',{staticClass:"margin-r-3",attrs:{"cols":"4"}},[_c('SkeletonBox',{attrs:{"type":"button","block":"","small":""}})],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }