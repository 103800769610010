import {
  ApiResult,
  CreateCustomerAccountPayload,
  CustomerAccount,
  EditCustomerAccountPayload,
  InvitePayload,
  InviteResult,
  TableViewParameters,
  TableViewResult,
} from '@/models/dto'
import { TableViewCustomer } from '@/models/dto/TableViewCustomer'
import { baseUrl } from '@/utils/env'
import { AxiosResponse } from 'axios'
import { HttpService } from './common/HttpService'
import CustomerAccountDefaultValueSet from '@/models/dto/CustomerAccountDefaultValueSet'
import SharedContactBasicInfo from '@/models/dto/SharedContact'
import SharedContact from '@/models/dto/SharedContact'

const httpService: HttpService = new HttpService()
const host: string = baseUrl()

export default {
  /**
   * Retrieves a user's customer account.
   *
   * @returns A promise that resolves to the customer account.
   */
  get(): Promise<AxiosResponse<CustomerAccount>> {
    const url = `https://${host}/customerAccounts`
    return httpService.get(url)
  },
  /**
   * Retrieves a customer account by its ID.
   *
   * @param id - The ID of the customer account to be retrieved.
   * @returns A promise that resolves to the customer account.
   */
  byId(id: number): Promise<AxiosResponse<CustomerAccount>> {
    const url = `https://${host}/customerAccounts/${id}`
    return httpService.get(url)
  },
  /**
   * Creates a new customer account.
   *
   * @param payload - The payload containing the customer account's information.
   * @returns A promise that resolves to the ID of the created customer account.
   */
  create(
    payload: CreateCustomerAccountPayload
  ): Promise<AxiosResponse<number>> {
    const { parentCustomerAccountId } = payload
    const url = `https://${host}/customerAccounts/${parentCustomerAccountId}/team`
    return httpService.post<number, CreateCustomerAccountPayload>(url, payload)
  },
  /**
   * Updates a customer account.
   *
   * @param customerAccountId - The ID of the customer account to be updated.
   * @param body - The payload containing the updated information.
   * @returns A promise that resolves to the ID of the updated customer account.
   */
  edit(
    customerAccountId: number,
    body: EditCustomerAccountPayload
  ): Promise<AxiosResponse<number>> {
    const url = `https://${host}/customerAccounts/${customerAccountId}`
    return httpService.patch<number, EditCustomerAccountPayload>(url, body)
  },
  /**
   * Deletes a customer account.
   * @param customerAccountId - The ID of the customer account to delete.
   * @returns A promise that resolves to the HTTP response from the server.
   */
  delete(customerAccountId: number): Promise<AxiosResponse<string>> {
    const url = `https://${host}/customerAccounts/${customerAccountId}`
    return httpService.delete(url)
  },
  /**
   * Uploads a light logo for a customer account.
   * @param customerAccountId - The ID of the customer account.
   * @param formData - The form data containing the logo to upload.
   * @returns A promise that resolves to the HTTP response from the server.
   */
  uploadLightLogo(
    customerAccountId: number,
    formData: FormData
  ): Promise<AxiosResponse<ApiResult>> {
    const url = `https://${host}/customerAccounts/${customerAccountId}/uploadLightLogo`
    return httpService.post<ApiResult, FormData>(url, formData)
  },
  /**
   * Sends an invite to a customer.
   * @param payload - The invite payload containing the email and other details.
   * @returns A promise that resolves to the HTTP response from the server. The response will contain
   *   information about the invited customer, such as their active status, company ID, email, name,
   *   and other details.
   */
  invite(payload: InvitePayload): Promise<AxiosResponse<InviteResult>> {
    const url = `https://${host}/customerAccounts/invite`
    return httpService.post<InviteResult, InvitePayload>(url, payload)
  },
  /**
   * Resends an invite to a customer.
   * @param payload - The payload containing the email of the customer to resend the invite to.
   * @returns A promise that resolves to the HTTP response from the server.
   */
  resendInvite(payload: { email: string }): Promise<AxiosResponse<ApiResult>> {
    const url = `https://${host}/customerAccounts/resendCustomerInvite`
    return httpService.post<ApiResult, { email: string }>(url, payload)
  },
  /**
   * Retrieves a table view of customers based on specified parameters.
   * @param params - The parameters to use for filtering, sorting, and pagination of the table view.
   * @returns A promise that resolves to a table view of customers.
   */
  customersTableView(
    params: TableViewParameters
  ): Promise<AxiosResponse<TableViewResult<TableViewCustomer>>> {
    const { filters = null, sorts = null, pageSize = 10, page = 1 } = params
    let query = `page=${page}&pageSize=${pageSize}`
    if (sorts) {
      query = `${query}&${sorts}`
    }
    if (filters) {
      query = `${query}&${filters}`
    }
    query = encodeURI(query)
    const url = `https://${host}/customers/customerAccount?${query}`
    return httpService.get(url)
  },
  effectiveDefaultValueSet(
    customerAccountId: number
  ): Promise<AxiosResponse<CustomerAccountDefaultValueSet>> {
    const url = `https://${host}/customerAccounts/${customerAccountId}/effectiveDefaultValueSet`
    return httpService.get(url)
  },
  /**
   * Retrieves a list of shared contacts for a given customer account. These are the users who have custom view access to
   * at least one quote or reservation of the customer account or one of its descendants.
   *
   * @param customerAccountId - The ID of the customer account.
   * @return An array of SharedContact objects.
   */
  sharedContactsList(
    customerAccountId: number
  ): Promise<AxiosResponse<Array<SharedContactBasicInfo>>> {
    const url = `https://${host}/customerAccount/${customerAccountId}/sharedContacts/list`
    return httpService.get(url)
  },
  sharedContactsTable(
    customerAccountId: number
  ): Promise<AxiosResponse<Array<SharedContact>>> {
    const url = `https://${host}/customerAccount/${customerAccountId}/sharedContacts/table`
    return httpService.get(url)
  },
  /**
   * Retrieves a list of customers as shared contacts for a given customer account and all its descendants.
   *
   * @param customerAccountId - The ID of the customer account.
   * @return An array of SharedContact objects.
   */
  customers(
    customerAccountId: number
  ): Promise<AxiosResponse<Array<SharedContactBasicInfo>>> {
    const url = `https://${host}/customerAccounts/${customerAccountId}/customers`
    return httpService.get(url)
  },
}
