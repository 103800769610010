
import {
  EditTripContactPayload,
  ReservationDetail,
  SharedList,
} from '@/models/dto'
import { phoneFormatFilter } from '@/utils/phone'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { Contact } from '@/models/Contact'
import { Customer } from '@/models/dto/Customer'
import ReservationDetailShareDialog from '@/components/ReservationDetailShareDialog.vue'
import ReservationDetailTripContactForm from '@/components/ReservationDetailTripContactForm.vue'
import ReservationDetailContactInfomationVerifySMS from '@/components/ReservationDetailContactInfomationVerifySMS.vue'
import reservation from '@/services/reservation'
import trip from '@/services/trip'
import { toKebab } from '@/utils/string'
import auth from '../store/modules/auth'
import { ReservationStatusKey } from '../utils/enum'
import customer from '@/services/customer'
import { EventBus } from '@/utils/eventBus'
import { TranslateResult } from 'vue-i18n'

@Component({
  components: {
    ReservationDetailShareDialog,
    ReservationDetailTripContactForm,
    ReservationDetailContactInfomationVerifySMS,
  },
})
export default class ReservationDetailContactInfo extends Vue {
  @Prop({ type: Object, required: true })
  readonly reservation: ReservationDetail
  @Prop({ type: Array, required: true })
  readonly sharedList: SharedList
  @Prop({ type: Boolean, required: false, default: false })
  readonly allowUserManagement: boolean
  @Prop({ type: Boolean, required: false, default: false })
  readonly isTripContactModalOpen: boolean
  @Prop({ type: Boolean, required: false, default: false })
  readonly isRiderModalOpen: boolean
  @Prop({ type: Boolean, required: false, default: false })
  readonly isVerifyPhoneModalOpen: boolean
  phoneFormatFilter = phoneFormatFilter
  isShareReservationDialogOpen = false
  isTripContactDialogOpen = false
  toKebab = toKebab
  tripContactCustomer: Customer = null
  customer: Customer = null

  @Watch('isTripContactModalOpen')
  isTripContactModalOpenChanged(val: boolean): any {
    this.isTripContactDialogOpen = !this.showTripContact && this.isTripContactModalOpen
  }

  @Watch('isRiderModalOpen')
  isRiderModalOpenChanged(val: boolean): any {
    this.isShareReservationDialogOpen = this.isRiderModalOpen
  }

  get isFinished(): boolean {
    return this.reservation?.reservationStatus === ReservationStatusKey.Finished
  }

  get isUserSMSVerified(): boolean {
    return auth.customer?.smsConfirmed
  }

  get isCustomerSMSEnabled(): boolean {
    return this.customer?.smsConfirmed
  }

  get isTripContactSMSEnabled(): boolean {
    return this.tripContactCustomer?.smsConfirmed
  }

  get bookingContact(): Contact {
    return {
      id: this.customer?.customerId,
      firstName: this.customer?.firstName,
      lastName: this.customer?.lastName,
      email: this.customer?.email,
      phone: this.customer?.phone,
    }
  }

  get tripContact(): Contact {
    return {
      id: this.tripContactCustomer?.customerId,
      firstName: this.tripContactCustomer?.firstName,
      lastName: this.tripContactCustomer?.lastName,
      email: this.tripContactCustomer?.email,
      phone: this.tripContactCustomer?.phone,
    }
  }

  get showTripContact(): boolean {
    return (
      this.reservation?.tripContact?.customerId &&
      this.reservation?.tripContact?.customerId !== this.customer?.customerId
    )
  }

  get invitedUserContacts(): Contact[] {
    if (!this.sharedList.length) {
      return []
    }

    let sharedList = this.sharedList
    if (!this.allowUserManagement) {
      sharedList = sharedList.filter((user) => user.customerId === auth.userId)
    } else {
      const tripContactId = this.tripContact?.id || this.reservation?.tripContact?.customerId
      sharedList = sharedList.filter(
        (user) =>
          ![tripContactId, this.bookingContact.id].includes(
            user.customerId
          )
      )
    }
    return sharedList.map((user) => {
      return {
        id: user.customerId,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        phone: user.phone,
      }
    })
  }

  get contacts(): { label: TranslateResult; list: Contact[] }[] {
    const contacts = []

    const booking = {
      label: this.$t('reservationDetail.contactInformation.BOOKING_CONTACT'),
      list: [this.bookingContact],
    }
    contacts.push(booking)

    if (this.showTripContact) {
      const trip = {
        label: this.$t('reservationDetail.contactInformation.TRIP_CONTACT'),
        list: [this.tripContact],
      }
      contacts.push(trip)
    }

    if (this.invitedUserContacts.length) {
      const invited = {
        label: this.$t(
          'reservationDetail.contactInformation.RIDERS_WITH_ACCESS'
        ),
        list: this.invitedUserContacts,
      }
      contacts.push(invited)
    }

    return contacts
  }

  mounted(): void {
    this.refreshContacts()

    EventBus.$on('refresh-contacts', this.refreshContacts)
    this.isTripContactDialogOpen = !this.showTripContact && this.isTripContactModalOpen
    this.isShareReservationDialogOpen = this.isRiderModalOpen
  }

  beforeUnmount(): void {
    EventBus.$off('refresh-contacts')
  }

  isSMSEnabled(contactTypeLabel: string): boolean {
    if (contactTypeLabel === 'Booking Contact') {
      return this.isCustomerSMSEnabled
    } else if (contactTypeLabel === 'Trip Contact') {
      return this.isTripContactSMSEnabled
    } else {
      return false
    }
  }

  refreshContacts(): void {
    this.loadCustomer()
    this.loadTripContact()
  }

  openTripContactDialog(): void {
    this.isTripContactDialogOpen = true
  }

  async remove(userId: number): Promise<void> {
    if (!userId) {
      return
    }
    if (userId === this.tripContact.id) {
      const tripId = this.reservation.tripId
      const payload: EditTripContactPayload = {
        tripContact: {
          customerId: null,
        },
      }
      await trip.editTripContact(tripId, payload)
      this.tripContactCustomer = null
      this.$emit('refresh')
      return
    }
    await reservation.unshare(this.reservation.reservationId, userId)
    this.$emit('refresh-shared-list')
  }

  async loadTripContact(): Promise<void> {
    if (!this.reservation?.tripContact?.customerId) {
      return
    }

    const tripContactResponse = await customer.byId(
      this.reservation?.tripContact?.customerId
    )

    this.tripContactCustomer = tripContactResponse.data.customer
  }

  async loadCustomer(): Promise<void> {
    const customerResponse = await customer.byId(
      this.reservation?.customer?.customerId
    )
    this.customer = customerResponse.data.customer
  }
}
