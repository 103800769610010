
import EnterpriseTrackingFiltering from '@/components/EnterpriseTrackingFiltering.vue'
import EnterpriseTrackingSidebarVehicleCard from '@/components/EnterpriseTrackingSidebarVehicleCard.vue'
import EnterpriseTrackingSidebarItineraryItem from '@/components/EnterpriseTrackingSidebarItineraryItem.vue'
import EnterpriseTrackingSidebarItineraryItemSkeleton from '@/components/EnterpriseTrackingSidebarItineraryItemSkeleton.vue'
import { DateTime } from 'luxon'
import { timeAgo } from '@/utils/datetime'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { TypeWithId } from '../models/dto'
import SkeletonBox from '@/components/SkeletonBox.vue'

@Component({
  components: {
    EnterpriseTrackingFiltering,
    EnterpriseTrackingSidebarItineraryItem,
    EnterpriseTrackingSidebarVehicleCard,
    EnterpriseTrackingSidebarItineraryItemSkeleton,
    SkeletonBox,
  },
})
export default class EnterpriseTrackingSidebar extends Vue {
  @Prop({ type: Boolean, required: true }) readonly isModePublic: boolean
  @Prop({ type: Boolean }) readonly loading: boolean
  @Prop({ type: Array, default: () => [] }) readonly lastVehiclePositions: any[]
  @Prop({ type: String, required: true }) readonly listMode: string
  @Prop({ type: Boolean }) readonly isEnterpriseAdmin: boolean
  @Prop({ type: Boolean }) readonly isTableView: boolean
  @Prop({ type: Array, default: () => [] }) readonly filteredVehicles: any[]
  @Prop({ type: [String, Number], required: true }) readonly hoverItem: any
  @Prop({ type: Array, default: () => [] })
  readonly reservationItineraries: any[]
  @Prop({ type: Object, required: true }) readonly filters: any
  @Prop({ type: Array, default: () => [] }) readonly eldTypes: TypeWithId[]

  drawerOpen = false
  selectedItineraryReservationId = null
  timeAgo = timeAgo

  get totalPickupAndDropoffCount(): number {
    let count = 0
    if (this.itinerarySource) {
      for (const location of this.itinerarySource) {
        count += location.dropoffTimes.length
        count += location.pickupTimes.length
      }
    }
    return count
  }
  get itinerarySource(): any[] {
    if (this.selectedItineraryReservationId !== null) {
      const itinerary = this.reservationItineraries.find(
        (itinerary) =>
          itinerary[0].reservationId === this.selectedItineraryReservationId
      )
      if (itinerary.length >= 2) {
        const dropoffTimes = []
        for (const location of itinerary) {
          dropoffTimes.push(...location.dropoffTimes)
        }
        const lastDropoff = dropoffTimes.sort()[dropoffTimes.length - 1]
        for (const location of itinerary) {
          location.dropoffTimes = location.dropoffTimes.filter(
            (dropoffTime) => dropoffTime === lastDropoff
          )
        }
      }
      return itinerary
    }
    return null
  }
  get shouldShowTimeline(): boolean {
    return this.drawerOpen || this.$vuetify.breakpoint.smAndUp
  }
  get columns(): any[] {
    return [
      { text: 'Vehicle', value: 'vehicleName' },
      { text: 'Contract', value: 'contractName', sortable: false },
      { text: 'Res ID', value: 'reservationId', sortable: false },
      { text: 'Operator', value: 'operatorName' },
      { text: 'Tracker #', value: 'trak4DeviceId' },
      {
        text: 'Last Transmitted',
        value: 'lastTransmitted',
      },
      { text: 'Timestamp', value: 'receivedDate' },
      { text: 'Active', value: 'active' },
    ]
  }
  get isListModeVehicle(): boolean {
    return this.listMode === 'vehicle'
  }
  get isListModeItinerary(): boolean {
    return this.listMode === 'itinerary'
  }
  get nextStopTime(): string {
    const now = DateTime.utc().toISO()
    const stopTimes = []
    for (const itinerary of this.reservationItineraries) {
      for (let i = 0; i < itinerary.length; i++) {
        for (const time of itinerary[i].pickupTimes) {
          if (time && now < time) {
            stopTimes.push(time)
          }
        }
      }
    }
    stopTimes.sort((a, b) => {
      return new Date(a).getTime() - new Date(b).getTime()
    })
    return stopTimes[0] || null
  }

  emitListMode(mode: string): void {
    this.$emit('change-list-mode', mode)
  }
  formatTimestamp(timestamp: string): string {
    const datetime = DateTime.fromISO(timestamp)
    return datetime.toFormat('LL-dd-yyyy hh:mm a ZZZZ')
  }
  setHover(value): void {
    this.$emit('hover', value)
  }
  selectReservationItinerary(reservation): void {
    this.selectedItineraryReservationId = reservation.reservationId
    const newFilters = {
      ...this.filters,
      reservationId: [reservation.managedId],
    }
    this.$emit('update:filters', newFilters)
  }
  clearReservationItinerarySelection(): void {
    this.selectedItineraryReservationId = null
    const newFilters = {
      ...this.filters,
    }
    delete newFilters.reservationId
    this.$emit('update:filters', newFilters)
  }
}
