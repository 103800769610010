
import { Vue, Component, Prop, InjectReactive } from 'vue-property-decorator'
import alert from '@/store/modules/alert'

@Component
export default class ShareLiveTrackingDialog extends Vue {
  @Prop({ type: Boolean }) readonly value: boolean
  @Prop({ type: String }) readonly reservationHash!: string

  @InjectReactive('trackingLink') trackingLink

  async copyURL(): Promise<void> {
    await navigator.clipboard.writeText(this.trackingLink)
    alert.add({
      text: '',
      color: 'green',
      title: 'Link copied',
      dismissible: true,
    })
  }

  async shareURL(): Promise<void> {
    try {
      if (navigator.share) {
        await navigator.share({
          title: 'CharterUP Live Tracking',
          url: location.href,
          text: 'View your CharterUP itinerary and track your vehicle live.'
        })
      } else {
        this.copyURL()
      }
    } catch (error) {
      console.error('Error sharing link', error)
    }
  }
}
