
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class CUSkeletonLoaderTextField extends Vue {
  @Prop({ type: Boolean, required: false, default: false })
  readonly hideDetails: boolean
  @Prop({ type: Boolean, required: false, default: false })
  readonly hideLabel: boolean
  @Prop({ type: String, required: false, default: '' }) readonly label: string

  get classes(): Record<string, boolean> {
    return { 'margin-b-7': !this.hideDetails }
  }
}
