import { Action, Module, VuexModule } from 'vuex-class-modules'

import auth from '@/services/auth'
import store from '@/store/index'
import { clear, load, save } from '@/utils/localStorage'
import { findPermissionByName } from '@/utils/permissions'
import customerAccount from '@/services/customerAccount'
import router from '@/router'
import { AuthPayload, AuthResult, AuthUser, Customer, CustomerAccount, Role, User } from '@/models/dto'
import customer from '@/services/customer'
import user from '@/services/user'
import support from '@/store/modules/support'
import * as datadog from '../../utils/datadog'
import { hasTokenValue, loadTokenValue, saveAuthToken } from '@/services/authTokenRepository'
import Vue from 'vue'
import { PermissionName, SplitKeyType, SplitTrafficType } from '@/utils/enum'

const DEFAULT_CUSTOMER_SUPPORT_NUMBER = '8559202287'

@Module({ generateMutationSetters: true })
class AuthModule extends VuexModule {
  _user: User = load('user') || null
  _customer: Customer = load('customer') || null
  _token: string = loadTokenValue()
  _isTokenSet: boolean = hasTokenValue()
  _roles: Role[] = []
  _userId: number = load('userId') || null
  _customerAccount: CustomerAccount = load('customerAccount') || null
  _customerAccountId: number = null
  _childCustomerAccountIds: number[] = []

  /**
   * @returns the authenticated user.
   */
  get user(): User {
    return this._user
  }

   /**
     * This is used when a new user is completing the self serve flow.
     * @returns the default customer support number.
   */
  get defaultCustomerSupportNumber(): string {
    return DEFAULT_CUSTOMER_SUPPORT_NUMBER
  }

  /**
   * @returns the authenticated customer.
   */
  get customer(): Customer {
    return this._customer
  }

  /**
   * @returns the customer account associated with the authenticated user.
   */
  get customerAccount(): CustomerAccount {
    return this._customerAccount
  }

  /**
   * @returns the customer sub-accounts associated with the authenticated user.
   * These are the child accounts of the authenticated user's customer account.
   * If the authenticated user is not associated with a customer account, this will return an empty array.
   */
  get customerSubAccounts(): CustomerAccount[] {
    return this._customerAccount?.teams || []
  }

  /**
   * @returns the customer account ID associated with the authenticated user.
   */
  get customerAccountId(): number {
    return this._customerAccountId
  }

  /**
   * @returns the token for the authenticated user.
   */
  get token(): string {
    return this._token
  }

  /**
   * @returns whether the token is set for the authenticated user.
   */
  get isTokenSet(): boolean {
    return this._isTokenSet
  }

  /**
   * @returns the roles associated with the authenticated user.
   */
  get roles(): Role[] {
    return this._roles
  }

  /**
   * @returns whether the authenticated user is a CMS admin.
   */
  get isCMSAdmin(): boolean {
    return findPermissionByName(this._roles, PermissionName.OperatorDetailPreview)
  }

  /**
   * @returns whether the authenticated user is an enterprise client.
   */
  get isEnterpriseClient(): boolean {
    return findPermissionByName(this._roles, PermissionName.EnterpriseTracking)
  }

  /**
   * @returns whether the authenticated user is an enterprise admin.
   */
  get isEnterpriseAdmin(): boolean {
    return findPermissionByName(this._roles, PermissionName.EnterpriseTrackingReservationDetails)
  }

  /** @returns wether or not the user is an organization admin */
  get isCustomerAccountAdmin(): boolean {
    return findPermissionByName(this._roles, PermissionName.CustomerAccountAdmin)
  }

  /**
   * @returns whether the authenticated user is a finance admin.
   */
  get isFinanceAdmin(): boolean {
    return findPermissionByName(this._roles, PermissionName.CharterUPCheckoutMeta)
  }

  /**
   * @returns the current user's user ID.
   */
  get userId(): number {
    return this._userId
  }

  /**
   * @returns the user's child customer account IDs.
   */
  get childCustomerAccountIds(): number[] {
    return this._childCustomerAccountIds
  }

  /**
   * Login the user with the given payload.
   * @param payload - The payload containing the user's login credentials.
   */
  @Action
  async login(payload: AuthPayload): Promise<void> {
    const response = await auth.login(payload)
    const { data } = response
    await this.setSessionData({ authResult: data, legacy: true })
    await this.postLoginActions()
  }

  /**
   * Login the user with the given payload.
   * @param token - The JWT to authenticate the user.
   */
  @Action
  async jwtLogin(token): Promise<void> {
    try {
      const response = await auth.jwtLogin({ token })
      const { data } = response
      await this.setSessionData({ authResult: data, legacy: true })
      await this.postLoginActions()
    } catch (error) {
      console.warn(error)
    }
  }

  /**
   * Attempts to automatically log in by loading the saved user data from local storage.
   */
  @Action
  autoLogin(): void {
    this._user = load('user')
    this._userId = load('userId')
    this._token = loadTokenValue()
    this._roles = load('roles')
    this._customerAccountId = load('customerAccountId')

    setDataDogUserContext(this._user)
  }

  /**
   * Refreshes the user data using the saved user ID.
   */
  @Action
  async fetchUserDetails(): Promise<void> {
    if (!this._userId) {
      return
    }

    const { data } = await user.byId(this._userId)
    this._user = data.user
    save('user', data.user)
  }

  /**
   * Refreshes the user data using the saved user ID.
   */
  @Action
  async refreshCustomer(): Promise<void> {
    if (!this._userId) {
      return
    }

    const response = await customer.byId(this._userId)
    this._customer = response.data.customer
    save('customer', response.data.customer)
  }

  /**
   * Refreshes the users roles and permissions from the database.
   */
  @Action
  async refreshRolesAndPermissions() {
    const response = await auth.getUserRolesAndPermissions()
    save('roles', response.data.userProfile.roles)
    this._roles = response.data.userProfile.roles
  }

  /**
   * Refreshes the customer account information by fetching the latest data from the database and storing it in the state.
   */
  @Action
  async refreshCustomerAccount(): Promise<void> {
    try {
      const response = await customerAccount.get()
      const customerAccountResponse = response.data
      save('customerAccount', customerAccountResponse)
      save('customerAccountId', customerAccountResponse.customerAccountId)
      let childCustomerAccountIds: number[] = []
      if (customerAccountResponse.teams) {
        childCustomerAccountIds = customerAccountResponse.teams.map(
          (team) => team.customerAccountId
        )
      }
      save('childCustomerAccountIds', childCustomerAccountIds)
      this._childCustomerAccountIds = childCustomerAccountIds
      this._customerAccount = customerAccountResponse
    } catch (error) {
      console.warn('Could not refresh customer account')
      console.warn(error)
    }
  }

  /**
   * Logs out the user and clears the state.
   */
  @Action
  async logout(): Promise<void> {
    clear()
    // keep other stores synced with local storage
    support.clear()
    this.resetState()
    datadog.clearUserContext()
    Vue.prototype.$split.updateKey(SplitKeyType.AnonymousUser)

    if (Vue.prototype.$auth0.isInitialized) {
      await Vue.prototype.$auth0.logout()
    } else {
      router.push({
        name: 'login',
      })
    }
  }

  /**
   * Sets the user id from the user stored in the state
   */
  @Action
  setUserIdFromUser(): void {
    this._userId = this._user.userId
  }

  @Action
  async auth0Login(token) {
    this.setToken({token, legacy: false })

    try {
      const response = await auth.getUserProfileWithToken()
      const { data } = response
      await this.setSessionData({ authResult: data, legacy: false })
      await this.postLoginActions()
    } catch (error) {
      console.warn(error)
    }
  }

  @Action
  resetState() {
    this._user = null
    this._customer = null
    this._token = null
    this._isTokenSet = false
    this._roles = []
    this._userId = null
    this._customerAccount = null
    this._customerAccountId = null
    this._childCustomerAccountIds = []
  }

  @Action
  async setSessionData({ authResult, legacy }: { authResult: AuthResult, legacy: boolean }) {
    const { user, token } = authResult

    save('userId', user.id)
    this._userId = user.id

    save('customerAccountId', user.customerAccountId)
    this._customerAccountId = user.customerAccountId

    this.setToken({token, legacy})

    await this.fetchUserDetails()

    setDataDogUserContext(this._user)

    const userId = this._userId.toString()
    Vue.prototype.$split.updateKey(userId, SplitTrafficType.User)
  }

  @Action
  setToken({token, legacy}: {token: string, legacy: boolean}): void {
    this._token = token
    this._isTokenSet = !!token
    saveAuthToken(token, legacy)
  }

  @Action
  async postLoginActions(): Promise<void> {
    await Promise.all([
      this.refreshRolesAndPermissions(),
      this.refreshCustomerAccount(),
      this.refreshCustomer(),
    ])
  }

}

/**
 * Sets the DataDog context to a given user.
 * @param user - The User whose userId, email, and name will be set in the DataDog context.
 */
const setDataDogUserContext = (user: User | null): void => { // add null here since we are effectively checking for that
  if (!user) {
    return
  }
  const { firstName, lastName, userId, email } = user
  const fullName = [firstName, lastName].filter(Boolean).join(" ")
  datadog.setUserContext(userId.toString(), email, fullName)
};

export default new AuthModule({ store, name: 'auth' })
