
import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator'
import { DateTime, Duration } from 'luxon'
import AutoCompleteAddress from '@/components/AutoCompleteAddress.vue'
import CUDigitalTimePicker from '@/components/CUDigitalTimePicker.vue'
import CUDatePicker from '@/components/CUDatePicker.vue'
import CUTimePicker from '@/components/CUTimePicker.vue'
import SelfServeTripItineraryStopKeepOnSiteDialog from '@/components/SelfServeTripItineraryStopKeepOnSiteDialog.vue'
import CUIcon from '@/components/CUIcon.vue'
import { TripModificationTrip } from '@/classes/TripModificationTrip'
import { TripModificationStop } from '@/classes/TripModificationStop'
import { Address, PlaceDetail } from '@/models/dto'
import { secondsToFormattedDuration } from '@/utils/datetime'
import { placeDetailToAddress } from '@/utils/address'
import { RiskTypeId, RiskTypeLabel } from '@/utils/enum'

const DEFAULT_STOP_TIME = '08:00:00'
const STOP_TIME_LABEL = 'Pickup Time'
const STOP_DATE_LABEL = 'Pickup Date'
const HOUR_MINUTE_FORMAT = 'hh:mm a'
const MONTH_DAY_YEAR_FORMAT = 'LL/dd/yy'

@Component({
  components: {
    AutoCompleteAddress,
    CUDigitalTimePicker,
    CUIcon,
    CUDatePicker,
    CUTimePicker,
    SelfServeTripItineraryStopKeepOnSiteDialog,
  },
})
export default class TripModificationItineraryStop extends Vue {
  @Prop({ type: Object, required: true })
  readonly trip: TripModificationTrip
  @Prop({ type: Object, required: true }) readonly stop: TripModificationStop
  @Prop({ type: Boolean }) readonly hideDelete: boolean
  @Prop({ type: Boolean }) readonly hideAdd: boolean
  @Prop({ type: Number, required: true }) readonly stopIndex: number

  isKeepOnSiteDialogOpen = false
  date: string = ''
  time: string = ''

  @Emit('update:address')
  emitAddress(place: PlaceDetail): { address: Address; index: number } {
    const address = placeDetailToAddress(place)
    return { address, index: this.stopIndex }
  }

  @Emit('update:datetime')
  emitDateTime(): { date: string; time: string; index: number } {
    return { date: this.date, time: this.time, index: this.stopIndex }
  }

  @Watch('errorMessage')
  errorMessageChanged(): void {
    this.$emit('update:has-error', !!this.errorMessage)
  }

  @Watch('isLastStop')
  isLastStopChanged(value: boolean): void {
    // If this becomes the last stop, reset pickup date and time
    if (value) {
      this.date = ''
      this.time = ''
      this.emitDateTime()
    }
  }

  get nextIndex(): number {
    return this.stopIndex + 1
  }

  get isFirstStop(): boolean {
    return this.stopIndex === 0
  }

  get isLastStop(): boolean {
    return this.stopIndex === this.trip.stops.length - 1
  }

  get previousStop(): TripModificationStop | null {
    return !this.isFirstStop ? this.trip.stops[this.stopIndex - 1] : null
  }

  get stopType(): string {
    switch (this.stopIndex) {
      case 0:
        return 'Pickup'
      case this.trip.stops.length - 1:
        return 'Dropoff'
      default:
        return 'Stop'
    }
  }

  get addressLabel(): string {
    return `${this.stopType} Location`
  }

  get addressTitle(): string {
    return this.stop?.address?.title || ''
  }

  get addressName(): string {
    return this.stop?.address?.name || ''
  }

  get errorMessage(): string {
    const pickupDatetime = DateTime.fromISO(this.stop?.pickupDatetime)
    const dropoffDatetime = DateTime.fromISO(this.stop?.dropoffDatetime)

    if (this.isFirstStop && pickupDatetime <= DateTime.now()) {
      return 'Your trip must start in the future'
    }

    const previousPickupDatetime = DateTime.fromISO(
      this.previousStop?.pickupDatetime
    )
    if (previousPickupDatetime && pickupDatetime < previousPickupDatetime) {
      return 'Pickup must be after prior stops'
    }

    if (
      dropoffDatetime &&
      pickupDatetime < dropoffDatetime &&
      this.stop?.address?.zoneId
    ) {
      const arrivalDatetime = DateTime.fromISO(dropoffDatetime, {
        zone: this.stop.address.zoneId,
      })
      const arrivalTimeFormatted = arrivalDatetime.toFormat(HOUR_MINUTE_FORMAT)
      const arrivalDateFormatted = arrivalDatetime.toFormat(
        MONTH_DAY_YEAR_FORMAT
      )
      return `Pickup must be after the estimated arrival time of ${arrivalTimeFormatted} on ${arrivalDateFormatted}`
    }

    return ''
  }

  get stopDate(): {
    label: string
    value: string
    displayValue: string
    min: string
  } {
    const formattedStopDate = this.date
      ? DateTime.fromISO(this.date).toFormat(MONTH_DAY_YEAR_FORMAT)
      : ' '

    const previousStopDuration = DateTime.fromISO(
      this.previousStop?.pickupDatetime,
      {
        zone: this.previousStop?.address?.zoneId,
      }
    )
    const previousStopDate =
      this.extractDateString(previousStopDuration) ||
      this.extractDateString(DateTime.now())

    const splitDate = previousStopDate.split('-')
    const formattedPreviousDate =
      splitDate.length === 3 ? `${splitDate[0]}-${splitDate[1]}` : ''

    return {
      label: STOP_DATE_LABEL,
      value: this.date || formattedPreviousDate,
      displayValue: formattedStopDate,
      min: previousStopDate,
    }
  }

  get stopTime(): {
    label: string
    displayValue: string
  } {
    const formattedStopTime = this.time
      ? DateTime.fromISO(this.time).toFormat(HOUR_MINUTE_FORMAT)
      : ''
    return {
      label: STOP_TIME_LABEL,
      displayValue: formattedStopTime,
    }
  }

  get showKeepOnSite(): boolean {
    return (
      !this.isFirstStop && !this.isLastStop && this.trip.stops.length > 2
    )
  }

  get timeFromPreviousStopFormatted(): string {
    return this.stop.travelTimeFromPreviousStopInSeconds
      ? secondsToFormattedDuration(
          this.stop.travelTimeFromPreviousStopInSeconds
        )
      : ''
  }

  get addressRisks(): {
    riskTypeId: number
    icon: string
    label: string
    class: string
  }[] {
    const riskIconMap = []
    for (const risk of this.stop?.address?.risks || []) {
      const { riskTypeId } = risk
      if (riskTypeId === RiskTypeId.IncompleteAddress) {
        riskIconMap.push({
          riskTypeId,
          icon: 'warning',
          label: RiskTypeLabel.IncompleteAddress,
          class: 'yellow',
        })
      }
    }
    return riskIconMap
  }

  mounted(): void {
    if (!this.stop?.pickupDatetime) {
      return
    }
    const pickupDatetime = DateTime.fromISO(this.stop?.pickupDatetime, {
      zone: this.stop?.address?.zoneId,
    })
    if (pickupDatetime) {
      this.time = this.extractTimeString(pickupDatetime)
      this.date = this.extractDateString(pickupDatetime)
    }
  }

  extractTimeString(time: Duration): string {
    if (!time.isValid) {
      return ''
    }
    return time.toISO().substring(11, 19)
  }

  extractDateString(date: Duration): string {
    if (!date.isValid) {
      return ''
    }
    return date.toISO().substring(0, 10)
  }

  setStopDate(event: string): void {
    this.date = event
    if (!this.time) {
      this.time = this.setDefaultTime()
    }
    this.emitDateTime()
  }

  setStopTime(event: string): void {
    this.time = event === null || event === undefined ? '' : event
    this.emitDateTime()
  }

  setDefaultTime(): string {
    const baseTime = this.previousStop?.pickupDatetime
    const zone = this.stop?.address?.zoneId

    if (!this.previousStop || !baseTime || !zone) {
      return DEFAULT_STOP_TIME
    }

    // Get time and round up to the nearest hour
    const calculatedStopTime = DateTime.fromISO(baseTime, { zone })
      .plus({
        seconds: this.stop.travelTimeFromPreviousStopInSeconds,
      })
      .plus({ hours: 1 })
      .startOf('hour')

    return this.extractTimeString(calculatedStopTime)
  }
}
