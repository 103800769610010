
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class AlertBox extends Vue {
  @Prop({ type: String, default: 'success' }) readonly type: string
  @Prop({ type: Boolean }) readonly active: boolean
  @Prop({ type: String, default: 'warning' }) readonly errorIcon: string
  @Prop({ type: String, default: null }) readonly height: string
}
