var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (!!_vm.$slots.notFound && _vm.serverItemsLength === 0)?_c('div',[_vm._t("notFound")],2):(_vm.items)?_c('div',{class:{
    'v-data-table__detail': _vm.isDetailTable,
    'v-data-table__editable': _vm.isEditableTable,
    'v-data-table__mobile': _vm.isMobile,
  }},[_c('v-data-table',_vm._b({staticClass:"position-relative",class:{ 'v-data-table__transparent-header': _vm.transparentHeaders },attrs:{"hide-default-header":_vm.hideDefaultHeader,"headers":_vm.columns,"items":_vm.items,"server-items-length":_vm.serverItemsLength,"disable-sort":"","mobile-breakpoint":_vm.$vuetify.breakpoint.thresholds[_vm.mobileViewOnBreakpoint],"disable-filtering":"","loader-height":2,"options":_vm.options,"no-data-text":_vm.noDataText,"dense":_vm.dense,"show-select":_vm.showSelect,"hide-default-footer":_vm.hideDefaultFooter || _vm.isDetailTable || _vm.isMobile},on:{"update:options":function($event){return _vm.$emit('update:options', $event)}},scopedSlots:_vm._u([(_vm.selectColumn)?{key:`header.select`,fn:function({ on, props }){return [_c('CUDataTableHeaderCheckbox',{attrs:{"predefined-selects":_vm.selectColumn.predefinedSelects,"selected-items":_vm.selectedItems,"items":_vm.items,"item-key":_vm.itemKey},on:{"select":function($event){return _vm.$emit('select-predefined', $event)}}})]}}:null,(_vm.selectColumn && (_vm.areItemsSelected || _vm.isMobile))?{key:"header",fn:function(){return [(_vm.areItemsSelected)?_c('thead',{staticClass:"d-flex h-60 align-center",class:{ 'position-absolute top-2 left-88': !_vm.isMobile }},[_c('CUDataTableHeaderActionRow',{attrs:{"actions":_vm.selectColumn.actions}})],1):_vm._e(),(_vm.isMobile)?_c('CUDataTableMobilePredefinedSelects',{attrs:{"select-column":_vm.selectColumn},on:{"select":function($event){return _vm.$emit('select-predefined', $event)}}}):_vm._e()]},proxy:true}:null,_vm._l((_vm.headerColumns),function(col,index){return {key:col.headerName,fn:function(props){return [_c('thead',{key:`header-${col.slotName}-${index}`,staticClass:"transition-all transition-duration-150 transition-ease-in-out",class:{
          'opacity-0': _vm.areItemsSelected,
          'opacity-100': !_vm.areItemsSelected,
        },style:(_vm.areItemsSelected ? 'pointer-events: none;' : '')},[_c('th',{staticClass:"text-start",style:({ 'border-bottom': 0 })},[(col.headerComponent)?_c('span',[_c(col.headerComponent,{tag:"component"})],1):(!!props)?_c('span',{staticClass:"d-flex",class:{ 'text-transparent': _vm.areItemsSelected },style:([_vm.areItemsSelected ? 'opacity: 0;' : ''])},[_vm._v(" "+_vm._s(props.header.text)+" ")]):_vm._e()])])]}}}),(!_vm.isMobile)?{key:"item",fn:function({ item, index }){return [_c('tr',{class:{ 'cursor-pointer': !!_vm.rowClickHandler }},_vm._l((_vm.columns),function(col,colIndex){return _c('td',{key:`column-${col.value}-${colIndex}-${index}`,class:col.classes || '',on:{"click":function($event){return _vm.handleRowClick(col, item, index)}}},[(!_vm.hideCell(col, item))?_c('CUDataTableCell',_vm._g({key:`data-table-cell-${col.value}-${colIndex}-${index}`,attrs:{"column":col,"row":item,"row-index":index,"actions":_vm.actions,"select-column":_vm.selectColumn,"selected-items":_vm.selectedItems,"is-mobile":false,"is-detail-table":_vm.isDetailTable,"display-actions-on-mobile":_vm.displayActionsOnMobile,"detail-name":_vm.detailName,"item-key":_vm.itemKey,"table-element-id":_vm.tableElementId},on:{"refresh":function($event){return _vm.$emit('refresh')},"select":function($event){return _vm.$emit('select', $event)}}},_vm.$listeners)):_vm._e()],1)}),0)]}}:(!!_vm.$scopedSlots.mobileLayout)?{key:"item",fn:function({ item, index }){return [_vm._t("mobileLayout",null,{"item":item,"index":index})]}}:{key:"item",fn:function({ item, index }){return [_c('div',{staticClass:"d-flex border-solid border-gray-mid-light padding-a-3 border-radius-8 margin-y-3 border-1",class:{
          'padding-x-3': _vm.isDetailTable,
          'flex-row flex-wrap': _vm.isEditableTable,
          'flex-column': !_vm.isEditableTable,
          'border-primary': _vm.isItemSelected(item),
        }},_vm._l((_vm.columns),function(col,colIndex){return _c('div',{key:`column-${col.value}-${colIndex}-${index}`,class:{
            'flex-basis-48-percent margin-x-1-percent':
              _vm.isEditableTable && col.type !== 'actions',
            'flex-basis-full': _vm.isEditableTable && col.type === 'actions',
            'cursor-pointer': !!_vm.rowClickHandler,
          },on:{"click":function($event){return _vm.handleRowClick(col, item, index)}}},[(!_vm.hideCell(col, item))?_c('CUDataTableCell',_vm._g({attrs:{"column":col,"row":item,"row-index":index,"actions":_vm.actions,"select-column":_vm.selectColumn,"selected-items":_vm.selectedItems,"is-detail-table":_vm.isDetailTable,"is-mobile":"","detail-name":_vm.detailName,"display-actions-on-mobile":_vm.displayActionsOnMobile,"item-key":_vm.itemKey},on:{"refresh":function($event){return _vm.$emit('refresh')},"select":function($event){return _vm.$emit('select', $event)}}},_vm.$listeners)):_vm._e()],1)}),0)]}}],null,true)},'v-data-table',_vm.$attrs,false),[(!_vm.isDetailTable)?_c('v-pagination',{attrs:{"length":_vm.serverItemsLength},model:{value:(_vm.options.page),callback:function ($$v) {_vm.$set(_vm.options, "page", $$v)},expression:"options.page"}}):_vm._e()],1),(_vm.isMobile && _vm.serverItemsLength >= 1)?_c('v-pagination',{attrs:{"value":_vm.options.page,"length":Math.ceil(_vm.serverItemsLength / _vm.options.itemsPerPage),"total-visible":5},on:{"input":function($event){return _vm.updateOptionsPage($event)}}}):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }