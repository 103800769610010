
import { Vue, Component, Prop } from 'vue-property-decorator'
import locale from '../store/modules/locale'

@Component
export default class LanguageSelector extends Vue {
  @Prop({ type: Boolean, default: false, required: false })
  readonly hideLabel: boolean

  locale = locale
}
