
import { InfoModalDetail } from '@/models/InfoModalDetail'
import { TranslateResult } from 'vue-i18n';
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class InfoModalTextStandard extends Vue {
  @Prop({ type: Object, required: true }) readonly modalDetail: InfoModalDetail

  get modalText(): { subLabel: string; body: TranslateResult }[] {
    return this.modalDetail?.modalText || []
  }
}
