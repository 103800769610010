
import { Vue, Component, Prop } from 'vue-property-decorator'
import { addressPretty } from '@/utils/string'

@Component({})
export default class TripInfoItineraryAddress extends Vue {
  @Prop({ type: String, default: null }) readonly title!: string
  @Prop({ type: String, default: null }) readonly street!: string
  @Prop({ type: String, default: null }) readonly city!: string
  @Prop({ type: String, default: null }) readonly state!: string

  get addressPretty(): string {
    return addressPretty(this.street, this.city, this.state)
  }
}
