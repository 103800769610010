
import { Vue, Component, Prop, Inject, Watch } from 'vue-property-decorator'
import { roundedCurrencyFilter } from '@/utils/string'
import SoldOutDetail from '@/components/SoldOutDetail.vue'
import deepClone from '@/utils/deepClone'
import fallBackImage from '../assets/images/placeholder_bus.jpg'
import { QuoteDetailV2Bid, VehiclePhoto } from '@/models/dto'
import { hostBaseUrl } from '../utils/env'
import { PartnerTypeId, SplitFeatureFlag } from '../utils/enum'

import BidCardCertifiedPartnerBadge from '@/components/BidCardCertifiedPartnerBadge.vue'
import BidCardAmenities from '@/components/BidCardAmenities.vue'
import BidCardPricing from '@/components/BidCardPricing.vue'
import BidCardSoldOut from '@/components/BidCardSoldOut.vue'
import CharterUPRating from '@/components/CharterUPRating.vue'

@Component({
  computed: {
    buttonText() {
      if(this.isSecondaryButtonStyle) {
        return this.$t('common.SELECT')
      }
      return this.$t('common.CONTINUE')
    },
    flipRatingAndAmenities(): boolean {
      return this.featured && this.isReseller && this.isBrandedQuoteFlowEnabled
    },
  },
  components: {
    SoldOutDetail,
    BidCardAmenities,
    BidCardCertifiedPartnerBadge,
    BidCardPricing,
    BidCardSoldOut,
    CharterUPRating
  },
})
export default class BidCard extends Vue {
  @Inject('isInCarousel') readonly isInCarousel: boolean

  @Prop({ type: String, default: null }) readonly id: string
  @Prop({ type: Object, default: () => {} }) readonly bid: QuoteDetailV2Bid
  @Prop({ type: Boolean }) readonly featured: boolean
  @Prop({ type: Object, default: null }) readonly goTo
  @Prop({ type: Boolean }) readonly carousel: boolean
  @Prop({ type: Boolean }) readonly isElite: boolean
  @Prop({ type: Boolean }) readonly showUsd: boolean
  @Prop({ type: Boolean }) readonly charterUpChoiceStyling: boolean
  @Prop({ type: Boolean }) readonly showAmenities: boolean

  isBrandedQuoteFlowEnabled = false

  fallBackImage = fallBackImage
  aspectRatio = '1.87'

  get stacked(): boolean {
    return !this.$vuetify.breakpoint.smAndUp || this.isInCarousel
  }

  get isValidBid(): boolean {
    return !this.isSoldOut && !this.isBronze
  }

  get clickable() {
    return this.goTo && !this.bid?.awaitngBid && this.isValidBid
  }

  get vehiclePhotos(): VehiclePhoto[] {
    return deepClone(this.bid?.vehiclePhotos).sort(
      (a, b) => a.vehicleTypeId - b.vehicleTypeId
    )
  }

  get companyPhotoPath(): string | any {
    const path = this.vehiclePhotos?.[0]?.imagePath
    if (path?.[0] !== '/') {
      return path
    }
    return `${hostBaseUrl()}${path}`
  }

  get companyPhotoHeight(): number {
    if (this.stacked) {
      return 155
    } else if (this.featured && this.isReseller || !this.isBrandedQuoteFlowEnabled) {
      return 173
    }
    return 120
  }

  get isBronze(): boolean {
    return this.bid?.partnerTypeId === PartnerTypeId.Bronze
  }

  get isSoldOut(): boolean {
    return !!this.bid?.soldOut
  }

  get priceCharCountMoreThan7(): boolean {
    let total = roundedCurrencyFilter(Math.ceil(this.bid.totalAmount))?.length
    if (this.showUsd) {
      total += 4
    }
    return total > 7
  }

  get borderClasses(): string {
    if (this.featured) {
      return 'border-primary outline-none'
    }
    if (this.charterUpChoiceStyling) {
      return 'border-primary border-3'
    }
    return 'border-1 border-border-gray'
  }

  get shouldDisplayCompanyLogo(): boolean {
    return this.featured && this.isReseller && !this.stacked && this.isBrandedQuoteFlowEnabled && this.companyLogo
  }

  get companyLogo(): string {
    const path = this.bid?.lightLogoUrl
    if (!path) {
      return ''
    }
    return `${hostBaseUrl()}${path}`
  }

  get isReseller(): boolean {
    return !!this.bid?.reseller
  }

  get isSecondaryButtonStyle(): boolean {
    return this.isBrandedQuoteFlowEnabled && (!this.isReseller || !this.featured)
  }

  get dottedBorderStyle(): string {
    if (this.stacked || !this.isBrandedQuoteFlowEnabled) {
      return 'none'
    } else if (this.featured) {
      return '3px dotted'
    }
    return '1px dotted'
  }

  async mounted() {
    this.isBrandedQuoteFlowEnabled = await this.$split.isFeatureEnabled(
      SplitFeatureFlag.BrandedQuoteFlow
    )
  }

  get tooltipText(): string {
    const dba = this.bid.companyDBAName
    return `${dba} is a nationwide network of bus companies that independently operate under the ${dba} brand.`
  }

  onClick(): void {
    if (!this.clickable) {
      return
    }
    this.trackViewBid()
    this.$router.push(this.goTo)
  }

  trackViewBid(): void {
    this.$ga4Event('view_bid', {
      companyId: this.bid?.companyId,
      isElite: this.isElite,
    })
  }
}
