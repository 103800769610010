import { Action, Mutation, Module, VuexModule } from 'vuex-class-modules'
import store from '@/store/index'
import { TrackingMapZoomOptions } from '@/models/Tracking'
import { TrackingReservation } from '../../models/dto/TrackingReservation'
import { TrackingWaypoint } from '../../models/TrackingWaypoint'
import { TrackingJourneyData } from '../../models/dto'
import { JourneyStopStatus } from '@/models/dto/OnTime'
import { TrackingStop } from '@/models/dto/TrackingStop'
import { formatDisplayTime } from '@/utils/string'
import { DateTime } from 'luxon'
import deepClone from '@/utils/deepClone'

const TRACKING_START_THRESHOLD = 60;

@Module({ generateMutationSetters: true })
class TrackingModule extends VuexModule {
  _zoomOptions: TrackingMapZoomOptions = {
    customPosition: false,
    customOption: false,
    fitEntireRoute: false,
    centerOnBus: false,
    fitNextStop: false,
    fitPreviousStop: false,
  }
  _statusText = ''
  _etaCountdownText = ''
  _isVehicleAtStop = false
  _singleVehicleEta = 0
  _reservation: TrackingReservation
  _waypoints: TrackingWaypoint[] = []
  _trackerList: TrackingJourneyData[] = []
  _activeStopStatuses: JourneyStopStatus[] = []
  _previousStop: TrackingStop = null
  _nextStop: TrackingStop = null
  _nextStopStatus: JourneyStopStatus = null

  /**
   * @returns The zoom options for the tracking map.
   */
  get zoomOptions(): TrackingMapZoomOptions {
    return this._zoomOptions
  }

  /**
   * @returns The current status text for the tracking map.
   */
  get statusText(): string {
    return this._statusText
  }

  /**
   * @returns The ETA to next stop in DateTime format.
   */
  get nextStopEtaDatetime(): DateTime {
    return DateTime.now().plus({
      minutes: this._singleVehicleEta,
    })
  }

  /**
   * @returns The ETA countdown (e.g., '2 min') text for the tracking map.
   */
  get etaCountdownText(): string {
    if (
      !this._singleVehicleEta ||
      this._singleVehicleEta < 1 ||
      this.isFinished
    ) {
      return ''
    }
    const minutes = this._singleVehicleEta
    if (Math.round(minutes) <= 60) {
      return `${Math.round(minutes)} min`
    }
    const hours = Math.floor(minutes / 60)
    return `${hours} hr ${Math.round(minutes % 60)} min`
  }

  /**
   * @returns The ETA timestamp in simplified, local format (e.g., '10:00 AM').
   */
  get etaTimestampText(): string {
    if (
      !this._singleVehicleEta ||
      this._singleVehicleEta < 1 ||
      this.isFinished
    ) {
      return ''
    }
    return formatDisplayTime(
      this.nextStopEtaDatetime.toString(),
      this._nextStop?.address?.timeZone
    )
  }

  /**
   * @returns true if the ETA is soon enough that the vehicle is
   * deemed arriving; false otherwise.
   */
  get isArrivingNowEta(): boolean {
    return !!this._singleVehicleEta && this._singleVehicleEta < 1
  }

  /**
   * Returns whether the vehicle is currently at a stop.
   * @returns true if the vehicle is at a stop, false otherwise.
   */
  get isVehicleAtStop(): boolean {
    return this._isVehicleAtStop
  }

  /**
   * Returns the estimated time of arrival (ETA) for the vehicle.
   * @returns The ETA for the vehicle, in minutes.
   */
  get singleVehicleEta(): number {
    return this._singleVehicleEta
  }

  /**
   * @returns The current reservation for live tracking
   */
  get reservation(): any {
    return this._reservation
  }

  /**
   * @returns the waypoints for the current reservation.
   */
  get waypoints(): TrackingWaypoint[] {
    return this._waypoints
  }

  /**
   * @returns the list of tracking data for the current reservation.
   */
  get trackerList(): TrackingJourneyData[] {
    return this._trackerList
  }

  /**
   * @returns the list of statuses for the currently shown journey.
   */
  get activeStopStatuses(): JourneyStopStatus[] {
    return this._activeStopStatuses
  }

  /**
   * @returns the next stop in the currently shown journey.
   */
  get nextStop(): TrackingStop {
    return this._nextStop
  }

  /**
   * @returns the previous stop in the currently shown journey.
   */
  get previousStop(): TrackingStop {
    return this._previousStop
  }

  /**
   * @returns the next stop status in the currently shown journey.
   */
  get nextStopStatus(): JourneyStopStatus {
    return this._nextStopStatus
  }

  /**
   * @returns true if all stops in the current journey are complete.
   */
  get areAllStopsComplete(): boolean {
    return (
      this.reservation?.stops.every((stop) => stop.isReached) ||
      (this.activeStopStatuses?.length > 0 &&
        this.activeStopStatuses?.every((status) => status.complete))
    )
  }

  /**
   * @returns true if reservation is finished or all stops are reached.
   */
  get isFinished(): boolean {
    return (
      this.reservation.reservationStatus === 'finished' ||
      this.areAllStopsComplete
    )
  }

  /**
   * @returns true if current vehicle is en route to the next stop.
   */
  get isEnRoute(): boolean {
    return !!this.nextStopStatus?.enRouteTimestamp
  }

  /**
   * @returns a list of stops sorted by increasing orderIndex.
   */
  get orderedStops(): TrackingStop[] {
    const stops = deepClone(this._reservation.stops)
    return stops.sort((a, b) => (a.orderIndex > b.orderIndex ? 1 : -1))
  }

  get pickupStop(): TrackingStop {
    if (!this.orderedStops) {
      return null
    }
    return this.orderedStops[0]
  }

  /**
   * @returns true if the tracking map is disabled, i.e., if < 1 hour
   * before the trip's scheduled start time.
   */
  get disableTracking(): boolean {
    if (!this._reservation) {
      return true
    }
    const startDate = DateTime.fromISO(this._reservation.startDate)
    return (
      startDate.diff(DateTime.now(), ['minutes']).minutes >
      TRACKING_START_THRESHOLD
    )
  }

  /**
   * @returns true if the trip is estimated to have tracking.
   */
  get willHaveLiveTracking(): boolean {
    if (!this._reservation) {
      return false
    }
    return this._reservation.willHaveLiveTracking
  }

  /**
   * Sets the status text for the tracking map.
   * @param statusText - the new status text for the tracking map.
   */
  @Action
  setStatusText(statusText: string): void {
    this._statusText = statusText
  }

  /**
   * Updates the zoom options for the tracking map.
   * @param zoomOptions - the new zoom options for the tracking map.
   */
  @Action
  updateZoomOptions(zoomOptions: TrackingMapZoomOptions): void {
    this._zoomOptions = zoomOptions
  }

  /**
   * Sets the custom position flag for the zoom options.
   * @param customPosition - the new value for the custom position flag.
   */
  @Action
  setCustomZoomPosition(customPosition: boolean) {
    this._zoomOptions.customPosition = customPosition
  }

  /**
   * Sets the custom option flag for the zoom options.
   * @param customOption - the new value for the custom option flag.
   */
  @Action
  setCustomZoomOption(customOption: boolean): void {
    this._zoomOptions.customOption = customOption
  }

  /**
   * Sets the zoom options to fit the entire route on the tracking map.
   * @param checkForCustomSettings - Whether to check for custom settings before updating the zoom options, defaults to false.
   * If true and custom settings are enabled, the zoom options will not be updated.
   */
  @Action
  setZoomToFullRoute(checkForCustomSettings = false): void {
    if (checkForCustomSettings && this._zoomOptions.customOption) {
      return
    }
    this._zoomOptions = {
      customPosition: this._zoomOptions.customPosition,
      customOption: this._zoomOptions.customOption,
      fitEntireRoute: true,
      centerOnBus: false,
      fitNextStop: false,
      fitPreviousStop: false,
    }
  }

  /**
   * Sets the zoom options to center on the vehicle on the tracking map.
   * @param checkForCustomSettings - whether to check for custom settings before updating
   * the zoom options, defaults to false. If true and custom settings are enabled, the
   * zoom options will not be updated.
   */
  @Action
  setZoomToVehicle(checkForCustomSettings = false): void {
    if (checkForCustomSettings && this._zoomOptions.customOption) {
      return
    }
    this._zoomOptions = {
      customPosition: this._zoomOptions.customPosition,
      customOption: this._zoomOptions.customOption,
      fitEntireRoute: false,
      centerOnBus: true,
      fitNextStop: false,
      fitPreviousStop: false,
    }
  }

  /**
   * Sets the zoom options to center on the vehicle and the previous stop on the tracking map.
   * @param checkForCustomSettings - whether to check for custom settings before updating the
   * zoom options, defaults to false. If true and custom settings are enabled, the zoom options
   * will not be updated.
   */
  @Action
  setZoomToVehicleAndPreviousStop(checkForCustomSettings = false): void {
    if (checkForCustomSettings && this._zoomOptions.customOption) {
      return
    }
    this._zoomOptions = {
      customPosition: this._zoomOptions.customPosition,
      customOption: this._zoomOptions.customOption,
      fitEntireRoute: false,
      centerOnBus: false,
      fitNextStop: false,
      fitPreviousStop: true,
    }
  }

  /**
   * Sets the zoom options to center on the vehicle and the previous and next stops on the tracking map.
   * @param checkForCustomSettings - whether to check for custom settings before updating the zoom options,
   * defaults to false. If true and custom settings are enabled, the zoom options will not be updated.
   */
  @Action
  setZoomToVehicleAndPreviousStopAndNextStop(
    checkForCustomSettings = false
  ): void {
    if (checkForCustomSettings && this._zoomOptions.customOption) {
      return
    }
    this._zoomOptions = {
      customPosition: this._zoomOptions.customPosition,
      customOption: this._zoomOptions.customOption,
      fitEntireRoute: false,
      centerOnBus: false,
      fitNextStop: true,
      fitPreviousStop: true,
    }
  }

  /**
   * Sets the zoom options to center on the vehicle and the next stop on the tracking map.
   * @param checkForCustomSettings - whether to check for custom settings before updating the zoom options, defaults to false.
   * If true and custom settings are enabled, the zoom options will not be updated.
   */
  @Action
  setZoomToVehicleAndNextStop(checkForCustomSettings = false): void {
    if (checkForCustomSettings && this._zoomOptions.customOption) {
      return
    }
    this._zoomOptions = {
      customPosition: this._zoomOptions.customPosition,
      customOption: this._zoomOptions.customOption,
      fitEntireRoute: false,
      centerOnBus: false,
      fitNextStop: true,
      fitPreviousStop: false,
    }
  }

  /**
   * Sets whether the vehicle is currently at a stop.
   * @param isVehicleAtStop - true if the vehicle is at a stop, false otherwise.
   */
  @Action
  setIsVehicleAtStop(isVehicleAtStop: boolean): void {
    this._isVehicleAtStop = isVehicleAtStop
  }

  /**
   * Sets the estimated time of arrival (ETA) for the vehicle.
   * @param singleVehicleEta - the new ETA for the vehicle, in minutes.
   */
  @Action
  setSingleVehicleEta(singleVehicleEta: number): void {
    this._singleVehicleEta = singleVehicleEta
  }

  /**
   * Sets the reservation for the tracking state.
   * @param reservation - the new reservation for the tracking state.
   */
  @Action
  setReservation(reservation: any) {
    this._reservation = reservation
  }

  /**
   *
   * Sets the waypoints for the tracking state for the current reservation.
   *
   */
  @Action
  calculateWaypointsForCurrentReservation() {
    const reservation = this._reservation
    const waypointMap: Record<string, TrackingWaypoint> = {}
    for (const stop of reservation.stops) {
      const key = `${stop.address.lat}-${stop.address.lng}`
      if (!waypointMap[key]) {
        waypointMap[key] = {
          info: stop.address,
          stops: [],
        }
      }
      waypointMap[key].stops.push(stop)
    }
    const waypoints: TrackingWaypoint[] = Object.values(waypointMap)
    this._waypoints = waypoints
  }

  @Action
  setTrackerList(trackerList: TrackingJourneyData[]) {
    this._trackerList = trackerList
  }

  @Mutation
  setActiveStopStatuses(stopStatuses: JourneyStopStatus[]): void {
    this._activeStopStatuses = stopStatuses
    const nextStopStatus = stopStatuses?.find((status) => !status.complete)
    if (!stopStatuses || !nextStopStatus) {
      this._previousStop = null
      this._nextStop = null
      this._nextStopStatus = null
      return
    }
    const stops = this._reservation?.stops
    const nextStopIndex = stops.findIndex(
      (stop) => stop.stopId === nextStopStatus.stopId
    )
    this._previousStop = nextStopIndex > 0 ? stops[nextStopIndex - 1] : null
    this._nextStop = stops[nextStopIndex]
    this._nextStopStatus = nextStopStatus
  }

  isPickup(orderIndex: number): boolean {
    if (!this.orderedStops) {
      return false
    }
    return this.orderedStops[0].orderIndex === orderIndex
  }

  isComplete(stop: TrackingStop): boolean {
    const stopStatus = this._activeStopStatuses?.find(
      (status) => status.stopId === stop?.stopId
    )
    if (!stopStatus) {
      return false
    }
    return stopStatus?.complete || !!stopStatus?.skippedTimestamp
  }

  isNext(stop: TrackingStop): boolean {
    return stop?.stopId === this._nextStop?.stopId
  }
}

export default new TrackingModule({ store, name: 'tracking' })
