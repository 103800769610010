
import SharedContact from '@/models/dto/SharedContact'
import SharedTripsModal from '@/components/SharedTripsModal.vue'
import { Vue, Prop, Component } from 'vue-property-decorator'
import { EventBus } from '@/utils/eventBus'

@Component({
  components: {
    SharedTripsModal,
  },
})
export default class SharedContactsSharedTrips extends Vue {
  @Prop({ required: true }) readonly row: SharedContact

  isSharedTripsModalOpen: boolean = false

  get totalSharedTrips(): number {
    return this.row?.quoteIds?.length + this.row?.reservationIds?.length || 0
  }

  mounted(): void {
    EventBus.$on('update-shared-contacts', () => {
      this.isSharedTripsModalOpen = false
    })
  }

  selectUserTrips(): void {
    if (this.totalSharedTrips > 0) {
      this.isSharedTripsModalOpen = true
    }
  }
}
