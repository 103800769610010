
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class BusSlider extends Vue {
  @Prop({ type: Number, required: true }) readonly min: number
  @Prop({ type: Number, required: true }) readonly max: number
  @Prop({ type: Number, default: 1 }) readonly step: number
  @Prop({ type: Number, default: 5 }) readonly value: number

  busDirection = 1
  busAnimationLength = 1
  debounce = null
  delay = null
  dragTarget = null
  maxXPosition = 0

  get stepListCount(): number[] {
    const values = []
    for (let i = this.min; i <= this.max; i += this.step) {
      values.push(i)
    }
    return values
  }

  mounted(): void {
    this.setInitialValue()
    setTimeout(() => {
      this.moveBusToValue(this.value)
    }, 1)
    document.getElementById('bus__slider__image').ondragstart = () => {
      return false
    }
    this.addDragHandler()
  }

  setInitialValue(): void {
    if (this.value !== null) {
      let value = this.value
      if (typeof value === 'string') {
        value = Number(value)
      }
      if (typeof value === 'number' && (value < this.min || value > this.max)) {
        this.$emit('input', this.max)
      }
    }
  }

  setBusPosition(moveTo: any): void {
    const bus = this.$refs['slider-bus'] as any
    const slider = this.$refs['slider'] as any
    const busOffset = bus.offsetWidth / 2
    let positionPixels
    let middlePoint

    const moveToType = typeof moveTo
    if (moveToType === 'number') {
      positionPixels = moveTo
      middlePoint = ((positionPixels + busOffset) / slider.offsetWidth) * 100
    } else if (moveToType === 'object') {
      const valueOffset = moveTo.offsetWidth / 2
      positionPixels = `${moveTo.offsetLeft - busOffset + valueOffset}`
      middlePoint =
        ((moveTo.offsetLeft + valueOffset) / slider.offsetWidth) * 100
    }
    const positionPercent = (positionPixels / slider.offsetWidth) * 100
    bus.style.left = `${positionPercent}%`
    this.setBarPositions(middlePoint)
  }

  setBarPositions(middlePoint: number): void {
    const left = this.$refs['left-bar'] as any
    const right = this.$refs['right-bar'] as any
    left.style.width = `${middlePoint}%`
    right.style.width = `${100 - middlePoint}%`
  }

  moveBusToValue(value: number): void {
    this.setBusAnimationLength(value)
    const element = this.$refs[`value-${value}`][0]
    this.setBusPosition(element)
  }

  moveBusToHoverValue(value: number): void {
    if (this.dragTarget) {
      return
    }
    this.delay = setTimeout(() => {
      clearTimeout(this.debounce)
      this.moveBusToValue(value)
    }, 100)
  }

  returnBusToActiveValue(): void {
    if (this.dragTarget) {
      return
    }
    this.debounce = setTimeout(() => {
      this.moveBusToValue(this.value)
    }, 250)
  }

  handleValueClick(value: number): void {
    this.moveBusToValue(value)
    this.$emit('input', value)
    this.$emit('submit', this.busAnimationLength)
  }

  handleMouseLeave(): void {
    clearTimeout(this.delay)
    this.returnBusToActiveValue()
  }

  setBusAnimationLength(destinationValue: number): void {
    const destinationElement = this.$refs[`value-${destinationValue}`][0] as any
    const busElement = this.$refs['slider-bus'] as any
    const slider = this.$refs['slider'] as any
    const minumumDuration = 0.25
    const maximumDuration = 2

    const busPosition = busElement?.offsetLeft
    const destinationPosition =
      destinationElement?.offsetLeft + destinationElement.offsetWidth / 2

    const positionDifference = destinationPosition - busPosition
    if (positionDifference < 0) {
      this.busDirection = -1
    } else if (positionDifference > 0) {
      this.busDirection = 1
    }

    const percentToMove = Math.abs(positionDifference) / slider.offsetWidth
    this.busAnimationLength = Math.max(
      percentToMove * maximumDuration,
      minumumDuration
    )
  }
  startDrag(): void {
    const rightEdgeOfBar =
      (this.$refs['right-bar'] as any).offsetLeft +
      (this.$refs['right-bar'] as any).offsetWidth
    const busOffset = (this.$refs['slider-bus'] as any).offsetWidth / 2
    this.maxXPosition = rightEdgeOfBar - busOffset
    this.dragTarget = this.$refs['slider-bus']
    this.busAnimationLength = 0
  }

  mouseMoveHandler(event: any): void {
    if (this.dragTarget === null) {
      return
    }

    let xDisplacement = event.movementX
    if (xDisplacement === xDisplacement && xDisplacement !== 0) {
      if (this.dragTarget.offsetLeft + xDisplacement < 0) {
        xDisplacement = -1 * this.dragTarget.offsetLeft
      } else if (
        this.dragTarget.offsetLeft + xDisplacement >
        this.maxXPosition
      ) {
        xDisplacement = this.maxXPosition - this.dragTarget.offsetLeft
      }
      const busDirection = xDisplacement / Math.abs(xDisplacement)
      this.busDirection = busDirection !== 0 ? busDirection : this.busDirection
      this.setBusPosition(this.dragTarget.offsetLeft + xDisplacement)
      const closestValue = this.findClosestValue().value
      this.$emit('input', closestValue)
    }
  }
  addDragHandler(): void {
    document.addEventListener('mousemove', this.mouseMoveHandler)
  }
  mouseUpHandler(): void {
    if (this.dragTarget) {
      this.$emit('show-submit', true)
    }
    this.moveBusToValue(this.findClosestValue().value)
    this.dragTarget = null
  }
  findClosestValue(): any {
    const values = []
    const busOffsetLeft = (this.$refs['slider-bus'] as any).offsetLeft
    for (const value of this.stepListCount) {
      const offsetLeft = this.$refs[`value-${value}`][0].offsetLeft
      const distanceToBus = Math.abs(offsetLeft - busOffsetLeft)
      values.push({ value, distanceToBus })
    }
    values.sort((a, b) => {
      return a.distanceToBus - b.distanceToBus
    })
    return values[0]
  }
}
