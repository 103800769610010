import { Action, Module, VuexModule } from 'vuex-class-modules'
import store from '@/store/index'
import { ViewQuoteSource } from '@/utils/enum'

// Store for Google Analytics (GA4)
@Module({ generateMutationSetters: true })
class GA4Module extends VuexModule {
  _viewQuoteSource: ViewQuoteSource = null

  /**
   * Returns the source from which the quote was viewed (email or quote table).
   * Used for the 'view_quote' event trigger
   *
   * @returns The source.
   */
  get viewQuoteSource(): ViewQuoteSource {
    return this._viewQuoteSource
  }

  /**
   * Sets the source from which the quote was viewed (email or quote table).
   *
   * @param source - The source.
   */
  @Action
  setViewQuoteSource(source: ViewQuoteSource): void {
    this._viewQuoteSource = source
  }
}

export default new GA4Module({ store, name: 'ga4' })
