var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('CUModal',{attrs:{"width":"500px"},on:{"input":function($event){return _vm.$emit('input', $event)}},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-list-item',_vm._g({attrs:{"id":`add-team`},on:{"click":() => {}}},on),[_c('v-list-item-title',[_vm._v(" Add Team ")])],1)]}},{key:"title",fn:function(){return [_vm._v("Create New Team")]},proxy:true},{key:"text",fn:function(){return [_c('v-form',{ref:"form",model:{value:(_vm.formIsValid),callback:function ($$v) {_vm.formIsValid=$$v},expression:"formIsValid"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('label',{attrs:{"for":"team"}},[_vm._v("Team Name")]),_c('v-text-field',_vm._b({attrs:{"id":"new-team-dialog-text-team-name","required":"","validate-on-blur":"","outlined":""},model:{value:(_vm.formData.teamName.value),callback:function ($$v) {_vm.$set(_vm.formData.teamName, "value", $$v)},expression:"formData.teamName.value"}},'v-text-field',_vm.formData.teamName,false))],1),_c('v-col',{attrs:{"cols":"6"}},[_c('label',{attrs:{"for":"firstName"}},[_vm._v("First name")]),_c('v-text-field',_vm._b({ref:"firstName",class:{
              'padding-r-3': _vm.$vuetify.breakpoint.xs,
              'padding-r-4': _vm.$vuetify.breakpoint.smAndUp,
            },attrs:{"id":"new-team-dialog-text-first-name","required":"","validate-on-blur":"","outlined":""},model:{value:(_vm.formData.firstName.value),callback:function ($$v) {_vm.$set(_vm.formData.firstName, "value", $$v)},expression:"formData.firstName.value"}},'v-text-field',_vm.formData.firstName,false))],1),_c('v-col',{attrs:{"cols":"6"}},[_c('div',{class:{
              'padding-l-3': _vm.$vuetify.breakpoint.xs,
              'padding-l-4': _vm.$vuetify.breakpoint.smAndUp,
            }},[_c('label',{attrs:{"for":"lastName"}},[_vm._v("Last name")]),_c('v-text-field',_vm._b({ref:"lastName",attrs:{"id":"new-team-dialog-text-last-name","required":"","validate-on-blur":"","outlined":""},model:{value:(_vm.formData.lastName.value),callback:function ($$v) {_vm.$set(_vm.formData.lastName, "value", $$v)},expression:"formData.lastName.value"}},'v-text-field',_vm.formData.lastName,false))],1)]),_c('v-col',{attrs:{"cols":"12"}},[_c('label',{attrs:{"for":"email"}},[_vm._v("Email")]),_c('v-text-field',_vm._b({ref:"email",attrs:{"id":"new-team-dialog-text-email","required":"","validate-on-blur":"","outlined":""},model:{value:(_vm.formData.email.value),callback:function ($$v) {_vm.$set(_vm.formData.email, "value", $$v)},expression:"formData.email.value"}},'v-text-field',_vm.formData.email,false))],1),_c('v-col',{attrs:{"cols":"12"}},[_c('label',{attrs:{"for":"title"}},[_vm._v("Title")]),_c('v-text-field',_vm._b({attrs:{"id":"new-team-dialog-text-title","required":"","validate-on-blur":"","outlined":""},model:{value:(_vm.formData.title.value),callback:function ($$v) {_vm.$set(_vm.formData.title, "value", $$v)},expression:"formData.title.value"}},'v-text-field',_vm.formData.title,false))],1)],1),_c('AlertBox',{staticClass:"margin-b-3",attrs:{"active":!!_vm.errorMessage,"type":"error"}},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")])],1)]},proxy:true},{key:"actions",fn:function(){return [_c('div',{staticClass:"d-flex grow"}),_c('v-btn',{staticClass:"margin-r-2",attrs:{"id":"new-team-dialog-btn-cancel","small":"","text":"","color":"error"},on:{"click":() => {
          _vm.dialog = false
          _vm.$emit('close')
        }}},[_vm._v(" Cancel ")]),_c('v-btn',{staticClass:"primary",attrs:{"id":"new-team-dialog-btn-create","small":"","tabindex":"7"},on:{"click":_vm.send}},[_vm._v(" Create Team ")])]},proxy:true}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}})
}
var staticRenderFns = []

export { render, staticRenderFns }