export const detectCardType = (cardNumber: string): string => {
  const cardPrefixes = {
    '4': 'visa',
    '5': 'mastercard',
    '6': 'discover',
    '34': 'amex',
    '37': 'amex',
    '30': 'diners',
    '36': 'diners',
    '38': 'diners',
  }

  if (isJCB(cardNumber)) {
    return 'jcb'
  }

  const prefix =
    cardNumber?.[0] === '3' ? cardNumber?.slice(0, 2) : cardNumber?.slice(0, 1)

  return cardPrefixes[prefix] || 'default'
}

export const isJCB = (cardNumber: string): boolean => {
  return /^35[2-8][0-9]{13}$/.test(cardNumber)
}

export const cardMaskByType = (type: string): string => {
  const masks = {
    amex: '#### ###### #####',
    diners: '#### ###### ####',
    default: '#### #### #### ####',
  }
  return masks[type] || masks.default
}

export const buildMaskedCardNumber = (lastFour: string, type: string): string => {
  if (!lastFour) {
    return ''
  }
  const mask = cardMaskByType(type).replace(/#/g, '*')
  return mask.slice(0, -4) + lastFour
}
