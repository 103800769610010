
import { Vue, Component } from 'vue-property-decorator'
import { currencyFilter } from '@/utils/string'

@Component({})
export default class NoReservations extends Vue {
  currencyFilter = currencyFilter

  reservation = {
    company: {
      companyDBAName: 'Bus Company',
      rating: 4.8,
      ratingRounded: 5,
      reviewCount: 177,
      amenities: [
        {
          icon: 'wifi',
          color: 'primary',
        },
        {
          icon: 'air',
          color: 'primary',
        },
        {
          icon: 'volume_up',
          color: 'primary',
        },
        {
          icon: 'power',
          color: 'primary',
        },
        {
          icon: 'airline_seat_recline_normal',
          color: 'primary',
        },
        {
          icon: 'wc',
          color: 'primary',
        },
        {
          icon: 'accessible',
          color: 'primary',
        },
        {
          icon: 'tv',
          color: 'primary',
        },
        {
          icon: 'card_travel',
          color: 'lightGray',
        },
        {
          icon: 'mic',
          color: 'lightGray',
        },
        {
          icon: 'event_seat',
          color: 'lightGray',
        },
      ],
      busPhotoSource: '@/assets/images/stock_bus.png',
    },
    transactions: [
      { notes: 'Grand Total', amount: 799.0 },
      { notes: 'Remaining Balance ', amount: 0 },
    ],
  }
  payment = {
    headers: [
      { text: 'Date', value: 'date', align: 'center' },
      { text: 'Description', value: 'paymentType.label', align: 'left' },
      { text: 'Amount', value: 'amount', align: 'right' },
    ],
  }
  driver = {
    rating: 5,
    tripCount: 458,
  }
}
