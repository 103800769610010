
import { EditCustomerPayload, TableViewCustomer } from '@/models/dto'
import deepClone from '@/utils/deepClone'
import { validateEmailAddress } from '@/utils/string'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import Customer from '@/services/customer'
import alert from '@/store/modules/alert'

@Component({})
export default class OrganizationTeamEditUser extends Vue {
  @Prop({ type: Object, required: true })
  readonly existingCustomer: TableViewCustomer

  submitting = false

  @Watch('existingCustomer', { immediate: true })
  existingCustomerChanged(value: TableViewCustomer): void {
    this.customer = {
      firstName: value.firstName,
      lastName: value.lastName,
      title: value.title,
    }
  }

  customer: EditCustomerPayload = {
    firstName: '',
    lastName: '',
    title: '',
  }

  cancel(): void {
    this.$emit('close')
  }

  async submit(): Promise<void> {
    const form = this.$refs['edit-customer-form'] as any
    if (!form.validate()) {
      this.$emit('invalid')
      return
    }
    const payload = deepClone(this.customer)
    this.submitting = true

    try {
      await Customer.editCustomer(this.existingCustomer.customerId, payload)
      this.$emit('close')
    } catch (e) {
      alert.add({
        text:
          'Error editing user. Please try again later. If the problem persists, contact support.',
        color: 'error',
        title: 'Error',
        dismissible: true,
      })

      this.$emit('close')
    }

    this.submitting = false
  }
}
