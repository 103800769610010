
import { Vue, Component, Prop } from 'vue-property-decorator'
import Main from '@/layouts/Main.vue'
import TripModificationFooter from '@/components/TripModificationFooter.vue'
import WizardProgressTracker from '@/components/WizardProgressTracker.vue'
import TripModificationCloseDialog from '@/components/TripModificationCloseDialog.vue'
import tripModification from '@/store/modules/tripModification'
import { TripModificationStep } from '@/models/TripModification'
import { ReservationDetail } from '@/models/dto'
import { TripModificationStepKey } from '@/utils/enum'

@Component({
  components: {
    Main,
    TripModificationFooter,
    WizardProgressTracker,
    TripModificationCloseDialog,
  },
})
export default class TripModificationWizard extends Vue {
  @Prop({ type: Object, required: true })
  readonly reservation: ReservationDetail
  @Prop({ type: Object }) readonly trip: any

  value = false
  tripModification = tripModification

  get subHeader(): string {
    const { managedId, routeName } = this.reservation
    const formattedId = `ID ${managedId}`
    return routeName ? `${routeName} – ${formattedId}` : formattedId
  }

  get currentStep(): TripModificationStep {
    return tripModification.step
  }

  get component(): any {
    return this.currentStep?.component
  }

  get showHeader(): boolean {
    return ![TripModificationStepKey.Confirmation, TripModificationStepKey.Application].includes(this.currentStep.key)
  }

  created() {
    tripModification.setManagedId(this.reservation.managedId)
    tripModification.setRouteName(this.reservation.routeName)
    tripModification.start()
  }

  beforeDestroy(): void {
    this.close()
  }

  close(): void {
    tripModification.setIsOpen(false)
  }
}
