
import { Vue, Component, Prop, InjectReactive } from 'vue-property-decorator'
import InfoModal from '@/components/InfoModal.vue'
import { tripModificationStartModalDetail, tripModificationWithin72HoursModalDetail } from '@/utils/infoModals'
import tripModification from '@/store/modules/tripModification'
import { InfoModalDetail } from '@/models/InfoModalDetail'
import { getSupportNumber, phoneFormatFilter } from '@/utils/phone'
import { EventBus } from '@/utils/eventBus'
import auth from '@/store/modules/auth'

@Component({
  components: {
    InfoModal
  },
})
export default class TripModificationStartDialog extends Vue {
  @Prop({ type: Boolean, required: true }) readonly value!: boolean

  @InjectReactive('isWithin72HoursOfPickup') isWithin72HoursOfPickup

  mounted(): void {
    EventBus.$on('open-trip-modification-dialog', () => this.$emit('input', true))
    EventBus.$on('close-trip-modification-dialog', () => this.$emit('input', false))
  }

  async handleClickContinue(): Promise<void> {
    tripModification.setIsOpen(true)
    tripModification.setForwardButtonDisabled(false)
    this.$emit('input', false)
  }

  get tripModificationModalDetail(): InfoModalDetail {
    return this.isWithin72HoursOfPickup ?
      tripModificationWithin72HoursModalDetail :
      tripModificationStartModalDetail
  }

  get supportNumber(): string {
    return getSupportNumber(auth.customer.isElite)
  }

  get formattedSupportNumber(): string {
    return phoneFormatFilter(this.supportNumber)
  }

  beforeDestroy(): void {
    EventBus.$off('open-trip-modification-dialog')
    EventBus.$off('close-trip-modification-dialog')
  }
}
