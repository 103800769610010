
import { SelectColumn } from '@/models/SelectColumn'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { toKebab } from '@/utils/string'

@Component({})
export default class CUDataTableMobilePredefinedSelects extends Vue {
  @Prop({ type: Object, required: true }) readonly selectColumn: SelectColumn
  toKebab = toKebab

  isExpanded = false
}
