/* eslint-disable no-redeclare */
import Vue from 'vue'
import Router, {
  isNavigationFailure,
  NavigationFailureType,
  RawLocation,
  Route,
} from 'vue-router'
import VueMeta from 'vue-meta'

import routes from './routes'
import modules from '@/store/modules'
import systemParameter from '@/store/modules/systemParameter'

import { handleJWTLogin, redirectToQuoteTableIfNeeded, handleToEntityDetailByHash, routeRequiresAuth } from '@/utils/router'

Vue.use(Router)
Vue.use(VueMeta)

const originalPush = Router.prototype.push
Router.prototype.push = function push(location: RawLocation): Promise<Route> {
  return new Promise((resolve, reject) => {
    const onComplete = () => resolve(this.currentRoute)
    const onAbort = (error) => {
      const isDuplicate = isNavigationFailure(
        error,
        NavigationFailureType.duplicated
      )
      const isRedirected = isNavigationFailure(
        error,
        NavigationFailureType.redirected
      )
      const isCancelled = isNavigationFailure(
        error,
        NavigationFailureType.cancelled
      )
      if (isDuplicate || isRedirected || isCancelled) {
        console.warn(error)
        resolve(this.currentRoute)
      } else {
        reject(error)
      }
    }
    originalPush.call(this, location, onComplete, onAbort)
  })
}

const router = new Router({
  routes,
  mode: 'history',
  base: process.env.BASE_URL,
  // Simulate native-like scroll behavior when navigating to a new
  // route and using back/forward buttons.
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    return { x: 0, y: 0 }
  },
})

router.beforeEach(async (to, from, next) => {
  const requiresAuth = routeRequiresAuth(to)

  if (!requiresAuth) {
    return next()
  }

  // Redirect to quote table view with modal if specific query params are present
  if (redirectToQuoteTableIfNeeded(to, next)) {
    return
  }

  await handleJWTLogin(to)

  const isTokenSet = modules.auth.isTokenSet
  if (isTokenSet) {
    const isUserIdMissing = !modules.auth?.userId
    if (isUserIdMissing && modules.auth?.user) {
      modules.auth.setUserIdFromUser()
    }

    await Promise.all([
      systemParameter.fetchSystemParameters(),
      modules.auth.fetchUserDetails(),
      modules.auth.refreshCustomer(),
      modules.auth.refreshRolesAndPermissions(),
    ])

    await modules.quotes.fetchUnreadQuoteCount()
  }

  const isRedirectedToEntityDetailByHash = await handleToEntityDetailByHash(isTokenSet, to, next)
  if (isRedirectedToEntityDetailByHash) {
    return
  }

  if (requiresAuth && !isTokenSet) {
    return next({
      name: 'login',
      query: {
        redirectFrom: to.fullPath,
      },
    })
  }

  return next()
})

export default router
