import { Action, Module, Mutation, VuexModule } from 'vuex-class-modules'
import { DateTime } from 'luxon'
import onTimeStatus from '@/services/onTimeStatus'
import store from '@/store/index'
import tracking from '@/store/modules/tracking'
import {
  TrackingVehicle,
  Location,
  MotionPath,
} from '@/models/dto/TrackingVehicle'
import { GpsData } from '@/models/dto/Tracking'
import { TrackingJourney } from '@/models/dto/TrackingJourney'
import { JourneyEta } from '@/models/dto'
import { VehicleTypeLabel } from '@/utils/enum'
import colors from '@/scss/_colors-export.scss'

const ETA_CACHE_TTL = 5
const VEHICLE_COLORS = [
  'live-tracking-map-black',
  'live-tracking-map-dark-blue',
  'live-tracking-map-turquoise',
  'live-tracking-map-orange',
  'live-tracking-map-green',
]

@Module({ generateMutationSetters: true })
class TrackingVehiclesModule extends VuexModule {
  _trackingVehicles: TrackingVehicle[] = []
  _refreshInterval: number = 60 // LONG DEFAULT INTERVAL, 10 FOR LIVE
  _activeTrackingVehicle: TrackingVehicle | null = null
  _journeyEtaMap: Map<number, JourneyEta> = new Map()

  get trackingVehicles(): TrackingVehicle[] {
    return this._trackingVehicles.filter((vehicle) => vehicle.isTracking)
  }

  get trackingAndNotTrackingVehicles(): TrackingVehicle[] {
    return this._trackingVehicles
  }

  get refreshInterval(): number {
    return this._refreshInterval
  }

  get activeTrackingVehicle(): TrackingVehicle {
    return this._activeTrackingVehicle
  }

  @Mutation
  async setActiveTrackingVehicle(vehicle: TrackingVehicle): Promise<void> {
    this._activeTrackingVehicle = vehicle
    if (!vehicle || !vehicle?.journeyId) {
      return
    }
    if (!this._journeyEtaMap.has(vehicle.journeyId)) {
      const journeyEtaMap = await this.getJourneyEtaMap(this._trackingVehicles)
      this.updateJourneyEtaMap(journeyEtaMap)
    }
    const eta = this._journeyEtaMap.get(vehicle.journeyId)?.eta
    if (!eta) {
      tracking.setSingleVehicleEta(null)
      return
    }
    const minuteEta = DateTime.fromISO(eta).diffNow('minutes').minutes
    tracking.setSingleVehicleEta(minuteEta)
  }

  @Mutation
  setRefreshInterval(refreshInterval: number): void {
    this._refreshInterval = refreshInterval
  }

  @Action
  async processVehicles(): Promise<void> {
    if (tracking.isFinished) {
      this._trackingVehicles = []
      return
    }

    let trackingVehicles = []
    for (const [index, journey] of tracking.reservation.journeys.entries()) {
      if (!trackingVehicles.some((vehicle) => vehicle.vehicleId === journey?.vehicleId)) {
        trackingVehicles.push(this.journeyToVehicle(journey, index))
      }
    }

    const journeyEtaMap = await this.getJourneyEtaMap(trackingVehicles)
    this.updateJourneyEtaMap(journeyEtaMap)

    if (trackingVehicles.length > 1) {
      trackingVehicles = this.labelVehicles(trackingVehicles)
      this.sortVehicles(trackingVehicles)
    }
    this._trackingVehicles = trackingVehicles

    this.setActiveTrackingVehicle(this.getActiveTrackingVehicle())
  }

  updateJourneyEtaMap(updateMap: Map<number, JourneyEta>): void {
    const journeyIds = new Set([
      ...this._journeyEtaMap.keys(),
      ...updateMap.keys(),
    ])

    for (const journeyId of journeyIds) {
      if (updateMap.has(journeyId) && !!updateMap.get(journeyId)) {
        this._journeyEtaMap.set(journeyId, updateMap.get(journeyId))
        continue
      }
      const now = DateTime.local()
      const etaCacheTime = DateTime.fromISO(
        this._journeyEtaMap.get(journeyId)?.updatedAt
      )
      const isEtaStale = now.diff(etaCacheTime, 'minute').minutes > ETA_CACHE_TTL
      if (isEtaStale) {
        this._journeyEtaMap.set(journeyId, null)
      }
    }
  }

  /**
   * Converts a tracking journey to a tracking vehicle. If a vehicle is already
   * being tracked, it will be updated with the latest GPS data. If not, a new
   * vehicle will be created.
   * @param journey - the tracking journey.
   * @param index - the current journey index.
   * @returns a TrackingVehicle object.
   */
  journeyToVehicle(journey: TrackingJourney, index: number): TrackingVehicle {
    const reservation = tracking.reservation
    const { journeyId, vehicleId, vehicleTypeKey } = journey

    const vehicle = this._trackingVehicles.find((vehicle) => vehicle.journeyId === journeyId)
    const vehicleLabel = VehicleTypeLabel?.[vehicleTypeKey] || "Bus"
    const newVehicle = {
      busName: `${vehicleLabel}`,
      color: this.getVehicleColor(index),
      reservationId: reservation.reservationId,
      journeyId,
      vehicleId,
      vehicleType: vehicleTypeKey,
      heading: 0,
      lastTimestamp: undefined,
      location: undefined,
      previousLocation: undefined,
      motionPath: undefined,
      locationUpdateCount: 0,
      isTracking: false,
    }

    const hasTracker = tracking.trackerList.some((tracker) => tracker.vehicleId === vehicleId)
    if (hasTracker) {
      return this.getTrackingData(vehicle || newVehicle)
    }

    return vehicle || newVehicle
  }

  /**
   * Mutates vehicle location and motion path from tracking data if
   * available, not updated, or new position is detected.
   * @param vehicle - a TrackingVehicle object.
   * @returns a TrackingVehicle object.
   */
  getTrackingData(vehicle): TrackingVehicle {
    const tracker = tracking.trackerList.find(
      (tracker) => tracker.vehicleId === vehicle.vehicleId
    )

    const { gpsData } = tracker
    if (!gpsData?.length) {
      return vehicle
    }

    const previous =
      vehicle?.location ||
      this.spoofLastGpsPoint(gpsData, this._refreshInterval)
    const currentSpeed = gpsData[0].gpsSpeed
    const current = (currentSpeed === 0) ? previous : this.gpsPointByIndex(gpsData, 0)

    vehicle.isTracking = true
    vehicle.lastTimestamp = current.receivedDate

    const isFirstUpdate = vehicle.locationUpdateCount === 0
    const hasVehicleMoved =
      vehicle?.location?.lat !== current.lat ||
      vehicle?.location?.lng !== current.lng ||
      vehicle?.previousLocation?.lat !== previous.lat ||
      vehicle?.previousLocation?.lng !== previous.lng

    if (!hasVehicleMoved && !isFirstUpdate) {
      return vehicle
    }

    vehicle.motionPath = this.getVehiclePathTraveled(current, previous)
    vehicle.location = current
    vehicle.previousLocation = previous
    vehicle.locationUpdateCount += 1

    return vehicle
  }

  /**
   * Deserializes GPSData at a given index into a Location object.
   * @param gpsData - a list of GPSData objects.
   * @param index - a number.
   * @returns a Location object.
   */
  gpsPointByIndex(gpsData: GpsData[], index: number): Location {
    return {
      lat: parseFloat(gpsData[index].lat),
      lng: parseFloat(gpsData[index].lng),
      receivedDate: gpsData[index].receivedDate || gpsData[index].reportedOn,
    }
  }

  /**
   * Creates a Location object from the most recently received or
   * reported GPS data point that's within the given time frame.
   * If no such point is found, the most recent point is returned.
   * @param gpsData - a list of GPSData objects.
   * @param index - a number.
   * @returns a Location object.
   */
  spoofLastGpsPoint(gpsData: GpsData[], secondsAgo: number): Location {
    const mostRecent = gpsData[0]
    const mostRecentTime = DateTime.fromISO(
      mostRecent.receivedDate || mostRecent.reportedOn
    )
    let gpsPoint = null
    for (let i = 1; i < gpsData.length; i++) {
      const indexTime = DateTime.fromISO(
        gpsData[i].receivedDate || gpsData[i].reportedOn
      )
      const diff = mostRecentTime.diff(indexTime, ['seconds'])
      if (diff <= secondsAgo) {
        gpsPoint = this.gpsPointByIndex(gpsData, i)
      }
    }
    return gpsPoint || this.gpsPointByIndex(gpsData, 0)
  }

  /**
   * Returns a list representing a path traveled between two locations.
   * @param current - a Location object representing the current vehicle location.
   * @param previous - a Location object representing the previous vehicle location.
   * @returns a list of MotionPaths.
   */
  getVehiclePathTraveled(current: Location, previous: Location): MotionPath[] {
    if (!previous) {
      previous = current
    }
    return [
      {
        location: {
          lat: previous.lat,
          lng: previous.lng,
        },
      },
      {
        location: {
          lat: current.lat,
          lng: current.lng,
        },
      },
    ]
  }

  /**
   * Fetches on time status for given journey IDs, mapping them to returned ETA.
   * @param trackingVehicles - a list of tracking vehicles.
   * @returns a Map of journey IDs and string ISO timestamps representing ETA.
   */
  async getJourneyEtaMap(trackingVehicles: TrackingVehicle[]): Promise<Map<number, JourneyEta>> {
    const journeyEtaMap = new Map()
    const journeyIds = trackingVehicles.map((vehicle) => vehicle.journeyId)
    try {
      const res = await onTimeStatus.byJourneyIds(journeyIds)
      for (const journey of res.data.data) {
        if (journey.journeyId && journey.eta) {
          const journeyEta = {
            eta: journey.eta,
            updatedAt: new Date().toISOString(),
          }
          journeyEtaMap.set(journey.journeyId, journeyEta)
        }
      }
    } catch (err) {
      console.error('Failed to get ETA for journeys', err)
    }
    return journeyEtaMap
  }

  /**
   * Labels tracking vehicles based on vehicle ID ordering and typing.
   * @param trackingVehicles - a list of tracking vehicles.
   * @returns a list of labeled tracking vehicles ordered by type and vehicle ID.
   */
  labelVehicles(trackingVehicles: TrackingVehicle[]): TrackingVehicle[] {
    const vehicleTypeMap = new Map()
    for (const vehicle of trackingVehicles) {
      if (!vehicleTypeMap.has(vehicle.vehicleType)) {
        vehicleTypeMap.set(vehicle.vehicleType, [])
      }
      vehicleTypeMap.get(vehicle.vehicleType).push(vehicle)
    }
    for (const [type, vehicles] of vehicleTypeMap) {
      vehicleTypeMap.set(type, vehicles.sort((a, b) => a.vehicleId - b.vehicleId))
    }
    const labeledVehicles = []
    for (const vehicles of vehicleTypeMap.values()) {
      if (vehicles.length === 1) {
        labeledVehicles.push(...vehicles)
        continue
      }
      for (const [index, vehicle] of vehicles.entries()) {
        const vehicleLabel = VehicleTypeLabel?.[vehicle.vehicleType] || "Bus"
        vehicle.busName = `${vehicleLabel} ${index + 1}`
      }
      labeledVehicles.push(...vehicles)
    }
    return labeledVehicles
  }

  /**
   * Sorts tracking vehicles in descending order by
   * comparing tracking status, furthest stop reached,
   * and ETA DateTime. Mutates given array.
   * @param trackingVehicles - a list of tracking vehicles.
   */
  sortVehicles(trackingVehicles: TrackingVehicle[]) {
    if (!trackingVehicles.length) {
      return
    }

    const vehicleSortMap = this.getVehicleSortMap(trackingVehicles)

    const byFurthestEarliestTracked = (a: TrackingVehicle, b: TrackingVehicle) => {
      const vehicleA = vehicleSortMap.get(a.journeyId)
      const vehicleB = vehicleSortMap.get(b.journeyId)

      const isBTrackingAndANotTracking = !vehicleA.isTracking && vehicleB.isTracking
      const isBFurtherInJourneyThanA = vehicleA.furthestStopIndex < vehicleB.furthestStopIndex
      const doAAndBHaveEtas = !!vehicleA.eta && !!vehicleB.eta
      const areAAndBAtSamePointInJourney = vehicleA.furthestStopIndex === vehicleB.furthestStopIndex
      const isAFurtherFromStopThanB = vehicleA.eta > vehicleB.eta
      const isATrackingAndBNotTracking = vehicleA.isTracking && !vehicleB.isTracking
      const isAFurtherInJourneyThanB = vehicleA.furthestStopIndex > vehicleB.furthestStopIndex
      const isACloserToStopThanB = vehicleA.eta < vehicleB.eta

      const aLessThanB = isBTrackingAndANotTracking ||
        isBFurtherInJourneyThanA ||
        (doAAndBHaveEtas && areAAndBAtSamePointInJourney && isAFurtherFromStopThanB)
      const bLessThanA = isATrackingAndBNotTracking ||
        isAFurtherInJourneyThanB ||
        (doAAndBHaveEtas && areAAndBAtSamePointInJourney && isACloserToStopThanB)

      if (aLessThanB) {
        return 1;
      } else if (bLessThanA) {
        return -1;
      }
      return 0;
    }

    trackingVehicles.sort(byFurthestEarliestTracked)
  }

  /**
   * Creates a map to simplify vehicle sorting operation.
   * @param trackingVehicles - a list of tracking vehicles.
   * @returns journey IDs mapped to an object containing
   * the furthest journey stop index reached, a DateTime ETA,
   * and whether the journey is being tracked.
   */
  getVehicleSortMap(trackingVehicles: TrackingVehicle[]): Map<number, { furthestStopIndex: number, eta: DateTime, isTracking: boolean }> {
    const vehicleSortMap = new Map()
    for (const vehicle of trackingVehicles) {
      const { journeyId, isTracking } = vehicle
      const furthestStopIndex = this.getFurthestJourneyStopIndex(journeyId)
      let eta = null
      if (this._journeyEtaMap.has(journeyId)) {
        eta = DateTime.fromISO(this._journeyEtaMap.get(journeyId).eta)
      }
      vehicleSortMap.set(journeyId, { furthestStopIndex, eta, isTracking })
    }
    return vehicleSortMap
  }

  /**
   * Retrieves the largest journey stop index that has
   * been marked as reached.
   * @param journeyId - a journey ID number.
   * @returns a number.
   */
  getFurthestJourneyStopIndex(journeyId: number): number {
    const reachedStopIndexes = []
    for (const [index, stop] of tracking.reservation.stops.entries()) {
      const journeyStop = stop.journeyStops.find(
        journeyStop => journeyStop.journeyId === journeyId
      )
      if (journeyStop.reached) {
        reachedStopIndexes.push(index)
      }
    }
    const maxIndex = (reachedStopIndexes.length) ? Math.max(...reachedStopIndexes) : -1
    return maxIndex
  }

  /**
   * Retrieves the current active tracking vehicle if it exists,
   * the first tracked vehicle in the list otherwise.
   * @returns a TrackingVehicle object.
   */
  getActiveTrackingVehicle(): TrackingVehicle {
    if (this._activeTrackingVehicle) {
      return this._activeTrackingVehicle
    }

    // Note this getter is filtered by tracking status
    if (!this.trackingVehicles.length) {
      return null
    }

    const activeTrackingVehicle = this.trackingVehicles[0] // presorted
    return activeTrackingVehicle
  }

  getVehicleColor(index: number): string {
    const vehicleColor = VEHICLE_COLORS[index % VEHICLE_COLORS.length]
    return colors[vehicleColor]
  }
}

export default new TrackingVehiclesModule({ store, name: 'tracking-vehicles' })
