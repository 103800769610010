
import { DateTime } from 'luxon'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class EnterpriseTrackingNoTrackingDialog extends Vue {
  @Prop({ type: Boolean }) readonly value: boolean
  @Prop({ type: String, default: '' }) readonly nextReservationTime: string
  @Prop({ type: String, default: '' }) readonly nextStopTimezone: string

  get checkBackMessage(): string {
    if (!this.nextReservationTime) {
      return 'Live tracking is currently not available.'
    }
    const datetime = DateTime.fromISO(this.nextReservationTime).setZone(
      this.nextStopTimezone
    )
    const time = datetime.toFormat('h:mm a ZZZZ')
    const date = datetime.toFormat('LL/dd')
    return `Live tracking will be available approximately 30 minutes before your route's next pickup at ${time} on ${date}.`
  }
}
