
import { LocaleMessage } from 'vue-i18n'
import { Vue, Component, InjectReactive } from 'vue-property-decorator'

@Component({ components: {} })
export default class LiveTrackingMapShareButton extends Vue {
  copied = false

  @InjectReactive('trackingLink') trackingLink

  get tooltipCopy(): LocaleMessage {
    if (this.$vuetify.breakpoint.smAndDown) {
      return this.$t('liveTracking.actions.SHARE')
    }
    return this.copied
      ? this.$t('liveTracking.actions.LINK_COPIED')
      : this.$t('liveTracking.actions.COPY_LINK')
  }

  async copyURL(): Promise<void> {
    try {
      if (this.$vuetify.breakpoint.smAndDown && navigator.share) {
        await navigator.share({
          title: this.$t('liveTracking.actions.SHARE_TITLE').toString(),
          url: this.trackingLink,
          text: this.$t('liveTracking.actions.SHARE_TEXT').toString(),
        })
      } else {
        await navigator.clipboard.writeText(this.trackingLink)
      }
      this.copied = true
    } catch (err) {
      console.error(err)
    }
  }
}
