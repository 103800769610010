
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import rider from '@/services/rider'
import auth from '@/store/modules/auth'
import { TableViewRider } from '@/models/dto/TableViewRider'
import { CustomerAccount, UpdateRiderPayload } from '@/models/dto'
import OrganizationEditRiderChildCustomerAccounts from './OrganizationEditRiderChildCustomerAccounts.vue'
import axios from 'axios'
import AlertBox from '@/components/AlertBox.vue'

@Component({
  components: {
    OrganizationEditRiderChildCustomerAccounts,
    AlertBox,
  },
})
export default class OrganizationEditRiderDialog extends Vue {
  @Prop({ type: Boolean, default: false }) readonly value: boolean
  @Prop({ type: Object, default: () => {} }) readonly rider: TableViewRider
  @Prop({ type: Object, default: () => {} })
  readonly customerAccount: CustomerAccount

  @Watch('rider', { immediate: true })
  riderChanged(): void {
    if (this.value) {
      this.resetValues()
      this.formData.employeeId.value = this.rider.employeeId
      this.formData.firstName.value = this.rider.firstName
      this.formData.lastName.value = this.rider.lastName
      this.formData.email.value = this.rider.email
      this.formData.phone.value = this.rider.phone
      this.childCustomerAccountIds = this.rider.childCustomerAccountIds
    }
  }

  formIsValid = false
  success = false
  errorMessage = ''
  childCustomerAccountIds = null

  formData: Record<string, { value: string; rules: any[] }> = {
    employeeId: {
      value: '',
      rules: [(v) => !!v || 'Employee ID is required'],
    },
    firstName: {
      value: '',
      rules: [],
    },
    lastName: {
      value: '',
      rules: [],
    },
    email: {
      value: '',
      rules: [(v) => !v || /.+@.+/.test(v) || 'E-mail must be valid'],
    },
    phone: {
      value: '',
      rules: [
        (v) =>
          !v ||
          /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/.test(
            v
          ) ||
          'Phone must be valid',
      ],
    },
  }

  mounted(): void {
    this.setup()
  }

  setup(): void {
    this.success = false
  }

  resetValues(): void {
    const form = this.$refs.form as any
    if (form) {
      form.resetValidation()
    }
  }

  close(): void {
    this.$emit('input', false)
  }

  backToSite() {
    this.success = false
    this.setup()
    this.close()
  }

  async send() {
    const form = this.$refs.form as any
    if (!form.validate()) {
      return
    }
    this.errorMessage = ''
    const payload: UpdateRiderPayload = {
      firstName: this.formData.firstName.value,
      lastName: this.formData.lastName.value,
      employeeId: this.formData.employeeId.value,
      email: this.formData.email.value,
      phone: this.formData.phone.value,
      customerAccountId: auth.customerAccount.customerAccountId,
      riderId: this.rider.riderId,
      childCustomerAccountIds: this.childCustomerAccountIds,
    }
    try {
      await rider.update(payload)
      this.success = true
      this.close()
      this.$emit('success')
      // eslint-disable-next-line prettier/prettier, @typescript-eslint/no-explicit-any
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const errorMessage = e.response?.data?.message
        this.errorMessage = errorMessage
      }
    }
  }
}
