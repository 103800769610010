var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.active)?_c('div',{staticClass:"alert-box-row d-flex border-radius-4 border-1 border-solid padding-a-1",class:{
    'border-error background-background-error': _vm.type === 'error',
    'border-success background-background-success': _vm.type === 'success',
  },style:(`height:${_vm.height}`),attrs:{"no-gutters":"","align":"stretch"}},[_c('div',{staticClass:"d-flex flex-shrink flex-column border-radius-top-left-4 border-radius-top-right-0 border-radius-bottom-right-0 border-radius-bottom-left-4 padding-y-3 padding-l-2",class:{
      'd-flex align-center justify-center': _vm.$vuetify.breakpoint.smAndUp,
      'text-center': _vm.$vuetify.breakpoint.xsOnly,
    },attrs:{"cols":"auto","align-self":"center"}},[(_vm.type === 'error')?_c('CUIcon',{attrs:{"color":"error","view-box":"0 0 24 24"}},[_vm._v(" "+_vm._s(_vm.errorIcon)+" ")]):_c('CUIcon',{attrs:{"color":"success","view-box":"0 0 24 24"}},[_vm._v(" check_circle ")])],1),_c('v-col',{staticClass:"border-radius-top-right-3 border-radius-bottom-right-3 font-14 d-flex align-center padding-y-0 padding-x-4",class:{
      'background-background-success': _vm.type === 'success',
      'background-background-error': _vm.type === 'error',
    }},[_c('div',[_vm._t("default")],2)])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }