var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('CUModal',{attrs:{"id":"sales-will-call-dialog","value":_vm.value,"max-width":"586px","width":"586px"},on:{"input":function($event){return _vm.$emit('input', $event)}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(_vm._s(`${' '}`))]},proxy:true},{key:"text",fn:function(){return [_c('div',{staticClass:"d-flex flex-column text-center margin-x-auto"},[_c('div',{staticClass:"d-flex flex-column text-center margin-x-auto padding-b-4"},[_c('img',{staticClass:"min-h-60 max-h-60 margin-b-4",attrs:{"src":_vm.largeEventIcon}}),_c('h2',[_vm._v(" Thank you for using CharterUP "),(_vm.$vuetify.breakpoint.smAndUp)?_c('br'):_vm._e(),_vm._v(" to plan your large event! ")]),_c('p',[_vm._v(" Your booking is our priority ")])]),_c('v-divider'),_c('div',{staticClass:"d-flex flex-column align-start padding-x-4 padding-t-2"},[_c('p',{staticClass:"padding-y-2"},[_vm._v(" Here's what's next: ")]),_c('div',{staticClass:"d-flex align-start",class:{
            'padding-y-4': _vm.$vuetify.breakpoint.smAndDown,
            'padding-y-2': _vm.$vuetify.breakpoint.mdAndUp,
          }},[_c('img',{staticClass:"min-h-10 max-h-20 margin-t-2 margin-r-2",attrs:{"src":_vm.greenCheckCircle}}),_c('p',{staticClass:"text-left"},[_vm._v(" Our Large Events team has been alerted to secure your "+_vm._s(_vm.busCount)+" buses. ")])]),_c('div',{staticClass:"d-flex align-start",class:{
            'padding-y-4': _vm.$vuetify.breakpoint.smAndDown,
            'padding-y-2': _vm.$vuetify.breakpoint.mdAndUp,
          }},[_c('img',{staticClass:"min-h-10 max-h-20 margin-t-2 margin-r-2",attrs:{"src":_vm.checkCircle}}),_c('p',{staticClass:"text-left"},[_c('strong',[_vm._v("We'll call you"+_vm._s(_vm.mainActionText))]),_vm._v(" at "+_vm._s(_vm.customerPhoneComputed)+" to discuss any special trip requirements and email your detailed quote. ")])])]),(_vm.$vuetify.breakpoint.smAndDown)?_c('v-spacer'):_vm._e(),_c('v-btn',{staticClass:"w-full margin-t-6",attrs:{"color":"primary"},on:{"click":_vm.close}},[_vm._v(" Ok, got it! ")])],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }