
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class CheckoutSubmitButton extends Vue {
  @Prop({ type: Boolean, default: false }) loading!: boolean
  @Prop({ type: Boolean, default: false }) disabled!: boolean
  @Prop({ type: String, default: 'Reserve now' }) text!: string
  @Prop({ type: String }) error!: string

  get isWidthLessThan360(): boolean {
    return this.$vuetify.breakpoint.width < 360
  }

  get formattedError(): string {
    return this.error.replace(/;.*/, '')
  }
}
