
import { Vue, Component, Watch } from 'vue-property-decorator'

import { Alert } from '@/models/Alert'
import alert from '@/store/modules/alert'
@Component({})
export default class TheAlert extends Vue {
  timeout = null

  @Watch('alert', { deep: true })
  alertChange(a: Alert): void {
    if (a?.duration && a.duration >= 0) {
      this.timeout = setTimeout(this.close, a.duration)
    }
  }
  get alert(): Alert {
    return alert.current
  }

  get show(): boolean {
    return !!this.alert
  }

  get color(): string {
    return this.alert?.color || ''
  }

  get title(): string {
    return this.alert?.title || ''
  }

  get text(): string {
    return this.alert?.text || ''
  }

  get dismissible(): boolean {
    return !!this.alert?.dismissible
  }

  close(): void {
    alert.clear()
    clearTimeout(this.timeout)
  }
}
