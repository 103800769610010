var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.isTripModificationApplied)?_c('div',[_c('div',{staticClass:"d-flex align-center justify-space-between"},[_c('div',{class:{
          'd-flex align-center': _vm.$vuetify.breakpoint.smAndUp,
        }},[_c('h1',{staticClass:"margin-y-0"},[_vm._v(" Your modifications have been applied! ")]),_c('p',{staticClass:"font-22 font-medium",class:{
            'margin-l-4 margin-y-0': _vm.$vuetify.breakpoint.smAndUp,
            'margin-t-2': _vm.$vuetify.breakpoint.xsOnly,
          }},[_vm._v(" "+_vm._s(_vm.subHeader)+" ")])])]),_c('li',{staticClass:"margin-t-8 margin-l-4 margin-b-4"},[_vm._v(" An email with your modification details was sent to "),_c('span',{staticClass:"font-bold"},[_vm._v(_vm._s(_vm.customerEmail)+".")])]),(_vm.$vuetify.breakpoint.xsOnly)?_c('v-divider'):_vm._e(),_c('div',{class:{
        'margin-t-12': !_vm.$vuetify.breakpoint.xsOnly,
      }},[_c('TripModificationReview')],1)],1):_c('TripModificationError')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }