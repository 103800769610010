/**
 * A regular expression pattern that matches U.S. state abbreviations.
 *
 * @example
 * if (stateAbbreviationPattern.test('CA')) {
 *   console.log('"CA" is a U.S. state abbreviation.')
 * }
 */
export const stateAbbreviationPattern = /^(A[KLRZ]|C[AOT]|D[CE]|FL|GA|HI|I[ADLN]|K[SY]|LA|M[ADEINOST]|N[CDEHJMVY]|O[HKR]|PA|RI|S[CD]|T[NX]|UT|V[AT]|W[AIVY])$/

/**
 * A regular expression pattern that matches any whole number.
 *
 * @example
 * if (anyNumberPattern.test('123-456-7890')) {
 *   console.log('"123-456-7890" contains numbers and hyphens.')
 * }
 */
export const anyNumberPattern = /^[0-9-]+$/
