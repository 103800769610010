
import { Vue, Component, Prop } from 'vue-property-decorator'
import OrganizationTeam from '@/components/OrganizationTeam.vue'
import OrganizationInviteUserTeamDialog from '@/components/OrganizationInviteUserTeamDialog.vue'
import OrganizationTeamsActionsMenu from '@/components/OrganizationTeamsActionsMenu.vue'
import { Team } from '@/models/dto'

@Component({
  components: {
    OrganizationTeam,
    OrganizationInviteUserTeamDialog,
    OrganizationTeamsActionsMenu,
  },
})
export default class OrganizationTeams extends Vue {
  @Prop({ type: Array, required: true }) readonly teams: Team[]
  @Prop({ type: Boolean }) readonly isEnterpriseAccount: boolean

  search = ''

  clearSearch(): void {
    if (this.search) {
      this.search = ''
    }
  }
}
