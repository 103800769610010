
import { Vue, Component, Prop } from 'vue-property-decorator'
import BidCard from '@/components/BidCard.vue'
import { QuoteDetailV2Bid } from '../models/dto/QuoteDetailV2'

@Component({ components: { BidCard } })
export default class QuoteDetailBidListUnavailableBids extends Vue {
  @Prop({ required: true, type: Array }) bids!: QuoteDetailV2Bid[]
  @Prop({ required: true, type: Boolean }) isElite!: boolean
  @Prop({ required: true, type: Number }) quoteId!: number

}
