
import { Vue, Component, VModel, Prop } from 'vue-property-decorator'
import { CustomerAccount } from '@/models/dto'

@Component({})
export default class OrganizationEditRiderChildCustomerAccounts extends Vue {
  @VModel({ type: Array, default: () => [] })
  readonly childCustomerAccountIds: Array<number>
  @Prop({ type: Array }) readonly availableCustomerAccounts: Array<
    CustomerAccount
  >

  get possibleChildCustomerAccounts(): CustomerAccount[] {
    return this.availableCustomerAccounts.filter((team) => !team.isParent)
  }
}
