
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import ReservationDetailVerifySMS from '@/components/ReservationDetailVerifySMS.vue'
import auth from '../store/modules/auth'
import { Customer } from '../models/dto'

@Component({ components: { ReservationDetailVerifySMS } })
export default class ReservationDetailContactInfomationVerifySMS extends Vue {
  @Prop({ type: Boolean, required: false, default: false })
  readonly isVerifyPhoneModalOpen: boolean

  showSMSConfirmation = false

  @Watch('isVerifyPhoneModalOpen')
  isVerifyPhoneModalOpenChanged(val: boolean): any {
    this.showSMSConfirmation = this.isVerifyPhoneModalOpen && !auth.customer?.smsConfirmed
  }

  get customer(): Customer {
    return auth.customer
  }

  handleConfirmed(): void {
    this.close()
  }

  mounted(): void {
    this.showSMSConfirmation = this.isVerifyPhoneModalOpen && !auth.customer?.smsConfirmed
  }

  open(): void {
    this.showSMSConfirmation = true
  }

  close(): void {
    this.showSMSConfirmation = false
  }
}
