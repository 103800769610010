
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import customerAccount from '@/services/customerAccount'
import axios from 'axios'

@Component({})
export default class OrganizationDeleteTeamDialog extends Vue {
  @Prop({ type: Object, required: true }) readonly team: any
  @Prop({ type: Number }) readonly index: number

  formIsValid = false
  dialog = false
  success = false
  errorMessage = ''

  @Watch('dialog')
  dialogChanged(newVar: boolean): void {
    if (!newVar) {
      this.resetValues()
    } else {
      this.setup()
    }
    if (!this.dialog) {
      this.$emit('close')
    }
  }

  get isParent(): boolean {
    return this.team?.teams?.length
  }

  mounted(): void {
    this.setup()
  }
  setup(): void {
    this.success = false
  }
  resetValues(): void {
    const form = this.$refs.form as any
    form.reset()
  }
  backToSite(): void {
    this.success = false
    this.setup()
    this.dialog = false
  }

  async send(): Promise<void> {
    this.errorMessage = ''
    try {
      await customerAccount.delete(this.team.customerAccountId)
      this.$emit('success')
      this.dialog = false
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const errorMessage = e.response?.data?.message
        this.errorMessage = errorMessage
      }
    }
  }
}
