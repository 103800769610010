import RouteConfigWithMeta from '@/models/RouteConfig'
import auth from '@/store/modules/auth'
import { hasPermissionGuard } from '@/utils/router'

const enterpriseTracking: RouteConfigWithMeta = {
  path: '/enterprise/tracking',
  name: 'enterprise-tracking',
  props: { mode: 'customer-account' },
  meta: { requiresAuth: true },
  component: () => import( /* webpackChunkName: "enterprisetracking" */ '@/views/EnterpriseTracking.vue'),
  beforeEnter: (to, from, next) => {
    hasPermissionGuard(auth.isEnterpriseClient || auth.isEnterpriseAdmin)(to, from, next)
  },
}

export default enterpriseTracking
