import { datadogLogs, LogsInitConfiguration } from '@datadog/browser-logs'
import { datadogRum, RumInitConfiguration } from '@datadog/browser-rum'
import { resolveEnvironment, EnvironmentName } from "@/utils/env";
function datadogEnvName(): string | null {
  if (process.env.VUE_APP_DD_ENV_OVERRIDE) {
    // This is useful when testing Datadog integration or setup during local development,
    // but shouldn't be used outside of that.
    return process.env.VUE_APP_DD_ENV_OVERRIDE
  }

  const environmentName: EnvironmentName = resolveEnvironment();

  // Map environment to its corresponding string value
  const envMap: Record<EnvironmentName, string> = {
    [EnvironmentName.dev]: 'dev',
    [EnvironmentName.staging]: 'staging',
    [EnvironmentName.production]: 'production',
    [EnvironmentName.local]: 'local',
    [EnvironmentName.unknown]: null,
  }

  return envMap[environmentName] || null
}
/**
 * Update the Datadog current session with information about the active user.
 */
export function setUserContext(id: string | null, email: string | null, name: string | null) {
  datadogRum.setUser({ id, email, name })
}
/**
 * Update the Datadog current session with information about the active user.
 */
export function clearUserContext() {
  datadogRum.stopSession()
  datadogRum.clearUser()
}
export function initDatadog(): void {
  const DATADOG_ENV = datadogEnvName()
  if (DATADOG_ENV !== null) {
    // we're running in one of our deployed environments, initialize datadog
    const DATADOG_APPLICATION_ID = '097ea9cd-4750-4558-95a2-9ab3130ebbc9'
    const DATADOG_CLIENT_TOKEN = 'pub13ac61073e9394d3d0eb2ebde85761f6'
    const DATADOG_SITE = 'us5.datadoghq.com'
    const DATADOG_SERVICE = 'charterup-ui'
    const DATADOG_APP_VERSION = process.env.VUE_APP_BUILD_VERSION || 'unknown'
    const DATADOG_CONFIG: RumInitConfiguration & LogsInitConfiguration = {
      applicationId: DATADOG_APPLICATION_ID,
      clientToken: DATADOG_CLIENT_TOKEN,
      site: DATADOG_SITE,
      service: DATADOG_SERVICE,
      env: DATADOG_ENV,
      version: DATADOG_APP_VERSION,
      // Browser RUM config
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'allow',

      // Enable the client-side collection of split.io information
      enableExperimentalFeatures: ["feature_flags"],

      // Browser APM tracing config
      traceSampleRate: 20,
      traceContextInjection: 'sampled',
      allowedTracingUrls: [
        {
          match: /https:\/\/.*\.coachrail\.com/,
          propagatorTypes: ['datadog', 'tracecontext'],
        },
        {
          match: /https:\/\/.*\.charterup\.com/,
          propagatorTypes: ['datadog', 'tracecontext'],
        },
      ],
      // Logging configuration
      forwardErrorsToLogs: true,
      forwardConsoleLogs: ['warn', 'error'],
      forwardReports: 'all',
      // Add beforeSend to filter out specific errors
      beforeSend: (event) => {
        if (event.error) {
          const regex = /Object Not Found Matching Id:\d+, MethodName:\w+, ParamCount:\d+/
          if (regex.test(event.error.message)) {
            return false // Discard this error
          }
        }
        return true // Allow all other events
      }
    }
    // Initialize Datadog log collection
    datadogLogs.init(DATADOG_CONFIG);
    // Set up browser session tracking and APM
    datadogRum.init(DATADOG_CONFIG)
  } else {
    console.log('Skipping datadog instrumentation')
  }
}
