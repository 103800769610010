var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.loading)?_c('CUSkeletonLoader',{attrs:{"width":_vm.outerDiameter,"height":_vm.outerDiameter,"round":""}}):_c('div',[_c('div',{staticClass:"position-relative border-solid border-2 border-radius-round",class:{
      'border-primary': !_vm.disabled && _vm.selected,
      'border-gray-subtle-text': !_vm.disabled && !_vm.selected,
      'border-border-gray': _vm.disabled,
      'w-24 h-24': _vm.sizeClass === 'x-large',
      'w-20 h-20': _vm.sizeClass === 'large',
      'w-16 h-16': _vm.sizeClass === 'regular',
    }},[(_vm.selected)?_c('div',{staticClass:"border-radius-round position-absolute left-3 top-3",class:{
        'background-primary': !_vm.disabled && _vm.selected,
        'background-gray': _vm.disabled,
        'w-14 h-14': _vm.sizeClass === 'x-large',
        'w-10 h-10': _vm.sizeClass === 'large',
        'w-6 h-6': _vm.sizeClass === 'regular',
      }}):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }