import RouteConfigWithMeta from '@/models/RouteConfig'
import auth from '@/store/modules/auth'
import { hasPermissionGuard } from '@/utils/router'

const provider: RouteConfigWithMeta = {
  path: 'provider/:providerId/:marketId',
  name: 'provider-detail-preview',
  meta: { requiresAuth: true },
  component: () =>
    import(
      /* webpackChunkName: "reservation-index" */ '@/views/Provider.vue'
    ),
    beforeEnter: (to, from, next) => {
      hasPermissionGuard(auth.isCMSAdmin)(to, from, next)
    },
}

export default provider
