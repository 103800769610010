
import { Vue, Component, Prop } from 'vue-property-decorator'
import { TrackingMapZoomOptions } from '@/models/Tracking'
import tracking from '@/store/modules/tracking'

@Component({ components: {} })
export default class LiveTrackingMapToggleGroup extends Vue {
  @Prop({ type: Object, required: true })
  readonly zoomOptions: TrackingMapZoomOptions
  @Prop({ type: Boolean, required: true }) readonly disableZoomModes: boolean

  changeMode(mode: string): void {
    tracking.setCustomZoomOption(true)
    if (mode === 'full-route') {
      tracking.setZoomToFullRoute()
    } else if (!this.disableZoomModes && mode === 'next-stop') {
      // TODO: consider this and see how to have this handle possibly fitting previous stop
      tracking.setZoomToVehicleAndNextStop()
    } else if (!this.disableZoomModes && mode === 'center') {
      tracking.setZoomToVehicle()
    }
    this.$emit('input')
  }

  resetCustomOptions(): void {
    tracking.setCustomZoomOption(false)
    tracking.setCustomZoomPosition(false)
    this.$emit('input')
  }
}
