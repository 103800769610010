
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { Customer } from '@/models/dto'
import customer from '@/services/customer'
import auth from '@/services/auth'

@Component({})
export default class ReservationDetailConfirmPhone extends Vue {
  @Prop({ type: Boolean }) readonly value: boolean
  @Prop({ type: Boolean, required: false }) readonly closeable: boolean
  @Prop({ type: Object, required: false }) readonly customer: Customer
  @Prop({ type: Boolean, required: false }) readonly anonymous: boolean
  @Prop({ type: Boolean, required: false, default: true }) readonly showConfirmCheckbox: boolean
  @Prop({ type: String, required: true }) readonly header: string
  @Prop({ type: String, required: true }) readonly subheader: string

  @Watch('customer', { immediate: true, deep: true })
  customerPhoneChanged(value: Customer): void {
    this.phone = value?.phone || ''
  }

  phone = ''
  confirmCheck = true
  submitting = false

  get phoneRaw(): string {
    return this.phone?.replace(/\D/g, '')
  }

  handleSkip(): void {
    this.$emit('skip')
  }

  next(): void {
    if (!this.confirmCheck) {
      this.$emit('skip')
    } else {
      this.submit()
    }
  }

  async submit(): Promise<void> {
    const form = this.$refs.tripReceivedForm as any
    if (!form.validate()) {
      return
    }

    this.submitting = true
    try {
      if (this.anonymous) {
        await auth.requestSmsOtp({
          phoneNumber: this.phoneRaw,
        })
      } else {
        await customer.updateSMS(this.customer?.customerId, {
          phoneNumber: this.phoneRaw,
        })
      }
    } catch (err) {
      console.error(err)
    }
    this.submitting = false
    this.$emit('update-phone')
    this.$emit('confirm', this.phoneRaw)
  }
}
