
import { Vue, Component, Prop } from 'vue-property-decorator'
import { formatStopTime } from '@/utils/datetime'

@Component({})
export default class TripInfoItineraryPickupTime extends Vue {
  @Prop({ type: String, required: true }) pickupDatetime: string
  @Prop({ type: String, required: true }) zoneId: string

  get formattedDisplayTime(): string {
    return formatStopTime(this.pickupDatetime, this.zoneId)
  }
}
