var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.showRisks)?_c('TripInfoItineraryHotelInfo',{attrs:{"allow-reservation-management":_vm.allowReservationManagement,"trip":_vm.trip,"reservation-id":_vm.reservationId,"managed-id":_vm.managedId,"hotel-information":_vm.hotelInformation,"hotel-requirement":_vm.hotelRequirement,"trip-status":_vm.tripStatus}}):_vm._e(),_c('CUItinerary',_vm._l((_vm.stopsToDisplay),function(stop,stopIndex){return _c('CUItineraryItem',{key:stop.id,staticClass:"w-full",attrs:{"upcoming":!_vm.showLiveStops || _vm.stopStatuses[stopIndex].upcoming,"in-progress":_vm.stopStatuses[stopIndex].inProgress,"completed":_vm.stopStatuses[stopIndex].completed,"last-stop":_vm.stopStatuses[stopIndex].lastStop,"color":"white","small":""}},[_c('div',{staticClass:"padding-x-3 margin-b-2 w-full padding-b-6"},[(stop.address)?_c('TripInfoItineraryAddress',{attrs:{"id":`trip-info-itinerary-stop-${stopIndex}`,"title":stop.address.title,"street":stop.address.street1,"state":stop.address.state,"city":stop.address.city}}):_vm._e(),(stop.dropoffDatetime && stop.address)?_c('TripInfoItineraryDropoffTime',{attrs:{"id":`trip-info-itinerary-stop-${stopIndex}-pickup-time`,"dropoffDatetime":stop.dropoffDatetime,"zoneId":stop.address.timeZone}}):_vm._e(),_c('div',[(stop.pickupDatetime)?_c('TripInfoItineraryPickupTime',{attrs:{"id":`trip-info-itinerary-stop-${stopIndex}-pickup-time`,"pickupDatetime":stop.pickupDatetime,"zoneId":stop.address.timeZone}}):_vm._e(),(_vm.showRisks && _vm.allowReservationManagement)?[(_vm.riskTypeIdToShowForStop(stopIndex))?_c('ItineraryRiskWarning',{staticClass:"display-flex align-center justify-start",class:{
                'margin-t-n2': _vm.isMissingStopRisk(
                  _vm.riskTypeIdToShowForStop(stopIndex)
                ),
              },attrs:{"reservation-id":_vm.reservationId,"managed-id":_vm.managedId,"index":stopIndex,"stop":stop,"risk-type-id":_vm.riskTypeIdToShowForStop(stopIndex)}}):_vm._e(),(_vm.isFlightRiskOnStop(_vm.risksPerStop[stopIndex]))?_c('ItineraryRiskWarning',{staticClass:"display-flex align-center justify-start",attrs:{"reservation-id":_vm.reservationId,"managed-id":_vm.managedId,"index":stopIndex,"stop":stop,"risk-type-id":_vm.flightInfoMissingRiskTypeId}}):_vm._e()]:_vm._e(),(
              !_vm.isFlightRiskOnStop(_vm.risksPerStop[stopIndex]) &&
              _vm.showFlightInformation(stop)
            )?_c('TripInfoItineraryStopFlightInfo',{staticClass:"margin-t-2",attrs:{"id":`stop-risk-item-${stopIndex}`,"stop":stop,"trip-status":_vm.tripStatus,"allow-reservation-management":_vm.allowReservationManagement}}):_vm._e()],2),_c('TripInfoItineraryStopNote',{attrs:{"stop":stop}})],1)])}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }