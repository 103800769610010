
import { Vue, Component, Prop } from 'vue-property-decorator'
import rider from '@/services/rider'
import auth from '@/store/modules/auth'
import { TableViewRider } from '@/models/dto/TableViewRider'
import axios from 'axios'
import AlertBox from '@/components/AlertBox.vue'

@Component({ components: { AlertBox } })
export default class OrganizationDeleteRiderDialog extends Vue {
  @Prop({ type: Boolean, default: false }) readonly value: boolean
  @Prop({ type: Object, default: () => {} }) readonly rider: TableViewRider

  success = false
  errorMessage = ''

  close(): void {
    this.$emit('input', false)
  }
  backToSite(): void {
    this.success = false
    this.close()
  }
  async send(): Promise<void> {
    this.errorMessage = ''
    const payload = {
      customerAccountId: auth.customerAccount.customerAccountId,
      riderId: this.rider.riderId,
    }
    try {
      await rider.delete(payload)
      this.success = true
      this.close()
      this.$emit('success')
      // eslint-disable-next-line prettier/prettier
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const errorMessage = e.response?.data?.message
        this.errorMessage = errorMessage
      }
    }
  }
}
