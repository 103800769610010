
import { Vue, Component, Prop } from 'vue-property-decorator'
import { Stop } from '../models/dto'
import support from '../store/modules/support'
import { getPickupDestinationCitiesFromAddresses } from '../utils/string'
import TripItinerary from '@/components/TripItinerary.vue'
import { QuoteDetailV2, QuoteDetailV2Trip } from '@/models/dto/QuoteDetailV2'

@Component({ components: { TripItinerary } })
export default class QuoteCardMobileInformation extends Vue {
  @Prop({ type: Object, required: true }) quote!: QuoteDetailV2
  @Prop({ type: Object, required: true }) trip!: QuoteDetailV2Trip

  support = support
  isItineraryModalOpen = false

  get quoteId(): number {
    return this.quote?.quoteId
  }

  get stops(): Stop[] {
    return this.trip?.stops || []
  }

  get cities(): {
    pickup: string
    dropoff: string
  } {
    const firstAddress = this.trip?.stops?.[0]?.address
    const secondAddress = this.trip?.stops?.[1]?.address
    return getPickupDestinationCitiesFromAddresses(firstAddress, secondAddress)
  }
}
