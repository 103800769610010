
import { Vue, Component, Prop } from 'vue-property-decorator'
import { snakeToPascal } from '@/utils/string'

@Component
export default class CUIcon extends Vue {
  @Prop({ type: [Number, String], default: 24 }) readonly width!: number | string
  @Prop({ type: [Number, String], default: 24 }) readonly height!: number | string
  @Prop({ type: String }) readonly color?: string
  @Prop({ type: String }) readonly hoverColor?: string
  @Prop({ type: String, default: 'box' }) readonly iconName!: string
  @Prop({ type: String, default: '0 0 32 32' }) readonly viewBox!: string
  @Prop({ default: false, required: false }) readonly decorative!: boolean
  @Prop({ type: Boolean, default: false, required: false })
  readonly hideTooltip?: boolean

  iconCache: Record<string, Promise<any>> = {}

  get iconFileName(): string {
    const slotText = this.$slots.default?.[0]?.text?.trim()
    return slotText ? snakeToPascal(slotText) : null
  }

  get listeners(): Record<string, unknown> {
    return {
      ...this.$listeners,
      click: (event: PointerEvent) => {
        this.$emit('click', event)
      },
    }
  }

  get icon(): any {
    if (this.iconFileName) {
      return () => import(`@/components/icons/Icon${this.iconFileName}.vue`)
    }
    return null
  }

  get style(): Record<string, unknown> {
    let color = 'currentColor'
    const hexRegex = new RegExp('#[0-9a-fA-F]{3,6}', 'g')
    if (this.color && hexRegex.test(this.color)) {
      color = this.color
    }
    return {
      '--color': color,
    }
  }

  get classes(): string {
    let classes = ''
    const hexRegex = new RegExp('#[0-9a-fA-F]{3,6}', 'g')

    if (this.color && !hexRegex.test(this.color)) {
      classes = `${classes} text-${this.color}`
    }

    if (this.hoverColor && !hexRegex.test(this.hoverColor)) {
      classes = `${classes} hover:text-${this.hoverColor} transition-duration-200`
    }

    return classes
  }
}
