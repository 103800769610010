
import { Address, CustomerAccount, EditCustomerAccountPayload } from "@/models/dto";
import { formatCityStateZip, formatStreetAddress } from "@/utils/address";
import { phoneFormatFilter } from "@/utils/phone";
import { Vue, Component, Prop } from "vue-property-decorator";
import AutoCompleteAddress from "@/components/AutoCompleteAddress.vue";
import customerAccount from "@/services/customerAccount";
import alert from "@/store/modules/alert";

@Component({ components: { AutoCompleteAddress }})
export default class OrganizationAccount extends Vue {
  @Prop({ type: Object, required: true }) customerAccount!: CustomerAccount

  isEditing = false

  get initialSearch(): string {
    return this.customerAccount?.address?.addressName || ''
  }

  get customerAccountInformation(): {label: string; key: string; value: any; formattedValue?: string, columnClasses?: string, inputHandler: (input: any) => void, rules: Array<(v: string) => boolean | string>;}[] {
    const { name, email, phone } = this.customerAccount || {}
    return [
      {
        label: 'Name',
        key: 'name',
        value: name || '',
        columnClasses: 'col-md-4 col-12',
        inputHandler: (name: string) => this.emitUpdate({
          ...this.customerAccount,
          name
        }),
        rules: [(v: string) => !!v || 'Name is required'],
      },
      {
        label: 'Email',
        key: 'email',
        value: email || '',
        columnClasses: 'col-md-4 col-12',
        inputHandler: (email: string) => this.emitUpdate({
          ...this.customerAccount,
          email
        }),
        rules: [(v: string) => !!v || 'Email is required'],
      },
      {
        label: 'Phone',
        key: 'phone',
        value:  phone,
        formattedValue: phoneFormatFilter(phone),
        columnClasses: 'col-md-4 col-12',
        inputHandler: (phone: string) => this.emitUpdate({
          ...this.customerAccount,
          phone
        }),
        rules: [
          (v: string) => !!v || 'Phone is required',
          (v: string): boolean | string => /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(v) || 'Phone number must be valid'
        ],
      },
      {
        label: 'Address',
        key: 'address',
        value: this.customerAccount?.address,
        formattedValue: this.accountAddress,
        columnClasses: 'col-12',
        inputHandler: (address: Address) => this.emitUpdate({
          ...this.customerAccount,
          address
        }),
        rules: [(v: string) => !!v || 'Address is required'],
      }
    ]
  }

  get accountAddress(): string {
    const { address } = this.customerAccount || {}
    if (!address) {
      return ''
    }
    const streetAddress = formatStreetAddress(address)
    const cityStateZip = formatCityStateZip(address)
    return `${streetAddress}, ${cityStateZip}`
  }

  async save(): Promise<void> {
    if (!(this.$refs['info-form'] as any).validate()) {
      return
    }

    try {
      const payload: EditCustomerAccountPayload = {
        name: this.customerAccount.name,
        email: this.customerAccount.email,
        phone: this.customerAccount.phone,
        address: this.customerAccount.address
      }
      delete payload.address.addressId
      await customerAccount.edit(this.customerAccount.customerAccountId, payload)
      this.$emit('refresh')
      this.isEditing = false
    } catch (error) {
      alert.add({
        text: 'Error saving customer account information',
        color: 'error',
        title: 'Error',
        dismissible: true
      })
    }
  }

  cancel(): void {
    this.$emit('refresh')
    this.isEditing = false
  }

  emitUpdate(customerAccount: CustomerAccount): void {
    this.$emit('update:customer-account', customerAccount)
  }

}
