/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 * Saves a value to local storage under the given key.
 *
 * @param key - The key to save the value under.
 * @param value - The value to save.
 */
export const save = (key: string, value: any): void => {
  window.localStorage.setItem(key, JSON.stringify(value))
}

/**
 * Loads a value from local storage by the given key.
 *
 * @param key - The key to load the value for.
 * @returns The value saved under the given key, or `undefined` if no value was saved for that key.
 */
export const load = (key: string): any => {
  const data = window.localStorage.getItem(key)

  try {
    return data ? JSON.parse(data) : data
  } catch (error) {
    console.error('Error parsing JSON:', error)
    return null
  }
}

/**
 * Clears all data saved in local storage.
 */
export const clear = (): void => {
  window.localStorage.clear()
}

/**
 * Removes a value from local storage by the given key.
 *
 * @param key - The key to remove the value for.
 */
export const remove = (key: string): void => {
  window.localStorage.removeItem(key)
}
