
import { Vue, Component, Prop } from 'vue-property-decorator'
import rider from '@/services/rider'
import ImportRiderFileUpload from './ImportRiderFileUpload.vue'
import { CustomerAccount } from '@/models/dto'
import { saveAs } from 'file-saver'
import AlertBox from '@/components/AlertBox.vue'

@Component({ components: { ImportRiderFileUpload, AlertBox } })
export default class ImportRiderModal extends Vue {
  @Prop({ type: Boolean, required: true }) readonly value: boolean
  @Prop({ type: Object }) readonly customerAccount: CustomerAccount

  importType = null
  importTypeMap = [
    {
      label: 'Import All',
      value: 'importAll',
    },
    {
      label: 'Import Update',
      value: 'importUpdate',
    },
  ]
  sendInviteEmail = false
  uploadReady = false
  errorMessage = ''
  formData = new FormData()
  filePresent = false

  mounted(): void {}

  close(): void {
    this.$emit('input', false)
  }

  addFile(file) {
    this.formData.set('file', file)
    this.filePresent = true
  }

  clearFile(): void {
    this.formData = new FormData()
    this.filePresent = false
    const importFileComponent = this.$refs.importFileUpload as any
    importFileComponent.clearFile()
  }

  importSubmit(): void {
    if (!this.formData.get('file')) {
      this.errorMessage = 'Must select a file to upload.'
      return
    }

    rider.import(
      this.customerAccount.customerAccountId,
      this.formData,
      this.importType === 'importAll',
      this.sendInviteEmail
    )
    this.uploadReady = false
    this.$nextTick(() => {
      this.uploadReady = true
    })
    this.clearFile()
    this.importType = null
    this.sendInviteEmail = false
    this.$emit('open-import-dialog')
    this.$emit('close')
  }

  async downloadImportRiderTemplate(): Promise<void> {
    try {
      const templateData = await rider.importRiderTemplate()
      await saveAs(templateData.data, 'ImportRiderTemplate')
    } catch (e) {
      console.error('Getting import rider template failed.')
    }
  }
}
