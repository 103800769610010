import { DateTime } from 'luxon'

/**
 * Calculates the estimated drop-off time based on travel time from the previous stop,
 * the departure time of the previous stop, and the time zone of the stop.
 *
 * @param {string} previousStopDepartureTime - The ISO 8601 formatted departure time of the previous stop.
 * This value is required for calculating the drop-off time.
 *
 * @param {number} travelTimeInSeconds - The travel time from the previous stop to the current stop in seconds.
 * This is the amount of time to add to the previous stop's departure time to estimate the drop-off time.
 * Must be 0 or a positive number.
 *
 * @param {string} stopTimeZone - The time zone of the current stop in IANA format (e.g., 'America/New_York').
 * This ensures the correct time zone is used for the calculation.
 *
 * @returns {DateTime | null} - The calculated drop-off time as a `DateTime` object.
 * Returns `null` if any of the required parameters are missing or invalid.
 *
 */
export const estimateDropoffDatetime = (
  previousStopDepartureTime: string,
  travelTimeInSeconds: number,
  stopTimeZone: string
): DateTime | null => {
  // Check if travelTimeInSeconds is null, undefined, or a negative value.
  if (travelTimeInSeconds == null || travelTimeInSeconds < 0) {
    return null
  }

  if (!previousStopDepartureTime || !stopTimeZone) {
    return null
  }

  const dateTime = DateTime.fromISO(previousStopDepartureTime, { zone: stopTimeZone })

  if (!dateTime.isValid) {
    return null
  }

  return dateTime.plus({ seconds: travelTimeInSeconds })
}
