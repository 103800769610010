
import { Vue, Component, Prop } from 'vue-property-decorator'
import { AmenityType, QuoteDetailV2Bid } from '../models/dto'
import AmenityIcon from '@/components/AmenityIcon.vue'
import { AmenityTypeId } from '../utils/enum'

@Component({ components: { AmenityIcon } })
export default class BidCardAmenities extends Vue {
  @Prop({ type: Object, default: null }) readonly bid: QuoteDetailV2Bid
  @Prop({ type: Boolean, default: false }) readonly isBronze: boolean

  get amenities(): AmenityType[] {
    const amenityMap: Record<number | string, AmenityType> = {}

    if (this.bid.vehicleTypeAmenities === null) {
      return []
    }

    // Flatten and de-duplicate vehicleTypeAmenities
    for (const vehicleType of Object.values(this.bid.vehicleTypeAmenities)) {
      for (const amenity of vehicleType) {
        amenityMap[amenity.id] = amenity
      }
    }

    // Search for SPAB and ADA amenities in companyAmenities and update amenityMap
    const specialAmenityTypes = ['SPAB', 'ADA']
    for (const typeId of specialAmenityTypes) {
      const foundAmenity = this.bid.compliances.find(
        ({ id }) => id === AmenityTypeId[typeId]
      )
      if (!foundAmenity) {
        continue
      }
      amenityMap[AmenityTypeId[typeId]] = foundAmenity
    }

    return Object.values(amenityMap)
  }
}
