/**
 * Calculates the distance between two points on the Earth's surface.
 *
 * @param lat1 - The latitude of the first point.
 * @param lon1 - The longitude of the first point.
 * @param lat2 - The latitude of the second point.
 * @param lon2 - The longitude of the second point.
 * @returns The distance between the two points in meters.
 */
export const distanceBetween = (
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number
): number => {
  const R = 6371 // km
  const dLat = toRad(lat2 - lat1)
  const dLon = toRad(lon2 - lon1)
  lat1 = toRad(lat1)
  lat2 = toRad(lat2)

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  const d = R * c * 1000
  return d
}

/**
 * Converts a numeric degree value to radians.
 *
 * @param value - The degree value to convert.
 * @returns The equivalent value in radians.
 */
export const toRad = (value: number): number => {
  return (value * Math.PI) / 180
}
