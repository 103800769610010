
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { DateTime } from 'luxon'
import AutoCompleteAddress from '@/components/AutoCompleteAddress.vue'
import trip from '@/services/trip'
import support from '@/store/modules/support'
import { KeyCode, SupportTicketKey } from '@/utils/enum'
import {
  HotelInformation,
  HotelRequirement,
  HotelInformationPayload,
  Address,
  PlaceDetail,
} from '@/models/dto'
import { EventBus } from '@/utils/eventBus'
import { placeDetailToAddress } from '@/utils/address'
import { nullIfEmpty } from '@/utils/string'

@Component({
  components: {
    AutoCompleteAddress,
  },
})
export default class HotelInformationDialog extends Vue {
  @Prop({ type: Boolean, default: false }) readonly value: boolean
  @Prop({ type: Number, default: null }) readonly tripId: number
  @Prop({ type: Object, default: null }) hotelInformation: HotelInformation
  @Prop({ type: Object, default: null }) hotelRequirement: HotelRequirement

  @Watch('value')
  onValueChange(): void {
    if (this.value) {
      this.hotelName = this.hotelInformation?.hotelName
      this.confirmationNumber = this.hotelInformation?.confirmationNumber
      this.bookingName = this.hotelInformation?.nameOnReservation
      this.address = this.hotelInformation?.address
    }
  }

  support = support
  SupportTicketKey = SupportTicketKey

  loading = false
  hotelName = ''
  confirmationNumber = ''
  bookingName = ''
  address: Address = null

  get title() {
    if (this.hotelInformation) {
      return this.$t('reservationDetail.hotelInformationDialog.TITLE_EDIT')
    }
    return this.$t('reservationDetail.hotelInformationDialog.TITLE_ADD')
  }

  get hotelCheckInDate(): string {
    return this.hotelRequirement?.checkIn
      ? DateTime.fromISO(this.hotelRequirement?.checkIn).toFormat('MM/dd/yy')
      : ''
  }

  get hotelCheckOutDate(): string {
    return this.hotelRequirement?.checkOut
      ? DateTime.fromISO(this.hotelRequirement?.checkOut).toFormat('MM/dd/yy')
      : ''
  }

  get addressName(): string {
    return this.address?.addressName || this.address?.name || ''
  }

  close(): void {
    this.$emit('input', false)
  }

  handleKeydown(event): void {
    if (event.keyCode === KeyCode.Escape) {
      this.close()
    }
  }

  handleContactSupport(): void {
    support.open({
      ticketTypeKey: SupportTicketKey.Other,
    })
  }

  setHotelAddress(place: PlaceDetail): void {
    this.address = place ? placeDetailToAddress(place) : null
    this.hotelName = this.hotelName || this.address?.title
  }

  async submitHotelInformation(): Promise<void> {
    const payload: HotelInformationPayload = {
      hotelName: nullIfEmpty(this.hotelName),
      confirmationNumber: nullIfEmpty(this.confirmationNumber),
      nameOnReservation: nullIfEmpty(this.bookingName),
      address: this.address,
    }
    this.loading = true
    try {
      await trip.editHotelInfo(this.tripId, payload)
      EventBus.$emit('refresh-reservation-detail')
      this.close()
    } catch (error) {
      console.error(error)
    }
    this.loading = false
  }
}
