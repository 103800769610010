// Uncomment next line to debug in Safari
// import devtools from '@vue/devtools'
import 'babel-polyfill'

// Initialize Datadog before anything else
import { initDatadog } from './utils/datadog'

// Vue and plugins
import Vue from 'vue'
import VuetifyConfirm from 'vuetify-confirm'
import VueTheMask from 'vue-the-mask'
import vuetify from '@/plugins/vuetify'
import i18n from '@/plugins/i18n'
import router from '@/router'
import ga4 from '@/plugins/ga4'
import cuLibrary from '@/plugins/cuLibrary'
import dayjs from '@/plugins/dayjs'
import '@/plugins/split'
import '@/plugins/auth0'
import LoadScript from 'vue-plugin-load-script'

// Global styles
import '@/scss/main.scss'

// Other plugins
import '@/plugins/axios'
import '@/plugins/gmaps'

// App component and store modules
import App from '@/App.vue'
import modules from '@/store/modules'

// Scoped slots hack
import applyScopedSlotsHack from '@/plugins/scopedSlotsHack'

import { SplitFeatureFlag, SplitTrafficType } from "@/utils/enum";
import { load } from "@/utils/localStorage";
import { getTokenProvider, TokenProvider } from '@/services/authTokenRepository'

// before anything else, initialize datadog integration
initDatadog()

// Configurations
Vue.prototype.$dayjs = dayjs
Vue.config.productionTip = false

// Use plugins
Vue.use(LoadScript)
Vue.use(ga4)
Vue.use(VuetifyConfirm, { vuetify })
Vue.use(VueTheMask)
Vue.use(cuLibrary)

// Apply scoped slots hack
applyScopedSlotsHack(Vue)

const initApp = async (): Promise<void> => {
  // Initializes Split client with logged-in user ID
  try {
    const userId = load('userId')
    if (!userId) {
      await Vue.prototype.$split.initClient()
    } else {
      await Vue.prototype.$split.updateKey(userId.toString(), SplitTrafficType.User)
    }
  } catch (e) {
    console.warn('Failed to initialize Split client', e)
    await Vue.prototype.$split.initClient()
  }

  const isAuth0Enabled = await Vue.prototype.$split.isFeatureEnabled(SplitFeatureFlag.Auth0SignIn)
  const currentTokenProvider = getTokenProvider()

  // Ensure the Auth0 client is fully initialized before validating the user or starting the router.
  // Initialize the Auth0 client if Auth0 is enabled or the current token provider is Auth0.
  // This ensures proper functionality if the feature flag is overridden or if the flag changes while a user is active.
  // TODO: this conditional can be removed enitrely once the Auth0 feature flag is removed as we will always initialize Auth0.
  if (isAuth0Enabled || currentTokenProvider === TokenProvider.Auth0) {
    await Vue.prototype.$auth0.init();
  }

  // Initialize the Vue instance
  new Vue({
    router,
    i18n,
    vuetify,
    async created() {
      modules.locale.setLocale(modules.locale.locale)
      if (modules.auth.isTokenSet) {
        modules.auth.autoLogin()
      }
    },
    render: (h) => h(App),
  }).$mount('#app')
}

initApp()
