
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import customerAccount from '@/services/customerAccount'
import { Team } from '@/models/dto'
import axios from 'axios'
import AlertBox from '@/components/AlertBox.vue'

@Component({ components: { AlertBox } })
export default class OrganizationInviteUserTeamDialog extends Vue {
  @Prop({ type: Array, default: () => [] }) readonly teams: Team[]
  @Prop({ type: Object, default: () => {} }) readonly startingTeam: Team
  @Prop({ type: Boolean }) readonly displayInList: boolean

  @Watch('dialog')
  dialogChanged() {
    this.resetValues()
    this.setup()
  }

  formIsValid = false
  dialog = false
  success = false
  errorMessage = ''
  formData: Record<string, { value: string; rules: any[] }> = {
    firstName: {
      value: '',
      rules: [(v) => !!v || 'First name is required'],
    },
    lastName: {
      value: '',
      rules: [(v) => !!v || 'Last name is required'],
    },
    email: {
      value: '',
      rules: [
        (v) => !!v || 'E-mail is required',
        (v) => /.+@.+/.test(v) || 'E-mail must be valid',
      ],
    },
    title: {
      value: '',
      rules: [(v) => !!v || 'Title name is required'],
    },
    team: {
      value: '',
      rules: [(v) => !!v || 'Title name is required'],
    },
  }

  mounted(): void {
    this.setup()
  }
  setup(): void {
    if (this.startingTeam) {
      this.success = false
      this.formData.team.value = this.startingTeam.customerAccountId.toString()
    }
  }
  resetValues(): void {
    const form = this.$refs.form as any
    form.reset()
  }

  backToSite(): void {
    this.success = false
    this.setup()
    this.dialog = false
  }
  async send(): Promise<void> {
    const form = this.$refs.form as any
    if (!form.validate()) {
      return
    }
    this.errorMessage = ''
    const payload = {
      firstName: this.formData.firstName.value,
      lastName: this.formData.lastName.value,
      email: this.formData.email.value,
      title: this.formData.title.value,
      customerAccountId: parseInt(this.formData.team.value),
      role: 1,
    }
    try {
      await customerAccount.invite(payload)
      this.success = true
      this.dialog = false
      this.$emit('success')
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const errorMessage = e.response?.data?.message
        this.errorMessage = errorMessage
      }
    }
  }
}
