
import { Vue, Component, Prop } from 'vue-property-decorator'
import { OperatorDetailCompany } from '../models/dto'
import systemParameter from '../store/modules/systemParameter'
import ViewPdfModal from '@/components/ViewPdfModal.vue'

@Component({ components: { ViewPdfModal } })
export default class ProviderInfoInsurance extends Vue {
  @Prop({ type: Object, required: true })
  readonly company!: OperatorDetailCompany

  showInsuranceModal = false

  get insuranceUrl(): string {
    return systemParameter.insuranceUrl
  }

  get insurancePolicy(): string {
    return this.company?.insurancePolicy
  }
}
