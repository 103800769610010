import Vue from 'vue'
import { AxiosRequestConfig } from 'axios'
import jwtDecode from 'jwt-decode'
import { load, save } from '@/utils/localStorage'
import { hoursToSeconds, secondsFromNow } from '@/utils/datetime'

type Token = {
  provider: TokenProvider
  value: string
}

export enum TokenProvider {
  Auth0 = 'auth0',
  Legacy = 'legacy',
}

/**
 * Adds a bearer token to the authorization header for outgoing requests.
 *
 * @returns {Promise<boolean>} - A promise that returns an Axios request configuration object.
 */
export const setRequestConfig = async (
  config: AxiosRequestConfig
): Promise<AxiosRequestConfig> => {
  const token = await getCurrentAuthToken()
  // If there is an existing token, add it to the request headers
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  return config
}

/**
 * Stores the token in local storage. If it's from a legacy provider, it will be stored differently.
 */
export const saveAuthToken = (token: string, legacy: boolean = false): void => {
  const newToken: Token = {
    provider: legacy ? TokenProvider.Legacy : TokenProvider.Auth0,
    value: token,
  }
  save('token', newToken)
}

/**
 * Retrieves the current authorization token from local storage.
 * Legacy tokens are given priority if they exist, otherwise the Auth0 token is used.
 * Auth0 tokens that are expiring within an hour will be refreshed.
 *
 * @returns {Promise<string | null>} - A promise that returns the current token or null if no token was found.
 */
export const getCurrentAuthToken = async (): Promise<string | null> => {
  const token = loadTokenFromLocalStorage()

  if (!token) {
    return null
  }

  const { value, provider } = token
  // If we have a legacy token, still return it
  if (provider === TokenProvider.Legacy) {
    return value
  }

  const decoded: any = jwtDecode(value)

  // if the token does not have an expiration, return it as is
  const expiration = decoded?.exp
  if (!expiration) {
    return value
  }

  // If we've assigned a token that's expiring in an hour, refresh it
  const expiresInHour = secondsFromNow(expiration) < hoursToSeconds(1)
  if (!expiresInHour) {
    return value
  }

  const didTokenRefresh = await refreshAuthToken()
  if (!didTokenRefresh) {
    return value
  }

  // at this point, it must be Token since we refreshed it and saved it ourselves.
  return loadTokenValue()
}

/**
 * Attempts to refresh the current access token and save it to local storage using the session's refresh token.
 * https://auth0.com/docs/authenticate/login/configure-silent-authentication
 *
 * @returns {Promise<boolean>} - A promise that resolves to `true` if the refresh succeeds,
 * or `false` if the refresh is unsuccessful.
 */
export const refreshAuthToken = async (): Promise<boolean> => {
  try {
    if (!Vue.prototype.$auth0.isInitialized) {
      throw new Error('Auth0 client not initialized')
    }
    const refreshed = await Vue.prototype.$auth0.getTokenSilently()
    saveAuthToken(refreshed)
    return true
  } catch (error) {
    // Something went wrong with refreshing, likely the refresh token expired
    console.error('Error refreshing token:', error)
    return false
  }
}

/**
 * Checks if a token value exists.
 *
 * @returns {boolean} True if a token value exists, false otherwise.
 */
export const hasTokenValue = (): boolean => {
  return !!loadTokenValue()
}

/**
 * Loads the token value from local storage.
 *
 * This function attempts to retrieve a token from local storage. If the token is a string,
 * it returns the token directly. If the token is an object, it returns the `value` property
 * of the token object. If no token is found, it returns `null`.
 *
 * @returns {string | null} The token value if found, otherwise `null`.
 */
export const loadTokenValue = (): string | null => {
  const token = loadTokenFromLocalStorage()
  return token?.value || null
}

/**
 * Loads the authentication token from local storage.
 *
 * If the token is in the old string format, it is automatically converted
 * and saved in the new object format.
 *
 * @returns {Token | null} The token retrieved from local storage in the correct format, or null if no token is found.
 */
export const loadTokenFromLocalStorage = (): Token | null => {
  let token = load('token')

  if (!token) {
    return null
  }

  // If the token is a string, treat it as a legacy format and re-save as an object
  if (typeof token === 'string') {
    saveAuthToken(token, true)
    token = load('token')
  }

  return token as Token
}

export const getTokenProvider = (): TokenProvider | null => {
  const token = loadTokenFromLocalStorage()
  return token?.provider || null
}
