
import { ReservationDetail } from '@/models/dto'
import support from '@/store/modules/support'
import { phoneFormatFilterHyphens, getSupportNumber } from '@/utils/phone'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class ReservationDetailSupport extends Vue {
  @Prop({ type: Object, required: true }) reservation!: ReservationDetail

  support = support
  get supportNumber(): string {
    return getSupportNumber(this.reservation.isElite)
  }
  get formattedSupportNumber(): string {
    return `1-${phoneFormatFilterHyphens(this.supportNumber)}`
  }

  get supportEmail(): string {
    return 'support@charterup.com'
  }

  get formattedSupportEmail(): string {
    return `CharterUP Support<${this.supportEmail}>?subject=Reservation ${this.reservation.managedId}`
  }
}
