
import { CancellationConfirmation, SimpleReservation } from '@/models/dto'
import { phoneRaw } from '@/utils/phone'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { PaymentMethodTypeLabel } from '@/utils/enum'

@Component({})
export default class CancellationForm extends Vue {
  @Prop({ type: Object, required: true }) reservation!: SimpleReservation
  @Prop({ type: Boolean, required: false }) submitting!: boolean

  didRequesterAgreeToTerms = null
  email = ''
  isUserAuthorizedToCancel = null
  paymentMethod = ''
  phone = ''
  requesterInitials = ''
  requesterName = ''

  get disabled(): boolean {
    return !(
      this.email &&
      this.paymentMethod &&
      this.phone &&
      this.requesterInitials &&
      this.requesterName &&
      (this.didRequesterAgreeToTerms || this.isUserAuthorizedToCancel)
    )
  }

  get managedId(): string {
    return this.reservation?.managedId
  }

  get termsAgreementDate(): string {
    const date = this.$dayjs(this.reservation?.createdOn)
    if (!date) {
      return ''
    }
    return date.format('MM/DD/YYYY')
  }

  get currentDate(): string {
    return this.$dayjs().format('MM/DD/YYYY')
  }

  get currentTimestamp(): string {
    return this.$dayjs().format('MM/DD/YYYY hh:mm A z')
  }

  get phoneRaw(): string {
    return phoneRaw(this.phone)
  }

  get paymentMethods(): string[] {
    return Object.values(PaymentMethodTypeLabel)
  }

  buildPayload(): CancellationConfirmation {
    const reservationId = this.reservation?.reservationId
    const parentTicketId = this.reservation?.cancellationTicketId
    const payload = {
      reservationId,
      parentTicketId,
      requesterName: this.requesterName,
      requesterPhone: this.phone,
      requesterEmail: this.email,
      paymentMethod: this.paymentMethod,
      didRequesterAgreeToTerms: this.didRequesterAgreeToTerms,
      isUserAuthorizedToCancel: this.isUserAuthorizedToCancel,
      requesterInitials: this.requesterInitials,
      createdOn: null,
      cancellationConfirmationId: null,
    }
    return payload
  }

  async submit(): Promise<void> {
    const valid = (this.$refs.cancellationForm as any).validate()
    if (!valid) {
      return
    }
    const payload = this.buildPayload()
    this.$emit('submit', payload)
  }
}
