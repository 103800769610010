
import { Vue, Component, Prop } from 'vue-property-decorator'
import OrganizationEditTeamDialog from '@/components/OrganizationEditTeamDialog.vue'
import OrganizationDeleteTeamDialog from '@/components/OrganizationDeleteTeamDialog.vue'
import OrganizationInviteUserTeamDialog from '@/components/OrganizationInviteUserTeamDialog.vue'
import { Team } from '@/models/dto'
@Component({
  components: {
    OrganizationEditTeamDialog,
    OrganizationDeleteTeamDialog,
    OrganizationInviteUserTeamDialog,
  },
})
export default class OrganizationTeamActionsMenu extends Vue {
  @Prop({ type: Object, required: true }) readonly team: Team
  @Prop({ type: Array, required: true }) readonly teams: Team[]
  @Prop({ type: Number, required: true }) readonly index: number

  teamOptionsMenuOpen = false
}
