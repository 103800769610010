import { render, staticRenderFns } from "./RecommendedBanner.vue?vue&type=template&id=4da7779d&scoped=true"
var script = {}
import style0 from "./RecommendedBanner.vue?vue&type=style&index=0&id=4da7779d&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4da7779d",
  null
  
)

export default component.exports