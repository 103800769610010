var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"d-flex flex-row justify-space-between align-start"},[(_vm.$vuetify.breakpoint.smAndUp)?_c('div',{staticClass:"d-flex flex-column border-radius-regular padding-x-1",class:{
      'background-modified-trip': _vm.vehiclesChanged,
    }},_vm._l((_vm.vehicles),function(vehicle,vehicleIndex){return _c('div',{key:`${vehicle.vehicleTypeKey}-${vehicleIndex}`,attrs:{"id":`trip-info-vehicle-${vehicleIndex}-content`}},[_vm._v(" "+_vm._s(_vm.vehiclesPretty(vehicle.vehicleTypeKey, vehicle.quantity))+" ")])}),0):_c('div',{staticClass:"border-radius-regular padding-x-1",class:{
      'background-modified-trip': _vm.vehiclesChanged,
    }},[_vm._v(" "+_vm._s(_vm.formattedVehiclesList)+" ")]),_c('span',{staticClass:"border-radius-regular padding-x-1",class:{
      'background-modified-trip': _vm.passengerCountChanged,
    },attrs:{"id":"trip-info-passenger-count"}},[_vm._v(" "+_vm._s(_vm.tripPassengerCount)+" ")]),_c('span',{staticClass:"border-radius-regular padding-x-1",class:{
      'background-modified-trip': _vm.tripTypeChanged,
    }},[_vm._v(" "+_vm._s(_vm.tripTypeTitle)+" ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }