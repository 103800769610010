const components = [
  'CUIcon',
  'CUModal',
  'CUTextField',
  'CUSkeletonLoader',
  'CUSkeletonLoaderTableView',
  'CUDataTable',
  'CUSelect',
  'CUCard'
]

const cuLibrary = {
  install(Vue) {
    components.forEach(component => {
      const componentConfig = require(`@/components/${component}.vue`).default
      Vue.component(component, componentConfig)
    })
  }
}

export default cuLibrary
