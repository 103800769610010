// src/utils/scopedSlotsHack.ts
import Vue from 'vue'

export default function applyScopedSlotsHack(Vue): void {
  Vue.prototype._b = (function (bind) {
    return function (data, tag, value) {
      if (value && value.$scopedSlots) {
        data.scopedSlots = value.$scopedSlots
        delete value.$scopedSlots
      }
      // eslint-disable-next-line prefer-rest-params
      return bind.apply(this, arguments)
    }
  })(Vue.prototype._b)
}
