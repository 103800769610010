
import { Vue, Component, Prop } from 'vue-property-decorator'
import { TripVehicle, WizardVehicle } from '@/models/dto'
import { VehicleTypeKey, VehicleTypeLabel } from '@/utils/enum';
import { vehicleTypeKeyToLabel } from '@/utils/vehicle';

@Component
export default class VehicleOptionVehicleList extends Vue {
  @Prop({ type: Array, required: true }) readonly vehicles: WizardVehicle[]

  get vehicleTypes(): { label: VehicleTypeLabel; quantity: number }[] {
    const typeQuantityMap = new Map<VehicleTypeKey, { label: VehicleTypeLabel; quantity: number }>()

    this.vehicles.forEach(({ vehicleTypeKey, quantity }) => {
      if (!typeQuantityMap.has(vehicleTypeKey)) {
        typeQuantityMap.set(vehicleTypeKey, { label: vehicleTypeKeyToLabel(vehicleTypeKey), quantity })
      }
    })

    return Array.from(typeQuantityMap.values())
  }
}
