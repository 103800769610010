import Vue, { VueConstructor } from 'vue'
import SplitIO from '@splitsoftware/splitio/types/splitio'
import { SplitFactory } from '@splitsoftware/splitio'
import {
  SplitTrafficType,
  SplitFeatureFlag,
  SplitKeyType,
  SplitEvents,
} from '@/utils/enum'
import { datadogRum } from '@datadog/browser-rum'
import { splitKey } from '@/utils/env'
import { resolveEnvironment } from "@/utils/env";

let instance: Vue | null = null

/** Returns the current instance of the SDK */
export const useSplit = (): Vue => {
  /** Creates an instance of the Split SDK. If one has already been created, it returns that instance */
  if (instance) {
    return instance
  }
  // The 'instance' is simply a Vue object
  instance = new Vue({
    data() {
      return {
        ready: false,
        key: SplitKeyType.AnonymousUser,
        trafficType: SplitTrafficType.Anonymous,
        attributes: {},
        client: null,
      }
    },
    methods: {
      /** Use this method to instantiate the SDK client */
      async initClient(): Promise<void> {
        // Client must be destroyed before reinitializing
        if (this.client) {
          await this.destroyClient()
        }

        const splitFactory = this.createFactory()
        try {
          const client = splitFactory.client(this.key, this.trafficType)
          await client.ready().catch((e) => {
            throw e
          })
          this.client = client
          this.ready = true
        } catch (e) {
          console.warn(e)
        }
      },
      async destroyClient(): Promise<void> {
        await this.client.destroy()
        this.client = null
        this.ready = false
      },
      createFactory(): SplitIO.ISDK {
        const authorizationKey = splitKey()
        const environment = resolveEnvironment()

        // Might want to swap back to polling if error logs show up again
        return SplitFactory({
          streamingEnabled: true,
          debug: environment === 'local',
          core: {
            authorizationKey,
            trafficType: this.trafficType,
            key: this.key,
          },
          startup: {
            readyTimeout: 1.5,
          },
          impressionListener: {
            logImpression(impressionData) {
              datadogRum.addFeatureFlagEvaluation(
                impressionData.impression.feature,
                impressionData.impression.treatment
              )
            },
          },
        })
      },
      /**
       * Check whether a feature flag's treatment is set to the value of on.
       * @param {SplitFeatureFlag} flag - The feature flag to check.
       * @returns {boolean} Whether the feature flag is enabled or not.
       */
      async isFeatureEnabled(flag: SplitFeatureFlag): Promise<boolean> {
        try {
          await this.client.ready().catch((e) => {
            throw e
          })
          // SDK is now ready
          const treatment = await this.client.getTreatment(
            flag,
            this._attributes
          )
          return treatment === 'on'
        } catch (e) {
          console.error('Split SDK has timed out getting treatment for', flag)
          return false
        }
      },
      /**
       * Gets the dynamically configured treatment for a given feature flag.
       * https://help.split.io/hc/en-us/articles/360020448791-JavaScript-SDK#get-treatments-with-configurations
       * @param {SplitFeatureFlag} flag - The feature flag to check.
       * @returns {SplitIO.TreatmentWithConfig} The treatment object.
       */
      async getTreatmentWithConfig(
        flag: SplitFeatureFlag
      ): Promise<SplitIO.TreatmentWithConfig> {
        try {
          await this.client.ready().catch((e) => {
            throw e
          })
          // SDK is now ready
          return await this.client.getTreatmentWithConfig(
            flag,
            this.attributes
          )
        } catch (e) {
          console.error('Split SDK has timed out getting treatment for', flag)
          return null
        }
      },
      /**
       * Updates the current Split key and optionally the traffic type.
       * https://help.split.io/hc/en-us/articles/9648555765133-Foundational-concepts#traffic-type
       * @param {string} key - The unique key that is targeted via traffic type.
       * @param {SplitTrafficType=} trafficType - The traffic type to update. Defaults to SplitTrafficType.User.
       * @returns void
       */
      async updateKey(
        key: string,
        trafficType?: SplitTrafficType
      ): Promise<void> {
        if (!key) {
          throw new Error('Invalid Split key')
        }

        let defaultTrafficType =
          key === SplitKeyType.AnonymousUser
            ? SplitTrafficType.Anonymous
            : SplitTrafficType.User

        // Always confirm that the key matches your intended traffic type
        this.key = key
        this.trafficType = trafficType || defaultTrafficType
        console.debug(
          `Set Split key ${this.key} for traffic type ${this.trafficType}`
        )

        await this.initClient()
      },
      /**
       * Records a user action corresponding to an event type.
       * https://help.split.io/hc/en-us/articles/360020448791-JavaScript-SDK#track
       * @param {string} eventType - The event type corresponding to this event.
       * @param {number=} value - The value of this event.
       * @param {Properties=} properties - The properties of this event. Values can be string, number, boolean or null.
       * @returns {boolean} Whether the event was queued to be sent to Split successfully or not.
       */
      async track(
        eventType: SplitEvents,
        value?: number,
        properties?: SplitIO.Properties
      ): Promise<boolean> {
        try {
          await this.client.ready().catch((e) => {
            throw e
          })
          // SDK is now ready
          return await this.client.track(eventType, value, properties)
        } catch (e) {
          console.error('Split SDK has timed out tracking', eventType)
          return false
        }
      },
    },
  })
  return instance
}

export const SplitPlugin = {
  install(Vue: VueConstructor): void {
    Vue.prototype.$split = useSplit()
  },
}

Vue.use(SplitPlugin)
