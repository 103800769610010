import Vue from 'vue'
import * as VueGoogleMaps from 'vue2-google-maps'

// FIXME: env?
const API_KEY = 'AIzaSyDO28NAxNFD_KrMmGhr7cJIujlNO3-PcJM'

Vue.use(VueGoogleMaps, {
  load: {
    key: API_KEY,
    installComponents: true,
  },
})
