
import { ValuePropKey } from '@/utils/enum'
import { Vue, Component, Prop, Ref } from 'vue-property-decorator'
import CharterUPValueProp from '@/components/CharterUPValueProp.vue'

@Component({
  name: 'CharterUPValueProps',
  components: { CharterUPValueProp },
})
export default class CharterUPValueProps extends Vue {
  @Prop({ type: Array, default: [] }) readonly valuePropKeys: ValuePropKey[]
  @Prop({ type: String, required: true }) readonly page!: string
  @Ref() readonly rowContainer!: HTMLDivElement
  private isDown = false
  private startX: number
  private scrollLeft: number

  get hasOddNumber() {
    return this.valuePropKeys.length % 2 === 1
  }

  get isMobile() {
    return this.$vuetify.breakpoint.xsOnly
  }

  calculateColumnWidth(index: number): number {
    // Early return for mobile view when there's more than one key.
    if (this.isMobile && this.valuePropKeys?.length > 1) {
      return 8
    }

    // For non-mobile or single-element arrays, handle odd/even number of keys.
    if (!this.hasOddNumber || index !== 0) {
      return 6 // Use 6 columns by default except for the first element in an odd count on non mobile
    }

    return 12 // The first item in an odd count gets 12 columns.
  }

  mounted() {
    this.triggerViewValuePropEvents()

    const el = this.rowContainer
    el.addEventListener('mousedown', (e) => {
      this.isDown = true
      this.startX = e.pageX - el.offsetLeft
      this.scrollLeft = el.scrollLeft
      el.classList.add('grabbing') // Add grabbing class to change cursor
    })

    el.addEventListener('mouseleave', () => {
      this.isDown = false
      el.classList.remove('grabbing') // Remove grabbing class
    })

    el.addEventListener('mouseup', () => {
      this.isDown = false
      el.classList.remove('grabbing') // Remove grabbing class
    })

    el.addEventListener('mousemove', (e) => {
      if (!this.isDown) {
        return
      }
      e.preventDefault()
      const x = e.pageX - el.offsetLeft
      const walk = x - this.startX
      el.scrollLeft = this.scrollLeft - walk
    })
  }

  triggerViewValuePropEvents(): void {
    for (const key of this.valuePropKeys) {
      this.trackView(key)
    }
  }

  trackView(key: ValuePropKey): void {
    const eventMap: Record<ValuePropKey, string> = {
      [ValuePropKey.BookingProtection]: 'view_booking_protection_value_prop',
      [ValuePropKey.Tracking]: 'view_vehicle_tracking_value_prop',
      [ValuePropKey.LiveSupport]: 'view_live_support_value_prop',
      [ValuePropKey.QualifiedOperators]: 'view_qualified_operators_value_prop',
    }

    this.$ga4Event(eventMap[key], {
      page: this.page,
      isSelfServe: false,
    })
  }
}
