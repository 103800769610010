import { SalesBotCustomer as SalesBotCustomerDTO } from '@/models/dto'

export class SalesBotCustomer implements SalesBotCustomerDTO {
  bookings = null
  email = ''
  firstName = ''
  lastName = ''
  phone = ''
  password = ''
  isQuoteSalesSMSEnabled = true
}
