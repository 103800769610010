
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import AutoCompleteAddress from '@/components/AutoCompleteAddress.vue'
import { getStates, getProvinces, SelectOption } from "@/utils/states";
import { v4 } from 'uuid'
import { AddressSearch } from '@/models/AddressSearch'
import { TranslateResult } from 'vue-i18n'

@Component({
  components: {
    AutoCompleteAddress,
  },
})
export default class AddressPicker extends Vue {
  @Prop({ type: Object, default: () => {} }) readonly value: any
  @Prop({ type: String, default: '' }) readonly label: string
  @Prop({ type: Boolean }) readonly disputedCheckout: boolean
  @Prop({ type: Boolean, default: false }) readonly isPaymentForm: boolean
  @Prop({ type: Boolean, required: false, default: false }) readonly required: boolean

  @Watch('address', { deep: true })
  addressChanged(): void {
    this.$emit('input', this.address)
  }

  @Watch('value', { deep: true })
  valueChanged(): void {
    if (this.disputedCheckout && !this.valueWatcherFired) {
      this.valueWatcherFired = true
      this.selectAddress({ place: this.value })
    }
  }

  valueWatcherFired = false
  addressSelected = false
  address: AddressSearch = {
    street1: null,
    street2: null,
    city: null,
    state: null,
    country: null,
    postalCode: null,
    completeAddress: null,
    addressName: null,
    lat: null,
    lng: null,
    timeZone: null,
  }

  get computedLabel(): TranslateResult {
    return this.addressSelected
      ? this.$t('addressPicker.FORM.STREET1.LABEL')
      : this.label || this.$t('addressPicker.FORM.STREET1.SEARCH_AND_SELECT')
  }

  get isUSAddress(): boolean {
    return this.address.country === 'US'
  }

  get isCanadaAddress(): boolean {
    return this.address.country === 'CA'
  }

  get stateLabel(): TranslateResult {
    if (this.isUSAddress) {
      return this.$t('addressPicker.FORM.STATE.LABEL')
    }
    if (this.isCanadaAddress) {
      return this.$t('addressPicker.FORM.PROVINCE.LABEL')
    }
    return this.$t('addressPicker.FORM.REGION.LABEL')
  }

  get postalCodeLabel(): TranslateResult {
    return this.isUSAddress
      ? this.$t('addressPicker.FORM.ZIP.LABEL')
      : this.$t('addressPicker.FORM.POSTAL.LABEL')
  }

  get stateItems(): Array<SelectOption> {
    if (this.isUSAddress) {
      return getStates()
    }
    if (this.isCanadaAddress) {
      return getProvinces()
    }
    return []
  }

  get stateErrorCopy(): TranslateResult {
    if (this.isUSAddress) {
      return this.$t('addressPicker.FORM.STATE.RULES.REQUIRED')
    }
    if (this.isCanadaAddress) {
      return this.$t('addressPicker.FORM.PROVINCE.RULES.REQUIRED')
    }
    return this.$t('addressPicker.FORM.REGION.RULES.REQUIRED')
  }

  selectAddress(address): void {
    const place = address.place
    this.address = {
      street1: place.street1,
      street2: '',
      city: place.city,
      state: place.state,
      country: place.country,
      postalCode: place.postalCode,
      completeAddress: place.completeAddress,
      addressName: place.addressName,
      lat: place.lat,
      lng: place.lng,
      timeZone: place.timeZone,
      initialSearch: place.street1,
    }
    this.addressSelected = true
  }

  clearAddress(): void {
    this.address = {
      street1: null,
      street2: null,
      city: null,
      state: null,
      country: null,
      postalCode: null,
      completeAddress: null,
      addressName: null,
      lat: null,
      lng: null,
      timeZone: null,
      initialSearch: null,
    }
    this.addressSelected = false
  }

  uuidv4(): any {
    return v4()
  }
}
