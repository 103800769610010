import { deepSortObject } from "./object"

/**
 * Compares two values and returns -1 if the first value is less than the second,
 * 1 if the first value is greater than the second, and 0 if they are equal.
 *
 * @param a - The first value to compare
 * @param b - The second value to compare
 * @returns -1 if a is less than b, 1 if a is greater than b, or 0 if they are equal
 */
export const defaultComparator = <T>(a: T, b: T) => {
  if (a < b) {
    return -1
  }
  if (a > b) {
    return 1
  }
  return 0
}

/**
 * Determines if the elements of two arrays are equal when sorted using the given compare function.
 *
 * @param arr1 - The first array to compare
 * @param arr2 - The second array to compare
 * @param compare - A compare function that takes two values of type T and returns -1, 1, or 0
 * @returns true if the arrays have the same elements, false otherwise
 */
export const doArraysElementsMatch = <T>(
  arr1: T[],
  arr2: T[],
  compare: (a: T, b: T) => number = defaultComparator
): boolean => {
  if (!Array.isArray(arr1) || !Array.isArray(arr2)) {
    return false
  }

  arr1.sort(compare)
  arr2.sort(compare)
  if (arr1.length !== arr2.length) {
    return false
  }
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false
    }
  }
  return true
}

/**
 * Removes a value from an array, if it exists in the array.
 *
 * @param arr - The array to modify
 * @param valueToRemove - The value to remove from the array
 * @returns The modified array
 */
export const removeFromArray = <T>(arr: T[], valueToRemove: T): T[] => {
  if (!Array.isArray(arr)) {
    return arr
  }

  const index = arr.indexOf(valueToRemove)
  if (index > -1) {
    arr.splice(index, 1)
  }
  return arr
}

/**
 * Compares two arrays deeply by sorting the items inside each array and their nested objects.
 * Ensures that the arrays contain the same items, regardless of order.
 *
 * @param arr1 - The first array to compare.
 * @param arr2 - The second array to compare.
 * @returns `true` if both arrays contain the same items (in any order), otherwise `false`.
 */
export const deepCompareArrays = (arr1: any[], arr2: any[]): boolean => {
  if (arr1.length !== arr2.length) {
    return false
  }

  const sortedArr1 = deepSortArray(arr1)
  const sortedArr2 = deepSortArray(arr2)

  return JSON.stringify(sortedArr1) === JSON.stringify(sortedArr2)
}

/**
 * Recursively sorts an array and its nested objects/arrays.
 * Ensures that all items in the array are sorted and normalized for comparison.
 *
 * @param arr - The array to sort.
 * @returns A new array with sorted elements. Nested objects and arrays are also sorted.
 */
export const deepSortArray = (arr: any[]): any[] => {
  return arr.map(item => deepSortObject(item)).sort((a, b) => JSON.stringify(a).localeCompare(JSON.stringify(b)))
}
