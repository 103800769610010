import flight from '@/store/modules/flight'
import support from '@/store/modules/support'
import { RiskTypeId, SupportTicketKey } from '@/utils/enum'
import { firstStopWithFlightRisk } from '@/utils/risk'
import { Stop } from './dto'

export class ActionableRisk {
  reservationId = null
  stops = null
  itineraryRequirementsDialogIsOpen = false
  hotelInformationDialogIsOpen = false
  driverHotelRequirementsDialogIsOpen = false

  constructor(reservationId?: number, stops?: Stop[]) {
    this.reservationId = reservationId
    this.stops = stops
  }

  setReservationId(reservationId: number) {
    this.reservationId = reservationId
  }

  setStops(stops: Stop[]) {
    this.stops = stops
  }

  riskObject(riskTypeId: number) {
    return {
      1: {
        riskTypeId: RiskTypeId.IncompleteAddress,
        riskText: 'incompleteStop',
        actionLabel: 'INCOMPLETE_ADDRESS',
        linkActionLabel: 'MESSAGE_US',
        linkInfoLabel: null,
        openActionModal: () => {
          support.open({
            reservationId: this.reservationId,
            ticketTypeKey: SupportTicketKey.Change,
          })
          return
        },
        openInfoModal: () => {
          this.itineraryRequirementsDialogIsOpen = true
          return
        },
      },
      2: {
        riskTypeId: RiskTypeId.PickupTimeMissing,
        riskText: 'incompleteStop',
        actionLabel: 'PICKUP_TIME_MISSING',
        linkActionLabel: 'MESSAGE_US',
        linkInfoLabel: null,
        openActionModal: () => {
          support.open({
            reservationId: this.reservationId,
            ticketTypeKey: SupportTicketKey.Change,
          })
          return
        },
        openInfoModal: () => {
          this.itineraryRequirementsDialogIsOpen = true
          return
        },
      },
      3: {
        riskTypeId: RiskTypeId.DriverHotelMissing,
        riskText: 'driverHotelMissing',
        actionLabel: 'DRIVER_HOTEL_MISSING',
        linkActionLabel: 'ADD',
        linkInfoLabel: 'reservationDetail.informationNeeded.REQUIREMENTS',
        openActionModal: () => {
          this.hotelInformationDialogIsOpen = true
          return
        },
        openInfoModal: () => {
          this.driverHotelRequirementsDialogIsOpen = true
          return
        },
      },
      4: {
        riskTypeId: RiskTypeId.FlightInfoMissing,
        riskText: 'flightInfoMissing',
        actionLabel: 'FLIGHT_INFO_MISSING',
        linkActionLabel: 'ADD',
        linkInfoLabel: null,
        openActionModal: () => {
          const stop = firstStopWithFlightRisk(this.stops)
          const flightInformation = stop?.flightInformation
          flight.open({ flightInformation, stop })
          return
        },
        openInfoModal: () => {},
      },
      6: {
        riskTypeId: RiskTypeId.IncompleteStop,
        riskText: 'incompleteStop',
        actionLabel: 'INCOMPLETE_ITINERARY',
        linkActionLabel: 'MESSAGE_US',
        linkInfoLabel: null,
        openActionModal: () => {
          support.open({
            reservationId: this.reservationId,
            ticketTypeKey: SupportTicketKey.Change,
          })
          return
        },
        openInfoModal: () => {
          this.itineraryRequirementsDialogIsOpen = true
          return
        },
      },
      7: {
        riskTypeId: RiskTypeId.MissingStop,
        riskText: 'incompleteStop',
        actionLabel: 'INCOMPLETE_ITINERARY',
        linkActionLabel: 'MESSAGE_US',
        linkInfoLabel: null,
        openActionModal: () => {
          support.open({
            reservationId: this.reservationId,
            ticketTypeKey: SupportTicketKey.Change,
          })
          return
        },
        openInfoModal: () => {
          this.itineraryRequirementsDialogIsOpen = true
          return
        },
      },
    }[riskTypeId]
  }
}
