
import { Vue, Component } from 'vue-property-decorator'
import SelfServeDefaultAmenities from './SelfServeDefaultAmenities.vue'
import AmenityCard from './AmenityCard.vue'
import { AmenityTypeId, TripEventTypeId, VehicleTypeId } from '@/utils/enum'
import { AmenityType } from '@/models/dto'
import selfServe from '@/store/modules/selfServe'
import { getAvailableAmenityTypesByVehicleTypeId } from '@/utils/amenity'

@Component({ components: { SelfServeDefaultAmenities, AmenityCard } })
export default class SelfServeAmenities extends Vue {
  submitting = false
  submitInterval: any = null

  get availableAmenities(): AmenityType[] {
    let availableAmenities = getAvailableAmenityTypesByVehicleTypeId(
      selfServe.availableAmenities,
      selfServe.tripVehicles[0].vehicleTypeId
    )

    const { tripEventTypeId } = selfServe.quote.trips[selfServe.currentTripIndex]
    if (tripEventTypeId === TripEventTypeId.K12) {
      availableAmenities = availableAmenities.filter(
        (amenity) => amenity.id !== AmenityTypeId.AlcoholConsumption
      )
    }

    return availableAmenities
  }

  get vehicleTypeCount(): number {
    const uniqueVehicles = new Set()
    const vehicles = selfServe.tripVehicles
    for (const vehicle of vehicles) {
      uniqueVehicles.add(vehicle.vehicleTypeId)
    }
    return uniqueVehicles.size
  }

  get hasCharterBus(): boolean {
    return selfServe.tripVehicles.some(
      (vehicle) => vehicle.vehicleTypeId === VehicleTypeId.CharterBus
    )
  }

  get isVehicleCombination(): boolean {
    return this.vehicleTypeCount > 1
  }

  get displayThreeAmenities(): boolean {
    return this.availableAmenities?.length === 3
  }

  beforeDestroy() {
    clearInterval(this.submitInterval)
  }

  async submit(): Promise<void> {
    try {
      this.submitting = true
      await selfServe.submitSelfServeQuote()
      this.pollForQuoteDetail()
    } catch (err) {
      console.error(err)
      this.submitting = false
    }
  }

  pollForQuoteDetail() {
    this.submitInterval = setInterval(async () => {
      await selfServe.getQuoteDetailAndRedirect()
    }, 1000)
  }
}
