
import { Vue, Component, Prop } from 'vue-property-decorator'
import CUSkeletonLoaderTextField from '@/components/CUSkeletonLoaderTextField.vue'

@Component({ components: { CUSkeletonLoaderTextField } })
export default class CUTextField extends Vue {
  @Prop({ type: Boolean, required: false, default: false }) dense!: boolean
  @Prop({ required: false, default: false, type: Boolean }) skeletonLoader: boolean
  @Prop({ type: Boolean, required: false, default: false }) readonly required: boolean
  @Prop({ type: Number, required: false, default: null }) readonly tabIndex: number
  @Prop({ type: Boolean, required: false, default: false }) readonly isFinix: boolean

  get listeners(): Record<string, unknown> {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const vm = this
    return Object.assign({}, this.$listeners, {
      input(event: InputEvent) {
        vm.$emit('input', event)
      },
    })
  }
}
