
import { Vue, Component, Prop } from 'vue-property-decorator'
import { ClientCancellationPenaltyInfo } from '@/models/dto'
import { currencyFilter } from '../utils/string'
import { TranslateResult } from 'vue-i18n'
@Component({})
export default class ReservationCancellationSummaryCharges extends Vue {
  @Prop({ type: Object, required: true })
  readonly penaltyInfo!: ClientCancellationPenaltyInfo

  get bookingAmount(): string {
    return currencyFilter(this.penaltyInfo?.amount)
  }

  get paidAmount(): string {
    return currencyFilter(this.penaltyInfo?.paid)
  }

  get cancellationAmount(): string {
    return currencyFilter(this.penaltyInfo?.cancellationFee)
  }

  get totalText(): TranslateResult {
    return this.penaltyInfo?.balanceDue < 0
      ? this.$t('reservationDetail.cancellationSummary.REFUND')
      : this.$t('reservationDetail.cancellationSummary.BALANCE_DUE')
  }

  get totalAmount(): string {
    return this.penaltyInfo?.balanceDue < 0
      ? currencyFilter(-this.penaltyInfo?.balanceDue)
      : currencyFilter(this.penaltyInfo?.balanceDue)
  }
}
