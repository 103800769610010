import { baseUrl } from '@/utils/env'
import { CreateTicketParams, CreateTicketPayload } from '@/models/dto'
import { HttpService } from './common/HttpService'

const httpService: HttpService = new HttpService()

export default {
  /**
   * Creates a ticket with the given parameters.
   * @param params - The parameters to use for creating the ticket.
   * @returns A promise that resolves to the result of creating the ticket.
   */
  create(params: CreateTicketParams) {
    const {
      comments,
      title,
      ticketTypeId,
      ticketSeverityTypeId,
      createdById,
      creatorFirstName,
      creatorLastName,
      creatorEmail,
    } = params

    const payload: CreateTicketPayload = {
      companyId: 2,
      ticketStatusTypeId: '1',
      comments,
      ticketSeverityTypeId,
      ticketTypeId,
      createdById,
      creatorFirstName,
      creatorLastName,
      creatorEmail,
      title: `CharterUP - ${title}`,
    }
    const { reservationId, managedId, quoteId } = params
    if (reservationId) {
      payload.reservationId = reservationId
    }
    if (managedId) {
      payload.managedId = managedId
    }
    if (quoteId) {
      payload.quoteId = quoteId
    }
    const host = baseUrl()
    const url = `https://${host}/v2/tickets/customers`
    return httpService.post(url, payload)
  },
}
