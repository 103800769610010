
import { Vue, Component, Prop } from 'vue-property-decorator'
import { QuoteDetailV2 } from '../models/dto/QuoteDetailV2'

@Component({})
export default class CheckoutTripSplitNotice extends Vue {
  @Prop({ default: null }) quoteDetail!: QuoteDetailV2
  @Prop({ type: Boolean, required: false, default: false })
  readonly fullWidth!: boolean
}
