
import { ReservationReview } from '@/models/dto'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import PickupSatisfactionSelector from '@/components/PickupSatisfactionSelector.vue'
import deepClone from '@/utils/deepClone'

@Component({ components: { PickupSatisfactionSelector } })
export default class PickupReviewModal extends Vue {
  @Prop({ type: Object, required: true }) readonly review: ReservationReview
  @Prop({ type: Boolean, required: true }) readonly open: boolean
  @Prop({ type: Boolean, required: true })
  readonly isPickupAlreadyReviewed: boolean

  pickupReview = ''
  pickupPositive = null

  get isNull(): boolean {
    return this.pickupPositive === null
  }

  get isNegative(): boolean {
    return this.pickupPositive === false
  }

  get isPositive(): boolean {
    return !!this.pickupPositive
  }

  get response(): string {
    if (this.isNegative) {
      return "We're sorry to hear your pickup wasn't great. Please tell us what happened."
    }
    if (this.isPositive) {
      return 'Thanks for your feedback! We hope you enjoy the rest of your trip.'
    }
    return ''
  }

  @Watch('review.pickupPositive', { immediate: true })
  onPickupPositiveChange(pickupPositive: boolean): void {
    this.pickupPositive = pickupPositive
  }

  save(): void {
    const review = deepClone(this.review)
    review.pickupReview = this.pickupReview
    review.pickupPositive = this.pickupPositive
    this.$emit('update:review', review)
    this.close()
  }

  close(): void {
    this.$emit('update:open', false)
  }
}
