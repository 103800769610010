
import { Component, Vue, Prop } from 'vue-property-decorator'
import { addressPrettyFromStop, formatDisplayTime } from '@/utils/string'
import { TrackingStop } from '../models/dto/TrackingStop'
import tracking from '@/store/modules/tracking'

const SINGLE_DAY_DATETIME_FORMAT = "h:mm a"
const MULTI_DAY_DATETIME_FORMAT = "hh:mm a '–' LLL d"

@Component
export default class LiveTrackingItineraryStop extends Vue {
  @Prop({ type: Object, required: true }) stop!: TrackingStop
  @Prop({ type: Number, required: true }) index!: number
  @Prop({ type: Object, required: true }) statusDetails: any
  @Prop({ type: String, required: true }) color: string
  @Prop({ type: Boolean, required: true }) isMultiDayTrip: boolean


  tracking = tracking
  addressPrettyFromStop = addressPrettyFromStop
  formatDisplayTime = formatDisplayTime
  showDetails = false

  get dropoffDatetime(): string {
    return this.stop.dropoffDatetime
  }

  get pickupDatetime(): string {
    return this.stop.pickupDatetime
  }

  get reservationStatus(): string {
    return tracking.reservation?.reservationStatus
  }

  get showEtaPill(): boolean {
    return (
      tracking.isNext(this.stop) &&
      tracking.isEnRoute &&
      !['upcoming', 'hold'].includes(this.reservationStatus) &&
      !!tracking.etaCountdownText &&
      !tracking.isFinished &&
      !tracking.isComplete(this.stop) &&
      !this.statusDetails?.isAtAnyStop &&
      (this.statusDetails?.isNextStopFirstStop
        ? this.statusDetails?.isPickupEtaOnSchedule
        : true)
    )
  }

  get displayFormat(): string {
    if (this.isMultiDayTrip) {
      return MULTI_DAY_DATETIME_FORMAT
    }
    return SINGLE_DAY_DATETIME_FORMAT
  }
}
