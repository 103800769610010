
import { Vue, Component, Prop } from 'vue-property-decorator'
import { VehiclePhoto } from '@/models/dto'
import { KeyCode } from '@/utils/enum'

@Component({})
export default class Carousel extends Vue {
  @Prop({ type: Number, default: 0 }) readonly value: number
  @Prop({ type: Array, default: () => [] }) readonly photos: VehiclePhoto[]

  get currentImage(): VehiclePhoto {
    return this.photos?.[this.value]
  }
  get thumbnailCount(): number {
    if (this.$vuetify.breakpoint.xl) {
      return 10
    }
    if (this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.md) {
      return 6
    }
    if (this.$vuetify.breakpoint.sm) {
      return 4
    }
    return 3
  }

  get imageLabel(): string {
    return this.currentImage?.label || ''
  }

  get visibleThumbnails(): VehiclePhoto[] {
    if (this.photos.length <= this.thumbnailCount) {
      return this.photos
    }
    if (this.photos.length - this.value >= this.thumbnailCount) {
      return this.photos.slice(this.value, this.value + this.thumbnailCount)
    }
    const backfillCount =
      this.thumbnailCount - (this.photos.length - this.value)
    const backfill = this.photos.slice(this.value - backfillCount, this.value)
    const frontfill = this.photos.slice(this.value, this.photos.length)
    return backfill.concat(frontfill)
  }

  mounted(): void {
    document.addEventListener('keydown', this.handleArrowKey)
  }

  destroyed(): void {
    document.removeEventListener('keydown', this.handleArrowKey)
  }

  handleArrowKey(e): void {
    let emitValue = null
    if (e.keyCode === KeyCode.RightArrow) {
      emitValue = this.value >= this.photos.length - 1 ? 0 : this.value + 1
    } else if (e.keyCode === KeyCode.LeftArrow) {
      emitValue = this.value <= 0 ? this.photos.length - 1 : this.value - 1
    }
    this.$emit('input', emitValue)
  }

  selectPhoto(photo): void {
    this.$emit('input', this.photos.indexOf(photo))
  }

  extractVehiclePhotoCharterUpId(photo: VehiclePhoto): number | undefined {
    return photo?.vehiclePhotoCharterUpId;
  }
}
