
import { Vue, Prop, Component } from 'vue-property-decorator'
import { Bar } from 'vue-chartjs/legacy'
import ChartDataLabels from 'chartjs-plugin-datalabels'

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from 'chart.js'
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

@Component({ components: { Bar } })
export default class CUBarChart extends Vue {
  @Prop({ type: String, default: 'bar-chart' }) readonly chartId!: string
  @Prop({ type: String, default: 'label' }) readonly datasetIdKey!: string
  @Prop({ type: [Number, String], default: 400 }) readonly width!:
    | number
    | string
  @Prop({ type: [Number, String], default: 400 }) readonly height!:
    | number
    | string
  @Prop({ type: String, default: '' }) readonly cssClasses!: string
  @Prop({
    type: Object,
    default: () => {
      return {}
    },
  })
  readonly styles!: Record<string, unknown>
  @Prop({
    type: Object,
    default: () => {
      return {}
    },
  })
  readonly chartOptions!: Record<string, unknown>
  @Prop({
    type: Object,
    default: () => {
      return {}
    },
  })
  readonly chartData!: Record<string, unknown>
  @Prop({ type: Array, default: () => [] }) readonly plugins!: any[]

  chartDataLabels = ChartDataLabels
}
