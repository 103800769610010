
import EnterpriseTrackingSidebarItineraryCardSkeleton from '@/components/EnterpriseTrackingSidebarItineraryCardSkeleton.vue'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  components: {
    EnterpriseTrackingSidebarItineraryCardSkeleton,
  },
})
export default class EnterpriseTrackingSidebarItineraryItemSkeleton extends Vue {
  @Prop({ type: String, default: '#00a6f2' }) readonly color: string

  get cssVariables() {
    return {
      '--stop-icon': `url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 42 51' style='enable-background:new 0 0 42 51;' xml:space='preserve'><path fill='${this.color.replace(
        '#',
        '%23'
      )}' d='M21,0.2c-11.6,0-21,9.4-21,21C0,27.4,2.7,33,7.1,36.9L21,50.8l13.9-13.9C39.3,33,42,27.4,42,21.2 C42,9.6,32.6,0.2,21,0.2z'/><circle fill='black' fill-opacity='0.25' cx='21' cy='21.2' r='6.3'/></svg>")`,
    }
  }
}
