
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import rider from '@/services/rider'
import auth from '@/store/modules/auth'
import { CustomerAccount } from '@/models/dto'
import axios from 'axios'
import OrganizationEditRiderChildCustomerAccounts from './OrganizationEditRiderChildCustomerAccounts.vue'
import AlertBox from '@/components/AlertBox.vue'

@Component({
  components: {
    OrganizationEditRiderChildCustomerAccounts,
    AlertBox,
  },
})
export default class OrganizationAddRiderDialog extends Vue {
  @Prop({ type: Object, default: () => {} })
  readonly customerAccount: CustomerAccount

  @Watch('dialog')
  dialogChanged(): void {
    this.resetValues()
    this.setup()
  }

  formIsValid = false
  dialog = false
  success = false
  errorMessage = ''
  sendInviteEmail = false
  childCustomerAccountIds = []

  formData: Record<string, { value: string; rules: any[] }> = {
    employeeId: {
      value: '',
      rules: [],
    },
    firstName: {
      value: '',
      rules: [],
    },
    lastName: {
      value: '',
      rules: [],
    },
    email: {
      value: '',
      rules: [(v) => !v || /.+@.+/.test(v) || 'E-mail must be valid'],
    },
    phone: {
      value: '',
      rules: [
        (v) =>
          !v ||
          /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/.test(
            v
          ) ||
          'Phone must be valid',
      ],
    },
  }

  mounted(): void {
    this.setup()
  }

  setup() {
    this.success = false
  }

  resetValues(): void {
    const form = this.$refs.form as any
    form.reset()
    this.errorMessage = ''
  }

  backToSite(): void {
    this.success = false
    this.setup()
    this.dialog = false
  }

  async send(): Promise<void> {
    const form = this.$refs.form as any
    if (!form.validate()) {
      return
    }
    this.errorMessage = ''
    if (!this.formData.email.value && !this.formData.employeeId.value) {
      this.errorMessage = 'Email or employee ID is required.'
      return
    }
    if (this.sendInviteEmail && !this.formData.email.value) {
      this.errorMessage = 'Email required to send invite email.'
      return
    }
    const payload = {
      firstName: this.formData.firstName.value,
      lastName: this.formData.lastName.value,
      employeeId: this.formData.employeeId.value
        ? this.formData.employeeId.value
        : this.formData.email.value,
      email: this.formData.email.value,
      phone: this.formData.phone.value,
      customerAccountId: auth.customerAccount.customerAccountId,
      sendInviteEmail: this.sendInviteEmail,
      childCustomerAccountIds: this.childCustomerAccountIds,
    }
    try {
      const resp = await rider.create(payload)

      if (resp.status === 200) {
        this.success = true
        this.dialog = false
        this.$emit('success')
      } else {
        this.errorMessage = resp.data.message || 'Error adding rider.'
      }
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const errorMessage = e.response?.data?.message
        this.errorMessage = errorMessage
      }
    }
  }
}
