
import { Vue, Component, Prop, Inject } from 'vue-property-decorator'
import {
  ComputedPaymentProfile,
  ComputedPaymentProfileAddress,
} from '../models/dto'
import paymentProfile from '@/services/paymentProfile'

@Component({})
export default class PaymentProfileV2 extends Vue {
  @Prop({ type: Object, required: true })
  readonly paymentProfile: ComputedPaymentProfile
  @Prop({ type: Boolean, default: false }) readonly isSelected: boolean
  @Inject({ from: 'isInBilling', default: false }) readonly isInBilling: boolean

  isExpanded = false

  get type(): string {
    return this.paymentProfile.typeLabel || 'default'
  }

  get label(): string {
    const labelMap = {
      amex: 'American Express',
      discover: 'Discover',
      mastercard: 'MasterCard',
      visa: 'Visa',
    }
    return labelMap[this.type] || 'Saved Card'
  }

  get lastFour(): string {
    return this.paymentProfile.mask
  }

  get cardholderName(): string {
    return this.paymentProfile.accountHolderName
  }

  get address(): ComputedPaymentProfileAddress {
    return this.paymentProfile.address
  }

  get expiration(): string {
    if (!this.expirationYear || !this.expirationMonth) {
      return ''
    }

    return `${this.expirationMonth}/${this.expirationYear}`
  }

  get isDefault(): boolean {
    return this.paymentProfile.isDefault
  }

  get isExpired(): string {
    const expirationDate = this.$dayjs(
      `${this.expirationYear}-${this.expirationMonth}-01`
    ).endOf('month')
    return expirationDate.isBefore(this.$dayjs())
  }

  get expirationMonth(): string {
    const { expiration } = this.paymentProfile

    if (!expiration) {
      return ''
    }

    const [month] = expiration.split('/')

    return month.padStart(2, '0').substr(0, 2)
  }

  get expirationYear(): string {
    const { expiration } = this.paymentProfile

    if (!expiration) {
      return ''
    }

    const [, yearFragment] = expiration.split('/')

    if (yearFragment) {
      return yearFragment.length === 2 ? `20${yearFragment}` : yearFragment
    }

    return expiration.length === 4
      ? `20${expiration.slice(-2)}`
      : expiration.slice(-4)
  }

  handleClick(): void {
    if (this.isExpired) {
      return
    }
    this.$emit('click')
  }

  async deletePaymentProfile(): Promise<void> {
    try {
      await paymentProfile.delete(this.paymentProfile.customerPaymentProfileId)
      this.$emit('payment-profile-deleted')
    } catch {
      console.error('Could not delete the payment profile')
    }
  }
}
