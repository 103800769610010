
import { Vue, Component, Prop } from 'vue-property-decorator'
import { HotelRequirement } from '@/models/dto'
import support from '@/store/modules/support'
import { SupportTicketKey } from '@/utils/enum'
import { DateTime } from 'luxon'

@Component
export default class DriverHotelRequirementsDialog extends Vue {
  @Prop({ type: Boolean }) readonly value: boolean
  @Prop({ type: Number, default: null }) readonly reservationId: number
  @Prop({ required: false, type: Object, default: null })
  readonly hotelRequirement: HotelRequirement

  requirements = [
    {
      title: this.$t('driverHotelRequirements.requirements.STAR_RATING.TITLE'),
      description: this.$t(
        'driverHotelRequirements.requirements.STAR_RATING.DESCRIPTION'
      ),
    },
    {
      title: this.$t(
        'driverHotelRequirements.requirements.ROOM_SPECIFICATIONS.TITLE'
      ),
      description: this.$t(
        'driverHotelRequirements.requirements.ROOM_SPECIFICATIONS.DESCRIPTION'
      ),
    },
    {
      title: this.$t('driverHotelRequirements.requirements.BUS_PARKING.TITLE'),
      description: this.$t(
        'driverHotelRequirements.requirements.BUS_PARKING.DESCRIPTION'
      ),
    },
    {
      title: this.$t('driverHotelRequirements.requirements.PROXIMITY.TITLE'),
      description: this.$t(
        'driverHotelRequirements.requirements.PROXIMITY.DESCRIPTION'
      ),
    },
    {
      title: this.$t(
        'driverHotelRequirements.requirements.NAME_ON_RESERVATION.TITLE'
      ),
      description: this.$t(
        'driverHotelRequirements.requirements.NAME_ON_RESERVATION.DESCRIPTION'
      ),
    },
  ]

  get driverHotelCheckIn(): string {
    return this.hotelRequirement?.checkIn
      ? DateTime.fromISO(this.hotelRequirement?.checkIn).toFormat('LL/dd/yy')
      : ''
  }

  get driverHotelCheckOut(): string {
    return this.hotelRequirement?.checkOut
      ? DateTime.fromISO(this.hotelRequirement?.checkOut).toFormat('LL/dd/yy')
      : ''
  }

  handleContactSupport(): void {
    this.close()
    support.open({
      reservationId: this.reservationId,
      ticketTypeKey: SupportTicketKey.Other,
    })
  }

  close(): void {
    this.$emit('input', false)
  }
}
