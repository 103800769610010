
import { PredefinedSelect } from '@/models/SelectColumn'
import { toKebab } from '@/utils/string'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class CUDataTableHeaderCheckbox extends Vue {
  @Prop({ type: String, default: 'primary', required: false })
  readonly color: string
  @Prop({ type: Array, default: () => [], required: false })
  readonly predefinedSelects: PredefinedSelect[]
  @Prop({ type: Array, required: false, default: undefined })
  selectedItems: unknown[]
  @Prop({ type: Array, required: false, default: undefined }) items!: any[]
  @Prop({ type: String, required: false }) itemKey!: string

  toKebab = toKebab

  get value(): boolean {
    const currentItems = this.items.map((item) => item[this.itemKey])
    if (this.selectedItems.length < currentItems.length) {
      return false
    }
    return currentItems.every((item) => !!this.selectedItems?.includes(item))
  }

  get indeterminate(): boolean {
    return !!(this.selectedItems.length && !this.value)
  }

  handleClick(value: boolean): void {
    const filter = (item: unknown) => value
    this.$emit('select', filter)
  }
}
