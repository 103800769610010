import { toCamel } from '@/utils/string'

export const makeSvgVehicleIcon = (
  vehicleType,
  google,
  vehicleColor
) => {
  let vehicleTypeKey = toCamel(vehicleType)
  vehicleTypeKey = ['charterBus', 'miniBus', 'sprinter'].includes(
    vehicleTypeKey
  )
    ? vehicleTypeKey
    : 'charterBus'
  const color = vehicleColor || '#8b47f3'

  const busShadow = {
    path: vehicleValues(vehicleTypeKey, google).shadow,
    scale: vehicleValues(vehicleTypeKey, google).scale,
    fillColor: '#000000',
    fillOpacity: 0.2,
    strokeColor: null,
    strokeWeight: 0,
    rotation: 0,
    anchor: vehicleValues(vehicleTypeKey, google).anchor,
  }
  const busWhite = {
    path: vehicleValues(vehicleTypeKey, google).white,
    scale: vehicleValues(vehicleTypeKey, google).scale,
    fillColor: '#FFFFFF',
    fillOpacity: 1,
    strokeColor: null,
    strokeWeight: 0,
    rotation: 0,
    anchor: vehicleValues(vehicleTypeKey, google).anchor,
  }
  const busBlack = {
    path: vehicleValues(vehicleTypeKey, google).black,
    scale: vehicleValues(vehicleTypeKey, google).scale,
    fillColor: '#110C0A',
    fillOpacity: 1,
    strokeColor: null,
    strokeWeight: 0,
    rotation: 0,
    anchor: vehicleValues(vehicleTypeKey, google).anchor,
  }
  const busFocusOuter = {
    path: vehicleValues(vehicleTypeKey, google).elipseOuter,
    scale: 1.15,
    fillColor: color,
    fillOpacity: 0,
    strokeColor: color,
    strokeOpacity: 0.7,
    strokeWeight: 2.5,
    rotation: 0,
    anchor: vehicleValues(vehicleTypeKey, google).anchor,
  }
  const busFocusInner = {
    path: vehicleValues(vehicleTypeKey, google).elipseInner,
    scale: 1.15,
    fillColor: color,
    fillOpacity: 0.3,
    strokeColor: color,
    strokeWeight: 0,
    rotation: 0,
    anchor: vehicleValues(vehicleTypeKey, google).anchor,
  }

  let icons = [
    {
      icon: busFocusOuter,
    },
    {
      icon: busFocusInner,
    },
    {
      icon: busWhite,
    },
    {
      icon: busBlack,
    },
    {
      icon: busShadow,
    },
  ]

  return icons
}

const vehicleValues = (vehicleTypeKey, google) => {
  if (vehicleTypeKey === 'charterBus') {
    return {
      white: `M14.4,1.3L14.4,1.3L12.5,0c0,0-4.6,0.1-5,0.4l0,0l0.5,0.4l0.7,0.5C8.9,1.4,9,1.5,9,1.5c0,0,0,0,0,0
      c0,0,0,0.1,0.1,0.1v0L8.9,21.4c0,0.2,0.1,0.3,0.2,0.5C9.3,21.9,9.4,22,9.5,22c0.8,0,3.7-0.1,4.4-0.3c0.3-0.1,0.5-0.3,0.5-0.6
      L14.4,1.3C14.4,1.3,14.4,1.3,14.4,1.3z M14.2,1.3c-0.8,0-4.5,0.2-5,0.2c0,0,0-0.1-0.1-0.1c0,0,0,0,0,0l0,0v0C8.9,1.2,8.5,1,8.2,0.8
      C8.1,0.8,8.1,0.6,8.3,0.6l4.3-0.3c0.2,0,0.4,0,0.6,0.1l1.2,0.8C14.3,1.3,14.3,1.3,14.2,1.3L14.2,1.3z`,
      black: `M14.3,1.3c0,0-0.1,0-0.1,0h0c-0.8,0-4.5,0.2-5,0.2c0,0,0,0,0-0.1L9,1.6c0,0,0,0,0,0c0,0,0,0.1,0.1,0.1v0
      L8.9,21.4c0,0.2,0.1,0.3,0.2,0.5l-0.9-0.6l0-2.9l-0.4,0v0.1c0,0.5-0.2,0.9-0.4,0.9c-0.2,0-0.4-0.4-0.4-0.9c0-0.5,0.2-0.9,0.4-0.9
      c0.2,0,0.3,0.3,0.4,0.7L8.2,18l0-1.1l-0.4,0c-0.1,0.3-0.2,0.6-0.3,0.6c-0.2,0-0.4-0.4-0.4-0.9c0-0.5,0.2-0.9,0.4-0.9
      c0.2,0,0.3,0.3,0.4,0.8h0.4L8.4,3.9l-0.5,0C7.8,4.2,7.7,4.5,7.5,4.5c-0.2,0-0.4-0.4-0.4-0.9s0.2-0.9,0.4-0.9c0.2,0,0.3,0.3,0.4,0.8
      l0.5-0.1l0-0.9C7.9,2.1,8,1,8.1,0.8l0,0l0.6,0.4l0.1-0.1C8.6,1,8.4,0.9,8.2,0.8C8.1,0.8,8.1,0.6,8.3,0.6l4.3-0.3
      c0.2,0,0.4,0,0.6,0.1L14.3,1.3z`,
      shadow: `M8.4,2.4L8.2,21.2l-0.4-0.3l-0.3-1.4l0-0.3c0,0,0,0,0.1,0c0.2,0,0.4-0.4,0.4-0.9c0-0.5-0.2-0.9-0.4-0.9
      c0,0,0,0-0.1,0v-0.1c0,0,0,0,0.1,0c0.2,0,0.4-0.4,0.4-0.9s-0.2-0.9-0.4-0.9c0,0,0,0-0.1,0L7.5,4.5c0.2,0,0.4-0.4,0.4-0.9
      c0-0.5-0.2-0.9-0.4-0.9l0-2.3l0,0l0.5,0.4l0,0C8,1,7.9,2.1,8.4,2.4L8.4,2.4z`,
      focus: `M -15, 12 a 25,25 0 1,1 50,0 a 25,25 0 1,1 -50,0`,
      elipseOuter: `M 32.0709632,21.4634
      a 20.3063,20.3063 0 1,0 -40.6126,0
      a 20.3063,20.3063 0 1,0 40.6126,0`,
      elipseInner: `M 28.2931,21.4635
      a 16.5284,16.5284 0 1,0 -33.0568,0
      a 16.5284,16.5284 0 1,0 33.0568,0`,
      scale: 2.75,
      anchor: new google.maps.Point(11, 11),
    }
  }
  if (vehicleTypeKey === 'miniBus') {
    return {
      white: `M15.3,6.1c0.2,0.4,0.3,1,0.1,1.1c-0.4,0.1-2.6,0.3-2.6-0.3c0.1-0.2,0-1.5,0.3-1.4c0.6,0.1,1.2,0.1,1.8,0
      c0.1,0,0.1,0,0.2,0.2C15.1,5.6,15.2,5.8,15.3,6.1h1.6c-0.1-0.4-0.2-0.8-0.5-1.1l0,0c-0.2,0-2.4-0.4-5.4,0.2l-0.2-0.6
      c0,0,2.1-0.9,5.1-0.2l-0.6-0.7L15,2c-0.1-0.6-0.9-0.6-1.4-0.7v0C12.5,1.1,11,1.2,9.8,1.5C9.5,1.6,9,1.6,9.1,1.9c0,0,0,0,0,0
      c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0.2,0.2,0.3,0.5,0.3,1H9.2c0-0.6-0.4-0.5-0.4,0c0,0.7,0.4,0.6,0.4,0h0.2
      c0,0.2,0,0.5-0.1,0.7c0,0,0,0,0,0c0,0-0.1,0.1-0.1,0.2c0,0,0,0,0,0.1C9.3,4,9.2,4,9.2,4c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
      c0,0,0,0-0.1,0h0c0,0,0,2.3,0,5h0.8V7h0.4V6.4c-0.2,0-0.4,0-0.4-0.2V4.8c0-0.2,0.3-0.2,0.3,0l0.5,1.4c0,0.1,0,0.2-0.1,0.2
      c-0.1,0-0.2,0-0.3,0V7h0.5v15.6l-0.9-0.2V9.1H9c0,4-0.1,9-0.1,9c0.3-0.1,0.5,0.4,0.5,1H9.3c-0.2-1.2-0.7,0.3-0.2,0.5
      c0.1,0,0.2-0.2,0.2-0.5h0.3c0,0.6-0.2,1.1-0.5,1V21l0.4,1.5l0,0.1c0.5,0.2,1.6,0.4,2.2,0.6v0c0.1,0,0.1,0,0.2,0h4.6
      c0.3,0,0.5-0.2,0.5-0.5V7.1l0-0.8c0-0.1,0-0.1,0-0.2L15.3,6.1L15.3,6.1z`,
      black: `M15.9,4.4L15.9,4.4c-3-0.7-5.1,0.2-5.1,0.2l0.1,0.2h-0.6l0-0.1c0-0.1-0.1-0.1-0.1-0.1h0c-0.1,0-0.1,0.1-0.1,0.1
      L9.4,3.7C9.4,3.5,9.5,3.3,9.5,3c0-0.6-0.2-1-0.4-1C8.8,2,8.6,2.4,8.6,3S8.8,4.1,9,4.1c0.1,0,0.2-0.1,0.3-0.4l0.5,1.1c0,0,0,0,0,0
      v1.4c0,0.1,0.1,0.2,0.2,0.2h0.3V7H9.9v12H9.5c0-0.6-0.2-1-0.4-1s-0.4,0.5-0.4,1c0,0.6,0.2,1.1,0.4,1.1s0.4-0.5,0.4-1.1c0,0,0,0,0,0
      h0.4v3.4l0.9,0.2V7h-0.5V6.4h0.2c0.1,0,0.2-0.1,0.2-0.2l-0.4-1.3h0.6L11,5.2c1.1-0.2,2.1-0.4,3-0.4v0.6c-0.3,0-0.6,0-0.9-0.1
      c-0.1,0-0.2,0.1-0.2,0.2l0,0.3l-0.1,0.9c0,0.1,0.1,0.3,0.2,0.3c0.8,0.2,1.6,0.2,2.4,0c0.1,0,0.2-0.2,0.1-0.4l-0.3-0.8l-0.1-0.4
      c0-0.1-0.1-0.2-0.2-0.2c-0.3,0-0.6,0.1-0.9,0.1V4.8c1.4,0,2.3,0.1,2.4,0.2l0,0c0,0,0,0,0,0L15.9,4.4z`,
      shadow: `M15,2c-0.1-0.3-0.3-0.5-0.6-0.6c0,0-0.1,0-0.2-0.1h0c-0.2,0-0.4-0.1-0.5-0.1h0l-0.4,0l-0.2,0l-1.4,0l0,0l0,0
      h-0.1l-0.5,0.1l0,0h0l0,0h0h0l0,0c-0.3,0.1-0.8,0.1-1.2,0.2c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0l0,0l-0.1,0c-0.2,0-0.3,0.1-0.4,0.1
      c-0.4,0.1-0.7,0.9-0.7,1L8.3,20.8l0.4,1.6l0.6,0.2l0,0.1l1.3,0.4h0c0,0,0,0,0,0h0l0.1,0l0,0l0,0l0.8,0.2v0c0,0,0,0,0,0v0L10.8,23
      c0.1,0,0.4,0,0.4-0.2c0-0.1,0-0.4,0-0.4l0,0V7.3h0l0-0.8l0-0.9l-0.6-2c0.6-0.9,0.5-2.3,0.5-2.4l0.5,0l-0.2,2.3l0.4-2.3l1.4,0l0,0
      l0,0L14,3.5l-0.7-2.2l0.4,0l0,0c0.1,0.1,0.5,1.5,0.9,2.1l0,0c0,0,0,0,0.1,0.1s0,0,0.1,0.1c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0.1,0
      c0,0,0,0,0.1,0c0,0,0,0,0,0c0.4,0.1,0.5,0.1,0.5,0.1l0,0l0,0L15,2z M9.1,19.5c-0.1,0-0.2-0.2-0.2-0.5c0-0.3,0.1-0.5,0.2-0.5
      s0.2,0.2,0.2,0.5C9.3,19.3,9.2,19.5,9.1,19.5z M9.3,3.9C9.2,4,9.1,4,9.1,4l0,0C9,4,8.9,4,8.9,3.9C8.7,3.7,8.6,3.4,8.6,3
      c0-0.6,0.2-1,0.4-1c0.2,0,0.4,0.5,0.4,1C9.5,3.4,9.4,3.7,9.3,3.9z`,
      focus: `M -10, 15 a 25,25 0 1,1 50,0 a 25,25 0 1,1 -50,0`,
      elipseOuter: `M 34.0709632,21.4634
      a 20.3063,20.3063 0 1,0 -40.6126,0
      a 20.3063,20.3063 0 1,0 40.6126,0`,
      elipseInner: `M 30.2931,21.4635
      a 16.5284,16.5284 0 1,0 -33.0568,0
      a 16.5284,16.5284 0 1,0 33.0568,0`,
      scale: 2.25,
      anchor: new google.maps.Point(12, 12),
    }
  }
  if (vehicleTypeKey === 'sprinter') {
    return {
      white: `M15.3,7.5l-1.3-2v0l0,0c-0.1,0-0.4-0.1-0.8-0.2c-1-0.2-2.7-0.4-4.7,0.2l0,0h0l0,0.7c0,0,0,0,0,0
      C8.2,6.2,8,6.7,8,7.3s0.2,1.1,0.4,1.1L8.4,14C8.2,14.1,8,14.5,8,15c0,0.5,0.1,0.9,0.4,1v0.4l0.1,1.1l0,0l0.1,0l0,0l0.1,0l0,0v0
      l0.4,0.1v0l1,0.3c0,0,0,0,0,0s0,0,0,0c0,0,0,0,0,0l0,0l0.1,0c0,0,0,0,0,0l0.2,0.1h0c0,0,0,0,0,0h0l0.4,0.1c0,0,0,0,0,0
      c0.1,0,0.2,0.1,0.2,0.1h4.6c0.3,0,0.5-0.2,0.5-0.5V9.3L15.3,7.5z`,
      black: `M16,8.9c-0.2-0.5-0.5-1-0.8-1.5c0,0,0,0,0,0v0l0,0c0,0,0,0,0,0c0,0-0.7-0.6-2.5-0.7c-0.1,0-0.2,0-0.2,0
      c-1.6,0-2.3,0.6-2.4,0.6l0,0l0,0c0,0,0,0,0,0l0.2,0.5H9.7L9.6,7.7c0-0.1-0.1-0.1-0.2-0.1h0c-0.1,0-0.2,0.1-0.2,0.2v0.1H8.8
      c0-0.2,0.1-0.3,0.1-0.5H8.6c0,0.2-0.1,0.4-0.2,0.5c0,0,0,0,0,0c-0.1,0-0.2-0.2-0.2-0.5s0.1-0.5,0.2-0.5c0,0,0,0,0,0
      c0.1,0,0.2,0.2,0.2,0.5v0h0.2v0c0-0.4-0.1-0.8-0.2-0.9V5.6l0,0c0,0,0,0,0.1,0c0.5-0.1,1.1-0.2,1.8-0.3l0,0.2
      c0.4-0.1,1.6-0.2,1.8-0.1l-0.1-0.1l0,0l0,0c0,0,0,0,0,0c0.5,0,1.1,0.1,1.7,0.2c0,0,0,0,0,0c0,0,0,0,0.1,0c-0.1,0-2.5-0.9-5.6,0l0,0
      h0l0,0.7c0,0,0,0,0,0C8.2,6.2,8,6.7,8,7.3s0.2,1,0.4,1c0,0,0,0,0,0l0,5.7c0,0,0,0,0,0C8.2,14,8,14.5,8,15s0.2,1,0.4,1c0,0,0,0,0,0
      l0,1.5l0.2,0.1V16c0.1-0.2,0.2-0.5,0.2-0.9H8.6c0,0.2-0.1,0.4-0.2,0.5c0,0,0,0,0,0c-0.1,0-0.2-0.2-0.2-0.5s0.1-0.5,0.2-0.5
      c0,0,0,0,0,0c0.1,0,0.2,0.2,0.2,0.5c0,0,0,0,0,0h0.2c0,0,0,0,0,0c0-0.4-0.1-0.8-0.2-0.9V8.2C8.7,8.1,8.8,8,8.8,7.8h0.5v1.3
      c0,0.1,0.1,0.2,0.2,0.2h0.3v1.3H9.5c-0.1,0-0.2,0.1-0.2,0.2v6.5c0,0.1,0,0.2,0.2,0.2l0.4,0.1c0.2,0,0.2-0.1,0.2-0.2v-6.7
      c0-0.1-0.1-0.2-0.2-0.2H9.8V9.3H10c0.1,0,0.2-0.1,0.1-0.2L9.7,7.9h0.6l0.2,0.6c0,0.1,0,0.1,0.1,0.2c0,0,0,0.1,0,0.1
      c0,0.1,0,0.1,0.1,0.2c0,0,0,0,0,0.1c0,0,0,0,0,0.1c0,0,0,0,0,0c0.1,0.2,0.3,0.2,0.5,0.2c1.6-0.5,3-0.5,4.6-0.1C15.9,9.1,16,9,16,8.9
      C16,8.9,16,8.9,16,8.9z`,
      shadow: `M7.8,5.3L7.8,5.3L7.8,5.3c0,0.1,0,0.1,0,0.1L7.7,16.3l0.1,1.1l2.5,0.7c0,0,0.1,0,0.1,0L10.1,18c0,0,0,0,0,0l0,0
      c0,0,0,0,0,0c0,0,0,0,0,0l0.1,0c0,0,0,0,0,0l0.2,0.1h0c0,0,0,0,0,0h0L10.2,18c0.1,0,0.4,0,0.4-0.2V9.4L10,7.9H9.7l0.4,1.2
      c0,0.1,0,0.2-0.2,0.2H9.8v1.3h0.2c0.1,0,0.2,0.1,0.2,0.2v6.7c0,0.1-0.1,0.2-0.2,0.2l-0.4-0.1c-0.2-0.1-0.2-0.1-0.2-0.2v-6.5
      c0-0.1,0.1-0.2,0.2-0.2h0.2V9.3H9.4c-0.1,0-0.2-0.1-0.2-0.2V7.8H8.8v0h0.5V7.7c0-0.1,0.1-0.2,0.2-0.2h0c0.1,0,0.1,0,0.1,0.1l0.1,0.2
      H10L9.8,7c-0.2-0.6-0.5-1.2-1-1.5c0,0-0.1,0-0.1,0l0,0v0.8c0.1,0.2,0.2,0.5,0.2,0.9v0h0c0,0,0,0,0,0h0c0,0.4-0.1,0.7-0.2,0.9v5.9
      c0.1,0.2,0.2,0.5,0.2,0.9v0H8.8c0,0,0,0,0,0h0.1c0,0.4-0.1,0.8-0.2,0.9v1.7l-0.2-0.1l0-1.5c0,0,0,0,0,0c-0.2,0-0.4-0.5-0.4-1
      C8,14.5,8.2,14,8.4,14c0,0,0,0,0,0l0-5.7c0,0,0,0,0,0C8.2,8.3,8,7.9,8,7.3s0.2-1.1,0.4-1.1c0,0,0,0,0,0l0-0.7h0l0,0
      c0.1,0,0.2-0.1,0.4-0.1c0,0,0.1,0,0.1,0c0.1,0,0.2-0.1,0.3-0.1c0,0,0,0,0,0c0.1,0,0.2,0,0.4-0.1c0,0,0,0,0.1,0c0.1,0,0.2,0,0.3,0
      c0,0,0,0,0.1,0c0.1,0,0.2,0,0.3,0c0,0,0,0,0,0c0.1,0,0.2,0,0.3,0c0,0,0,0,0.1,0c0.2,0,0.4,0,0.6,0c0,0,0,0,0,0c0,0,0,0,0,0
      c0.1,0,0.1,0,0.2,0c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0.1,0,0.1,0,0.2,0c0,0,0,0,0,0
      c0.1,0,0.1,0,0.2,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0.3,0,0.5,0.1,0.7,0.1C12.6,5.1,10.4,4.5,7.8,5.3z`,
      focus: `M -10, 15 a 25,25 0 1,1 50,0 a 25,25 0 1,1 -50,0`,
      elipseOuter: `M 32.0709632,11.4634
      a 20.3063,20.3063 0 1,0 -40.6126,0
      a 20.3063,20.3063 0 1,0 40.6126,0`,
      elipseInner: `M 28.2931,11.4635
      a 16.5284,16.5284 0 1,0 -33.0568,0
      a 16.5284,16.5284 0 1,0 33.0568,0`,
      scale: 2.5,
      anchor: new google.maps.Point(12, 12),
    }
  }
}
