var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',{class:{
    'padding-t-4 padding-x-4 padding-b-2 margin-t-0 margin-r-4 margin-b-4 margin-l-3':
      _vm.$vuetify.breakpoint.smAndUp,
    'padding-t-2 padding-x-2 padding-b-1': _vm.$vuetify.breakpoint.xsOnly,
  }},[_c('v-card-title',{staticClass:"padding-t-2 padding-x-2 padding-b-4",class:{ expanded: _vm.expanded }},[_c('span',{style:({ 'word-break': 'keep-all', width: 'calc(100% - 24px)' })},[_vm._v(" "+_vm._s(_vm.locationTitle)+" ")]),(!_vm.hideExpand)?_c('v-icon',{class:{ flipped: _vm.expanded },attrs:{"color":"gray"},on:{"click":function($event){_vm.expanded = !_vm.expanded}}},[_vm._v(" keyboard_arrow_down ")]):_vm._e()],1),(_vm.expanded && _vm.location.address.title)?_c('v-card-subtitle',{staticClass:"padding-t-0 padding-x-2 padding-b-2"},[_vm._v(" "+_vm._s(_vm.addressDisplayText)+" ")]):_vm._e(),_c('v-expand-transition',[(_vm.expanded)?_c('v-card-text',{class:{
        'padding-y-2 padding-r-0 padding-l-4': _vm.$vuetify.breakpoint.xsOnly,
      }},[(_vm.times.length)?_c('p',[_vm._v(_vm._s(_vm.timesLabel))]):_vm._e(),_vm._l((_vm.times),function(time,timeIndex){return _c('v-chip',{key:`${time}-${timeIndex}-stop`,staticClass:"margin-t-0 margin-l-0 margin-r-1 margin-b-1",attrs:{"outlined":"","disabled":"","color":time === _vm.nextStopTime ? 'primary' : 'grayMediumLight'}},[_vm._v(" "+_vm._s(_vm.formatStopItineraryTime(time.timestamp, _vm.location.address))+" ")])})],2):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }