import { InfoModalDetail } from '@/models/InfoModalDetail'
import { InfoModalVariant } from './enum'
import i18n from '@/plugins/i18n'
import { RefundPolicyEntry } from '@/models/dto'
import { pluralize } from '@/utils/string'

export const bookingProtectionModalDetail: InfoModalDetail = {
  label: 'Booking Protection Guarantee',
  shortLabel: 'Booking Protection',
  icon: { name: 'verified_user', viewBox: '0 0 24 24' },
  modalVariant: InfoModalVariant.Standard,
  modalText: [
    {
      subLabel: i18n.t('bookingGuarantee.VALUE_PROP.NETWORK.TITLE').toString(),
      body: i18n.t(
        'bookingGuarantee.VALUE_PROP.NETWORK.DESCRIPTION'
      ),
    },
    {
      subLabel: i18n.t(
        'bookingGuarantee.VALUE_PROP.120_PERCENT.TITLE'
      ).toString(),
      body: i18n.t(
        'bookingGuarantee.VALUE_PROP.120_PERCENT.DESCRIPTION'
      ),
    },
  ],
  showCharterUPLogo: true,
  showSupportButton: false,
}

export const vehicleLocationTrackingModalDetail: InfoModalDetail = {
  label: 'Vehicle Location Tracking',
  shortLabel: 'Vehicle Tracking',
  icon: { name: 'share_location', viewBox: '0 0 24 24' },
  modalVariant: InfoModalVariant.Standard,
  modalText: [
    {
      subLabel: 'Track Your Bus at Pickup',
      body:
        "Track your bus's location on the map as it nears the pickup point and all throughout the trip. Having on-demand access to your vehicle's whereabouts provides an extra layer of assurance and enables your group to connect for a smooth pickup experience.",
    },
    {
      subLabel: 'Share Your Ride',
      body:
        "Easily share the tracking link with the group to ensure everyone has real-time updates on the bus's status and knows exactly where to board. Even share it with colleagues, family, and friends to virtually join you on your journey.",
    },
  ],
  showCharterUPLogo: true,
  showSupportButton: false,
}

export const liveSupportModalDetail: InfoModalDetail = {
  label: '24/7 Live Support',
  shortLabel: '24/7 Support',
  icon: { name: 'support_agent', viewBox: '0 0 24 24' },
  modalVariant: InfoModalVariant.Standard,
  modalText: [
    {
      subLabel: 'Always-On Support',
      body:
        "Our customer success team serves as your travel partner, completing a pre-trip checklist to ensure you and your operator are all ready to go. We're available around-the-clock, whether you need to adjust your itinerary or sort out any unexpected bumps along the way. Your trip's success remains our top priority.",
    },
    {
      subLabel: 'Live Trip Hotline',
      body:
        'Access our Live Trip Team through a priority hotline for quick help to resolve any urgent issues on the day of the trip.',
    },
  ],
  showCharterUPLogo: true,
  showSupportButton: false,
}

export const qualifiedOperatorsModalDetail: InfoModalDetail = {
  label: 'Pre-Qualified Operators',
  shortLabel: 'Pre-Qualified Operators',
  icon: { name: 'bus_alert', viewBox: '0 0 24 24' },
  modalVariant: InfoModalVariant.Standard,
  modalText: [
    {
      subLabel: 'Operator Compliance',
      body:
        "The CharterUP partnership team conducts thorough due diligence to assess and pre-qualify all operators. This involves verifying the company's DOT status, licenses, certifications, and safety records to offer only qualified partners.",
    },
    {
      subLabel: 'Standards of Service',
      body:
        'CharterUP Partners are required to commit to our set of operator standards for vehicles, reliability of service, and code of conduct.',
    },
  ],
  showCharterUPLogo: true,
  showSupportButton: false,
}

export const certifiedPartnerModalDetail: InfoModalDetail = {
  label: i18n.t('certifiedPartner.TITLE').toString(),
  shortLabel: i18n.t('certifiedPartner.TITLE').toString(),
  icon: {
    name: 'verified_outline',
    viewBox: '0 -960 960 960',
    color: 'yellow'
  },
  modalVariant: InfoModalVariant.Standard,
  modalText: [
    {
      subLabel: i18n.t('certifiedPartner.VALUE_PROP.FAIR_PRICING.TITLE').toString(),
      body: i18n.t(
        'certifiedPartner.VALUE_PROP.FAIR_PRICING.DESCRIPTION'
      ),
    },
    {
      subLabel: i18n.t('certifiedPartner.VALUE_PROP.LIVE_TRACKING.TITLE').toString(),
      body: i18n.t(
        'certifiedPartner.VALUE_PROP.LIVE_TRACKING.DESCRIPTION'
      ),
    },
  ],
  showCharterUPLogo: true,
  showSupportButton: false,
}

export const refundPolicyModalDetail = (
  refundPolicy: RefundPolicyEntry[]
): InfoModalDetail => {
  const modalText = []
  for (const entry of refundPolicy) {
    //refundablePercent is nonNull
    const percentRefund = entry.refundablePercent * 100
    const subLabel =
      percentRefund > 0
        ? `${percentRefund}% ${i18n.t('refundPolicy.REFUND')}`
        : i18n.t('refundPolicy.NO_REFUND')

    let body = ''
    if (!entry.maxHours && !!entry.minHours) {
      const useDays = entry.minHours > 24
      const timeUnit = useDays
        ? i18n.t('refundPolicy.DAY')
        : i18n.t('refundPolicy.HOUR')
      const duration = useDays
        ? Math.floor(entry.minHours / 24)
        : entry.minHours
      body = `${i18n.t('refundPolicy.CANCELLATIONS')} ${duration} ${pluralize(
        duration,
        timeUnit.toString()
      )} ${i18n.t('refundPolicy.OR_MORE')} ${i18n.t(
        'refundPolicy.FROM_PICKUP'
      )}`
    }

    if (!entry.minHours && !!entry.maxHours) {
      const useDays = entry.maxHours > 24
      const timeUnit = useDays
        ? i18n.t('refundPolicy.DAY')
        : i18n.t('refundPolicy.HOUR')
      const duration = useDays
        ? Math.floor(entry.maxHours / 24)
        : entry.maxHours
      body = `${i18n.t('refundPolicy.CANCELLATIONS')} ${i18n.t(
        'refundPolicy.FEWER_THAN'
      )} ${duration}
      ${pluralize(duration, timeUnit.toString())} ${i18n.t(
        'refundPolicy.FROM_PICKUP'
      )}`
    }

    if (!!entry.minHours && !!entry.maxHours) {
      const useMaxDays = entry.maxHours > 24
      const maxTimeUnit = useMaxDays
        ? i18n.t('refundPolicy.DAY')
        : i18n.t('refundPolicy.HOUR')
      const maxDuration = useMaxDays
        ? Math.floor(entry.maxHours / 24)
        : entry.maxHours
      const useMinDays = entry.minHours > 24
      const minTimeUnit = useMinDays
        ? i18n.t('refundPolicy.DAY')
        : i18n.t('refundPolicy.HOUR')
      const minDuration = useMinDays
        ? Math.floor(entry.minHours / 24)
        : entry.minHours

      body = `${i18n.t('refundPolicy.CANCELLATIONS')} ${i18n.t(
        'refundPolicy.BETWEEN'
      )} ${maxDuration - 1}
      ${pluralize(maxDuration, maxTimeUnit.toString())} ${i18n.t(
        'refundPolicy.AND'
      )} ${minDuration} ${pluralize(
        minDuration,
        minTimeUnit.toString()
      )} ${i18n.t('refundPolicy.FROM_PICKUP')}`
    }

    modalText.push({ subLabel, body })
  }

  return {
    label: i18n.t('refundPolicy.HEADER').toString(),
    shortLabel: i18n.t('refundPolicy.HEADER').toString(),
    icon: {
      name: 'free_cancellation',
      viewBox: '0 0 24 24',
      color: 'primary',
    },
    modalVariant: InfoModalVariant.Itemized,
    modalText,
    footerText: '*' + i18n.t('refundPolicy.CALCULATION_DETAILS').toString(),
    showCharterUPLogo: false,
    showSupportButton: false,
  }
}

export const cancellationNotAvailableModalDetail: InfoModalDetail = {
  label: i18n.t('reservationDetail.cancellationUnavailableModal.HEADER').toString(),
  shortLabel: i18n.t('reservationDetail.cancellationUnavailableModal.HEADER').toString(),
  icon: {
    name: 'free_cancellation',
    viewBox: '0 0 24 24',
    color: 'primary'
  },
  modalVariant: InfoModalVariant.Simple,
  simpleBody: i18n.t('reservationDetail.cancellationUnavailableModal.PICKUP_IS_TOO_CLOSE').toString(),
  showCharterUPLogo: false,
  showSupportButton: true,
}

export const tripModificationStartModalDetail: InfoModalDetail = {
  label: "Do you want to modify your trip?",
  shortLabel: "Do you want to modify your trip?",
  icon: {
    name: 'bus_clock',
    viewBox: '0 0 48 48',
    color: 'primary'
  },
  modalVariant: InfoModalVariant.Simple,
  showCharterUPLogo: false,
  showSupportButton: false,
}

export const tripModificationWithin72HoursModalDetail: InfoModalDetail = {
  label: "Your pickup time is coming up soon!",
  shortLabel: "Your pickup time is coming up soon!",
  icon: {
    name: 'bus_clock',
    viewBox: '0 0 48 48',
    color: 'primary'
  },
  modalVariant: InfoModalVariant.Simple,
  showCharterUPLogo: false,
  showSupportButton: false,
}

export const tripModificationRequestModalDetail: InfoModalDetail = {
  label: "Your trip modification was requested.",
  shortLabel: "Your trip modification was requested.",
  icon: null,
  modalVariant: InfoModalVariant.Simple,
  showCharterUPLogo: false,
  showSupportButton: false,
}
