var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.hasStopNotes)?_c('div',{staticClass:"d-flex w-full margin-t-2"},[_c('CUIcon',{staticClass:"margin-x-2 flex-shrink-0 cursor-default",attrs:{"view-box":"0 0 24 24","icon-name":"text-snippet","width":18,"height":18}},[_vm._v(" text_snippet ")]),(_vm.stopNotes)?_c('p',{staticClass:"d-inline-block max-w-340 margin-t-n2 font-14",class:{
      'text-truncate': !_vm.isExpanded,
    },domProps:{"innerHTML":_vm._s(_vm.stopNotes)}}):(_vm.stop.notes)?_c('p',{staticClass:"d-inline-block max-w-340 margin-t-0 font-14",class:{
      'text-truncate': !_vm.isExpanded,
    },style:({ 'font-style': 'italic' })},[_vm._v(" “"+_vm._s(_vm.stop.notes)+"” ")]):_vm._e(),(!_vm.hideStopNotesArrow)?_c('CUIcon',{staticClass:"flex-shrink-0 transition-all transition-duration-100 transition-ease-in-out",class:{
      'icon-arrow--flipped': _vm.isExpanded,
    },style:(_vm.formattedAddress.length > 45 ? 'bottom: -27px;' : 'top: 4px;'),attrs:{"view-box":"0 0 24 24","icon-name":"keyboard-arrow","width":18,"height":18},nativeOn:{"click":function($event){return _vm.toggleStopNotes.apply(null, arguments)}}},[_vm._v(" keyboard_arrow_down ")]):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }