import { render, staticRenderFns } from "./CUIcon.vue?vue&type=template&id=75b3e760&scoped=true"
import script from "./CUIcon.vue?vue&type=script&lang=ts"
export * from "./CUIcon.vue?vue&type=script&lang=ts"
import style0 from "./CUIcon.vue?vue&type=style&index=0&id=75b3e760&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "75b3e760",
  null
  
)

export default component.exports