import { TripModificationVehicle as TripModificationVehicleDTO } from '@/models/dto'

export class TripModificationVehicle implements TripModificationVehicleDTO {
  quantity = 0
  vehicleTypeKey = null

  constructor(vehicle: TripModificationVehicleDTO) {
    if (!vehicle) {
      return
    }
    this.vehicleTypeKey = vehicle.vehicleTypeKey
    this.quantity = vehicle.quantity
  }
}
