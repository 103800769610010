var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"font-14"},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('CUIcon',_vm._g(_vm._b({staticClass:"kebab-button",attrs:{"id":"info-actions","view-box":"0 0 24 24"}},'CUIcon',_vm.$attrs,false),on),[_vm._v(" more_vert ")])]}}])},[_c('v-list',{staticClass:"dropdown-menu padding-a-0"},[_c('v-list-item',{key:"edit-user",attrs:{"id":"edit-user"},on:{"click":function($event){_vm.isEditing = true}}},[_c('v-list-item-title',[_vm._v(" Edit User ")])],1),_c('v-list-item',{key:"delete-user",attrs:{"id":"delete-user"},on:{"click":function($event){_vm.isDeleting = true}}},[_c('v-list-item-title',[_vm._v(" Delete User ")])],1),(!_vm.isSignedUp)?_c('v-list-item',{key:"resend-invite",attrs:{"id":"resend-invite"},on:{"click":_vm.resendInvite}},[_c('v-list-item-title',[_vm._v(" Resend Invitation ")])],1):_vm._e()],1)],1),_c('CUModal',{attrs:{"width":"550","content-class":_vm.$vuetify.breakpoint.xs ? 'fullscreen-dialog' : '',"fullscreen":_vm.$vuetify.breakpoint.xs},on:{"keydown":function($event){$event.stopPropagation();return ((event) => {
        if (event.keyCode === 27) {
          _vm.isEditing = false
        }
      }).apply(null, arguments)}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(_vm._s(`${''}`))]},proxy:true},{key:"text",fn:function(){return [_c('div',{staticClass:"w-full padding-x-4 padding-b-4"},[_c('div',{staticClass:"w-full text-center"},[_c('h2',[_vm._v(_vm._s(_vm.$t('editCustomerForm.HEADER')))])]),_c('div',{staticClass:"w-full margin-t-3"},[_c('OrganizationTeamEditUser',{attrs:{"existing-customer":_vm.row},on:{"close":function($event){_vm.isEditing = false
              _vm.$emit('refresh')}}})],1)])]},proxy:true}]),model:{value:(_vm.isEditing),callback:function ($$v) {_vm.isEditing=$$v},expression:"isEditing"}}),_c('CUModal',{attrs:{"width":"550","content-class":_vm.$vuetify.breakpoint.xs ? 'fullscreen-dialog' : '',"fullscreen":_vm.$vuetify.breakpoint.xs},on:{"keydown":function($event){$event.stopPropagation();return ((event) => {
        if (event.keyCode === 27) {
          _vm.isDeleting = false
        }
      }).apply(null, arguments)}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(_vm._s(`${''}`))]},proxy:true},{key:"text",fn:function(){return [_c('div',{staticClass:"w-full padding-x-4 padding-b-4"},[_c('div',{staticClass:"w-full text-center"},[_c('h2',[_vm._v(_vm._s(_vm.$t('deleteCustomerForm.HEADER')))]),_c('h4',[_vm._v(_vm._s(_vm.$t('deleteCustomerForm.CONFIRMATION')))])]),_c('div',{staticClass:"w-full margin-t-3"},[_c('OrganizationTeamDeleteUser',{attrs:{"existing-customer":_vm.row},on:{"close":function($event){_vm.isDeleting = false
              _vm.$emit('refresh')}}})],1)])]},proxy:true}]),model:{value:(_vm.isDeleting),callback:function ($$v) {_vm.isDeleting=$$v},expression:"isDeleting"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }