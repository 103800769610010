import RouteConfigWithMeta from '@/models/RouteConfig'

const Profile = () => import('@/views/Profile.vue')

const profile: RouteConfigWithMeta =
{
  path: '/profile',
  meta: { requiresAuth: true },
  component: () => import('@/views/Empty.vue'),
  children: [
    {
      path: '',
      name: 'profile',
      props: { mode: 'profile' },
      meta: { requiresAuth: true },
      component: Profile,
    },
    {
      path: 'password',
      name: 'password',
      props: { mode: 'password' },
      meta: { requiresAuth: true },
      component: Profile,
    },
    {
      path: 'billing',
      name: 'billing',
      props: { mode: 'billing' },
      meta: { requiresAuth: true },
      component: Profile,
    },
    {
      path: 'notifications',
      name: 'notifications',
      props: { mode: 'notifications' },
      meta: { requiresAuth: true },
      component: Profile,
    },
  ],
}

export default profile
