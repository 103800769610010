
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { DateTime } from 'luxon'
import LiveTrackingItineraryStop from '@/components/LiveTrackingItineraryStop.vue'
import LiveTrackingVehicleSelector from '@/components/LiveTrackingVehicleSelector.vue'
import LiveTrackingItineraryItem from '@/components/LiveTrackingItineraryItem.vue'
import ShareLiveTrackingDialog from '@/components/ShareLiveTrackingDialog.vue'
import ReservationDetailVerifySMS from '@/components/ReservationDetailVerifySMS.vue'
import CUItinerary from '@/components/CUItinerary.vue'
import CUItinerarySkeletonLoader from '@/components/CUItinerarySkeletonLoader.vue'
import { TrackingStop } from '@/models/dto/TrackingStop'
import auth from '@/store/modules/auth'
import customer from '@/services/customer'
import tracking from '@/store/modules/tracking'
import trackingVehicleStore from '@/store/modules/trackingVehicles'
import { formatReservationPickupDestinationText } from '@/utils/string'
import { LiveTrackingStatus } from '@/utils/enum'
import { DEFAULT_MAP_THEME_COLOR } from '@/views/LiveTracking.vue'
import { Customer } from '@/models/dto'
import SkeletonBox from '@/components/SkeletonBox.vue'

@Component({
  components: {
    CUItinerary,
    CUItinerarySkeletonLoader,
    LiveTrackingItineraryStop,
    LiveTrackingVehicleSelector,
    LiveTrackingItineraryItem,
    ReservationDetailVerifySMS,
    ShareLiveTrackingDialog,
    SkeletonBox,
  },
})
export default class LiveTrackingItinerary extends Vue {
  @Prop({ type: Object, required: true }) readonly date!: Date
  @Prop({ type: Boolean, required: false }) readonly loading!: boolean
  @Prop({ type: Object, required: true }) readonly statusDetails: any
  @Prop({ type: Boolean, required: true }) readonly refreshMap!: boolean

  drawerOpen = false
  tracking = tracking
  trackingVehicleStore = trackingVehicleStore
  stopUpdateCount = 0
  smsVerificationDialogIsOpen = false
  shareLiveTrackingDialogIsOpen = false

  @Watch('refreshMap')
  onRefreshMapChanged(value): Promise<void> {
    if (!value) {
      return
    }
    this.stopUpdateCount += 1
  }

  mounted(): void {
    this.checkForOpenModals()
  }

  get customer(): Customer {
    return auth.customer
  }

  get isUserLoggedIn(): boolean {
    return !!auth.user || !!auth.customerAccount
  }

  get isUserSMSVerified(): boolean {
    return auth.customer?.smsConfirmed
  }

  get shouldShowTimeline(): boolean {
    return this.drawerOpen || this.$vuetify.breakpoint.smAndUp
  }

  get displayDate(): string {
    return this.date?.toLocaleString(DateTime.DATE_FULL)
  }

  get managedId(): string {
    return tracking.reservation?.managedId
  }

  get reservationHash(): string {
    return tracking.reservation?.hash
  }

  get reservationCities(): string {
    return formatReservationPickupDestinationText(
      tracking.reservation,
      this.$t('common.TO')
    )
  }

  get reservationTitle(): string {
    const routeName = tracking.reservation?.routeName
    return (
      routeName ||
      this.reservationCities ||
      this.$t('liveTracking.itinerary.DEFAULT_RESERVATION_TITLE', {
        managedId: this.managedId,
      })
    )
  }

  get isUpcoming(): boolean {
    return ['upcoming', 'hold'].includes(tracking.reservation.reservationStatus)
  }

  get isInProgress(): boolean {
    return tracking.reservation.reservationStatus === 'started'
  }

  get activeVehicleColor(): string {
    return (
      trackingVehicleStore?.activeTrackingVehicle?.color ||
      DEFAULT_MAP_THEME_COLOR
    )
  }

  get isMultiDayTrip(): boolean {
    const stops = tracking.reservation.stops
    const firstPickupDate = DateTime.fromISO(stops[0]?.pickupDatetime)
    if (!firstPickupDate) {
      return false
    }
    return stops.some((stop) => {
      if (stop.pickupDatetime) {
        return !firstPickupDate.hasSame(
          DateTime.fromISO(stop.pickupDatetime),
          'day'
        )
      } else if (stop.dropoffDatetime) {
        return !firstPickupDate.hasSame(
          DateTime.fromISO(stop.dropoffDatetime),
          'day'
        )
      }
      return false
    })
  }

  openReservationDetail(): void {
    if (tracking.reservation?.reservationId) {
      const route = this.$router.resolve({
        name: 'reservation-detail',
        params: { id: tracking.reservation?.reservationId },
      })
      window.open(route.href, '_blank')
    }
  }

  getItineraryItemStatus(stop: TrackingStop): string {
    if (tracking.isFinished || tracking.isComplete(stop)) {
      return LiveTrackingStatus.Completed
    }
    if (this.isInProgress && tracking.isNext(stop)) {
      return LiveTrackingStatus.InProgress
    }
    return LiveTrackingStatus.Upcoming
  }

  checkForOpenModals(): void {
    const { verifyPhoneNumber, shareLiveTracking } = this.$route?.query

    if (verifyPhoneNumber) {
      this.smsVerificationDialogIsOpen = true
      return
    }

    if (shareLiveTracking) {
      this.shareLiveTrackingDialogIsOpen = true
      return
    }
  }
}
