
import { Vue, Component, Prop } from 'vue-property-decorator'
import { Driver, ReservationDetail, Stop } from '@/models/dto'
import DriverInfoContact from '@/components/DriverInfoContact.vue'
import { ReservationStatusKey } from '@/utils/enum'
import { TranslateResult } from 'vue-i18n'

@Component({ components: { DriverInfoContact } })
export default class ReservationDetailDriverInfo extends Vue {
  @Prop({ type: Boolean }) readonly value: boolean
  @Prop({ type: Object, default: () => {} })
  readonly reservation: ReservationDetail

  contactInfoMessage: TranslateResult = this.$t(
    'reservationDetail.driverInfo.DETAILS'
  )
  isDriverInfoExpanded = false

  get assignedDrivers(): Driver[] {
    return this.reservation?.assignedDrivers
  }

  get noNumber(): boolean {
    return !this.assignedDrivers
  }

  get noDrivers(): boolean {
    return !this.assigned
  }

  get assigned(): boolean {
    return !!this.assignedDrivers?.length
  }

  get noDriversHeader(): TranslateResult {
    if (!this.isWithin3Hours) {
      return this.$t('reservationDetail.driverInfo.NOT_AVAILABLE')
    }
    if (this.noNumber || !this.assigned) {
      return this.$t('reservationDetail.driverInfo.NO_LISTED_NUMBERS')
    }
    return ''
  }

  get noDriversBody(): TranslateResult {
    if (!this.isWithin3Hours) {
      return this.contactInfoMessage
    }
    if (this.noNumber || !this.assigned) {
      return this.$t('reservationDetail.driverInfo.CANT_FIND_NUMBER', {
        supportPhone: '1-855-920-2287',
      })
    }
    return ''
  }

  get firstStop(): Stop {
    const stops = this.reservation?.stops
    if (!stops || stops?.length === 0) {
      return null
    }
    return stops.find((stop) => stop.orderIndex === 0)
  }

  get isWithin3Hours(): boolean {
    return this.isWithinXHours(3)
  }

  get isWithin48Hours(): boolean {
    return this.isWithinXHours(48)
  }

  get isFinished(): boolean {
    return this.reservation.reservationStatus === ReservationStatusKey.Finished
  }

  isWithinXHours(hours: number) {
    const pickupDatetime = this.firstStop?.pickupDatetime
    if (!pickupDatetime) {
      return false
    }
    const pickupDate = this.$dayjs(pickupDatetime)
    const now = this.$dayjs()
    return pickupDate.diff(now, 'hours') <= hours
  }
}
