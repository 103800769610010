
import { Vue, Component, Prop } from 'vue-property-decorator'
import { AmenityType } from '@/models/dto'
import { convertAmenityKeyToIconName } from '../utils/string'

@Component({})
export default class ProviderInfoVehicleTypeAmenities extends Vue {
  @Prop({ type: Array, required: true })
  readonly amenities: AmenityType[]

  convertAmenityKeyToIconName = convertAmenityKeyToIconName
}
