
import { Vue, Component, Prop } from 'vue-property-decorator'
import TransportationTerms from '@/components/TransportationTerms.vue'

@Component({ components: { TransportationTerms } })
export default class CheckoutTerms extends Vue {
  @Prop({ type: Boolean, default: false }) hideDropdown!: boolean

  isTermsExpanded = false
}
