
import tracking from '@/store/modules/tracking'
import { Vue, Component } from 'vue-property-decorator'
import trackingVehicleStore from '@/store/modules/trackingVehicles'
import { DEFAULT_MAP_THEME_COLOR } from '@/views/LiveTracking.vue'
import auth from '@/store/modules/auth'

@Component
export default class LiveTrackingMapStatusCard extends Vue {
  tracking = tracking
  trackingVehicleStore = trackingVehicleStore

  get statusText(): string {
    return tracking.statusText
  }
  get reservationId(): string {
    return tracking.reservation?.reservationId?.toString() || ''
  }
  get activeVehicleColor(): string {
    return (
      trackingVehicleStore?.activeTrackingVehicle?.color ||
      DEFAULT_MAP_THEME_COLOR
    )
  }
  get showReviewLink(): boolean {
    return this.reservationId && this.tracking.isFinished && !!auth.user
  }
}
