import { Address, WizardAddress } from '@/models/dto'

/**
 * Returns true if the given value is not empty, otherwise returns false.
 *
 * @param val - The value to check for emptiness.
 * @returns true if the value is not empty, otherwise false.
 *
 * @example
 * const notEmpty = isNotEmpty('hello')
 * console.log(notEmpty) // true
 *
 * const empty = isNotEmpty('')
 * console.log(empty) // false
 */
export const isNotEmpty = (val: any): boolean => {
  return !!val
}

/**
 * Returns true if the given input value is not empty, otherwise returns false.
 *
 * @param val - The input value to check for emptiness.
 * @returns true if the input value is not empty, otherwise false.
 *
 * @example
 * const notEmpty = isNotEmptyInput('hello')
 * console.log(notEmpty) // true
 *
 * const empty = isNotEmptyInput('')
 * console.log(empty) // false
 */
export const isNotEmptyInput = (val: any): boolean => {
  return !(val === '' || val == null || val?.length === 0)
}

/**
 * Returns true if the given array is not empty, otherwise returns false.
 *
 * @param val - The array to check for emptiness.
 * @returns true if the array is not empty, otherwise false.
 *
 * @example
 * const notEmpty = isNotEmptyArray([1, 2, 3])
 * console.log(notEmpty) // true
 *
 * const empty = isNotEmptyArray([])
 * console.log(empty) // false
 */
export const isNotEmptyArray = (val: any): boolean => {
  if (!val) {
    return false
  }
  if (!val?.length) {
    return false
  }
  return true
}

/**
 * Checks if a value is not a negative number.
 * @param val - The value to check.
 * @returns `true` if `val` is not a negative number, `false` otherwise.
 * @example
 * isNotNegative(-10) // returns false
 * isNotNegative(0) // returns true
 * isNotNegative(10) // returns true
 * isNotNegative('hello') // returns true
 */
export const isNotNegative = (val: any): boolean => {
  if (isNaN(Number(val))) {
    return true
  }
  return Number(val) >= 0
}

/**
 * Verifies that the length of a phone number is either 10 or 12 digits.
 * @param phoneNumber - The phone number to verify.
 * @returns true if the phone number is either 10 or 12 digits long, false otherwise.
 */
export const verifyPhoneLength = (phoneNumber: string): boolean => {
  return (
    phoneNumber != null &&
    (phoneNumber.replace(/[^0-9]/g, '').length === 10 ||
      phoneNumber.replace(/[^0-9]/g, '').length === 12)
  )
}

/**
 * Verifies that the address contains non-empty state and country strings.
 * @param Address - The address to verify.
 * @returns true if the address is a non-empty string.
 */
export const validateAddressStateAndCountry = (address: Address | WizardAddress) => {
  return !!address?.state && !!address?.country
}

/**
 *
 * Check whether the two given passwords match.
 * @param p1 - The first password.
 * @param p2 - The second password.
 * @returns true if the passwords match, and false otherwise.
 */
export const doPasswordsMatch = (p1: string, p2: string): boolean => {
  return p1 === p2
}
