import { Action, Module, VuexModule } from 'vuex-class-modules'
import store from '@/store/index'
import { load, save } from '@/utils/localStorage'
import systemParameter from '@/services/systemParameter'
import { TableViewParameters } from '@/models/dto'
import { SystemParameter } from '@/models/dto/SystemParameter'

const DEFAULT_CHARTERUP_SUPPORT_EMAIL = 'support@charterup.com'

@Module({ generateMutationSetters: true })
class SystemParameterModule extends VuexModule {
  _systemParameters: SystemParameter[] = load('systemParameters')
  _elitePriorityPhoneNumber = ''
  _supportPhoneNumber = ''
  _supportEmail = ''

  /**
   * @returns the system parameters.
   */
  get systemParameters(): SystemParameter[] {
    return this._systemParameters
  }

  /**
   * @returns the W9 URL from the system parameters.
   */
  get w9Url(): string {
    return (
      this._systemParameters.find(
        (systemParameter) => systemParameter?.name === 'charterUpW9Url'
      )?.value || ''
    )
  }

  /**
   * @returns the insurance URL from the system parameters.
   */
  get insuranceUrl(): string {
    return (
      this._systemParameters.find(
        (systemParameter) => systemParameter?.name === 'charterUpInsuranceUrl'
      )?.value || ''
    )
  }

  /**
   * @returns elite priority phone number
   */
  get elitePriorityPhoneNumber(): string {
    return this._elitePriorityPhoneNumber
  }

  /**
   * @returns default support phone number
   */
  get supportPhoneNumber(): string {
    return this._supportPhoneNumber
  }

  /**
   * @returns default support email
   */
  get supportEmail(): string {
    return this._supportEmail || DEFAULT_CHARTERUP_SUPPORT_EMAIL
  }

  /**
   * Fetches all system parameters and saves them to the store.
   */
  @Action
  async fetchSystemParameters(): Promise<void> {
    const params: TableViewParameters = {
      filters: null,
      sorts: null,
      pageSize: -1,
      page: 1,
    }
    const response = await systemParameter.tableView(params)
    this._systemParameters = response.data.resultList
    this.calculateSystemParameters()
    save('systemParameters', response.data.resultList)
  }

  /**
   * Calculates the values of the specified system parameters
   */
  @Action
  calculateSystemParameters(): void {
    this._elitePriorityPhoneNumber =
      findSystemParameter(this._systemParameters, 'elitePriorityPhoneNumber')
        ?.value || ''
    this._supportPhoneNumber =
      findSystemParameter(this._systemParameters, 'supportPhoneNumber')
        ?.value || ''
    this._supportEmail =
      findSystemParameter(this._systemParameters, 'charterUpSupportEmail')
        ?.value || ''
  }
}

// PRIVATE HELPERS
/**
 * Check if a system parameter exists and has a specific value.
 *
 * @param systemParameters - An array of system parameters to search through.
 * @param systemParameterName - The name of the system parameter to search for.
 * @param value - The expected value of the system parameter.
 * @returns true if the system parameter exists and has the expected value, false if it exists but has a different value, or null if it doesn't exist.
 */
const checkSystemParameter = (
  systemParameters: SystemParameter[],
  systemParameterName: string,
  value: string
): boolean | null => {
  const foundParameter = findSystemParameter(
    systemParameters,
    systemParameterName
  )
  return foundParameter?.value === value
}

/**
 * Find a system parameter by name.
 *
 * @param systemParameters - An array of system parameters to search through.
 * @param systemParameterName - The name of the system parameter to search for.
 * @returns The system parameter with the specified name, or null if it doesn't exist.
 */
const findSystemParameter = (
  systemParameters: SystemParameter[],
  systemParameterName: string
): SystemParameter | null => {
  return systemParameters.find(
    (systemParameter) => systemParameter.name === systemParameterName
  )
}

export default new SystemParameterModule({ store, name: 'systemParameter' })
