
import { Driver } from '@/models/dto'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { phoneFormatFilter } from '@/utils/phone'

@Component({})
export default class DriverInfoContact extends Vue {
  @Prop({ type: Object, required: true }) readonly driver: Driver
  @Prop({ type: Boolean }) readonly hideNumber: boolean

  phoneFormatFilter = phoneFormatFilter

  driverAbbreviatedName(driver: Driver): string {
    const { firstName, lastName } = driver
    if (!firstName || !lastName) {
      return ''
    }
    return `${firstName} ${lastName[0]}.`
  }

  driverInitials(driver: Driver): string {
    const { firstName, lastName } = driver
    if (!firstName || !lastName) {
      return ''
    }
    return `${firstName[0]}${lastName[0]}`
  }
}
