var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (!_vm.routes)?_c('div',{staticClass:"margin-y-10 d-flex flex-column align-self-center"},[_c('div',{staticClass:"align-self-center"},[_vm._v("No data available.")]),_c('div',{staticClass:"align-self-center"},[_vm._v(" Route usage will appear here once riders start taking trips. ")])]):_c('div',{staticClass:"margin-y-4 align-self-center",class:_vm.$vuetify.breakpoint.smAndUp ? 'w-600' : 'w-300'},[_c('CUBarChart',{attrs:{"chart-data":_vm.formattedChartData,"chart-options":{
      responsive: true,
      aspectRatio: '1/3',
      plugins: {
        legend: {
          display: true,
        },
        datalabels: {
          display: false,
        },
      },
      scales: {
        yAxes: {
          stacked: true,
          min: _vm.minTickMark,
          max: _vm.maxTickMark,
          ticks: {
            display: true,
            maxTicksLimit: 6,
            beginAtZero: false,
          },
        },
        xAxes: {
          stacked: true,
        },
      },
    }}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }