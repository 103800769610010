
import { Vue, Component, Prop } from 'vue-property-decorator'
import ShareQuoteDialog from '@/components/ShareQuoteDialog.vue'
import BookingProtectionDialog from '@/components/BookingProtectionDialog.vue'
import { TranslateResult } from 'vue-i18n'
import CharterUPRating from '@/components/CharterUPRating.vue'

@Component({ components: { ShareQuoteDialog, BookingProtectionDialog, CharterUPRating } })
export default class ProviderMobileFooter extends Vue {
  @Prop({ type: Object, default: null }) readonly providerDetail: any
  @Prop({ type: Boolean }) readonly showShareQuoteDialog: boolean
  @Prop({ type: Boolean, default: false })
  readonly isElite: boolean
  @Prop({ type: Boolean }) readonly isSelfServe: boolean
}
