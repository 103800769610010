import { Action, Module, VuexModule } from 'vuex-class-modules'
// register module (could be in any file)
import store from '@/store/index'
import { Alert } from '@/models/Alert'

const DEFAULT_DURATION = 5000

@Module({ generateMutationSetters: true })
class AlertModule extends VuexModule {
  // state
  _queue: Alert[] = []
  _current: Alert = null

  // getters
  get current(): Alert {
    return this._current
  }

  // mutations (mutations are autogenerated for each root level state field)

  // actions
  @Action
  add(alert: Alert): void {
    if (!alert.hasOwnProperty('duration')) {
      alert.duration = DEFAULT_DURATION
    }
    if (!alert.hasOwnProperty('dismissible')) {
      alert.dismissible = true
    }
    this._queue.push(alert)
    if (!this._current) {
      this._current = this._queue.shift()
    }
  }

  @Action
  clear(): void {
    this._current = null
    if (this._queue.length) {
      this._current = this._queue.shift()
    }
  }
}

export default new AlertModule({ store, name: 'alert' })
