import { AxiosResponse } from 'axios'
import { baseUrl } from '@/utils/env'
import { HttpService } from './common/HttpService'
import { ApiResult, UpdateFlightInfoPayload } from '@/models/dto'

const httpService: HttpService = new HttpService()

export default {
  /**
   * Creates or updates flight information for an airport stop.
   * @param payload - The payload containing the stopId and flightInformation.
   * @returns  A promise that resolves to the HTTP response from the server.
   */
  updateFlightInformation(
    payload: UpdateFlightInfoPayload
  ): Promise<AxiosResponse<ApiResult>> {
    const url = `https://${baseUrl()}/stops/${payload?.stopId}/updateFlightInfo`
    return httpService.patch<ApiResult, UpdateFlightInfoPayload>(url, payload)
  },
}
