import { Action, Module, VuexModule } from 'vuex-class-modules'
import store from '@/store/index'

@Module({ generateMutationSetters: true })
class SurveyModule extends VuexModule {
  _show = false
  _quoteId: number = null

  /**
   * Whether the survey is visible or not.
   * @returns whether the survey is visible or not.
   */
  get show(): boolean {
    return this._show
  }
  /**
   * Returns the quote ID associated with the survey.
   * @returns the quote ID currently associated with the survey.
   */
  get quoteId(): number {
    return this._quoteId
  }

  /**
   * Opens the survey
   */
  @Action
  open(): void {
    this._show = true
  }

  /**
   * Closes the survey and clears the quote ID.
   */
  @Action
  close(): void {
    this._show = false
    this._quoteId = null
  }

  /**
   * Sets the quote ID associated with the survey.
   * @param quoteId - the quote ID to associate with the survey.
   */
  @Action
  setQuoteId(quoteId: number): void {
    this._quoteId = quoteId
  }
}

export default new SurveyModule({ store, name: 'survey' })
