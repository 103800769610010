import { TripModificationStop as TripModificationStopDTO } from '@/models/dto'
import { TripModificationRisk } from './TripModificationRisk'
import { TripModificationAddress } from './TripModificationAddress'
export class TripModificationStop implements TripModificationStopDTO {
  address = null
  orderIndex = null
  pickupDatetime = null
  dropoffDatetime = null
  risks = []
  travelTimeFromPreviousStopInSeconds = 0 // calculated from trip estimations

  constructor(stop?: TripModificationStopDTO) {
    if (!stop) {
      return
    }
    this.address = new TripModificationAddress(stop.address)
    this.orderIndex = stop.orderIndex
    this.pickupDatetime = stop.pickupDatetime
    this.dropoffDatetime = stop.dropoffDatetime
    this.risks = (stop.risks || []).map((risk) => new TripModificationRisk(risk))
    this.travelTimeFromPreviousStopInSeconds = stop.travelTimeFromPreviousStopInSeconds
  }
}
