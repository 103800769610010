
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class CUDataTableRowEditActions extends Vue {
  @Prop({
    required: true,
    type: String,
  })
  eventName!: string

  @Prop({
    required: true,
    type: Boolean,
  })
  isMobile!: boolean

  @Prop({
    required: true,
  })
  row!: any

  @Prop({
    required: true,
    type: Number,
  })
  rowIndex!: number

  get cancelEventName(): string {
    return `cancel-${this.eventName}`
  }
}
