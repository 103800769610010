
import { Vue, Component, Prop } from 'vue-property-decorator'
import CUDataTable from '@/components/CUDataTable.vue'
import CUDataTableFilters from '@/components/CUDataTableFilters.vue'
import {
  TableViewFilter,
  TableViewTab,
  TableViewChip,
  VDataTableParameters,
} from '@/models/TableView'
import { DataTableColumn } from '@/models/DataTableColumn'
import { EventBus } from '@/utils/eventBus'
import { toKebab } from '@/utils/string'
import { filter } from '@/utils/filter'
import { sort } from '@/utils/sort'
import { AxiosResponse } from 'axios'
import { ActionColumn } from '@/models/ActionColumn'
import { removeFromArray } from '@/utils/array'
import { SelectColumn } from '@/models/SelectColumn'
import { v4 as uuidv4 } from 'uuid'

@Component({
  components: { CUDataTable, CUDataTableFilters },
})
export default class CUCollectionTable extends Vue {
  @Prop({ type: Array, required: false, default: () => [] })
  columns!: DataTableColumn[]
  @Prop({ type: String, required: true }) collection!: string
  @Prop({ type: Array, required: false, default: () => [] })
  actions!: ActionColumn[]
  @Prop({ type: Object, required: false, default: () => {} })
  selectColumn!: SelectColumn
  @Prop({ type: String, required: false }) itemKey!: string
  @Prop(Function) fetchMethod!: any
  @Prop({ type: Function, required: false }) supplementalRowMethod!: any
  @Prop({ required: false, default: () => filter() }) filters: any
  @Prop({ required: false, default: () => sort() }) sorts: any
  @Prop({ required: false, default: () => [] })
  initialFilters!: TableViewFilter[]
  @Prop({ required: false, default: () => [] }) tabs!: TableViewTab[]
  @Prop({ required: false, default: () => [] }) chips!: TableViewChip[]
  @Prop({ type: Boolean, required: false, default: false })
  isFilterDialogOpen: boolean
  @Prop({ type: String, required: false })
  noDataText!: string
  @Prop({ type: String, required: false })
  mobileViewOnBreakpoint!: string
  @Prop({ type: Boolean, required: false })
  hideDefaultHeader!: boolean
  @Prop({ type: Boolean, required: false }) dense!: boolean
  @Prop({ type: Boolean, required: false }) showSelect!: boolean
  @Prop({ required: false, default: 10 }) pageSize: number
  @Prop({ required: false, default: 'id' }) elementKey: string
  @Prop({ type: String, required: false, default: 'data-table' })
  tableElementId!: string
  @Prop({ type: String, required: false, default: () => uuidv4() })
  uuid!: string
  @Prop(Function) rowClickHandler: (row: any, index: number) => any
  @Prop(Function) postFirstLoadHandler: (rows: any[]) => void

  items: unknown[] = []
  selectedItems: unknown[] = []
  loading = false
  serverItemsLength = 0
  debounce: any = null
  filterList: any[] = []
  initialFiltersSet = false
  initialLoadCompleted = false
  activeTab: TableViewTab = null
  page = 1

  get showLoaders(): boolean {
    return !this.initialLoadCompleted
  }

  get areInitialFiltersSet(): boolean {
    return this.initialFilters.length && !this.initialFiltersSet
  }

  get visibleColumns(): DataTableColumn[] {
    return this.columns.filter((column) => !column.hidden)
  }

  get activeTableElementId(): string {
    if (this.activeTab?.tableElementId != null) {
      return this.activeTab?.tableElementId
    }
    return this.tableElementId
  }

  get options(): VDataTableParameters {
    return {
      page: this.page,
      itemsPerPage: this.pageSize,
    }
  }
  set options(newOptions: VDataTableParameters) {
    this.page = newOptions.page
    this.load()
  }

  mounted(): void {
    EventBus.$on('set-tableview-page', (page) => {
      this.page = page
    })
    EventBus.$on(`refresh-tableview-${this.uuid}`, () => {
      this.load()
    })
    EventBus.$on(`toggle-select-item-${this.uuid}`, (event) => {
      this.handleSelect(event)
    })
  }

  beforeUnmount(): void {
    EventBus.$off(`refresh-tableview-${this.uuid}`)
    EventBus.$off(`toggle-select-item-${this.uuid}`)
  }

  load(immediate = false): Promise<void> {
    if (this.areInitialFiltersSet) {
      return
    }
    if (immediate) {
      this.refresh()
      return
    }
    if (this.debounce) {
      window.clearTimeout(this.debounce)
    }
    this.debounce = window.setTimeout(async () => {
      await this.refresh()
    }, 50)
  }

  async refresh(): Promise<void> {
    this.loading = true
    await this.$nextTick(async () => {
      const response: AxiosResponse = await this.fetchMethod({
        pageSize: this.options.itemsPerPage,
        page: this.options.page,
        filters: this.filters.asQueryParams(),
        sorts: this.sorts.asQueryParams(),
      })
      const { data } = response

      this.serverItemsLength = data.count

      const items: unknown[] = data.resultList

      // FOR BETTER SPEED, BUT MORE API CALLS, WE CAN MOVE THE CALLING OF DETAIL FUNCTIONS TO COMPONENTS SLOTTED INTO THE PROPER CELLS
      // THIS WILL DEFINITELY INCREASE SPEED, BUT ALSO THE NUMBER OF CALLS TO THE BACKEND WILL DOUBLE
      if (this.supplementalRowMethod) {
        await Promise.all(items.map((item) => this.supplementalRowMethod(item)))
      }

      this.items = items.map((item: any) => {
        const obj = {
          id: item[this.itemKey],
          elementId: toKebab(item[this.elementKey] || ''),
        }
        return Object.assign({}, item, obj)
      })
      this.loading = false
      if (!this.initialLoadCompleted) {
        this.initialLoadCompleted = true
        this.$emit('initial-load-completed')
        if (this.postFirstLoadHandler) {
          this.postFirstLoadHandler(this.items)
        }
      }
    })
    this.$emit('load')
  }

  handleSelect({ value, key }: { value: boolean; key: any }): void {
    const row = this.items.find((item) => item[this.itemKey] === key)
    if (!value) {
      removeFromArray(this.selectedItems, key)
      this.$emit('deselect-row', row)
    } else if (!this.selectedItems.includes(key)) {
      this.selectedItems.push(key)
      this.$emit('select-row', [row])
    }
  }

  handleSelectPredefined(filter: (item: unknown) => boolean): void {
    this.$emit('clear-selected')
    const filteredItems = this.items.filter(filter)
    const filteredItemKeys = filteredItems.map((item) => item[this.itemKey])
    this.selectedItems = filteredItemKeys
    this.$emit('select-row', filteredItems)
  }
}
