import { TripModificationRisk as TripModificationRiskDTO } from '@/models/dto'

export class TripModificationRisk implements TripModificationRiskDTO {
  riskTypeId = null;

  constructor(risk: TripModificationRiskDTO) {
    if (!risk) {
      return
    }
    this.riskTypeId = risk.riskTypeId
  }
}
