
import { Vue, Component, Prop } from 'vue-property-decorator'
import TripNameDialog from '@/components/TripNameDialog.vue'
import PendingTripDialog from '@/components/PendingTripDialog.vue'
import ReservationCardVehicleImage from '@/components/ReservationCardVehicleImage.vue'
import TripInfoItineraryModal from '@/components/TripInfoItineraryModal.vue'
import TripLiveTracking from '@/components/TripLiveTracking.vue'
import {
  toSentence,
  formatReservationPickupDestinationText,
} from '@/utils/string'
import { Stop } from '@/models/dto'
import { DateTime } from 'luxon'
import { ReservationStatusKey } from '@/utils/enum'
import { ReservationDetail } from '../models/dto/ReservationDetail'
import SkeletonBox from '@/components/SkeletonBox.vue'

@Component({
  components: {
    TripNameDialog,
    PendingTripDialog,
    ReservationCardVehicleImage,
    TripInfoItineraryModal,
    TripLiveTracking,
    SkeletonBox,
  },
})
export default class ReservationCard extends Vue {
  @Prop({ type: String, default: null }) readonly id: string
  @Prop({ type: Boolean }) readonly stacked: boolean
  @Prop({ type: Boolean, default: false }) readonly allowReservationManagement: boolean
  @Prop({ type: Number, default: 300 }) readonly imgWidth: number
  @Prop({ type: Object, required: true }) readonly reservation: ReservationDetail
  @Prop({ type: Boolean }) readonly featured: boolean
  @Prop({ type: Boolean }) readonly loading: boolean
  @Prop({ type: String, default: null }) readonly to: string
  @Prop({ type: Object, default: () => {}, required: true }) readonly trip: any

  pendingTripDialogIsOpen = false
  tripNameDialogIsOpen = false
  isItineraryModalOpen = false

  get isPending(): boolean {
    return (
      !this.wasBookedOver48HoursAgo &&
      !this.isReservationFullyAccepted &&
      this.isReservationUpcomingOrOnHold
    )
  }

  get wasBookedOver48HoursAgo(): boolean {
    const createdOn = this.reservation?.createdOn
    if (!createdOn) {
      return false
    }
    const now = DateTime.local()
    const diff = now.diff(DateTime.fromISO(createdOn), 'hours').toObject()
    return diff?.hours > 48
  }

  get isReservationFullyAccepted(): boolean {
    return this.reservation.referralStatus === 'fully_accepted'
  }

  get isReservationUpcomingOrOnHold(): boolean {
    return (
      this.reservation.reservationStatus === 'upcoming' ||
      this.reservation.reservationStatus === 'hold'
    )
  }

  get isReservationComplete(): boolean {
    return (
      this.reservation.reservationStatus === ReservationStatusKey.Finished ||
      this.reservation.reservationStatus === ReservationStatusKey.Cancelled
    )
  }

  get isCancelled(): boolean {
    return this.reservation.reservationStatus === ReservationStatusKey.Cancelled
  }

  get formattedReservationStatus(): string {
    return toSentence(this.reservation.reservationStatus)
  }

  get reservationCities(): string {
    return formatReservationPickupDestinationText(
      this.reservation,
      this.$t('common.TO')
    )
  }

  get firstPickup(): Stop {
    return this.reservation.stops.find((stop) => stop.orderIndex === 0)
  }

  get isWithin3HoursOfPickup(): boolean {
    if (!this.firstPickup) {
      return false
    }
    const pickupTime = this.$dayjs(this.firstPickup.pickupDatetime).subtract(
      3,
      'hours'
    )
    return pickupTime.isBefore(this.$dayjs())
  }

  get hasLiveTracking(): boolean {
    return this.reservation?.hasLiveTrackingData
  }

  get showViewDetailsButton(): boolean {
    if (!this.$vuetify.breakpoint.mdAndDown) {
      return false
    }
    if (
      this.reservation.reservationStatus === ReservationStatusKey.Started &&
      this.hasLiveTracking
    ) {
      return false
    }
    if (this.reservation.reservationStatus === ReservationStatusKey.Finished) {
      return true
    }
    return true
  }

  get formattedCancellationDate(): string {
    const { cancelledOn } = this.reservation || {}
    if (!cancelledOn) {
      return ''
    }
    return this.$dayjs(cancelledOn).format('MMMM D, h:mm A')
  }

  openNewTrackingWindow(): void {
    const path = {
      name: 'live-tracking',
      params: {
        reservationId: this.reservation.reservationId.toString(),
      },
    }
    const routeData = this.$router.resolve(path)
    window.open(routeData.href, '_blank')
  }
}
