
import auth from '@/store/modules/auth'
import { phoneFormatFilterHyphens, getSupportNumber } from '@/utils/phone'
import { Vue, Component } from 'vue-property-decorator'

@Component({})
export default class SupportNumber extends Vue {
  get supportNumber(): string {
    return (
      getSupportNumber(auth.customer?.isElite)
    )
  }
  get formattedSupportNumber(): string {
    return phoneFormatFilterHyphens(this.supportNumber)
  }
}
