var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"position-absolute top-0 left-0 padding-a-0 margin-a-0 w-full-vw overflow-hidden",style:({ height: '110vh' })},_vm._l((_vm.count),function(index){return _c('div',{key:index,ref:`confetti-${index}`,refInFor:true,staticClass:"confetti",style:({
      width: `${_vm.randomWidth()}px`,
      height: `${_vm.randomConfettiHeight()}px`,
      left: `${_vm.initialXPositions[index]}%`,
      top: `${_vm.randomConfettiInitialYPos()}%`,
      opacity: _vm.randomOpacity(),
      backgroundColor: _vm.randomColor(),
      transform: `rotate(${_vm.randomRotation()}deg)`,
      animationName: `drop-${index}`,
      animationIterationCount: _vm.iterations,
      animationDuration: `${_vm.randomAnimationDuration()}s`,
      animationDelay: `${Math.random()}s`,
      animationTimingFunction: 'cubic-bezier(0.310, 0.440, 0.445, 1.250)',
    })})}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }