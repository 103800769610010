
import { Vue, Component, Prop } from 'vue-property-decorator'
import { QuoteDetailV2, QuoteDetailV2Bid } from '../models/dto'
import { roundedCurrencyFilter } from '../utils/string'
import { isQuoteSelfServeAndNeedsManualAttention } from '../utils/quote'

@Component
export default class SelfServeVehiclePricingCardPrice extends Vue {
  @Prop({ type: Object, default: () => {}, required: true })
  readonly quote: QuoteDetailV2

  get doesQuoteNeedManualAttention(): boolean {
    return isQuoteSelfServeAndNeedsManualAttention(this.quote)
  }

  get bid(): QuoteDetailV2Bid {
    return this.quote?.bids?.[0]
  }

  get totalAmount(): number {
    return this.bid?.totalAmount
  }

  get totalDollars(): number {
    return Math.floor(this.totalAmount)
  }

  get totalDollarsFormatted(): string {
    if (!this.totalDollars) {
      return ''
    }
    return roundedCurrencyFilter(this.totalDollars)
  }

  get totalCents(): string {
    const cents = this.totalAmount - this.totalDollars
    return cents.toFixed(2).split('.')[1]
  }
}
