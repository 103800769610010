
import { Vue, Component, Prop } from 'vue-property-decorator'
import PaymentSent from '@/components/PaymentSent.vue'
import { ReservationIdentifiers } from '@/models/dto/Reservation'

@Component({
  components: {
    PaymentSent,
  },
})
export default class PaymentSentDialog extends Vue {
  @Prop({ type: Array, default: () => [] })
  readonly reservationIdentifiers: ReservationIdentifiers[]
  @Prop({ type: Boolean }) readonly value: boolean
}
