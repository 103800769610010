var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{ref:"slider",staticClass:"slider position-relative"},[_c('div',{staticClass:"slider__values"},_vm._l((_vm.stepListCount),function(val){return _c('div',{key:val,ref:`value-${val}`,refInFor:true,staticClass:"slider__values__value",class:{ 'slider__values__value--active': val === _vm.value },on:{"click":function($event){return _vm.handleValueClick(val)},"mouseover":function($event){return _vm.moveBusToHoverValue(val)},"mouseleave":_vm.handleMouseLeave}},[_vm._v(" "+_vm._s(val)+" ")])}),0),_c('div',{ref:"slider-bus",staticClass:"slider__bus position-absolute z-10 cursor-pointer draggable",style:({
      transform: _vm.busDirection < 0 ? 'scaleX(-1)' : '',
      transition: `left ${_vm.busAnimationLength}s`,
    }),attrs:{"id":"slider-bus"},on:{"mousedown":_vm.startDrag,"mouseup":_vm.mouseUpHandler,"mouseleave":_vm.mouseUpHandler}},[_c('img',{style:({
        transition: 'all 0.25s',
        'transform-origin': '50% 50%',
        transform: _vm.dragTarget ? 'scale(1.33)' : '',
      }),attrs:{"id":"bus__slider__image","src":require("@/assets/images/sliderBus.svg"),"alt":"bus slider handle","height":"18"}})]),_c('div',{ref:"slider-bar",staticClass:"slider__bar"},[_c('div',{ref:"left-bar",staticClass:"slider__bar--left",style:({ transition: `width ${_vm.busAnimationLength}s` })}),_c('div',{ref:"right-bar",staticClass:"slider__bar--right",style:({ transition: `width ${_vm.busAnimationLength}s` })})]),_c('v-row',[_c('v-col',{staticClass:"shrink font-medium font-12 cursor-default white-space-nowrap text-white padding-l-6",style:({ 'margin-top': '2px' })},[_vm._v(" Not Likely ")]),_c('v-spacer'),_c('v-col',{staticClass:"shrink font-medium font-12 cursor-default white-space-nowrap text-white padding-l-6",style:({ 'margin-top': '2px' })},[_vm._v(" Very Likely ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }