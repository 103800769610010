
import { Vue, Component, Prop } from 'vue-property-decorator'
import { NavigationItem } from '@/models/NavigationItem'
import quotes from '@/store/modules/quotes'
import { toKebab } from '@/utils/string'

@Component({})
export default class AppBarNavigationItem extends Vue {
  @Prop({ type: Object, required: true }) readonly item: NavigationItem
  @Prop({ type: Number, required: true }) readonly index: number

  quotes = quotes
  toKebab = toKebab
}
