
import { Vue, Component, Prop } from 'vue-property-decorator'
import { Stop } from '../models/dto'
import { addressPrettyFromStop } from '../utils/string'

@Component({})
export default class TripInfoItineraryStopNote extends Vue {
  @Prop({ type: Object, required: true }) stop!: Stop

  isExpanded = false

  get stopNotes(): string {
    return this.stop.stopNotes?.[0]?.html || this.stop.stopNotes?.[0]?.note
  }

  get hasStopNotes(): boolean {
    return !!this.stopNotes
  }

  get formattedAddress(): string {
    return addressPrettyFromStop(this.stop)
  }

  get hideStopNotesArrow(): boolean {
    const CUTOFF_TO_HIDE_ARROW = 55

    if (this.stopNotes) {
      return this.stopNotes.length < CUTOFF_TO_HIDE_ARROW
    }
    return this.stop.notes.length < CUTOFF_TO_HIDE_ARROW
  }

  toggleStopNotes(): void {
    this.isExpanded = !this.isExpanded
  }
}
