
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import customerAccount from '@/services/customerAccount'
import { Team } from '@/models/dto'
import AutoCompleteAddress from '@/components/AutoCompleteAddress.vue'
import FileUploader from '@/components/FileUploader.vue'
import AlertBox from '@/components/AlertBox.vue'

@Component({
  components: {
    AutoCompleteAddress,
    FileUploader,
    AlertBox,
  },
})
export default class OrganizationEditTeamDialog extends Vue {
  @Prop({ type: Object, default: () => {} }) readonly team: Team
  @Prop({ type: Number }) readonly index: number

  @Watch('dialog')
  dialogChanged(newVar: boolean): void {
    if (!newVar) {
      this.resetValues()
    } else {
      this.setup()
    }
    if (!this.dialog) {
      this.$emit('close')
    }
  }

  formIsValid = false
  dialog = false
  success = false
  changeImage = false
  errorMessage = ''

  formData: any = {
    teamName: {
      value: '',
      rules: [(v) => !!v || 'Team name is required'],
    },
    address: {
      value: '',
      rules: [(v) => !!v || 'Address is required'],
    },
    email: {
      value: '',
      rules: [
        (v) => !!v || 'E-mail is required',
        (v) => /.+@.+/.test(v) || 'E-mail must be valid',
      ],
    },
    phoneNumber: {
      value: '',
      rules: [(v) => !!v || 'Phone number is required'],
    },
    teamLogoImage: null,
  }

  mounted(): void {
    this.setup()
  }

  setup(): void {
    this.success = false
    this.changeImage = false
    this.formData.teamLogoImage = null
    this.formData.teamName.value = this.team.name
    this.formData.address.value = this.team.address
    this.formData.address.initialSearch = this.team.address?.addressName
    this.formData.email.value = this.team.email
    this.formData.phoneNumber.value = this.team.phone
  }
  resetValues(): void {
    const form = this.$refs.form as any
    form.reset()
  }
  backToSite(): void {
    this.success = false
    this.setup()
    this.dialog = false
  }
  async send(): Promise<void> {
    const form = this.$refs.form as any
    if (!form.validate()) {
      return
    }
    this.errorMessage = ''
    const payload = {
      name: this.formData.teamName.value,
      address: this.formData.address.value,
      email: this.formData.email.value,
      phone: this.formData.phoneNumber.value,
    }
    try {
      await customerAccount.edit(this.team.customerAccountId, payload)
      if (this.team.teamLogoImage && this.changeImage) {
        const bodyFormData = new FormData()
        bodyFormData.append('files', this.team.teamLogoImage[0])
        await customerAccount.uploadLightLogo(
          this.team.customerAccountId,
          bodyFormData
        )
      }
      this.$emit('success')
      this.dialog = false
      // eslint-disable-next-line prettier/prettier, @typescript-eslint/no-explicit-any
    } catch (e: any) {
      const errorMessage = e.response?.data?.message
      this.errorMessage = errorMessage
    }
  }
  selectAddress({ place }): void {
    this.formData.address.value = place
  }
}
