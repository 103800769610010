
import auth from '@/store/modules/auth'

import { Vue, Component, Watch } from 'vue-property-decorator'
import { phoneFormatFilterHyphens, getSupportNumber } from '@/utils/phone'
import CustomerAccountDefaultValueSet from '@/models/dto/CustomerAccountDefaultValueSet'
import AccountExecutive from '@/models/dto/AccountExecutive'
import customerAccount from '@/services/customerAccount'

@Component({})
export default class AccountSupport extends Vue {
  auth = auth
  effectiveDefaultValueSet: CustomerAccountDefaultValueSet = null

  async created(): Promise<void> {
    await this.getEffectiveDefaultValueSet()
  }

  @Watch('auth.customerAccount.customerAccountId')
  customerAccountChanged(): void {
    this.getEffectiveDefaultValueSet()
  }

  get updatesAndSupport() {
    if (
      !this.auth.customerAccount?.supportEmail &&
      !this.auth.customerAccount?.supportPhone
    ) {
      return null
    }

    let formattedPhone = ''
    if (this.auth.customerAccount?.supportPhone) {
      formattedPhone  = this.auth.customerAccount?.supportPhone
    } else {
      formattedPhone = getSupportNumber(auth.customer.isElite)
    }
    formattedPhone = phoneFormatFilterHyphens(formattedPhone)

    return {
      email: this.auth.customerAccount?.supportEmail,
      phone: formattedPhone,
    }
  }

  get formattedAccountExecutiveNumber(): string {
    const accountExecutivePhoneNumber = this.accountExecutive?.phone
    const supportNumber = getSupportNumber(auth.customer.isElite)
    return phoneFormatFilterHyphens(
      accountExecutivePhoneNumber || supportNumber
    )
  }

  get accountExecutive(): AccountExecutive {
    return this.effectiveDefaultValueSet?.accountExecutive
  }

  async getEffectiveDefaultValueSet(): Promise<void> {
    if (!auth.user || !auth.customerAccount) {
      return
    }
    try {
      const { data } = await customerAccount.effectiveDefaultValueSet(
        auth.customerAccount.customerAccountId
      )
      this.effectiveDefaultValueSet = data
    } catch (error) {
      console.error('Error fetching effective default value set', error)
    }
  }
}
