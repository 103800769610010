
import { Vue, Component } from 'vue-property-decorator'
import flight from '@/store/modules/flight'
import stop from '@/services/stop'
import { KeyCode } from '@/utils/enum'
import { FlightInformation, UpdateFlightInfoPayload } from '@/models/dto'
import { addressPrettyFromStop } from '@/utils/string'
import { DateTime } from 'luxon'

@Component({})
export default class FlightInformationDialog extends Vue {
  flight = flight
  addressPrettyFromStop = addressPrettyFromStop
  submitting = false

  get title() {
    if (flight.flightInformation) {
      return this.$t('flightInfo.UPDATE_TITLE')
    }
    return this.$t('flightInfo.ADD_TITLE')
  }

  get addressTitle(): string {
    return flight.stop?.address?.title
  }

  get pickupDatetime(): string {
    return flight.stop?.pickupDatetime
  }

  get dropoffDatetime(): string {
    return flight.stop?.dropoffDatetime
  }

  get timeZone(): string {
    return flight.stop?.address?.timeZone
  }

  close(): void {
    flight.close()
  }

  handleKeydown(event): void {
    if (event.keyCode === KeyCode.Escape) {
      this.close()
    }
  }

  formatDisplayTime(time: string, timeZone: string) {
    return DateTime.fromISO(time, { zone: timeZone }).toFormat(
      'LL/dd/yy - hh:mm a'
    )
  }

  async submitUpdate(): Promise<void> {
    this.submitting = true
    try {
      const flightInformation: FlightInformation = {
        flightInformationId: flight.flightInformation?.flightInformationId,
        flightNumber: flight.activeFlightNumber,
        airline: flight.activeAirline,
      }
      const payload: UpdateFlightInfoPayload = {
        stopId: flight.stop?.stopId,
        flightInformation,
      }
      await stop.updateFlightInformation(payload)
      this.$emit('update-flight-info')
    } catch (error) {
      console.error(error)
    }
    this.submitting = false
    this.$forceUpdate()
    this.close()
  }
}
