import {
  PaymentGatewayResult,
  PaymentPayload,
  PaymentResult,
} from '@/models/dto'
import { baseUrl } from '@/utils/env'
import { AxiosResponse } from 'axios'
import { HttpService } from './common/HttpService'

const httpService: HttpService = new HttpService()

export default {
  /**
   * Retrieves the default payment gateway for a given company ID.
   * @param companyId - The company ID to retrieve the default payment gateway for.
   * @returns A promise that resolves to the default payment gateway for the specified company ID.
   */
  getDefaultGateway(
    companyId: number
  ): Promise<AxiosResponse<PaymentGatewayResult>> {
    const url = `https://${baseUrl()}/paymentGateway/company/${companyId}/default`
    return httpService.get(url)
  },
  /**
   * Retrieves the all payment gateways for the actors company ID.
   * @returns A promise that resolves to a list of payment gateways.
   */
  getPaymentGateways(): Promise<AxiosResponse<PaymentGatewayResult>> {
    const url = `https://${baseUrl()}/paymentGateway`
    return httpService.get(url)
  },
  /**
   * Collects payment for a given payment payload.
   * @param payload - The payload containing the details of the payment.
   * @returns A promise that resolves to the result of the payment collection.
   */
  collect(payload: PaymentPayload): Promise<AxiosResponse<PaymentResult>> {
    if (
      payload.hasOwnProperty('paymentProfileId') &&
      !payload.paymentProfileId
    ) {
      delete payload.paymentProfileId
    }
    const url = `https://${baseUrl()}/payment/addReservationsPayment`
    return httpService.post<PaymentResult, PaymentPayload>(url, payload)
  },
}
