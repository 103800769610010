
import { Vue, Component, Prop, InjectReactive } from 'vue-property-decorator'

@Component({})
export default class ReservationCancelConfirmModal extends Vue {
  @Prop({ type: Boolean }) readonly value: boolean
  @InjectReactive('isSubmitting') isSubmitting

  close(): void {
    this.$emit('input', false)
  }
}
