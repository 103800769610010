
import { Vue, Component, Prop } from 'vue-property-decorator'
import { toTitle } from '@/utils/string'
import { NavigationLink } from '@/models/NavigationLink'

@Component({})
export default class MainWithSidePanelLayoutNavigation extends Vue {
  @Prop({ type: Array, required: true }) readonly links: NavigationLink[]

  toTitle = toTitle

  handleNavigationClick(index): void {
    const { name } = this.links[index]
    this.$router.push({ name })
  }

  get anyItemHasIcon(): boolean {
    return this.links.some((item) => item.icon)
  }

  get routeName(): string {
    return this.$route.name
  }
}
