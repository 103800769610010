
import { Vue, Component, Model } from 'vue-property-decorator'
import CUModal from '@/components/CUModal.vue'
import InfoModal from '@/components/InfoModal.vue'
import tripModificationStore from '@/store/modules/tripModification'
import { KeyCode } from '@/utils/enum'

@Component({
  components: {
    CUModal,
    InfoModal,
  },
})
export default class TripModificationCloseDialog extends Vue {
  @Model('input') readonly value!: boolean | null
  KeyCode = KeyCode

  closeDialog(): void {
    this.$emit('input', false)
  }

  closeWizard(): void {
    tripModificationStore.setIsOpen(false)
  }
}
