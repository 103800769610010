var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('CUModal',{attrs:{"value":_vm.value,"width":"550px","closeable":""},on:{"input":_vm.close,"keydown":function($event){$event.stopPropagation();return _vm.handleKeydown.apply(null, arguments)}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.title)+" ")]},proxy:true},{key:"text",fn:function(){return [_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.submitHotelInformation.apply(null, arguments)}}},[_c('div',{staticClass:"w-full"},[_c('p',{staticClass:"font-18 font-medium margin-t-n4"},[_vm._v(" "+_vm._s(_vm.$t('reservationDetail.hotelInformationDialog.HOTEL_START_LABEL'))+" "),_c('span',{staticClass:"font-weight-600"},[_vm._v(" "+_vm._s(_vm.hotelCheckInDate)+" ")]),_vm._v(" "+_vm._s(_vm.$t('reservationDetail.hotelInformationDialog.HOTEL_END_LABEL'))+" "),_c('span',{staticClass:"font-weight-600"},[_vm._v(" "+_vm._s(_vm.hotelCheckOutDate)+" ")])]),_c('div',{staticClass:"w-full margin-t-3"},[_c('label',{staticClass:"d-flex margin-t-1 font-weight-600",attrs:{"for":"hotel-address-input"}},[_vm._v(" "+_vm._s(_vm.$t( 'reservationDetail.hotelInformationDialog.HOTEL_ADDRESS_LABEL' ))+" ")]),_c('AutoCompleteAddress',{attrs:{"id":`hotel-information-dialog-address`,"clearable":"","clear-icon":"replay","initial-search":_vm.addressName,"auto-focus":!_vm.address,"hint":_vm.$t(
                'reservationDetail.hotelInformationDialog.HOTEL_ADDRESS_FOOTER'
              ),"placeholder":_vm.$t(
                'reservationDetail.hotelInformationDialog.HOTEL_ADDRESS_PLACEHOLDER'
              ),"limit-countries":""},on:{"place-selected":function($event){return _vm.setHotelAddress($event.place)},"place-cleared":function($event){return _vm.setHotelAddress(null)}}})],1),_c('div',{staticClass:"d-inline-block w-one-half"},[_c('label',{staticClass:"font-weight-600",attrs:{"for":"hotel-name"}},[_vm._v(" "+_vm._s(_vm.$t('reservationDetail.hotelInformationDialog.HOTEL_NAME_LABEL'))+" ")]),_c('v-text-field',{staticClass:"w-eleven-twelfths h-50",attrs:{"id":"hotel-name-input","name":"hotel-name-input","outlined":"","hide-details":"","maxlength":"50"},model:{value:(_vm.hotelName),callback:function ($$v) {_vm.hotelName=$$v},expression:"hotelName"}})],1),_c('div',{staticClass:"d-inline-block w-one-half"},[_c('label',{staticClass:"font-weight-600",attrs:{"for":"hotel-confirmation-number"}},[_vm._v(" "+_vm._s(_vm.$t( 'reservationDetail.hotelInformationDialog.CONFIRMATION_NUMBER_LABEL' ))+" ")]),_c('v-text-field',{staticClass:"w-eleven-twelfths h-50",attrs:{"id":"hotel-confirmation-number-input","name":"hotel-confirmation-number-input","outlined":"","maxlength":"50","hide-details":"","placeholder":"e.g. ASD821117"},model:{value:(_vm.confirmationNumber),callback:function ($$v) {_vm.confirmationNumber=$$v},expression:"confirmationNumber"}})],1),_c('div',{staticClass:"w-full margin-t-1"},[_c('label',{staticClass:"font-weight-600",attrs:{"for":"hotel-booking-name"}},[_vm._v(" "+_vm._s(_vm.$t( 'reservationDetail.hotelInformationDialog.BOOKING_NAME_LABEL' ))+" ")]),_c('v-text-field',{staticClass:"w-full h-50",attrs:{"id":"hotel-booking-name-input","name":"hotel-booking-name-input","outlined":"","maxlength":"50","placeholder":_vm.$t(
                'reservationDetail.hotelInformationDialog.BOOKING_NAME_PLACEHOLDER'
              )},model:{value:(_vm.bookingName),callback:function ($$v) {_vm.bookingName=$$v},expression:"bookingName"}})],1),_c('div',{staticClass:"w-full margin-t-n2"},[_c('span',[_vm._v(" "+_vm._s(_vm.$t( 'reservationDetail.hotelInformationDialog.CONTACT_SUPPORT_BODY' ))+" ")]),_c('a',{staticClass:"font-bold",on:{"click":function($event){$event.stopPropagation();return _vm.handleContactSupport.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t( 'reservationDetail.hotelInformationDialog.CONTACT_SUPPORT_LINK' ))+" ")])]),_c('v-btn',{staticClass:"w-full margin-t-6",attrs:{"id":"hotel-information-button-save","color":"primary","loading":_vm.loading},on:{"click":_vm.submitHotelInformation}},[_vm._v(" "+_vm._s(_vm.$t('common.SAVE'))+" ")])],1)])]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }