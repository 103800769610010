import { Action, Module, VuexModule } from 'vuex-class-modules'
import store from '@/store/index'

@Module({ generateMutationSetters: true })
class FooterModule extends VuexModule {
  _show = true

  /**
   * Whether the footer should be shown or not.
   * @returns whether the footer should be shown or not.
   */
  get show(): boolean {
    return this._show
  }

  /**
   * Sets whether the footer should be shown or not.
   *
   * @param show - Whether the footer should be shown or not.
   */
  @Action
  setShow(show: boolean) {
    this._show = show
  }
}

export default new FooterModule({ store, name: 'footer' })
