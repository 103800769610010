const now = () => new Date().toISOString()
const format = (m) => (typeof m === 'string' ? m : JSON.stringify(m))
const write = (level, stdwhere, ...msg) => {
  // FIXME: Add a way to turn logging on and off per the environment
  // FIXME: Consider a way to also log to a server for broker users
  const messageParts = []
  let error = null
  msg.forEach((item) => {
    if (typeof item === 'string') {
      // It's a string for the message
      messageParts.push(item)
    } else if (error === null && item && item.stack) {
      // it's an error, probably
      error = item
    } else {
      // Fallback - just stringify it
      messageParts.push(JSON.stringify(item))
    }
  })
  const message = messageParts.join(' ')
  if (error) {
    console[level](message, error)
  } else {
    console[level](message)
  }
}

const info = (...msg) => write('info', 'log', ...msg)
const debug = (...msg) => write('debug', 'log', ...msg)
const warn = (...msg) => write('warn', 'warn', ...msg)
const error = (...msg) => write('error', 'error', ...msg)

export const logger = {
  info,
  debug,
  warn,
  error,
}
