
import { Vue, Component, Prop } from 'vue-property-decorator'
import { TableAction } from '@/models/TableAction'

@Component
export default class CUDataTableActionRow extends Vue {
  @Prop({ type: Array, required: true }) actions!: TableAction[]
  @Prop({ required: false, default: undefined }) rows!: any[]

  actionColor(action: TableAction): string {
    return this.isActionDisabled(action)
      ? 'gray-mid-light'
      : action.color || 'primary'
  }

  handleAction(action: TableAction): void {
    if (action.action) {
      action.action(this.rows)
    }
  }

  isActionDisabled(action: TableAction): boolean {
    return (
      (action.isSingleSelectOnly && this.rows?.length > 1) ||
      (action.isMultiSelectOnly && this.rows?.length === 1) ||
      (typeof action.disabled === 'boolean' && action.disabled) ||
      (typeof action.disabled === 'function' && action.disabled(this.rows))
    )
  }
}
