
import {
  Vue,
  Component,
  Prop,
  ProvideReactive,
  Watch,
} from 'vue-property-decorator'
import TripRequirements from '@/components/TripRequirements.vue'
import TripItinerary from '@/components/TripItinerary.vue'
import TripAmenityCard from '@/components/TripAmenityCard.vue'
import TripNotes from '@/components/TripNotes.vue'
import TripMap from '@/components/TripMap.vue'
import TripMetadata from '@/components/TripMetadata.vue'
import {
  ReservationDetail,
  Trip,
  HotelInformation,
  HotelRequirement,
  AmenityType,
  RequiredVehicle,
} from '@/models/dto'
import { getPickupDestinationCitiesFromAddresses } from '@/utils/string'
import support from '@/store/modules/support'
import { SplitFeatureFlag, VehicleTypeKey } from '@/utils/enum'
import { AMENITY_CARD_SUPPORTED_VEHICLE_TYPE_KEYS } from '@/utils/amenity'
const CUSTOMER_NOTE_TYPE = 2
@Component({
  components: {
    TripMap,
    TripMetadata,
    TripNotes,
    TripRequirements,
    TripItinerary,
    TripAmenityCard,
  },
})
export default class ReservationDetailTripSummary extends Vue {
  @Prop({ type: Object, default: () => {} }) readonly trip: Trip
  @Prop({ type: Boolean }) readonly map: boolean
  @Prop({ type: Boolean }) readonly customerNotes: boolean
  @Prop({ type: Boolean }) readonly itinerary: boolean
  @Prop({ type: Boolean }) readonly quoteConfirmation: boolean
  @Prop({ type: Boolean }) readonly hideStats: boolean
  @Prop({ type: Boolean }) readonly hideTripNotes: boolean
  @Prop({ type: Boolean }) readonly hideAmenities: boolean
  @Prop({ type: Boolean }) readonly showLiveStops: boolean
  @Prop({ type: String, default: '' }) readonly tripStatus: string
  @Prop({ type: String, default: '' }) readonly managedId: string
  @Prop({ type: Object, default: () => {} }) readonly reservation: ReservationDetail
  @Prop({ type: Boolean }) readonly allowReservationManagement: boolean

  @ProvideReactive('reservationStatus') reservationStatus = null
  @ProvideReactive('hasPendingModificationRequest') hasPendingModificationRequest = false

  isItineraryModalOpen = false
  areNewTripAmenitiesEnabled = false
  support = support

  @Watch('reservation')
  reservationChanged(): void {
    this.reservationStatus = this.reservation?.reservationStatus
    this.hasPendingModificationRequest = this.reservation?.hasPendingModificationRequest
  }

  async mounted() {
    this.reservationStatus = this.reservation?.reservationStatus
    this.hasPendingModificationRequest = this.reservation?.hasPendingModificationRequest
    this.areNewTripAmenitiesEnabled = await this.$split.isFeatureEnabled(
      SplitFeatureFlag.NewTripAmenities
    )
  }

  get tripAllStops(): any {
    return this.trip?.stops
  }

  get amenities(): AmenityType[] {
    return this.reservation?.amenities || []
  }

  get tripVehicles(): RequiredVehicle[] {
    return this.reservation?.requiredVehicles
  }

  get amenityEligibleVehicleTypeKeys(): VehicleTypeKey[] {
    const keys = this.tripVehicles
      .map(({vehicleType}) => vehicleType.key as VehicleTypeKey)
      .filter(key => AMENITY_CARD_SUPPORTED_VEHICLE_TYPE_KEYS.includes(key))

    return [...new Set(keys)]
  }

  get hotelRequirement(): HotelRequirement {
    return this.reservation?.hotelRequirement
  }
  get hotelInformation(): HotelInformation {
    return this.reservation?.hotelInformation
  }

  get reservationId(): number {
    return this.reservation?.reservationId || null
  }

  get isSelfServe(): boolean {
    return !!this.reservation?.isSelfServe
  }

  get mobileTitle(): {
    pickup: string
    dropoff: string
  } {
    const firstAddress = this.trip?.stops?.[0]?.address
    const secondAddress = this.trip?.stops?.[1]?.address
    return getPickupDestinationCitiesFromAddresses(firstAddress, secondAddress)
  }

  get hasTripNotes(): boolean {
    const hasANote = !!this.trip?.tripNotes?.length
    const customerNote = this.trip?.tripNotes?.find((note) => {
      return note.noteType === CUSTOMER_NOTE_TYPE && note.active
    })
    const notEmptyNote = !!customerNote?.html || !!customerNote?.note
    return hasANote && notEmptyNote
  }

  customerNotesOpenChanged(): void {
    if (this.quoteConfirmation) {
      this.$nextTick(() => {
        this.setBoxHeight()
      })
    }
  }

  setBoxHeight(): void {
    const yPosition = (this.$refs[
      `timeline-wrapper-0`
    ] as any).getBoundingClientRect().top
    const footerHeight = document.querySelector('.confirm-footer').clientHeight
    const tripWrapperRef = this.$refs[`timeline-wrapper-0`] as any
    tripWrapperRef.style.height = `calc(100vh - ${yPosition + footerHeight}px)`
  }
}
